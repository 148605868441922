import { authContext } from "../auth/Adal";
import { UserRoles } from "ts-infra-common";
import config from "../../config.json";
import { HttpOperation } from "../services/HttpOperation";
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import { observable, runInAction } from "mobx";

class UserStore {
    @observable userRole: UserRoles = null;
    @observable isLoading: boolean = false;

    get username() {
        const user = authContext.getCachedUser();
        if (user) return user.userName;
    }

    initUserRole(refresh: boolean = false) {
        if (!this.userRole || refresh) {
            this.isLoading = true;
            try {
                const httpGetOp = HttpOperation.get<UserRoles>(config.adal.clientId, config.api.endpointUrl + "/api/Auth/GetUserRole");
                httpGetOp.wait().then((response) => {
                    if (httpGetOp.success) {
                        runInAction(() => {
                            this.userRole = httpGetOp.data;
                        });
                    } else {
                        AlertStore.add(AlertType.Error, `Failed to determine user's global role. Error: ${response ? response : "Unknown"}`);
                    }
                    runInAction(() => {
                        this.isLoading = false;
                    });
                }).catch((err) => {
                    AlertStore.add(AlertType.Error, `Failed to determine user's global role. Error: ${err}`);
                    runInAction(() => {

                        this.isLoading = false;
                    });
                });
            } catch (err) {
                AlertStore.add(AlertType.Error, `Failed to determine user's global role. Error: ${err}`);
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        }
    }
}

const store = new UserStore();
export default store;
