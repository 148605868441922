import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default class AppInsightsPageTracker {
    private inst: ApplicationInsights;
    private name: string;
    private tracking: boolean;
    constructor(inst: ApplicationInsights, name: string) {
        this.inst = inst;
        this.name = name;
        this.tracking = true;
    }

    /**
     * Stop page load tracking and emit telemetry, if still tracking. Safe to call if you don't know if it's tracking or not.
     *
     * @memberof AppInsightsPageTracker
     */
    stop = (url?: string) => {
        if (this.tracking) {
            this.inst.stopTrackPage(this.name);
            this.tracking = false;
        }
    }
}