import { IDataStore } from "@ts/ts-ux-querybuilder";
import { observable, action } from "mobx";

export class ObservableDataStore<T> implements IDataStore<T> {
    onDataChanged: (data: T) => void;
    setOnDataChanged(func: (data: T) => void) {
        this.onDataChanged = func;
    }
    @observable data: T;
    @action set(data: T) {
        this.data = data;
        if (this.onDataChanged) {
            this.onDataChanged(data);
        }
    }
}