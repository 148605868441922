import "core-js/stable";
import "regenerator-runtime/runtime";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { runWithAdal } from "react-adal";
import "bootstrap/dist/css/bootstrap.min.css";
import { authContext } from "./shared/auth/Adal";
import NotFoundPage from "./PageNotFound";
const TSNavbar = React.lazy(() => import('./shared/components/Navbar'));
import { Container } from "reactstrap";
const AttributesList = React.lazy(() => import('./AttributesList/AttributeList'));
const AttributeViewPage = React.lazy(() => import('./AttributeView/AttributeViewPage'));
const AttributeCreatePage = React.lazy(() => import('./AttributeCreate/AttributeCreatePage'));
const AudienceList = React.lazy(() => import('./AudienceList/AudienceList'));
const AudienceCreate = React.lazy(() => import('./AudienceCreate/AudienceCreate'));
const AudienceDetails = React.lazy(() => import('./AudienceDetails/AudienceDetails'));
const AdminTools = React.lazy(() => import('./AdminTools/AdminTools'));
const SubscriptionList = React.lazy(() => import('./SubscriptionList/SubscriptionList'));
import Typography from "@material-ui/core/Typography";

// Pre-import CSS for lazy loaded components
import "react-tabs/style/react-tabs.css";
import "./global.css";
import "./AdminTools/AdminTools.css";
import "./AudienceList/AudienceList.css";
import "./AttributeView/AttributeView.css";
import "./AudienceDetails/AudienceDetails.css";
import "./AttributesList/AttributesList.css";
import "./shared/components/Navbar/Navbar.css";
import './shared/components/AccessibleTable/Table.css';
import "./shared/components/AccessibleSelect/AccessibleSelect.css";

const mobx5Supported = typeof Proxy !== "undefined" && typeof Symbol !== "undefined";
let isFileSaverSupported = false;
try {
    isFileSaverSupported = !!new Blob();
} catch (e) { /* file-saver requires Blob */ }
runWithAdal(authContext, () => {
    if (mobx5Supported) {
        import("mobx").then((module) => {
            module.configure({
                enforceActions: "observed"
            });
        });
    }
    if (mobx5Supported && isFileSaverSupported) {
        ReactDOM.render(
            <React.Suspense fallback={<Typography>Loading...</Typography>}>
                <BrowserRouter>
                    <Container fluid>
                        <TSNavbar />
                        <main>
                            <Switch>
                                <Redirect from={"/fg/:functionalGroupId/audience/:audience"} to={"/audience/details/functionalgroup/:functionalGroupId/audience/:audience"} />
                                <Route path="/" exact component={() => <Redirect to="/audiences" />} />
                                <Route path="/null" component={() => <Redirect to="/audiences" />} />
                                <Route path="/index" component={() => <Redirect to="/audiences" />} /> {/* This one happens on Chrome? */}
                                <Route path="/attributes" component={AttributesList} />
                                <Route path="/attribute/view/:kind/:name" component={AttributeViewPage} />
                                <Route path="/attribute/create/:kind?/:name?" component={AttributeCreatePage} />
                                <Route path="/audiences/functionalgroup/:functionalGroupId?" component={AudienceList} />
                                <Route path="/audiences/user/:alias?" component={AudienceList} />
                                <Route path="/audiences" component={AudienceList} />
                                <Route path="/audience/create/" component={AudienceCreate} />
                                <Route path="/audience/details/functionalgroup/:functionalGroupId?/audience/:audience?/revision/:revision?" component={AudienceDetails} />
                                <Route path="/audience/details/functionalgroup/:functionalGroupId?/audience/:audience?" component={AudienceDetails} />
                                <Route path="/admin" component={AdminTools} />
                                <Route path="/subscriptions" component={SubscriptionList} />
                                <Route component={NotFoundPage} />
                            </Switch>
                        </main>
                    </Container>
                </BrowserRouter>
            </React.Suspense>,
            document.getElementById("root")
        );
    } else {
        ReactDOM.render(
            <BrowserRouter>
                <Container fluid>
                    <Typography variant="h4" style={{margin: '10px'}}>This browser is missing some features that this website requires. Why not try the new Microsoft Edge? <a href="https://support.microsoft.com/en-us/help/4501095/download-the-new-microsoft-edge-based-on-chromium">Click here for more information.</a></Typography>
                </Container>
            </BrowserRouter>,
            document.getElementById("root")
        );
    }
});