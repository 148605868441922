import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { HttpGet, HttpPostWithResponse, HttpOperation, HttpPost } from './HttpOperation';
import AlertStore from "../stores/AlertStore";
import { Alert, AlertType, IListGroupService, TSOperation } from "@ts/ts-ux-contracts";
import Service from "./service";
import { BulkIngestionParameters } from "ts-infra-common";

export class ListGroupService extends Service implements IListGroupService {
    @observable createBlobOp: HttpPostWithResponse<string>;
    @observable addOp: HttpPost;
    @observable removeOp: HttpPost;
    @observable getDeviceIdsOp: HttpGet<string[]>;

    get addRemoveOpSuccess() {
        return (!this.addOp || this.addOp.success) && (!this.removeOp || this.removeOp.success);
    }

    @computed
    get isLoading() {
        return this.getDeviceIdsOp && this.getDeviceIdsOp.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.getDeviceIdsOp.data;
    }

    @action
    async fetch(path: string, refresh: boolean = false) {
        if (!this.getDeviceIdsOp || refresh) {
            this.getDeviceIdsOp = HttpOperation.get<string[]>(config.adal.clientId, config.api.endpointUrl + path)
            await this.getDeviceIdsOp.wait();
            runInAction(() => {
                if (!this.getDeviceIdsOp.success) {
                    AlertStore.add(AlertType.Error, "Failed to get members of list based group.");
                }
            });
        }
    }

    @action
    post(path: string, postObj: any, getResponse: boolean = false): any {
        const op = getResponse
            ? HttpOperation.postWithResponse<string>(config.adal.clientId, config.api.endpointUrl + path, postObj)
            : HttpOperation.post(config.adal.clientId, config.api.endpointUrl + path, postObj);
        op.wait().then(() => {
            if (!op.success) {
                AlertStore.add(AlertType.Error, "Failed to update list based group.");
            }
        });
        return op;
    }

    createBlob(deviceIds: string, principalType: string): Promise<TSOperation<any>> {
        const formData = new FormData();
        formData.append('PrincipalType', principalType);
        formData.append('MembersFile', new Blob([deviceIds], { type: 'text/plain' }), 'File');
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.post(`/api/FileUpload/GroupMembersFile`, formData).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }
    getGroupDeviceIds(groupId: string): Promise<TSOperation<string[]>> {
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.get(`/api/Devices/GetListGroupMembers?groupId=${groupId}`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }
    updateIds(groupId: string, idsToAdd: string[], idsToRemove: string[], groupAppId: string): Promise<TSOperation<any>> {
        return new Promise((resolve, reject) => {
            const promises = [];
            if (idsToAdd && idsToAdd.length > 0) {
                let addSuccess = false;
                promises.push(ListGroupService.post(`/api/Devices/AddMultipleDevicesToGroup`, { GroupId: groupId, MemberValues: idsToAdd, GroupsAppId: !groupAppId ? null : groupAppId })
                    .then((response) => {
                        if (response.ok) {
                            addSuccess = true;
                            return response.json();
                        }

                        return response.text();
                    }).then((txt) => {
                        if (addSuccess) {
                            resolve({
                                success: true,
                                data: txt,
                                message: null
                            });
                        } else {
                            resolve({
                                success: false,
                                data: null,
                                message: txt
                            });
                        }
                    }).catch((err) => {
                        resolve({
                            success: false,
                            data: null,
                            message: err
                        });
                    }));
            }

            if (idsToRemove && idsToRemove.length > 0) {
                let removeSuccess = false;
                promises.push(ListGroupService.post(`/api/Devices/RemoveMultipleDevicesFromGroup`, { GroupId: groupId, MemberValues: idsToAdd, GroupsAppId: !groupAppId ? null : groupAppId })
                    .then((response) => {
                        if (response.ok) {
                            removeSuccess = true;
                            return response.json();
                        }

                        return response.text();
                    }).then((txt) => {
                        if (removeSuccess) {
                            resolve({
                                success: true,
                                data: txt,
                                message: null
                            });
                        } else {
                            resolve({
                                success: false,
                                data: null,
                                message: txt
                            });
                        }
                    }).catch((err) => {
                        resolve({
                            success: false,
                            data: null,
                            message: err
                        });
                    }));
            }

            if (promises.length > 0) {
                Promise.all(promises).then((vArr) => {
                    for (const item of vArr) {
                        if (!item.success) {
                            resolve(item);
                        }
                    }

                    resolve(vArr[0]);
                }).catch((err) => {
                    resolve({
                        success: false,
                        data: null,
                        message: err
                    });
                });
            }

            resolve({
                success: true,
                data: null,
                message: null
            });
        });
    }

    getIngestionStatus(appId: string, groupId: string): Promise<TSOperation<any>> {
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.get(`/api/GroupsLifecycle/GetIngestionStatus?appId=${appId}&groupId=${groupId}`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }

    getOperationTrackerStatus(appId: string, groupId: string): Promise<TSOperation<number>> {
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.get(`/api/GroupsLifecycle/GetOperationTrackerStatus?appId=${appId}&groupId=${groupId}&maxToCheck=${config.maxGroupsOperationsToCheck}`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }

    validateStream(uri: string): Promise<TSOperation<any>> {
        return new Promise((resolve, reject) => {
            let success = false;
            let globalResponse: Response;
            ListGroupService.post(`/api/GroupsLifecycle/ValidateForGroupsCosmosTxt?uri=${uri}`).then((response) => {
                globalResponse = response;
                if (response.ok) {
                    success = true;
                    return null;
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: true,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: Boolean(txt) ? `${globalResponse.status}: ${txt}` : `HTTP Status ${globalResponse.status}`
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }

    validateBulkIngestion(fgName: string, parameters: BulkIngestionParameters): Promise<TSOperation<boolean>> {
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.post(`/api/GroupsLifecycle/ValidateBulkIngestionByFG?functionalGroupName=${fgName}`, parameters).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.text();
                }
                
                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: true,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: false,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: false,
                    message: err
                });
            });
        });
    }
    getDataConnectionNames(): Promise<TSOperation<string[]>> {
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.get(`/api/GroupsLifecycle/GetOnboardedDataConnections`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }

    count(groupId: string, appId?: string): Promise<TSOperation<number>> {
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.get(`/api/GroupsService/Groups/${groupId}/Count${appId ? `?groupsAppId=${appId}` : null}`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }

    retryBulkIngestion(appId: string, groupId: string): Promise<TSOperation<boolean>> {
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.post(`/api/GroupsLifecycle/QueueForRetry?groupId=${groupId}${appId ? `&appId=${appId}` : null}`).then((response) => {
                if (response.ok) {
                    success = true;
                    return new Promise<any>((emptyResolve) => { emptyResolve(true); });
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: false,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: false,
                    message: err
                });
            });
        });
    }

    runOnDemandIngestion(fgName: string, audName: string): Promise<TSOperation<any>> {
        let globalResponse: Response;
        return new Promise((resolve, reject) => {
            let success = false;
            ListGroupService.post(`/api/GroupsLifecycle/FunctionalGroups/${fgName}/Audiences/${audName}/Ingestion`).then((response) => {
                globalResponse = response;
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: txt,
                        message: `HTTP ${globalResponse.status}: ${txt}`
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }
}

const service = new ListGroupService();
export default service;