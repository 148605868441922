// takes TimeStamp javascript date string and transforms it into a Date object
// javascript uses the unix time stamp
// unix time starts at midnight jan 1 1970 so however many milliseconds since this represents the date,
// but C# does not use same reference date
function getRevisionDateTimeString(revisionString: string) {
    let x = parseInt(revisionString, null);
    // because C# uses nanoseconds
    // converting nanoseconds to milliseconds
    x /= 10000;
    // Move from 1/1/0001 00:00:00 GMT to unix epoch start timestamp
    // Using a new Date calculation has issues, so just using a precalculated number instead
    x -= 62135596800000;
    let date = new Date(x);
    const now = new Date();
    // Sometimes the revision timestamp from the server is desynced from the client
    if (now.getTime() < date.getTime()) {
        date = new Date(now.getTime() - 100); // A little before now
    }
    return date.toLocaleString();
}

export { getRevisionDateTimeString };