import * as React from "react";
import { observer } from "mobx-react";
import { Button, Label, Modal } from "reactstrap";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { AudienceCriteriaType } from "ts-infra-common";
import Table from '../../components/AccessibleTable/Table';
import { AvForm,   AvField, AvInput } from 'availity-reactstrap-validation';
import FormGroup from "reactstrap/lib/FormGroup";
import { AttributeRequestsForAttributeState } from "./AttributeRequestsForAttributeState";
import { EventEmitter } from "events";
import AppInsightsService from '../../services/AppInsightsService';
import AppInsightsPageTracker from '../../services/AppInsightsPageTracker';
import AttributeRequestStore from "../../stores/AttributeRequestStore";
import { AttributeRequestForAttributeView } from "./AttributeRequestForAttributeView";
import { action, runInAction } from "mobx";
import { HttpPostWithResponse } from "../../services/HttpOperation";
import { AttributeRequestApproveResult, AttributeRequestRejection, AttributeRequestRejectResult } from "@ts/ts-ux-contracts";

interface AttributeRequestsForAttributeProps {
    attribute: AudienceCriteriaType;
    events: EventEmitter;
}

@observer
export class AttributeRequestsForAttribute extends React.Component<AttributeRequestsForAttributeProps, AttributeRequestsForAttributeState> {
    pageTracker: AppInsightsPageTracker;

    constructor(props) {
        super(props);
        this.state = new AttributeRequestsForAttributeState(props.attribute);
        props.events.on('newRequest', (results) => {
            for (const r of results) {
                this.state.addNewCollectionRequest(r.Request);
            }

            this.state.init(true);
        });
    }

    componentDidMount() {
        this.pageTracker = AppInsightsService.startTrackPage("AttributeRequestsForAttribute");
        this.state.init();
    }

    render() {
        const modalModel = {
            rejectionReason: this.state.reason,
            denyFutureRequests: this.state.denyFutureRequests
        };

        if (!this.state.isLoading && this.pageTracker) this.pageTracker.stop();

        return <div>
            <div>
                <Modal isOpen={this.state.isModalOpen}>
                    <div style={{ margin: '20px' }}>
                        <AvForm onSubmit={(event, errors, values) => {
                            if (!errors || errors.length === 0) {
                                this.state.setRejectionInfo(values);
                                this.state.setModalOpen(false, true);
                            }
                        }} model={modalModel}>

                            <AvField name="rejectionReason" label="Enter a reason for rejecting this request" id="reason"
                                value={this.state.reason || ''} errorMessage="Please enter a reason" onChange={(ev) => {
                                    if (ev.target) {
                                        this.state.setRejectionInfo({
                                            rejectionReason: ev.target.value
                                        });
                                    }
                                }} required />
                            <AvInput type="checkbox" name="denyFutureRequests" /> Deny future requests to add this attribute to this collection?
                                <FormGroup>
                                <Button className='ts-btn'>Submit</Button>
                                <Button className="ts-btn" onClick={(ev) => {
                                    this.state.setModalOpen(false, false);
                                }}>Cancel</Button>
                            </FormGroup>
                        </AvForm>
                    </div>
                </Modal>
            </div>
            <Label>Attribute Collection Requests</Label>
            {this.state.isLoading ? <LoadingIndicator text="Loading..." /> :
                <Table title="" name="Requests for attributes table"
                    columns={[
                        { label: 'ID', field: 'RequestId', type: 'string', sortOptions: { sortable: true } },
                        { label: 'Requestor', field: 'Requestor', type: 'string', sortOptions: { sortable: true } },
                        { label: 'Attribute Collection', field: 'AttributeCollection', type: 'string', sortOptions: { sortable: true } },
                        { label: 'Status', field: 'requestStatus', type: 'string', sortOptions: { sortable: true } },
                        { label: 'Action', field: 'Action', type: 'custom', renderDataFn: this.renderActionButtons },
                    ]}
                    data={this.state.actionableRequests ? this.state.actionableRequests.map(r => ({ ...r, ...r.request })) : []}
                />}
        </div>
    }

    private renderActionButtons = (cell, requestBad: AttributeRequestForAttributeView) => {
        const request: AttributeRequestForAttributeView = this.state.actionableRequests.filter(a => a.request.RequestId === requestBad.request.RequestId)[0];
        if (request.inProgress) {
            return <LoadingIndicator text="" />
        } else if (request.isActionable) {
            if (request.requestStatus === "Pending") {
                return <div><Button className='ts-btn' disabled={!request.isPending || request.inProgress} onClick={(ev) => {
                    this.approve(request);
                }}>Approve</Button> <Button className='ts-btn' disabled={!request.isPending || request.inProgress} onClick={(ev) => {
                    this.state.setModalCloseAction((isAccepted) => {
                        if (isAccepted) {
                            this.reject(request, this.state.reason, this.state.denyFutureRequests);
                        }
                    });
                    this.state.setModalOpen(true, false);
                }}>Reject</Button></div>;
            } else {
                this.props.events.emit("approveRejectDone");
                return <div>{request.requestStatus}</div>;
            }
        } else {
            return null;
        }
    }

    showNestedProp(propName: string, additionalFormat?: (arg0: any) => any) {
        return (cell, row) => {
            const v = cell[propName];
            if (additionalFormat) {
                return additionalFormat(v);
            }

            return v;
        };
    }

    @action approve(request: AttributeRequestForAttributeView) {
        request.inProgress = true;
        request.requestStatus = "Approving";
        AttributeRequestStore.approve(request.request.RequestId, [request.attribute], `Approve request ${request.request.RequestId}`)
            .then((httpOp: HttpPostWithResponse<AttributeRequestApproveResult[]>) => {
                runInAction(() => {
                    request.inProgress = false;
                    if (httpOp.success) {
                        request.isPending = false;
                        const approvedRequest = httpOp.data.filter(v => v.Request.RequestId === request.request.RequestId && v.Attribute.Name === request.attribute.Name
                            && v.Attribute.AttributePropertiesKind === request.attribute.AttributePropertiesKind);
                        if (approvedRequest && approvedRequest.length > 0) {
                            request.requestStatus = "Approved. Next OneSettings push: " + approvedRequest[0].NextOneSettingsPushFriendlyTimestamp;
                            request.isPending = false;
                        } else {
                            // Couldn't approve, so it was probably rejected or approved by someone else. A force refresh or the background refresh will fix the status.
                            request.requestStatus = "Out of sync. Refresh page.";
                        }
                    } else {
                        request.requestStatus = "Failed to approve";
                    }
                });
            });
    }

    @action reject(request: AttributeRequestForAttributeView, reason: string, denyFutureRequests: boolean) {
        const rejection = new AttributeRequestRejection();
        rejection.DenyFutureRequests = denyFutureRequests;
        rejection.Reason = reason;
        rejection.SpecificAttribute = request.attribute;
        request.inProgress = true;
        request.requestStatus = "Rejecting";
        AttributeRequestStore.reject(request.request.RequestId, [rejection], `Reject request ${request.request.RequestId}`)
            .then((httpOp: HttpPostWithResponse<AttributeRequestRejectResult[]>) => {
                runInAction(() => {
                    request.inProgress = false;
                    if (httpOp.success) {
                        request.isPending = false;
                        const rejectedRequest = httpOp.data.filter(v => v.Request.RequestId === request.request.RequestId && v.Attribute.Name === request.attribute.Name
                            && v.Attribute.AttributePropertiesKind === request.attribute.AttributePropertiesKind);
                        if (rejectedRequest && rejectedRequest.length > 0) {
                            request.requestStatus = "Rejected";
                            request.isPending = false;
                        } else {
                            // Couldn't approve, so it was probably rejected or approved by someone else. A force refresh or the background refresh will fix the status.
                            request.requestStatus = "Out of sync. Refresh page.";
                        }
                    } else {
                        request.requestStatus = "Failed to reject";
                    }
                });
            });
    }
}

export default AppInsightsService.trackComponent(AttributeRequestsForAttribute);