/*
 * The native <select> control is limited in its functionality. Other libraries that contain custom
 * select control have lots of options for functionality, extensibilty and customization, but fail
 * to deliver on the accessibility. See https://www.24a11y.com/2019/select-your-poison/ for an history and
 * analysis of this issue. This control is based off of the guidelines in that article and follows the
 * proposed ARIA 1.2 combobox rules mentioned.
 * */

import * as React from 'react';
type Option = { label: string, value: string };

/* Provide filter function options for the select control */

// Don't filter
export function noSearch(allOptions: Option[], searchString: string): Option[] {
    return allOptions;
}

// Find options that start with the search input
export function startsWithSearch(allOptions: Option[], searchString: string): Option[] {
    return allOptions.filter(o => o.label.toLowerCase().startsWith(searchString.toLowerCase()));
}

// Find options that contain the search input
export function containsSearch(allOptions: Option[], searchString: string): Option[] {
    return allOptions.filter(o => o.label.toLowerCase().indexOf(searchString.toLowerCase()) >= 0);
}

// find options that contain the search input, allowing for missing characters in the search term
// https://stackoverflow.com/questions/9206013/javascript-fuzzy-search
export function fuzzySearch(allOptions: Option[], searchString: string) {
    const s = searchString.toLowerCase();
    return allOptions.filter(option => {
        const o = option.label.toLowerCase();
        // tslint:disable-next-line
        for (let i = 0, n = -1, l; l = s[i++];) if (!~(n = o.indexOf(l, n + 1))) return false;
            return true;
    });
}


/* Define props and state for select control */

export type AccessibleSelectPropsBase = {
    id: string;
    labelId: string;
    options: Option[];
    filterFn?: (allOptions: Option[], searchString: string) => Option[];
    boxPosition?: 'top' | 'bottom';
    width: string;
    required?: boolean;
    clearable?: boolean;
}

type AccessibleSelectProps = AccessibleSelectPropsBase & {
    value: string;
    onChange: (value: string) => void;
    expandOnClick?: boolean;
}

type AccessibleSelectState = {
    activeIndex: number;
    resultsCount: number;
    isExpanded: boolean;
    searchModified: boolean;
    availableOptions: Option[];
}


/*
 * This select control follows proposed ARIA 1.2 accessibility standards for combobox controls. These
 * standards define the structure and use of aria tags. Beyond basic select functionality, it supports:
 *  - filtering the options based on user input using a specified filter function
 *  - clearing the value
 * It does NOT yet support:
 *  - setting a custom value
 *  - complex options lists (like groupings, grid, tree, etc)
 *  - multi-selection (this is handled using AccessibleMultiSelect which uses this control internally)
 */
export default class AccessibleSelect extends React.Component<AccessibleSelectProps, AccessibleSelectState> {
    private input: React.RefObject<HTMLInputElement>;
    private arrowButton: React.RefObject<HTMLButtonElement>;
    private clearButton: React.RefObject<HTMLButtonElement>;
    private listbox: React.RefObject<HTMLUListElement>;

    constructor(props) {
        super(props);

        this.input = React.createRef();
        this.arrowButton = React.createRef();
        this.clearButton = React.createRef();
        this.listbox = React.createRef();

        this.state = { activeIndex: -1, resultsCount: 0, isExpanded: false, searchModified: false, availableOptions: [] };
    }

    componentDidMount() {
        // Handle closing the list when the user clicks outside of the control
        document.body.addEventListener('click', this.checkHide);
        this.resetInput();
    }

    componentWillUnmount() {
        // Clean up
        document.body.removeEventListener('click', this.checkHide);
    }

    componentDidUpdate(oldProps: AccessibleSelectProps) {
        // Update the control when the value is updated externally
        if (oldProps.value !== this.props.value) {
            this.resetInput();
            this.hideListbox();
        }
    }

    render() {
        return <span className="combobox">
            <span className={`combobox-input-group ${(this.props.clearable && this.props.value) ? 'combobox-clearable' : ''}`}
                style={{ width: this.props.width }}>
                <input type="text"
                    role="combobox"
                    aria-labelledby={this.props.labelId}
                    aria-autocomplete="list"
                    aria-expanded={this.state.isExpanded}
                    aria-controls={`${this.props.id}-listbox`}
                    aria-activedescendant={this.state.activeIndex >= 0 ? `${this.props.id}-opt${this.state.activeIndex}` : ''}
                    aria-required={this.props.required}
                    ref={this.input}
                    onKeyUp={this.checkKey}
                    onKeyDown={this.setActiveItem}
                    onFocus={this.checkShow}
                    onClick={this.inputClick}
                    onBlur={this.resetInput} />
                {(this.props.clearable && this.props.value)
                    ? <button
                        className="combobox-clear-button"
                        id={`${this.props.id}-clear-btn`}
                        aria-labelledby={`${this.props.id}-clear-btn ${this.props.labelId}`}
                        ref={this.clearButton}
                        onClick={this.clearInput}>
                        <span className="clear-x" role="img" aria-label="clear value">&#x2716;</span>
                    </button>
                    : null}
                <button tabIndex={-1}
                    aria-label={`${this.state.isExpanded ? 'Hide' : 'Show'} options`}
                    className="combobox-arrow-button"
                    ref={this.arrowButton}
                    onClick={this.arrowClick}>
                    <span className="arrow">
                        <svg width="18"
                            height="16"
                            aria-hidden="true"
                            focusable="false">
                            <polygon className="arrow" points="3,7 6,7 9,11 12,7 15,7 9,14"></polygon>
                        </svg>
                    </span>
                </button>
            </span>
            <ul role="listbox"
                aria-labelledby={this.props.labelId}
                className={`combobox-listbox ${this.state.isExpanded ? '' : 'hidden'} ${(this.props.boxPosition && this.props.boxPosition === 'top') ? 'box-on-top' : ''}`}
                ref={this.listbox}
                id={`${this.props.id}-listbox`}
                style={{ minWidth: this.props.width, width: 'min-content' }}>
                {this.state.availableOptions.map((o, i) =>
                    <li key={o.value}
                        role="option"
                        aria-selected={i === this.state.activeIndex}
                        className={`combobox-result ${i === this.state.activeIndex ? 'focused' : ''}`}
                        id={`${this.props.id}-opt${i}`}
                        onClick={this.clickItem}
                        onMouseEnter={this.enterItem}>
                        {o.label}
                    </li>)
                }
            </ul>
        </span>;
    }


    /* Event handlers */

    // Arrow button click handler
    arrowClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        // The arrow is not focusable... set the focus on the input
        this.input.current.focus();

        if (this.state.isExpanded) {
            // Collapse
            this.resetInput();
            this.hideListbox();
        }
        else {
            // Expand
            await this.updateResults();
            this.goToFirstMatch();
        }
    }

    // Clear button click handler
    clearInput = (e: React.MouseEvent<HTMLButtonElement>) => {
        // The clear button will go away... set the focus on the input
        this.input.current.focus();

        // Set the value to null to clear
        this.props.onChange(null);
    }

    // Input click handler
    inputClick = async (e: React.MouseEvent) => {
        // If specified, expand the control immediately when clicked anywhere in the input.
        if (this.props.expandOnClick) {
            await this.updateResults();
            this.goToFirstMatch();
        }
    }

    // KeyUp handler
    checkKey = (e: React.KeyboardEvent) => {
        const key = e.key;

        switch (key) {
            case 'ArrowUp':
            case 'ArrowDown':
            case 'Escape':
            case 'Enter':
                // These are used in controlling the combobox dropdown and selection behavior, so don't let the textbox's default behavior happen.
                e.preventDefault();
                return;
            default:
                // Display the filtered list
                this.updateResults();
        }
    }

    // KeyDown handler
    setActiveItem = async (e: React.KeyboardEvent) => {
        const key = e.key;
        let activeIndex = this.state.activeIndex;

        if (key === 'Escape') {
            // Collapse
            this.hideListbox();
            this.resetInput();
            return;
        }

        let activeItem;

        switch (key) {
            case 'ArrowUp':
                // Cycle up the list
                if (activeIndex <= 0) {
                    activeIndex = this.state.resultsCount - 1;
                }
                else {
                    activeIndex--;
                }
                break;
            case 'ArrowDown':
                // If it wasn't expanded yet, expand and go to the first matching option
                if (!this.state.isExpanded) {
                     await this.updateResults();
                     activeIndex = this.getFirstMatchIndex();
                     break;
                }

                // Cycle down the list
                if (activeIndex === -1 || activeIndex >= this.state.resultsCount - 1) {
                    activeIndex = 0;
                }
                else {
                    activeIndex++;
                }
                break;
            case 'Enter':
                // Select the active option
                if (activeIndex >= 0) {
                    activeItem = this.getItemAt(activeIndex);
                    this.selectItem(activeItem);
                }
                return;
            case 'Tab':
                // On tab, select the active option if there is one, then collapse the list before changing control focus
                this.checkSelection();
                this.hideListbox();
                return;
            default:
                this.setState({ searchModified: true });
                return;
        }

        e.preventDefault();
        if (this.state.resultsCount <= 0) activeIndex = -1;
        this.setState({ activeIndex });

        // Keep the active item visible
        if (activeIndex >= 0) {
            activeItem = this.getItemAt(activeIndex);
            this.updateScroll(activeItem);
        }
    }

    // Focus handler
    checkShow = () => {
        // Select the search text so the user can easily start a new search
        this.input.current.setSelectionRange(0, this.input.current.value.length);
    }

    // Body click handler
    checkHide = (e: Event): any => {
        if (!this.state.isExpanded ||
            e.target === this.input.current ||
            this.arrowButton.current.contains(e.target as Node) ||
            (this.listbox.current && this.listbox.current.contains(e.target as Node))) {
            return;
        }

        // Close the list if clicking anywhere outside of the control
        this.resetInput();
        this.hideListbox();
    }

    // Option click handler
    clickItem = (e: React.MouseEvent) => {
        // Select the clicked option
        this.selectItem(e.currentTarget);
    }

    // Option mouse enter handler
    enterItem = (e: React.MouseEvent) => {
        // Set the hovered option as the active option
        const index = this.state.availableOptions.findIndex(o => o.label === e.currentTarget.innerHTML);
        this.setState({ activeIndex: index });
    }


    /* Helper functions */

    // Helper to ensure the selected option is in view
    updateScroll = (selectedOption) => {
        if (selectedOption && this.listbox.current.scrollHeight > this.listbox.current.clientHeight) {
            const scrollBottom = this.listbox.current.clientHeight + this.listbox.current.scrollTop;
            const elementBottom = selectedOption.offsetTop + selectedOption.offsetHeight;
            if (elementBottom > scrollBottom) {
                this.listbox.current.scrollTop = elementBottom - this.listbox.current.clientHeight;
            }
            else if (selectedOption.offsetTop < this.listbox.current.scrollTop) {
                this.listbox.current.scrollTop = selectedOption.offsetTop;
            }
        }
    };

    // Helper to display the filtered results
    updateResults = async () => {
        const searchString = this.input.current.value;

        const results = !this.state.searchModified
            ? this.props.options // If the search term has not been modified, show all options
            : (searchString // Otherwise...
                ? (this.props.filterFn || containsSearch)(this.props.options, searchString) // If a search term is present run the search
                : this.props.options); // If a search term is not present, all options match

        await this.setState({ availableOptions: results, resultsCount: results.length, isExpanded: true, activeIndex: -1 });
    }

    // Helper to set the input value back to the last chosen option
    resetInput = () => {
        this.input.current.value = this.props.value;
        this.setState({ searchModified: false });
    }

    // Helper to get an option element based on the index
    getItemAt = (index: number) => {
        return document.getElementById(`${this.props.id}-opt${index}`);
    }

    // Helper to choose an option
    selectItem = (item) => {
        if (item) {
            this.input.current.value = item.innerText;
            this.props.onChange(item.innerText);
            this.hideListbox();
            this.setState({ searchModified: false });
        }
    }

    // Helper to collapse the list
    hideListbox = () => {
        this.setState({ isExpanded: false, activeIndex: -1, resultsCount: 0 });
    }

    // Helper to choose the active option if there is one
    checkSelection = () => {
        if (this.state.activeIndex < 0) {
            this.resetInput();
            return;
        }
        const activeItem = this.getItemAt(this.state.activeIndex);
        this.selectItem(activeItem);
    }

    // Helper to get the index of the first matching item
    getFirstMatchIndex = () => {
        if (this.state.resultsCount === 0) return -1;
        const searchTerm = this.input.current.value;
        if (!searchTerm) return 0;
        const matches = (this.props.filterFn || containsSearch)(this.props.options, searchTerm);
        return matches.length > 0 ? this.state.availableOptions.findIndex(o => o.value === matches[0].value) : 0;
    }

    // Helper to activate the first matching item
    goToFirstMatch = () => {
        const index = this.getFirstMatchIndex();
        const item = this.getItemAt(index);
        this.setState({ activeIndex: index });
        // Keep the active item visible
        this.updateScroll(item);
    }
}
