import { authContext } from "../auth/Adal";
import { UserRoles } from "ts-infra-common";
import config from "../../config.json";
import { HttpOperation } from "./HttpOperation";
import AlertStore from "../stores/AlertStore";
import { Alert, AlertType, IUserService, TSOperation } from "@ts/ts-ux-contracts";
import { observable, runInAction } from "mobx";
import Service from "./service";

class UserService extends Service implements IUserService {
    @observable userRole: UserRoles = null;
    @observable isLoading: boolean = false;

    get username() {
        const user = authContext.getCachedUser();
        if (user) return user.userName;
        return null;
    }

    initUserRole(refresh: boolean): Promise<TSOperation<UserRoles>> {
        return new Promise((resolve, reject) => {
            let success = false;
            UserService.get(`/api/Auth/GetUserRole`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (success) {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                } else {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                }
            }).catch((err) => {
                resolve({
                    success: false,
                    data: null,
                    message: err
                });
            });
        });
    }

    /*initUserRole(refresh: boolean = false) {
        if (!this.userRole || refresh) {
            this.isLoading = true;
            try {
                const httpGetOp = HttpOperation.get<UserRoles>(config.adal.clientId, config.api.endpointUrl + "/api/Auth/GetUserRole");
                httpGetOp.wait().then((response) => {
                    if (httpGetOp.success) {
                        runInAction(() => {
                            this.userRole = httpGetOp.data;
                        });
                    } else {
                        AlertStore.add(AlertType.Error, `Failed to determine user's global role. Error: ${response ? response : "Unknown"}`);
                    }
                    runInAction(() => {
                        this.isLoading = false;
                    });
                }).catch((err) => {
                    AlertStore.add(AlertType.Error, `Failed to determine user's global role. Error: ${err}`);
                    runInAction(() => {

                        this.isLoading = false;
                    });
                });
            } catch (err) {
                AlertStore.add(AlertType.Error, `Failed to determine user's global role. Error: ${err}`);
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        }
    }*/
}

const store = new UserService();
export default store;
