import * as React from "react";
import { Row, Col, FormGroup } from "reactstrap";
import { observer } from "mobx-react";
import { AvField } from 'availity-reactstrap-validation';
import AttributeFormDetailsProps from "../AttributeFormDetailsProps";
import AppInsightsService from '../../../services/AppInsightsService';

@observer
export class FileAttributeDetails extends React.Component<AttributeFormDetailsProps> {
    constructor(props) {
        super(props);
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target) {
            this.props.onChange(e.target.name, e.target.value);
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="Path" label="Path" aria-label="Path" id="Path" type="text" errorMessage="Required" value={this.props.data.Path} onChange={this.onChange} disabled={this.props.disabled} validate={{
                                required: {value: true},
                            }} helpMessage="The path to the file. It may contain system environment variables like %windir%." />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <AvField type="select" value={this.props.data.IfExists} name="IfExists" label="IfExists (optional)" aria-label="IfExists (optional)" onChange={this.onChange} disabled={this.props.disabled}
                        helpMessage="default false: If true, attribute is just 1 or 0 (0 is not sent up in RS3+) if the file exists or not.">
                            <option value=""></option>
                            <option value="false">false</option>
                            <option value="true">true</option>
                        </AvField>
                    </Col>
                    <Col>
                        <AvField name="FolderGuid" value={this.props.data.FolderGuid} label="FolderGuid (optional)" aria-label="FolderGuid (optional)" type="text" onChange={this.onChange} disabled={this.props.disabled}
                        helpMessage={<div>If present, the path is appended to the result of SHGetKnownFolderPath() with the GUID specified here. <a target="_blank" href="https://docs.microsoft.com/en-us/windows/desktop/shell/knownfolderid">See here for details on values.</a></div>}
                        validate={!this.props.disabled ? {
                            pattern: { value: '^\\{([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\\}$', errorMessage: "Must be a valid GUID surrounded by curly braces." }
                        } : null} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(FileAttributeDetails);