import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { AttributeCollection, AttributeKind } from "ts-infra-common";
import { HttpGet, HttpPatch, HttpPostWithResponse } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import { AttributeToCollectionId } from "@ts/ts-ux-contracts";

class AttributeCollectionStore {
    @observable data: AttributeCollection[];
    @observable http: HttpGet<AttributeCollection[]>;
    @observable httpPatch: HttpPatch;
    @observable httpPost: HttpPostWithResponse<AttributeCollection>;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @computed
    get isCreateInProgress(){
        return this.httpPost && this.httpPost.inProgress;
    }

    @computed
    get isUpdateInProgress(){
        return this.httpPatch && this.httpPatch.inProgress;
    }

    @action
    async fetch(path: string, refresh:boolean=false) {
        if (!this.http || refresh) {
            this.http = HttpOperation.get<AttributeCollection[]>(config.adal.clientId, config.api.endpointUrl + path);
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                   this.data = this.http.data;
                }
                else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, "Failed to fetch Attribute Collection data.");
                }
            });
        }
    }

    @action
    async fetchAllAttributeCollections(refresh: boolean) {
        await this.fetch(`/api/AttributeCollections/`, refresh);
    }

    @action
    async addAttributeCollection(attrCollectionName: string, attrOwners: string[]) {
        const postObj : AttributeCollection = {
            id: null,
            DisplayName: attrCollectionName,
            InheritedAppCollections: null,
            Attributes: null,
            Owners: attrOwners,
            Changer: null,
            Revision: null,
            Token: null,
            Name: attrCollectionName,
            LowerName: attrCollectionName.toLowerCase()
        };
        this.httpPost = HttpOperation.postWithResponse<AttributeCollection>(config.adal.clientId, config.api.endpointUrl + `/api/AttributeCollections/Add`, postObj);
        await this.httpPost.wait();
        runInAction(() => {
            if (this.httpPost.success) {
                AlertStore.add(AlertType.Success, "Successfully created new attribute collection.");
                this.data.push(this.httpPost.data);
            } else {
                AlertStore.add(AlertType.Error, this.httpPost.errorMessage);
            }
        });
        return this.httpPost.wait();
    }

    @action
    async addAttributeToCollection(attributeCollectionName: string, attrName: string, kind: AttributeKind) {
        const httpOp = HttpOperation.postWithResponse<any>(config.adal.clientId,
            config.api.endpointUrl + `/api/AttributeCollections/${attributeCollectionName}/Attributes/Add/${attrName}/${kind}`, {});
        await httpOp.wait();
        runInAction(() => {
            if (!httpOp.success) {
                AlertStore.add(AlertType.Error, `Failed to add ${attrName} to collection. ${httpOp.errorMessage}`);
            }
        });
        return httpOp.data;
    }

    @action
    async bulkAddAttributesToCollection(attributeCollectionName: string, attributes: AttributeToCollectionId[]) {
        const httpOp = HttpOperation.postWithResponse<any>(config.adal.clientId,
            config.api.endpointUrl + `/api/AttributeCollections/${attributeCollectionName}/Attributes/BulkAdd`, attributes);
        await httpOp.wait();
        runInAction(() => {
            if (!httpOp.success) {
                AlertStore.add(AlertType.Error, `Failed to add attributes to collection. ${httpOp.errorMessage}`);
            }
        });
        return httpOp.data;
    }
}

const store = new AttributeCollectionStore();
export default store;