import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { FunctionalGroup } from "ts-infra-common";
import { HttpGet } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore, { AlertStoreV2 } from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class GroupsServiceStore {
    @observable data: any;
    @observable http: HttpGet<any>;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    async fetch(path: string, refresh:boolean=false, alertStore: AlertStoreV2=null) {
        if (!alertStore) {
            alertStore = AlertStore;
        }
        if (!this.http || refresh) {
            this.http = HttpOperation.get<any>(config.adal.clientId, config.api.endpointUrl + path)
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                   this.data = this.http.data
                }
                else {
                    this.data = null;
                    alertStore.add(AlertType.Error, "GroupsServiceStore failed to fetch api data. " + this.http.errorMessage);
                }
            });
        }
    }

    @action
    async fetchGroupServiceId(groupId: string, groupsAppId: string, refresh: boolean, alertStore: AlertStoreV2 = null) {
        if (!groupsAppId) {
            await this.fetch(`/api/GroupsService/Groups/${groupId}`, refresh, alertStore);
        } else {
            await this.fetch(`/api/GroupsService/Groups/${groupId}?groupsAppId=${groupsAppId}`, refresh, alertStore);
        }
    }
}

const store = new GroupsServiceStore();
export default store;
