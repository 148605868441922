import * as React from "react";
import AppInsightsService from '../../services/AppInsightsService';
import CircularProgress from "@material-ui/core/CircularProgress";

interface LoadingIndicatorProps {
    text: string;
}

export class LoadingIndicator extends React.Component<LoadingIndicatorProps> {
    render() {
        return (
            <>
                <h4>{this.props.text}</h4>
                <CircularProgress />
            </>
        );
    }
}

export default AppInsightsService.trackComponent(LoadingIndicator);