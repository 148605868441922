import { observable, action } from 'mobx';

export type PaginationOptions = { paginateTable?: boolean, rowsPerPageChoices?: number[], initialRowsPerPage?: number };

export class PaginationState {
    @observable currentRowsPerPage: number = 10;
    @observable currentPage: number = 0;

    @action setCurrentRowsPerPage = (val: number) => {
        this.currentPage = 0;
        this.currentRowsPerPage = val;
    }

    @action setCurrentPage(val: number) {
        this.currentPage = val;
    }
}