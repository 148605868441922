import React from "react";
import { observer } from "mobx-react";
import { Config, ConjunctionOption, TypedMap } from "react-awesome-query-builder";
import FormControl from "@material-ui/core/FormControl";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

@observer
export class CustomConjunctionsComponent extends React.Component<{
    id: string;
    key?: any;
    path: string;
    readonly?: boolean;
    disabled?: boolean;
    selectedConjunction?: string;
    setConjunction(conj: string): void;
    conjunctionOptions?: TypedMap<ConjunctionOption>;
    config?: Config;
    not: boolean;
    setNot(not: boolean): void;
}, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return <FormControl>
            <ButtonGroup
                disableElevation
                variant="contained"
                size="small"
                disabled={this.props.readonly}
            >
                <Button
                    key={this.props.id}
                    id={this.props.id + "__not"}
                    color={this.props.not ? "secondary" : "default"}
                    onClick={(ev) => { this.props.setNot(!this.props.not) }}
                    disabled={this.props.readonly}
                >NOT</Button>
                {Object.keys(this.props.conjunctionOptions).map(key => {
                    const { id, label, checked } = this.props.conjunctionOptions[key];
                    const postfix = (this.props.setConjunction as any).isDummyFn ? "__dummy" : "";
                    return (
                        <Button
                            key={id + postfix}
                            id={id + postfix}
                            color={checked ? "primary" : "default"}
                            value={key}
                            onClick={(ev) => {
                                this.props.setConjunction(key);
                            }}
                            disabled={!checked && (this.props.readonly || this.props.disabled)}
                        >
                            {label}
                        </Button>
                    );
                })}
            </ButtonGroup>
        </FormControl>;
    }
}