import { observable, action } from "mobx";

class AppStore {
    @observable pageTitle: string;

    constructor() {
        this.pageTitle = "Targeting Service";
    }

    @action
    setPageTitle(title: string) {
        this.pageTitle = title;
    }
}

const store = new AppStore();
export default store;
