import { observable, action, runInAction, observe } from "mobx";
import AttributeRequestStore from "../../stores/AttributeRequestStore";
import { AttributeRequest, AudienceCriteriaType } from "ts-infra-common";
import { AttributeRequestResultBase } from "@ts/ts-ux-contracts";
import { AttributeRequestForAttributeView } from "./AttributeRequestForAttributeView";

export class AttributeRequestsForAttributeState {
    @observable isLoading: boolean;
    @observable actionableRequests: AttributeRequestForAttributeView[];
    @observable isModalOpen: boolean;
    onModalClose: any;
    reason: string;
    denyFutureRequests: boolean;
    @observable attribute: AudienceCriteriaType;

    constructor(attribute: AudienceCriteriaType) {
        this.attribute = attribute;
        this.isLoading = false;
        this.isModalOpen = false;
    }

    @action init(refresh: boolean = false) {
        this.isLoading = true;
        AttributeRequestStore.getByUser("Get attribute requests by user", refresh).then(() => {
            runInAction(() => {
                if (AttributeRequestStore.fetchByUserOp.success) {
                    this.updateActionableRequests();
                }

                this.isLoading = false;
            });
        });
    }

    @action updateActionableRequests() {
        this.actionableRequests = [];
        if (this.attribute.AddToCollectionRequests) {
            this.attribute.AddToCollectionRequests.forEach((r) => {
                const matchingRequest = AttributeRequestStore.fetchByUserOp.data.find((v) => {
                    return v.Attribute.NameLower === this.attribute.Name.toLowerCase() && v.Attribute.AttributePropertiesKind === this.attribute.AttributePropertiesKind &&
                        v.Request.RequestId === r.RequestId;
                });
                if (matchingRequest) {
                    this.actionableRequests.push(new AttributeRequestForAttributeView(r, this.attribute, true));
                } else {
                    this.actionableRequests.push(new AttributeRequestForAttributeView(r, this.attribute, false));
                }
            });

            this.actionableRequests = this.actionableRequests.sort((a, b) => {
                return a.isActionable ? -1 : b.isActionable ? 1 : 0;
            });
        }
    }

    @action setModalOpen(isOpen: boolean, isAccepted?: boolean) {
        this.isModalOpen = isOpen;
        if (!isOpen && this.onModalClose) {
            this.onModalClose(isAccepted || false);
        }
    }

    @action setModalCloseAction(modalAction: (arg0: boolean) => void) {
        this.onModalClose = modalAction;
    }

    @action setRejectionInfo(info: any) {
        this.reason = info.rejectionReason;
        this.denyFutureRequests = info.denyFutureRequests;
    }

    @action addNewCollectionRequest(request: any) {
        if (!this.attribute.AddToCollectionRequests) {
            this.attribute.AddToCollectionRequests = [];
        }

        this.attribute.AddToCollectionRequests.push(request);
    }
}