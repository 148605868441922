import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";

export class CustomMaterialButton extends React.Component<{ type, label, onClick, config?}, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.type === 'delGroup' || this.props.type === 'delRule') {
            return <Button size="medium" onClick={this.props.onClick} color="secondary" startIcon={<DeleteIcon />} aria-label={this.props.label}></Button>
        } else if (this.props.type === 'addRule' || this.props.type === 'addRuleGroup') {
            return <Button size="medium" onClick={this.props.onClick} color="primary" startIcon={<AddIcon />} aria-label={this.props.label}>Criteria</Button>
        } else if (this.props.type === 'addGroup') {
            return <Button size="medium" onClick={this.props.onClick} color="primary" startIcon={<AddCircleIcon />} aria-label={this.props.label}>Group</Button>
        } else {
            return <Typography>Failed to render query builder button of type: {this.props.type}</Typography>;
        }
    }
}