import * as React from "react";
// Dropdown, DropdownToggle, DropdownItem, DropdownMenu
import { DropdownToggle, DropdownItem, DropdownMenu, Alert, Nav, NavItem, NavLink, NavbarBrand, UncontrolledDropdown } from "reactstrap";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import "../../../global.css";
import "./Navbar.css";
import config from "../../../config.json";
import UserStore from "../../stores/UserStore";
import AppStore from "../../stores/AppStore";
import AlertStore from "../../stores/AlertStore";
import { AlertType } from "@ts/ts-ux-contracts";
import AppInsightsService from "../../services/AppInsightsService";
import { Helmet } from 'react-helmet';
import FeedbackIcon from "@material-ui/icons/Feedback";
import { Button, Card, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Popover } from "@material-ui/core";
import { Check, ArrowDropDown } from "@material-ui/icons";

@observer
export class TSNavbar extends React.Component {
    @observable bannerVisible = true;
    @observable attributesMenuOpen = false;
    @observable attributesAnchor: null | HTMLElement = null;
    @observable linksMenuOpen = false;
    @observable linksAnchor: null | HTMLElement = null;
    constructor(props) {
        super(props);
        this.bannerVisible = new Date().getTime() < new Date(2020, 4, 1).getTime() && localStorage.getItem("ts-website-banner-show") !== "false";
    }

    @action
    setBannerVisible(visible: boolean) {
        this.bannerVisible = visible;
        localStorage.setItem("ts-website-banner-show", this.bannerVisible.toString());
    }

    @action
    setAttributesAnchor(attributesAnchor: null | HTMLElement) {
        this.attributesAnchor = attributesAnchor;
    }

    @action
    setAttributesMenuOpen(attributesMenuOpen: boolean) {
        this.attributesMenuOpen = attributesMenuOpen;
    }

    @action
    setLinksAnchor(linksAnchor: null | HTMLElement) {
        this.linksAnchor = linksAnchor;
    }

    @action
    setLinksMenuOpen(linksMenuOpen: boolean) {
        this.linksMenuOpen = linksMenuOpen;
    }

    getAlertColor(type: AlertType) {
        switch (type) {
            case AlertType.Error: return "danger";
            case AlertType.Info: return "info";
            case AlertType.Success: return "success";
            case AlertType.Warning: return "warning";

            default: return "primary";
        }
    }

    renderAlerts() {
        return (
            <>
                {AlertStore.alerts.map((alert, i) => <Alert key={`alert-${i}`} style={{ width: "100%" }} toggle={() => AlertStore.clear(alert)} color={this.getAlertColor(alert.type)}>{AlertType[alert.type].toUpperCase()}: {alert.message}</Alert>)}
            </>
        );
    }

    render() {
        return (
            <div style={{ marginBottom: "30px" }}>
                {this.bannerVisible ? <div tabIndex={1} style={{ background: "yellow" }}>
                    Looking for the old experience? Consider providing feedback using the feedback button on the right side of the navigation bar. Click <a href={`${config.v1}`} onClick={() => {
                        AppInsightsService.trackEvent("BackToOldWebsiteClicked", {
                            user: UserStore.username
                        });
                    }}>here</a> to go back to the old website. <a href="#" onClick={() => {
                        this.setBannerVisible(false);
                    }}>Dismiss</a>
                </div> : ""}
                <Helmet>
                    <title>{`Targeting Service - ${AppStore.pageTitle}`}</title>
                </Helmet>

                <div role="banner">
                    <Nav tabs role="navigation">
                        <NavbarBrand href="/" id="ts-logo">TS</NavbarBrand>
                        <NavbarBrand className="page-title">{AppStore.pageTitle}</NavbarBrand>
                        <div style={{ display: 'flex', marginBottom: '2px' }}>
                            <Link href="/audiences" style={{ color: "blue", marginLeft: "10px", marginRight: "10px", alignSelf: "flex-end" }} variant="subtitle1">
                                Audiences
                            </Link>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '2px' }}>
                            <Link id="attributes-menu-button" component="button" style={{ color: "blue", marginLeft: "10px", marginRight: "10px", alignSelf: "flex-end" }} variant="subtitle1" onClick={(e) => {
                                this.setAttributesAnchor(e.currentTarget);
                                this.setAttributesMenuOpen(true);
                            }} aria-controls={this.attributesMenuOpen ? "attributes-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={this.attributesMenuOpen ? 'true' : undefined}>Attributes <ArrowDropDown />
                            </Link>
                        </div>
                        <Popover
                            id="attributes-menu"
                            open={this.attributesMenuOpen}
                            anchorEl={this.attributesAnchor}
                            onClose={() => {
                                this.setAttributesMenuOpen(false);
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            disableAutoFocus={false}
                        >
                            <MenuList dense aria-labelledby="attributes-menu-button">
                                <MenuItem tabIndex={-1}>
                                    <Link href="/attributes">View all attributes</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link href="/attribute/create">Create attribute</Link>
                                </MenuItem>
                            </MenuList>
                        </Popover>
                        <div style={{ display: 'flex', marginBottom: '2px' }}>
                            <Link href="/subscriptions" style={{ color: "blue", marginLeft: "10px", marginRight: "10px", alignSelf: "flex-end" }} variant="subtitle1">
                                Subscriptions
                            </Link>
                        </div>
                        <div style={{ display: 'flex', marginBottom: '2px' }}>
                            <Link id="links-menu-button" component="button" style={{ color: "blue", marginLeft: "10px", marginRight: "10px", alignSelf: "flex-end" }} variant="subtitle1" onClick={(e) => {
                                this.setLinksAnchor(e.currentTarget);
                                this.setLinksMenuOpen(true);
                            }} aria-controls={this.linksMenuOpen ? "links-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={this.linksMenuOpen ? 'true' : undefined}>Links <ArrowDropDown />
                            </Link>
                        </div>
                        <Popover
                            id="links-menu"
                            open={this.linksMenuOpen}
                            anchorEl={this.linksAnchor}
                            onClose={() => {
                                this.setLinksMenuOpen(false);
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            disableAutoFocus={false}
                        >
                            <MenuList dense aria-labelledby="links-menu-button">
                                <MenuItem tabIndex={-1}>
                                    <Link href="https://osgwiki.com/wiki/Targeting_Service">TS Wiki</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link href="https://osgwiki.com/wiki/Common_Targeting_Attribute_Client">CTAC Wiki</Link>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <Link href={config.ct}>Control Tower</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link href="//missioncontrol">Mission Control</Link>
                                </MenuItem>
                                <MenuItem>
                                    <Link href="//es">Windows Engineering Systems</Link>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <Link href={`mailto:${config.helpAlias}`}>Contact the WaaS Experimentation and Targeting team</Link>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <Link href={`${config.api.endpointUrl}/swagger/ui/index`} target="_blank">Targeting Service Swagger API</Link>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <Link href="/admin">Admin Tools</Link>
                                </MenuItem>
                            </MenuList>
                        </Popover>
                        <NavItem className="navbar-item-text navbar-right-align navbar-flex-end">{UserStore.username}</NavItem>
                        <div style={{ display: 'flex' }}>
                            <Link href="Windows-Feedback:?contextid=1163" role="button" style={{ color: "indigo", marginTop: "47px", marginLeft: "10px", marginRight: "10px", alignSelf: "flex-end", marginBottom: "6px" }} variant="subtitle1">
                                <FeedbackIcon />
                            </Link>
                        </div>
                    </Nav>
                </div>
                {AlertStore.hasAlert &&
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                        {this.renderAlerts()}
                    </div>
                }
            </div>
        )
    }
}

export default AppInsightsService.trackComponent(TSNavbar);