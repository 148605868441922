import * as React from "react";
import { Row, Col, FormGroup } from "reactstrap";
import { observer } from "mobx-react";
import { AvField } from 'availity-reactstrap-validation';
import AttributeFormTransformProps from "../AttributeFormTransformProps";
import AppInsightsService from '../../../services/AppInsightsService';

@observer
export class Contains extends React.Component<AttributeFormTransformProps> {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        const target = ["Transform", "Contains"]
        if (e.target) {
            this.props.onChange(target, e.target.value);
        }
    }

    render() {
        return (
            <div>
                <Col>
                    <AvField name="Contains" label="Contains" type="text"
                        aria-labelledby="Contains"
                        value={this.props.data.Transform.Contains}
                        onChange={this.onChange}
                        helpMessage="Checks if this string is present in the attribute and returns a boolean"
                        disabled={this.props.disabled}
                    />
                </Col>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(Contains);