import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { action } from 'mobx';
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import AlertStore from "../shared/stores/AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import AppStore from "../shared/stores/AppStore";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import FunctionalGroupDetailsStore from "../shared/stores/FunctionalGroupDetailsStore";
import AttributeCollectionStore from "../shared/stores/AttributeCollectionStore";
import AudienceCriteriaStore from "../shared/stores/AudienceCriteriaStore";
import { AdminToolsState } from "./AdminToolsState";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./AdminTools.css";
import Select from "../shared/components/AccessibleSelect/Select";
import MultiInput from "../shared/components/AccessibleSelect/MultiInput";
import { BarLoader } from 'react-spinners';
import AppInsightsService from '../shared/services/AppInsightsService';
import AppInsightsPageTracker from '../shared/services/AppInsightsPageTracker';

import "react-tabs/style/react-tabs.css";

@observer
export class AdminTools extends React.Component<RouteComponentProps, AdminToolsState> {
    pageTracker: AppInsightsPageTracker;
    constructor(props) {
        super(props);
        this.state = new AdminToolsState();
    }

    componentDidMount() {
        this.pageTracker = AppInsightsService.startTrackPage("AdminTools");
        if(!FunctionalGroupStore.isLoaded) FunctionalGroupStore.fetchAllFunctionalGroups(true);
        if(!AttributeCollectionStore.isLoaded) AttributeCollectionStore.fetchAllAttributeCollections(true);
        if(!AudienceCriteriaStore.isLoaded) AudienceCriteriaStore.fecthAllCriteria(true);
        AppStore.setPageTitle("Admin Tools");
    }

    render () {
        if (!FunctionalGroupStore.isLoaded || FunctionalGroupStore.isLoading) {
            return <div style={{paddingTop: "100px"}}><LoadingIndicator text="Loading..."/></div>
        }

        if (this.pageTracker) this.pageTracker.stop();

        return (
            <Tabs>
                <TabList>
                    <Tab>Create FG</Tab>
                    <Tab>Update FG</Tab>
                    <Tab>Create Attr. Collection</Tab>
                </TabList>
                {this.renderCreateFG()}
                {this.renderUpdateFG()}
                {this.renderCreateAttributeCollection()}
            </Tabs>
        );
    }

    @action
    private onSubmitCreate = () => {
        AlertStore.clearAll();
        if(this.state.functionalGroupName.localeCompare("") !== 0 && this.state.functionalGroupOwner.length !== 0) {
            FunctionalGroupStore.addFunctionalGroup(this.state.functionalGroupName, this.state.functionalGroupOwner)
                .then(()=>{
                    if (FunctionalGroupStore.httpPost.success) {
                        this.state.clearForm();
                    }
                });
        } else {
            AlertStore.add(AlertType.Error, "One or more required fields are empty, unable to create new functional group.");
        }
    }

    @action
    private onSubmitUpdate = () => {
        AlertStore.clearAll();
        if(this.state.currentFunctionalGroupOwner.length !== 0) {
            const fg = { "id": this.state.selectedFunctionalGroupId, "Owners": this.state.currentFunctionalGroupOwner,
            "GroupsTags": this.state.currentFunctionalGroupTag, "TSAttributeGroup": this.state.currentFunctionalGroupAttributeGroup,
            "CTACAttributeGroup": this.state.currentFunctionalGroupCTACAttributeGroup };
            FunctionalGroupStore.updateFunctionalGroup(fg);
        } else {
            AlertStore.add(AlertType.Error, "One or more required fields are empty, unable to update functional group.");
        }
    }

    @action
    private onSubmitCreateAttrCollection = () => {
        AlertStore.clearAll();
        if(this.state.attrCollectionName.localeCompare("") !== 0 && this.state.attrCollectionOwners.length !== 0) {
            AttributeCollectionStore.addAttributeCollection(this.state.attrCollectionName, this.state.attrCollectionOwners)
                .then(() => {
                    if(AttributeCollectionStore.httpPost.success) {
                        this.state.clearForm();
                    }
                });
        } else {
            AlertStore.add(AlertType.Error, "One or more required fields are empty, unable to create new attribute collection.");
        }
    }

    @action
    private onChangeFunctionalGroup = (value: string) => {
        if(value) {
            this.state.updateSelectedFunctionalGroupId(value);
            FunctionalGroupDetailsStore.fetchFunctionalGroupName(this.state.selectedFunctionalGroupId, true)
                .then(() => {this.state.updateCurrentFunctionalGroupOwner(FunctionalGroupDetailsStore.data.Owners);
                             this.state.updateCurrentFunctionalGroupTag(FunctionalGroupDetailsStore.data.GroupsTags);
                             this.state.updateCurrentFunctionalGroupAttributeGroup(FunctionalGroupDetailsStore.data.TSAttributeGroup || '');
                             this.state.updateCurrentFunctionalGroupCTACAttributeGroup(FunctionalGroupDetailsStore.data.CTACAttributeGroup || '');
                            });
        }
    }

    @action
    private onChangeAttributeGroup = (value: string) => {
        this.state.updateCurrentFunctionalGroupAttributeGroup(value);
    }

    @action
    private onChangeCTACAttributeGroup = (value: string) => {
        this.state.updateCurrentFunctionalGroupCTACAttributeGroup(value);
    }

    @action
    private setOwners = (value: string, op: 'add' | 'remove') => {
        const index = this.state.currentFunctionalGroupOwner.findIndex(a => a === value);
        if (op === 'add' && index < 0) {
            this.state.updateCurrentFunctionalGroupOwner([...this.state.currentFunctionalGroupOwner, value]);
        }
        else if (op === 'remove' && index >= 0) {
            const newAdmins = [];
            for (const admin of this.state.currentFunctionalGroupOwner) {
                if (admin !== value) {
                    newAdmins.push(admin);
                }
            }

            this.state.updateCurrentFunctionalGroupOwner(newAdmins);
        }
    }

    @action
    private setGroupTags = (value: string, op: 'add' | 'remove') => {
        const index = this.state.currentFunctionalGroupTag ? this.state.currentFunctionalGroupTag.findIndex(a => a === value) : -1;
        if (op === 'add' && index < 0) {
            if (this.state.currentFunctionalGroupTag) {
                this.state.updateCurrentFunctionalGroupTag([...this.state.currentFunctionalGroupTag, value]);
            } else {
                this.state.updateCurrentFunctionalGroupTag([value]);
            }
        }
        else if (op === 'remove' && index >= 0) {
            const newAdmins = this.state.currentFunctionalGroupTag.slice();
            newAdmins.splice(index, 1);
            this.state.updateCurrentFunctionalGroupTag(newAdmins);
        }
    }

    @action
    private setNewOwners = (value: string, op: 'add' | 'remove') => {
        const index = this.state.functionalGroupOwner.findIndex(a => a === value);
        if (op === 'add' && index < 0) {
            this.state.updateNewFunctionalGroupOwners([...this.state.functionalGroupOwner, value]);
        }
        else if (op === 'remove' && index >= 0) {
            const newAdmins = this.state.functionalGroupOwner.slice();
            newAdmins.splice(index, 1);
            this.state.updateNewFunctionalGroupOwners(newAdmins);
        }
    }

    @action
    private setNewAttrCollectionOwners = (value: string, op: 'add' | 'remove') => {
        const index = this.state.attrCollectionOwners.findIndex(a => a === value);
        if (op === 'add' && index < 0) {
            this.state.updateNewAttrCollectionOwners([...this.state.attrCollectionOwners, value]);
        }
        else if (op === 'remove' && index >= 0) {
            const newAdmins = this.state.attrCollectionOwners.slice();
            newAdmins.splice(index, 1);
            this.state.updateNewAttrCollectionOwners(newAdmins);
        }
    }

    @action
    private setNewName = (value: any) => {
        if(value.target.value != null) {
            this.state.updateNewFunctionalGroupName(value.target.value);
        }
    }

    @action
    private setNewAttrCollectionName = (value: any) => {
        if(value.target.value != null) {
            this.state.updateNewAttrCollectionName(value.target.value);
        }
    }

    private renderCreateFG(){
        return(
            <TabPanel>
                <h4 className="fg-header"> CREATE FUNCTIONAL GROUP </h4>
                {FunctionalGroupStore.isCreateInProgress ?
                <div style={{paddingTop: "70px"}}><BarLoader/></div> :
                <AvForm>
                    <AvField name="functionalGroupName" label="Give this functional group a unique short name" id="functionalGroupName"
                        value={this.state.functionalGroupName || ''} errorMessage="Please enter a functional group name" onChange={this.setNewName} required/>
                    <label id="create-fg-owners-label" className="fg-update-select"> Alias of functional group owner(s)</label>
                    <MultiInput id="create-fg-owners" labelId="create-fg-owners-label"
                    values={this.state.functionalGroupOwner} onChange={this.setNewOwners}/>

                    <AvGroup className="button" style={{float: "right"}}>
                        <Button className="ts-btn" onClick={this.onSubmitCreate}>Submit</Button>
                    </AvGroup>
                </AvForm>}
            </TabPanel>
        );
    }

    private renderUpdateFG(){
        const functionalGroups = (FunctionalGroupStore.http && FunctionalGroupStore.http.success && FunctionalGroupStore.data != null) ?
                                FunctionalGroupStore.data.map((i) => ({
                                            label: i.Name,
                                            value: i.id
                                        })).sort((a,b) => a.label.localeCompare(b.label))
                                : [];

        const attrGroups = (AttributeCollectionStore.http && AttributeCollectionStore.http.success && AttributeCollectionStore.data != null) ?
                                AttributeCollectionStore.data.map((i) => ({
                                            label: i.Name,
                                            value: i.Name
                                        })).sort((a,b) => a.label.localeCompare(b.label))
                                : [];
        return(
            <TabPanel>
                <h4 className="fg-header"> UPDATE FUNCTIONAL GROUP </h4>
                <label id="context-fg-label" className="functional-group-label">Select a Functional Group</label>
                <Select id="context-fg" labelId="context-fg-label" width="100%"
                    options={functionalGroups} value={this.state.selectedFunctionalGroupId} onChange={this.onChangeFunctionalGroup}
                    expandOnClick={true}
                />

                {!FunctionalGroupDetailsStore.isLoaded && !FunctionalGroupDetailsStore.isLoading
                    ? <div/>
                    : (!FunctionalGroupDetailsStore.isLoading
                        ?
                        <div className="update-form-div">
                            <h4 className="fg-header"> UPDATE EXISTING FUNCTIONAL GROUP </h4>
                            {FunctionalGroupStore.isUpdateInProgress?
                            <div style={{paddingTop: "70px"}}><BarLoader/></div> :
                            <AvForm>
                                <strong>Functional Group: </strong><div>{this.state.selectedFunctionalGroupId}</div>
                                <label id="fg-owners-label" className="fg-update-select"> Owner(s):</label>
                                    <MultiInput id="fg-owners" labelId="fg-owners-label"
                                        values={this.state.currentFunctionalGroupOwner} onChange={this.setOwners} required={true} />

                                <label id="fg-group-tags-label" className="fg-update-select"> Group Tag(s):</label>
                                <MultiInput id="fg-group-tags" labelId="fg-group-tags-label"
                                    values={this.state.currentFunctionalGroupTag} onChange={this.setGroupTags} />

                                <label id="fg-ts-attribute-group-label" className="fg-update-select"> TS Attribute Group:</label>
                                <Select id="fg-ts-attribute-group" labelId="fg-ts-attribute-group-label" width="100%"
                                    options={attrGroups} onChange={this.onChangeAttributeGroup} value={this.state.currentFunctionalGroupAttributeGroup}
                                    clearable={true} expandOnClick={true}
                                />

                                <label id="fg-ctac-attribute-group-label" className="fg-update-select"> CTAC Attribute Group:</label>
                                <Select id="fg-ctac-attribute-group" labelId="fg-ctac-attribute-group-label" width="100%"
                                    options={attrGroups} onChange={this.onChangeCTACAttributeGroup} value={this.state.currentFunctionalGroupCTACAttributeGroup}
                                    clearable={true} expandOnClick={true}
                                />

                                <AvGroup style={{float: "right"}}>
                                    <Button aria-label="submit button" className="ts-btn submit-button" onClick={this.onSubmitUpdate}>Submit</Button>
                                </AvGroup>
                            </AvForm>}
                        </div>
                        : <div style={{paddingTop: "70px"}}><BarLoader/></div>)
                }
            </TabPanel>
        );
    }

    private renderCreateAttributeCollection(){
        return(
            (!AttributeCollectionStore.isLoaded || AttributeCollectionStore.isLoading) ?
            <div style={{paddingTop: "100px"}}><LoadingIndicator text="Loading..."/></div> :
            <TabPanel>
                <h4 className="fg-header"> CREATE ATTRIBUTE COLLECTION </h4>
                {AttributeCollectionStore.isCreateInProgress ?
                <div style={{paddingTop: "70px"}}><BarLoader/></div> :
                <AvForm>
                    <AvField name="attrCollectionName" label="Give this attribute collection a unique short name" id="attrCollectionName"
                        value={this.state.attrCollectionName || ''} errorMessage="Please enter an attribute collection name." onChange={this.setNewAttrCollectionName} required/>
                    <label id="attribute-collection-owners-label" className="fg-update-select"> Alias of attribute collection owner(s)</label>
                    <MultiInput id="attribute-collection-owners" labelId="attribute-collection-owners-label"
                        values={this.state.attrCollectionOwners} onChange={this.setNewAttrCollectionOwners}/>
                    <AvGroup className="button" style={{float: "right"}}>
                        <Button className="ts-btn" onClick={this.onSubmitCreateAttrCollection}>Submit</Button>
                    </AvGroup>
                </AvForm>}
            </TabPanel>
        );
    }
}

export default AppInsightsService.trackComponent(AdminTools);