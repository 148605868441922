import * as React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { observer } from "mobx-react";
import { AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import AttributeFormDetailsProps from "../AttributeFormDetailsProps";
import { runInAction, observable } from "mobx";
import AppInsightsService from '../../../services/AppInsightsService';

@observer
export class PolicyAttributeDetails extends React.Component<AttributeFormDetailsProps> {
    @observable policyType: string = "CSP";

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target) {
            this.props.onChange(e.target.name, e.target.value);
        }
    }

    onPolicyTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target) {
            const v = e.target.value;
            runInAction(() => {
                this.policyType = v;
            });
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <Label>Policy Type</Label>
                        <AvRadioGroup style={{
                            background: 'transparent'
                        }} inline name="HKey" value={this.policyType} onChange={this.onPolicyTypeChange}>
                            <AvRadio label="CSP" value="CSP" disabled={this.props.disabled} />
                            <AvRadio label="MDM" value="MDM" disabled={this.props.disabled} />
                        </AvRadioGroup>
                    </Col>
                </Row>
                {this.policyType === "CSP" ? this.renderGenericPolicyOptions() : this.renderMdmPolicyOptions()}
            </div>
        );
    }

    renderMdmPolicyOptions() {
        return <div>
            <Row>
                <Col>
                    <div style={{ fontSize: '12px' }}><i>MDM policies are only available in <b>RS4+</b></i></div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <AvField name="Area" label="Area" id="Area" type="text" value={this.props.data.Area} onChange={this.onChange}
                            errorMessage="Required" helpMessage="The policy area" disabled={this.props.disabled}
                            validate={!this.props.disabled ? {
                                required: { value: true },
                            } : null} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <AvField name="Name" label="Name" id="Name" type="text" value={this.props.data.Name} onChange={this.onChange}
                            errorMessage="Required" helpMessage="The policy name" disabled={this.props.disabled}
                            validate={!this.props.disabled ? {
                                required: { value: true },
                            } : null} />
                    </FormGroup>
                </Col>
            </Row></div>
    }

    renderGenericPolicyOptions() {
        return <div>
            <Row>
                <Col>
                    <div style={{ fontSize: '12px' }}><i>CSP policies are only available in <b>19H1+</b></i></div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <AvField name="LocUri" label="LocUri" aria-label="LocUri" id="LocUri" type="text" value={this.props.data.LocUri} onChange={this.onChange}
                            errorMessage="Required" helpMessage={<span>The URI to a <a target="_blank" rel="noreferrer" href="https://docs.microsoft.com/en-us/windows/client-management/mdm/configuration-service-provider-reference">CSP</a>. URI must be under a whitelisted path (currently './Device/Vendor/MSFT/')</span>} disabled={this.props.disabled}
                            validate={!this.props.disabled ? {
                                required: { value: true },
                                pattern: { value: '^\\./Device/Vendor/MSFT/[A-Za-z0-9/]+$', errorMessage: "Path is invalid. Make sure the path is under ./Device/Vendor/MSFT/ and contains only alphanumeric characters." }
                            } : null} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AvField name="VarFlags" label="VarFlags (optional)" aria-label="VarFlags (optional)" type="text" value={this.props.data.VarFlags} onChange={this.onChange} disabled={this.props.disabled}
                        helpMessage={<span>default 0: Can be used to override converting variant types to strings. See <a target="_blank" rel="noreferrer" href="https://msdn.microsoft.com/en-us/library/windows/desktop/ms221258.aspx">VariantChangeTypeEx documentation</a>.</span>}
                        validate={!this.props.disabled ? {
                            number: true,
                        } : null} />
                </Col>
            </Row>
        </div>
    }
}

export default AppInsightsService.trackComponent(PolicyAttributeDetails);