import { observable, computed, action, runInAction } from "mobx";
import { adalApiFetch } from "../auth/Adal";
import config from "../../config.json";
import { AudienceCriteriaType, AttributeKind } from "ts-infra-common";
import { HttpGet } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class AttributeStore {
    @observable data: AudienceCriteriaType[];
    @observable http:HttpGet<AudienceCriteriaType[]>;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    async fetch(refresh: boolean = false) {
        if (refresh || !this.http) {
            this.http = HttpOperation.get<AudienceCriteriaType[]>(config.adal.clientId, config.api.endpointUrl + "/api/Criteria")
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                    this.data = this.http.data
                } else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, "AttributeStore failed to fetch api data.");
                }
            });
        }
    }

    async getAllRevisions(name: string, kind: AttributeKind) {
        const result = await adalApiFetch(config.api.endpointUrl + `/api/Criteria/${name}/${kind}`)
        const data: AudienceCriteriaType[] = await result.json();

        return data;
    }
}

const store = new AttributeStore();
export default store;
