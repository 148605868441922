// Props come from react-awesome-query-builder & TSWidgetOptionsProps & TSQueryConfig
export class QueryBuilderWidgets {
    textWidget?: (props) => JSX.Element;
    versionWidget?: (props) => JSX.Element;
    numberWidget?: (props) => JSX.Element;
    selectWidget?: (props) => JSX.Element;
    multiSelectWidget?: (props) => JSX.Element;
    booleanWidget?: (props) => JSX.Element;
    listGroupWidget?: (props) => JSX.Element;
    cosmosStreamWidget?: (props) => JSX.Element;
    bulkIngestionWidget?: (props) => JSX.Element;
    percentageSliderWidget?: (props) => JSX.Element;
    fieldWidget?: (id, itemsArray, props) => JSX.Element;
    conjWidget?: (id, props) => JSX.Element;
}