import * as React from "react";
import { observer } from "mobx-react";
import {  match } from "react-router-dom";
import AppStore from "../shared/stores/AppStore";
import AttributeCreate from "./AttributeCreate";
import AppInsightsService from '../shared/services/AppInsightsService';

interface AttributeCreatePageProps {
    match: match<{ name: string, kind: string }>
}

@observer
export class AttributeCreatePage extends React.Component<AttributeCreatePageProps> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AppStore.setPageTitle("Create Attribute");
    }

    render() {
        return <AttributeCreate match={this.props.match} refreshStore={true} />
    }
}

export default AppInsightsService.trackComponent(AttributeCreatePage);