import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { FunctionalGroup } from "ts-infra-common";
import { HttpGet } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class FunctionalGroupDetailsStore {
    @observable data: FunctionalGroup;
    @observable http: HttpGet<FunctionalGroup>;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    async fetch(path: string, refresh:boolean=false) {
        if (!this.http || refresh) {
            this.http = HttpOperation.get<FunctionalGroup>(config.adal.clientId, config.api.endpointUrl + path)
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                   this.data = this.http.data
                }
                else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, "FunctionalGroupAdminStore failed to fetch api data.");
                }
            });
        }
    }

    @action
    async fetchFunctionalGroupName(funcGroupName: string, refresh: boolean) {
        await this.fetch(`/api/FunctionalGroups/${funcGroupName}`, refresh);
    }
}

const store = new FunctionalGroupDetailsStore();
export default store;
