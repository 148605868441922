import * as React from "react";
import { Row, Col, FormGroup } from "reactstrap";
import { observer } from "mobx-react";
import { AvField } from 'availity-reactstrap-validation';
import { runInAction } from "mobx";
import AttributeFormDetailsProps from "../AttributeFormDetailsProps";
import AppInsightsService from '../../../services/AppInsightsService';

@observer
export class WMIAttributeDetails extends React.Component<AttributeFormDetailsProps> {
    componentDidMount() {
        runInAction(() => {
            if(!this.props.data.VarFlags) this.props.data.VarFlags = "0";
            if(!this.props.data.Timeout) this.props.data.Timeout = "2000";
        });
    }

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target) {
            this.props.onChange(e.target.name, e.target.value);
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <div style={{ fontSize: '12px' }}><i><b>WARNING: </b> WMI is generally slow to retrieve and should be avoided, if possible.</i></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="Query" label="Query" aria-label="Query" id="Query" type="text" value={this.props.data.Query} onChange={this.onChange} disabled={this.props.disabled}
                            errorMessage="Required" helpMessage={<div>The WMI Query Language string to pass to IWbemServices-{'>'}ExecuteQuery. <a target="_blank" href="https://docs.microsoft.com/en-us/windows/desktop/WmiSdk/querying-with-wql">See Querying with WQL</a></div>}
                                validate={!this.props.disabled ? {
                                required: {value: true},
                            } : null} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="Name" label="Name" aria-label="Name" id="Name" type="text" value={this.props.data.Name} onChange={this.onChange} disabled={this.props.disabled}
                            errorMessage="Required" helpMessage="The name of the property to retrieve from the above query (only the first value is retrieved)."
                                validate={!this.props.disabled ? {
                                required: {value: true},
                            } : null} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <AvField type="text" name="Timeout" label="Timeout (optional)" aria-label="Timeout (optional)" value={this.props.data.Timeout} onChange={this.onChange} disabled={this.props.disabled}
                        errorMessage="Needs to be a number" helpMessage="default 2000: The time in milliseconds to wait for the WMI query to return."
                            validate={!this.props.disabled ? {
                            number: true
                        } : null} />
                    </Col>
                    <Col xs="4">
                        <AvField name="VarFlags" label="VarFlags (optional)" aria-label="VarFlags (optional)" type="text" value={this.props.data.VarFlags} onChange={this.onChange} disabled={this.props.disabled}
                        helpMessage="default 0: Can be used to override converting variant types to strings."
                            validate={!this.props.disabled ? {
                            number: true
                        } : null} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(WMIAttributeDetails);