import { action, observable } from "mobx";
import { CosmosStreamDeviceGroupDefinition } from "ts-infra-common";
import moment from "moment";

export class CosmosWidgetState {
    @observable query: CosmosStreamDeviceGroupDefinition;
    @observable ingestionStatus: string;
    @observable lastProcessedTime: string;
    @observable lastTimestamp: string;
    @observable lastError: string;
    @observable refreshingIngestionStatus: boolean;
    @observable ingestionStatusError: string;
    @observable lastValidatedStream: string;
    @observable validatingStream: boolean;
    @observable streamValidationError: string;
    @observable errorModalIsOpen: boolean;
    @observable groupCount: number = 0;
    @observable checkTrackers: boolean;
    @observable trackerProgress: number = -1;

    @action
    updateQuery(value: any) {
        this.query = value;
    }

    @action
    updateQueryProp(prop: string, value: any) {
        this.query[prop] = value;
    }

    @action
    setIngestionState(ingestionStatus: any) {
        const pipelineState: number = ingestionStatus.PipelineState;
        const knownIssue: boolean = ingestionStatus.Data ? ingestionStatus.Data.KnownIssue : false;
        const retry: boolean = ingestionStatus.Data ? ingestionStatus.Data.Retry : false;
        const lastProcessedTime: Date = ingestionStatus.Data ? ingestionStatus.Data.LastProcessedTime : null;
        const timestamp: Date = ingestionStatus.Data ? ingestionStatus.Data.Timestamp : null;
        const errors: string[] = ingestionStatus.Errors ? ingestionStatus.Errors : null;
        this.lastError = "None";
        let oldCheckTrackers = this.checkTrackers;
        this.checkTrackers = false;
        if (knownIssue) {
            this.ingestionStatus = "Disabled";
        } else if (retry) {
            this.ingestionStatus = "Waiting for Retry";
        } else {
            switch (pipelineState) {
                case 0:
                    this.ingestionStatus = "Ready";
                    this.checkTrackers = true;
                    break;
                case 1:
                    this.ingestionStatus = "Queued";
                    break;
                case 2:
                    this.ingestionStatus = "Preparing";
                    break;
                 case 3:
                    this.ingestionStatus = "Calculating diffs";
                    break;
                case 4:
                    this.ingestionStatus = "Ingesting";
                    break;
                case 5:
                    this.ingestionStatus = "Error";
                    if (errors && errors.length > 0) {
                        this.lastError = errors[errors.length - 1];
                    }
                    break;
                case 6:
                    this.ingestionStatus = "Queued in Groups";
                    this.checkTrackers = true;
                    break;
                case 7:
                    this.ingestionStatus = "Ingestion Paused";
                    break;
                default:
                    this.ingestionStatus = "In Progress";
                    this.checkTrackers = true;
                    break;
            }
        }

        if (lastProcessedTime) {
            this.lastProcessedTime = moment(lastProcessedTime).fromNow();
        } else {
            this.lastProcessedTime = "Never";
        }

        if (timestamp) {
            this.lastTimestamp = moment(timestamp).fromNow();
        } else {
            this.lastTimestamp = "Never";
        }

        // If we're starting to track ops, reset tracker progress
        if (!oldCheckTrackers && this.checkTrackers) {
            this.trackerProgress = -1;
        }
    }

    @action
    setRefreshingIngestionStatus(refreshingIngestionStatus: boolean) {
        this.refreshingIngestionStatus = refreshingIngestionStatus;
    }

    @action
    setIngestionStatusError(ingestionStatusError: string) {
        this.ingestionStatusError = ingestionStatusError;
    }

    @action
    setLastValidatedStream(lastValidatedStream: string) {
        this.lastValidatedStream = lastValidatedStream;
    }

    @action
    setValidatingStream(validatingStream: boolean) {
        this.validatingStream = validatingStream;
    }

    @action
    setStreamValidationError(streamValidationError: string) {
        this.streamValidationError= streamValidationError;
    }

    @action
    setErrorModalIsOpen(errorModalIsOpen: boolean) {
        this.errorModalIsOpen = errorModalIsOpen;
    }

    @action
    setGroupCount(groupCount: number) {
        this.groupCount = groupCount;
    }

    @action
    setTrackerProgress(trackerProgress: number) {
        this.trackerProgress = trackerProgress;
    }

    @action
    setCheckTrackers(checkTrackers: boolean) {
        this.checkTrackers = checkTrackers;
    }

    @observable actionInProgress: string;
    @action setActionInProgress(actionInProgress: string) {
        this.actionInProgress= actionInProgress;
    }

    @observable actionSuccesses: string[];
    @action clearActionSuccesses() {
        this.actionSuccesses = [];
    }
    @action addActionSuccess(msg: string) {
        if (!this.actionSuccesses.find(a => a === msg)) {
            this.actionSuccesses.push(msg);
        }
    }
    @action removeActionSuccess(msg: string) {
        this.actionSuccesses = this.actionSuccesses.filter(a => a !== msg);
    }

    @observable actionErrors: string[];
    @action clearActionErrors() {
        this.actionErrors = [];
    }
    @action addActionError(msg: string) {
        if (!this.actionErrors.find(a => a === msg)) {
            this.actionErrors.push(msg);
        }
    }
    @action removeActionError(msg: string) {
        this.actionErrors = this.actionErrors.filter(a => a !== msg);
    }
}