import { observable, action } from "mobx";

export class DeviceListComponentState {
    @observable removeIdsText: string = "";
    @observable addIdsText: string = "";
    @observable addIdsTextError: string = "";
    @observable removeIdsTextError: string = "";

    @action setAddIdsText(addIdsText: string) {
        this.addIdsText = addIdsText;
    }

    @action setRemoveIdsText(removeIdsText: string) {
        this.removeIdsText = removeIdsText;
    }

    @action setAddIdsTextError(addIdsTextError: string) {
        this.addIdsTextError = addIdsTextError;
    }

    @action setRemoveIdsTextError(removeIdsTextError: string) {
        this.removeIdsTextError = removeIdsTextError;
    }
}