import * as React from "react";
import { Row, Col, FormGroup } from "reactstrap";
import { observer } from "mobx-react";
import { AvField } from 'availity-reactstrap-validation';
import AttributeFormDetailsProps from "../AttributeFormDetailsProps";
import AppInsightsService from '../../../services/AppInsightsService';

@observer
export class LicensingAttributeDetails extends React.Component<AttributeFormDetailsProps> {
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target) {
            this.props.onChange(e.target.name, e.target.value);
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="Licensing_Name" label="Name" aria-label="Name" id="Licensing_Name" type="text" value={this.props.data.Name} onChange={this.onChange} disabled={this.props.disabled}
                            errorMessage="Required" helpMessage={<div>The name associated with the licensing value to retrieve. <a target="_blank" href="https://microsoft.visualstudio.com/DefaultCollection/OS/_git/os?path=%2Fonecore%2Fmerged%2Fsppdata%2Fcertificates.windows%2Fdefault%2Fslpolicylist.h&version=GBofficial%2Frsmaster">See slpolicylist.h</a></div>}
                            validate={!this.props.disabled ? {
                                required: {value: true},
                            } : null} />
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(LicensingAttributeDetails);