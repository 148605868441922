import { action, observable } from "mobx";
import { BulkIngestionType } from "ts-infra-common";

export class BulkIngestionWidgetState {
    @observable params: string;
    @observable type?: BulkIngestionType;
    @observable groupId?: string;

    @action
    setParams(params: string) {
        this.params = params;
    }

    @action
    setType(type: BulkIngestionType) {
        this.type = type;
    }

    @action
    setGroupId(groupId: string) {
        this.groupId = groupId;
    }
}