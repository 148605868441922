import { AudienceCriteriaType, AttributeKind } from "ts-infra-common";
import Service from "./service";

export default class CriteriaService extends Service {
    static async add(attribute: AudienceCriteriaType) {
        const response = await this.post("api/Criteria/AddDetailed", attribute);
        return response.ok;
    }

    static async addWithResponse(attribute: AudienceCriteriaType) {
        const response = await this.post("api/Criteria/AddDetailed", attribute);
        const json = await response.json();
        return {
            success: response.ok,
            criteria: json,
            errorMessage: json.Message
        };
    }

    static async update(attribute: AudienceCriteriaType) {
        const response = await this.patch(`api/Criteria/${attribute.Name}/${attribute.AttributePropertiesKind}`, attribute);
        return {
            success: response.ok,
            errorMessage: response.statusText
        };
    }

    static async submit(name: string, kind: AttributeKind) {
        const response = await this.post(`api/Criteria/Submit/${name}/${kind}`);
        return response.ok;
    }

    static async approve(name: string, kind: AttributeKind, message: string) {
        const response = await this.post(`api/Criteria/Approve/${name}/${kind}`, message);
        return response.ok;
    }

    static async reject(name: string, kind: AttributeKind, message: string) {
        const response = await this.post(`api/Criteria/Reject/${name}/${kind}`, message);
        return response.ok;
    }

    static async getTestJson(functionalGroup: string, name: string, kind: AttributeKind) {
        const response = await this.get(`api/CTAC/test/${functionalGroup}/${name}/${kind}`);
        if (response.ok) return response.text();
        return null;
    }

    static async isValidValue(criteriaName: string, value: string) {
        const response = await this.get(`api/Suggestions/Criteria/${criteriaName}/Values/${value}`);
        if(response.ok) {
            return response.json();
        }
        return null;
    }
}
