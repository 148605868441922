import * as React from "react";
import { Row, Col, FormGroup } from "reactstrap";
import { observer } from "mobx-react";
import { AvField } from 'availity-reactstrap-validation';
import AttributeFormDetailsProps from "../AttributeFormDetailsProps";
import AppInsightsService from '../../../services/AppInsightsService';

@observer
export class UpdatePolicyAttributeDetails extends React.Component<AttributeFormDetailsProps> {
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target) {
            this.props.onChange(e.target.name, e.target.value);
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="PolicyEnum" label="PolicyEnum" aria-label="PolicyEnum" id="PolicyEnum" type="text" value={this.props.data.PolicyEnum} onChange={this.onChange} disabled={this.props.disabled}
                                errorMessage="Required: Must be an integer" helpMessage={<div>The actual enum number value from UpdatePolicy or EnterprisePolicy to be passed to either ReadPolicy() or ReadPolicyWithFallback() respectively (depending on enterprise field) <a target="_blank" href="https://microsoft.visualstudio.com/OS/_git/os?path=%2Fonecore%2Fenduser%2FWindowsUpdate%2Fclient%2Fpolicy%2Fidl%2FUpdatePolicyIdl.idl&version=GBofficial%2Frsmaster">See UpdatePolicyIdl.idl</a></div>}
                                validate={!this.props.disabled ? {
                                required: {value: true},
                                number: true
                            } : null} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs="4">
                        <AvField type="select" value={this.props.data.Enterprise} name="Enterprise" label="Enterprise (optional)" aria-label="Enterprise (optional)" onChange={this.onChange} disabled={this.props.disabled}
                        helpMessage="default false: If true, ReadPolicyWithFallback() is called instead of ReadPolicy().">
                            <option value=""></option>
                            <option value="false">false</option>
                            <option value="true">true</option>
                        </AvField>
                    </Col>
                    <Col xs="4">
                        <AvField type="select" value={this.props.data.UseSource} name="UseSource" label="UseSource (optional)" aria-label="UseSource (optional)" onChange={this.onChange} disabled={this.props.disabled}
                        helpMessage="default false: If true, m_upsFrom is returned from the policy instead of m_varValue.">
                            <option value=""></option>
                            <option value="false">false</option>
                            <option value="true">true</option>
                        </AvField>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(UpdatePolicyAttributeDetails);