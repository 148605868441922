import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { observer } from "mobx-react";
import React from "react";
import { TSQueryConfig } from "./TSQueryConfig";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import isNil from "lodash/isNil";
@observer
export class CustomSwitchWidget extends React.Component<{ customProps?, value, setValue, readonly?, labelYes?, labelNo?} & TSQueryConfig & TSWidgetOptionsProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        const v = isNil(this.props.value) ? false : this.props.value;
        return <div className="ts-query-builder-switch">
            <Grid component="label" container direction="row" alignItems="center">
                {this.props.labelNo}
                <Switch checked={v} onChange={(ev, checked) => {
                    this.props.setValue(checked);
                }} />{this.props.labelYes}
            </Grid>
        </div>;
    }
}