import { observable, action, computed } from 'mobx';
import AudienceDetailsStore from "../shared/stores/AudienceDetailsStore";
import { AudienceCriteriaGroup, Audience, AudienceCriteriaType, CriteriaGroupOperator, CriteriaOperator } from 'ts-infra-common';
import { ObservableDataStore } from "../shared/stores/ObservableDataStore";
import { TSQueryConfig } from "@ts/ts-ux-querybuilder";
import AlertStore from "../shared/stores/AlertStore";
import CriteriaServiceV2 from "../shared/services/CriteriaServiceV2";
import ListGroupService from "../shared/services/ListGroupService";
import UserService from "../shared/services/UserService";
import QueryBuilderStore from "../shared/stores/QueryBuilderStore";
import { NavigationBlockerState } from '../shared/components/NavigationBlocker/NavigationBlockerState';

export function getRevisionString(a: Audience) {
    if (!a) return '';
    let revString = '';
    revString += `OWNER: ${a.Owner}\n`;
    revString += `ADMINS: ${a.Admins?.join(',')}\n`;
    revString += `GROUP IDS:\n${a.GroupServicesId?.join('\n')}\n`;
    revString += 'CRITERIA:\n';
    return revString + getGroupValue(a.Criteria);
}

export function getGroupValue(g: AudienceCriteriaGroup, level: number = 1) {
    if (!g) return '';
    const conj = g.Operator ? CriteriaGroupOperator[g.Operator].toString().toUpperCase() + '\n' : '';
    // This is to normalize the true/false True/False mismatch between UX and data that sometimes exists on legacy audiences
    const normalizeTrueFalse = (tfValue: string) => {
        if (tfValue === "true" || tfValue === "True") {
            return "1";
        } else if (tfValue === "false" || tfValue === "False") {
            return "0";
        }
        return tfValue;
    }
    const rules = g.Criteria ? g.Criteria.map(c => `${' '.repeat(level * 2)}- ${c.Type.Name} : ${(c.Operator ? CriteriaOperator[c.Operator].toLowerCase().toString() : '')} : ${normalizeTrueFalse(c.Value)}`).join('\n') : null;
    const groups = g.CriteriaGroups ? g.CriteriaGroups.map(sub => {
        const groupVal = getGroupValue(sub, level + 1);
        if (!groupVal) return null;
        return `${' '.repeat(level * 2)}+ ${groupVal}`;
    }).filter(i => !!i).join('\n') : null;
    if (!rules && !groups) return '';
    return g ? `${conj}${rules}${rules && groups ? '\n' : ''}${groups}` : '';
}

export class AudienceDetailsState {
    defaultWorkingQuery: string = '{}';
    @observable audienceOwner: string;
    @observable originalOwner: string;
    @observable revisionModalIsOpen: boolean = false;
    @observable criteriaModalIsOpen: boolean = false;
    @observable saveModalIsOpen: boolean = false;
    @observable groupsServiceIdModalIsOpen: boolean = false;
    @observable addLinkModalIsOpen: boolean = false;
    @observable selectedGroupsServiceId: string;
    @observable groupsServiceIdDetails: any;
    @observable currentAdmins: string[] = [];
    @observable originalAdmins: string[] = [];
    @observable estimatesIsLoaded: boolean;
    @observable expand: boolean;
    @observable selectedFunctionalGroupId: string;
    @observable selectedAudience: string;
    @observable selectAudienceIsDisabled: boolean = false;
    // @observable query: ITree;
    // @observable columnData: IConfig;
    @observable queryReady: boolean;
    @observable currentUser: string = '';
    @observable workingQuery: string = this.defaultWorkingQuery;
    @observable leftRevision: Audience;
    @observable rightRevision: Audience;
    @observable selectedLeftRevision: string = "";
    @observable selectedRightRevision: string = "";
    @observable isFetchingRevision: boolean = false;
    @observable editAudInPlace: boolean = false;
    @observable saveButtonTooltipOpen: boolean = false;
    @observable previewAudienceSizeTooltipOpen: boolean = false;
    @observable previewAudienceSizeModalIsOpen: boolean = false;
    @observable undoChangesTooltipOpen: boolean = false;
    @observable missingAttributes: string[] = [];
    @observable criteria: AudienceCriteriaGroup;
    @observable expSelectIsOpen: boolean = false;
    @observable renewDate: Date = null;
    @observable currentLabels: Map<string, string> = new Map<string, string>();
    @observable originalLabels: Map<string, string> = new Map<string, string>();
    @observable criteriaCheckIsOpen: boolean = false;
    @observable savingState: string = null;
    @observable queryBuilderFormValid: boolean = true;
    lastTrackedDiffRevisions: string[] = ["0", "0"];
    nextDiffRevisions: string[] = ["0", "0"];
    criteriaStore: ObservableDataStore<AudienceCriteriaType[]> = new ObservableDataStore<AudienceCriteriaType[]>();
    tsQueryConfig: TSQueryConfig = {
        helpAlias: "tshelp",
        alertStore: AlertStore,
        criteriaService: CriteriaServiceV2,
        listGroupService: ListGroupService,
        userService: UserService,
        queryBuilderStore: QueryBuilderStore,
        criteriaStore: this.criteriaStore
    };
    navigationBlockerState: NavigationBlockerState = new NavigationBlockerState();
    pendingChangesCriteria: any;
    qbCriteria: any;
    safetyCheckUpdater: any;
    @action setCriteria(criteria: AudienceCriteriaGroup) {
        this.criteria = criteria;
    }

    updateLastTrackedDiffRevisions() {
        this.lastTrackedDiffRevisions[0] = this.nextDiffRevisions[0];
        this.lastTrackedDiffRevisions[1] = this.nextDiffRevisions[1];
    }

    updateNextDiffRevisionLeft(rev: string) {
        this.nextDiffRevisions[0] = rev;
    }

    updateNextDiffRevisionRight(rev: string) {
        this.nextDiffRevisions[1] = rev;
    }

    @action
    toggleSaveButtonTooltip() {
        this.saveButtonTooltipOpen = !this.saveButtonTooltipOpen;
    }

    @action
    togglePreviewAudienceSizeButtonTooltip() {
        this.previewAudienceSizeTooltipOpen = !this.previewAudienceSizeTooltipOpen;
    }

    @action
    toggleUndoChangesTooltipOpen() {
        this.undoChangesTooltipOpen = !this.undoChangesTooltipOpen;
    }

    @action
    initTooltips() {
        this.saveButtonTooltipOpen = false;
        this.previewAudienceSizeTooltipOpen = false;
        this.undoChangesTooltipOpen = false;
    }

    @action
    updateEditAudInPlace(val: boolean) {
        this.editAudInPlace = val;
    }

    @action
    updateFetchingRevision(val: boolean) {
        this.isFetchingRevision = val;
    }

    @action
    updateLeftRevision(aud: Audience, selectedRev: string) {
        this.leftRevision = aud;
        this.selectedLeftRevision = selectedRev;
    }

    @action
    updateRightRevision(aud: Audience, selectedRev: string) {
        this.rightRevision = aud;
        this.selectedRightRevision = selectedRev;
    }

    @computed get isMetadataDirty(): boolean {
        return this.isOwnerDirty || this.isAdminsDirty || this.isLabelsDirty;
    }

    @computed get isOwnerDirty(): boolean {
        if (this.audienceOwner !== this.originalOwner) {
            return true;
        }

        return false;
    }

    @computed get isAdminsDirty(): boolean {
        const currentAdminsMap = {};
        const originalAdminsMap = {};
        for (const currentAdmin of this.currentAdmins) {
            currentAdminsMap[currentAdmin] = true;
        }

        for (const originalAdmin of this.originalAdmins) {
            originalAdminsMap[originalAdmin] = true;
            if (!currentAdminsMap.hasOwnProperty(originalAdmin)) {
                // Removed admin
                return true;
            }
        }

        for (const currentAdmin of this.currentAdmins) {
            if (!originalAdminsMap.hasOwnProperty(currentAdmin)) {
                // Added admin
                return true;
            }
        }

        return false;
    }

    @computed get isCriteriaDirty(): boolean {
        if (AudienceDetailsStore.data.RevisionInfo && AudienceDetailsStore.data.RevisionInfo.IsDraft) {
            return true;
        }

        const g1Value = getGroupValue(AudienceDetailsStore.data.Criteria);
        const g2Value = getGroupValue(JSON.parse(this.workingQuery));
        return g1Value !== g2Value;
    }

    @computed get isLabelsDirty(): boolean {
        if (this.currentLabels.size !== this.originalLabels.size) {
            return true;
        }

        let dirty = false;
        this.currentLabels.forEach((v, k) => {
            if (!this.originalLabels.has(k)) {
                dirty = true;
            }
        });

        this.originalLabels.forEach((v, k) => {
            if (!this.currentLabels.has(k)) {
                dirty = true;
            }
        });

        return dirty;
    }

    @computed get isDirty(): boolean {
        return this.isMetadataDirty || this.isCriteriaDirty;
    }

    @action
    updateUser(val: string) {
        this.currentUser = val;
    }

    @action
    updateSelectedAudienceIsDisabled(boo: boolean) {
        this.selectAudienceIsDisabled = boo;
    }

    @action
    updateFuncGroup(val: string) {
        this.selectedFunctionalGroupId = val;
    }

    @action
    updateSelectedAudience(val: string) {
        this.selectedAudience = val;
    }

    @action
    updateOriginalOwnerAndAdmins(owner: string, admins: string[]) {
        this.originalOwner = owner;
        this.originalAdmins = admins;
    }

    @action
    updateAudienceOwner(val: string) {
        this.audienceOwner = val;
    }

    @action
    updateAudienceLabels(labels: Map<string, string>) {
        this.originalLabels.clear();
        this.currentLabels.clear();
        if (labels) {
            if (labels.hasOwnProperty("forEach")) {
                labels.forEach((v, k) => {
                    this.originalLabels.set(k, v);
                    this.currentLabels.set(k, v);
                });
            }
            else {
                for (const key in labels) {
                    if (labels.hasOwnProperty(key)) {
                        this.originalLabels.set(key, String(labels[key]));
                        this.currentLabels.set(key, String(labels[key]));
                    }
                }
            }
        }
    }

    @action
    updateAddLinkModalIsOpen(val: boolean) {
        this.addLinkModalIsOpen = val;
    }

    @action
    updateRevisionModalIsOpen(val: boolean) {
        this.revisionModalIsOpen = val;
    }

    @action
    updatePreviewAudienceSizeModalIsOpen(val: boolean) {
        this.previewAudienceSizeModalIsOpen = val;
    }

    @action
    updateCriteriaModalIsOpen(val: boolean) {
        this.criteriaModalIsOpen = val;
    }

    @action
    updateSaveModalIsOpen(val: boolean) {
        this.saveModalIsOpen = val;
    }

    @action
    updateGroupsServiceIdModalIsOpen(val: boolean) {
        this.groupsServiceIdModalIsOpen = val;
    }

    @action
    updateSelectedGroupsServiceId(val: string) {
        this.selectedGroupsServiceId = val;
    }

    @action
    updateGroupsServiceIdDetails(val: any) {
        this.groupsServiceIdDetails = val;
    }

    @action
    updateAudienceAdmins(aliases: string[]) {
        this.currentAdmins = aliases;
    }

    @action
    updateEstimatesIsLoaded(boo: boolean) {
        this.estimatesIsLoaded = boo;
    }

    @action
    updateExpand() {
        this.expand = !this.expand;
    }

    @action
    updateQueryReady(value: boolean) {
        this.queryReady = value;
    }

    @action
    updateWorkingQuery(value: string) {
        this.workingQuery = value || '{}';
    }

    @action
    clearMissingAttributes() {
        if (this.missingAttributes && this.missingAttributes.length > 0) {
            this.missingAttributes = [];
        }
    }

    @action
    addMissingAttribute(attrName: string) {
        if (!this.missingAttributes.find(a => a === attrName)) {
            this.missingAttributes.push(attrName);
        }
    }

    @action updateNavigationBlocker() {
        this.navigationBlockerState.setBlockNavigation(this.isCriteriaDirty || this.isMetadataDirty);
    }

    @action setNavigationBlocker(block: boolean) {
        this.navigationBlockerState.setBlockNavigation(block);
    }

    @action setExpSelectIsOpen(expSelectIsOpen: boolean) {
        this.expSelectIsOpen = expSelectIsOpen;
    }

    @action setRenewDate(renewDate: Date) {
        this.renewDate = renewDate;
    }

    @computed
    get labelNames() {
        const arr = Array.from(this.currentLabels.keys());
        return arr;
    }

    @action tryAddLabel(labelName: string) {
        if (!this.currentLabels.has(labelName)) {
            this.currentLabels.set(labelName, "");
        }
    }

    @action tryRemoveLabel(labelName: string) {
        if (this.currentLabels.has(labelName)) {
            this.currentLabels.delete(labelName);
        }
    }

    @action setCriteriaCheckIsOpen(criteriaCheckIsOpen: boolean) {
        this.criteriaCheckIsOpen = criteriaCheckIsOpen;
    }

    setPendingChangesCriteria(pendingChangesCriteria: any) {
        this.pendingChangesCriteria = pendingChangesCriteria;
    }

    setQbCriteria(qbCriteria: any) {
        this.qbCriteria = qbCriteria;
    }

    setSafetyCheckUpdater(safetyCheckUpdater: any) {
        this.safetyCheckUpdater = safetyCheckUpdater;
    }

    @action setSavingState(savingState: string) {
        this.savingState = savingState;
    }

    @action setQueryBuilderFormValid(queryBuilderFormValid: boolean) {
        this.queryBuilderFormValid = queryBuilderFormValid;
    }
}
