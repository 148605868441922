import { observable, action } from 'mobx';

export type SelectionOptions = { enableSelection?: boolean, selectionField?: string, onSelectionChanged?: (items: any[]) => void };

export class SelectionState {
    @observable currentSelection: string[] = [];

    @action toggleSelection = (val: string) => {
        const index = this.currentSelection.findIndex(i => i === val);
        if (index < 0) {
            this.currentSelection = [...this.currentSelection, val];
        }
        else {
            const newSelection = this.currentSelection.slice();
            newSelection.splice(index, 1);
            this.currentSelection = newSelection;
        }
    }

    @action setSelection = (vals: string[]) => {
        this.currentSelection = vals;
    }
}