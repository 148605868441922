import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { AudienceCriteriaType } from "ts-infra-common";
import { HttpGet } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class AudienceCriteriaStore {
    @observable data: AudienceCriteriaType[];
    @observable http: HttpGet<AudienceCriteriaType[]>;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    async fetch(path: string, refresh: boolean = false) {
        if (!this.http || refresh) {
            this.http = HttpOperation.get<AudienceCriteriaType[]>(config.adal.clientId, config.api.endpointUrl + path)
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                   this.data = this.http.data
                }
                else {
                    AlertStore.add(AlertType.Error, "Failed to fetch AudienceCriteria data.");
                }
            });
        }
    }

    @action
    async fetchAudienceCriteria(funcGroupName: string, refresh: boolean) {
        await this.fetch(`/api/FunctionalGroups/${funcGroupName}/Criteria`, refresh);
    }

    @action
    async fecthAllCriteria(refresh: boolean) {
        await this.fetch('/api/AudienceCriteriaType', refresh);
    }
}

const store = new AudienceCriteriaStore();
export default store;
