import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { observer } from "mobx-react";
import React from "react";
import { DeviceListComponentProps } from "./DeviceListComponentProps";
import { DeviceListComponentState } from "./DeviceListComponentState";
import { FixedSizeList } from "react-window";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ClearIcon from "@material-ui/icons/Clear";

@observer
export class DeviceListComponent extends React.Component<DeviceListComponentProps, DeviceListComponentState> {
    constructor(props) {
        super(props);
        this.state = new DeviceListComponentState();
    }

    render() {
        return <Grid
            container
            item
            direction="column">
            <Typography variant="caption">Add or remove a comma-separated list of 16-digit device IDs. Click cancel to remove a pending add or remove. Click save to commit all pending adds and removes.</Typography>
            {this.addRemoveDeviceIdsComponent()}
            <div style={{ margin: '2rem' }}>
                <Typography variant="subtitle2">Pending Changes</Typography>
                <FixedSizeList height={this.props.deviceIdOps ? this.props.deviceIdOps.length < 10 ? this.props.deviceIdOps.length * 40 : 400 : 0} width={500} itemSize={40} itemCount={this.props.deviceIdOps ? this.props.deviceIdOps.length : 0}>
                    {this.deviceOpComponent}
                </FixedSizeList>
            </div>
        </Grid>;
    }

    addRemoveDeviceIdsComponent = () => {
        return <Grid
            container
            item
            direction="row">
            <FormControl error={Boolean(this.state.addIdsTextError)}>
                <TextField id="add-device-id-input" label="Device IDs" value={this.state.addIdsText}
                    onChange={(ev) => {
                        this.state.setAddIdsTextError("");
                        this.state.setAddIdsText(ev.target.value);
                    }} />
                {this.state.addIdsTextError ? <FormHelperText id="add-device-id-helper-text">{this.state.addIdsTextError}</FormHelperText> : null}
            </FormControl>
            <Button variant="outlined" color="primary" id={`add-new-device-id`} aria-label={`Add new device IDs`}
                onClick={(ev) => {
                    this.parseAndSendDeviceIds(this.state.addIdsText, "+", () => {
                        this.state.setAddIdsText("");
                        this.state.setAddIdsTextError("");
                    }, (err) => this.state.setAddIdsTextError(err));
                }}>Add Devices</Button>
            <Button variant="outlined" color="secondary" id={`remove-device-id`} aria-label={`Remove existing device IDs`}
                onClick={(ev) => {
                    this.parseAndSendDeviceIds(this.state.addIdsText, "-", () => {
                        this.state.setAddIdsText("");
                        this.state.setAddIdsTextError("");
                    }, (err) => this.state.setAddIdsTextError(err));
                }}>Remove Devices</Button>
        </Grid>;
    }

    parseAndSendDeviceIds(deviceIdStr: string, op: string, onClear: () => void, onErr: (err: string) => void) {
        const split = deviceIdStr.split(",");
        const trimmedDeviceIds = [];
        for (const deviceId of split) {
            const trimmedDeviceid = deviceId.trim();
            trimmedDeviceIds.push(trimmedDeviceid);
        }

        if (trimmedDeviceIds) {
            for (const trimmedDeviceId of trimmedDeviceIds) {
                this.props.onAddDeviceIdOp(op + trimmedDeviceId);
            }
        }

        onClear();
    }

    deviceOpComponent = (props) => {
        const deviceIdOp: string = this.props.deviceIdOps[props.index];
        const op = deviceIdOp[0];
        const deviceId = deviceIdOp.substr(1);
        const opStr = op === "+" ? "add" : "remove";
        return <ListItem style={props.style}>
            {op === "+" ? <AddIcon /> : <RemoveIcon />}
            <Typography style={{ width: '160px' }}>{deviceId}</Typography>
            <Button variant="outlined" color="secondary" id={`cancel-${opStr}-device-id-${deviceId}`} aria-label={`Cancel ${opStr} device ID ${deviceId}`}
                onClick={(ev) => {
                    this.props.onRemoveDeviceIdOp(deviceIdOp);
                }}><ClearIcon /></Button>
        </ListItem>;
    }
}