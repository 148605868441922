import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { AttributeCollection, AttributeKind, AttributeRequest, AudienceCriteriaType } from "ts-infra-common";
import { HttpGet, HttpPatch, HttpPostWithResponse, HttpDelete } from '../services/HttpOperation';
import { HttpOperation } from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import { AttributeRequestApproveResult, AttributeRequestRejectResult, AttributeRequestRejection, AttributeRequestCreatedCollectionResult, AttributeRequestResultBase } from '@ts/ts-ux-contracts';

class AttributeRequestStore {
    @observable fetchByIdOps: Map<string, HttpGet<AttributeRequestResultBase[]>>;
    @observable fetchByUserOp: HttpGet<AttributeRequestResultBase[]>;
    @observable deleteByIdOp: HttpDelete;

    constructor() {
        this.fetchByIdOps = new Map<string, HttpGet<AttributeRequestResultBase[]>>();
    }

    @action
    async approve(requestId: string, attributes?: AudienceCriteriaType[], operationFriendlyName?: string) {
        const httpOperation = HttpOperation.postWithResponse<AttributeRequestApproveResult[]>(config.adal.clientId, config.api.endpointUrl + `/api/AttributeCollections/Requests/${requestId}/Approve/Specific`, attributes);
        await this.handleHttpOperation<HttpPostWithResponse<AttributeRequestApproveResult[]>>(httpOperation, operationFriendlyName || "approve");
        return httpOperation;
    }

    @action
    async reject(requestId: string, rejection: AttributeRequestRejection[], operationFriendlyName: string) {
        const httpOperation = HttpOperation.postWithResponse<AttributeRequestRejectResult[]>(config.adal.clientId, config.api.endpointUrl + `/api/AttributeCollections/Requests/${requestId}/Reject`, rejection);
        await this.handleHttpOperation<HttpPostWithResponse<AttributeRequestRejectResult[]>>(httpOperation, operationFriendlyName);
        return httpOperation;
    }

    @action
    async getById(requestId: string, operationFriendlyName: string, refresh: boolean = false) {
        if (!this.fetchByIdOps.has(requestId) || refresh) {
            const httpOperation = HttpOperation.get<AttributeRequestResultBase[]>(config.adal.clientId, config.api.endpointUrl + `/api/AttributeCollections/Requests/${requestId}`);
            this.fetchByIdOps.set(requestId, httpOperation);
            await this.handleHttpOperation<HttpGet<AttributeRequestResultBase[]>>(httpOperation, operationFriendlyName);
        }
    }

    @action
    async getByUser(operationFriendlyName: string, refresh: boolean = false) {
        if (!this.fetchByUserOp || refresh) {
            this.fetchByUserOp = HttpOperation.get<AttributeRequestResultBase[]>(config.adal.clientId, config.api.endpointUrl + `/api/AttributeCollections/Requests/ByUser`);
            await this.handleHttpOperation<HttpGet<AttributeRequestResultBase[]>>(this.fetchByUserOp, operationFriendlyName);
        }
    }

    @action
    async deleteById(requestId: string, operationFriendlyName: string) {
        this.deleteByIdOp = HttpOperation.delete(config.adal.clientId, config.api.endpointUrl + `/api/AttributeCollections/Requests/${requestId}`);
        await this.handleHttpOperation<HttpDelete>(this.deleteByIdOp, operationFriendlyName);
    }

    @action
    async handleHttpOperation<T extends HttpOperation>(op: T, operationFriendlyName: string) {
        await op.wait();
        runInAction(() => {
            if (!op.success) {
                AlertStore.add(AlertType.Error, `'${operationFriendlyName}' failed: ${op.errorMessage}`);
            }
        });
    }
}

const store = new AttributeRequestStore();
export default store;