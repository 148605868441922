import { action, observable } from "mobx";
import { BulkIngestionParameters, BlobBulkIngestionParameters, GroupPrincipalType } from "ts-infra-common";

export class BlobIngestionWidgetState {
    @observable ingestionParameters: BlobBulkIngestionParameters = new BlobBulkIngestionParameters();
    @action setIngestionParameters(ingestionParameters: BlobBulkIngestionParameters) {
        this.ingestionParameters = ingestionParameters;
    }

    @observable principalType: GroupPrincipalType;
    @action setPrincipalType(principalType: GroupPrincipalType) {
        this.principalType = principalType;
    }

    @observable sizeCap: number;
    @action setSizeCap(sizeCap: number) {
        this.sizeCap = sizeCap;
    }

    @observable enabled: boolean;
    @action setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    @observable aliasesLoaded: boolean;
    @action setAliasesLoaded(aliasesLoaded: boolean) {
        this.aliasesLoaded = aliasesLoaded;
    }

    @observable loading: boolean;
    @action setLoading(loading: boolean) {
        this.loading = loading;
    }

    @observable aliases: string[];
    @action setAliases(aliases: string[]) {
        this.aliases = aliases;
    }

    @observable anchorEl: any;
    @action setAnchorEl(anchorEl: any) {
        this.anchorEl = anchorEl;
    }

    @observable validated: BulkIngestionParameters;
    @action setValidated(validated: BulkIngestionParameters) {
        this.validated = validated;
    }

    @observable validating: boolean;
    @action setValidating(validating: boolean) {
        this.validating = validating;
    }

    @observable errors: string[] = [];
    @action setErrors(errors: string[]) {
        this.errors = errors;
    }

    @action addError(err: string) {
        const newErrs = [];
        for (const e of this.errors){
            newErrs.push(e);
        }
        newErrs.push(err);
        this.setErrors(newErrs);
    }
}