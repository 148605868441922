import { observable, action } from 'mobx';

export class AdminToolsState {
    @observable functionalGroupName: string = "";
    @observable functionalGroupOwner: string[] = [];
    @observable selectedFunctionalGroupId: string = '';
    @observable currentFunctionalGroupOwner: string[] = [];
    @observable currentFunctionalGroupTag: string[] = [];
    @observable currentFunctionalGroupAttributeGroup: string = '';
    @observable currentFunctionalGroupCTACAttributeGroup: string = '';
    @observable attrCollectionName: string = "";
    @observable attrCollectionOwners: string[] = [];
    @observable selectedAttrCollection: string;
    @observable selectedAudienceCriteriaType: string;
    @observable selectedAudienceCriteriaTypeKind: number;

    @action
    updateNewAttrCollectionName(val: string) {
        this.attrCollectionName = val;
    }

    @action
    updateNewAttrCollectionOwners(val: string[]) {
        this.attrCollectionOwners = val;
    }

    @action
    updateNewFunctionalGroupName(val: string) {
        this.functionalGroupName = val;
    }

    @action
    updateNewFunctionalGroupOwners(val: string[]) {
        this.functionalGroupOwner = val;
    }

    @action
    updateSelectedAttributeCollection(val: any) {
        this.selectedAttrCollection = val;
    }

    @action
    updateSelectedAudienceCriteriaType(val: any) {
        this.selectedAudienceCriteriaType = val.value;
        this.selectedAudienceCriteriaTypeKind = val.kind;
    }

    @action
    updateSelectedFunctionalGroupId(val: string) {
        this.selectedFunctionalGroupId = val;
    }

    @action
    updateCurrentFunctionalGroupOwner(val: string[]) {
        this.currentFunctionalGroupOwner = val;
    }

    @action
    updateCurrentFunctionalGroupTag(val: string[]) {
        this.currentFunctionalGroupTag = val;
    }

    @action
    updateCurrentFunctionalGroupAttributeGroup(val: string) {
        this.currentFunctionalGroupAttributeGroup = val;
    }

    @action
    updateCurrentFunctionalGroupCTACAttributeGroup(val: string) {
        this.currentFunctionalGroupCTACAttributeGroup = val;
    }

    @action
    clearForm() {
        this.functionalGroupName = "";
        this.functionalGroupOwner = [];
        this.attrCollectionName = "";
        this.attrCollectionOwners = [];
        this.selectedAttrCollection = "";
        this.selectedAudienceCriteriaType = "";
        this.currentFunctionalGroupAttributeGroup = "";
        this.currentFunctionalGroupCTACAttributeGroup = "";
    }
}