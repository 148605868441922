import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { Audience } from "ts-infra-common";
import { HttpGet, HttpPatchWithResponse, HttpPost, HttpPostWithResponse } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class AudienceDetailsStore {
    @observable data: Audience = null;
    @observable http: HttpGet<Audience>;
    @observable httpPatch: HttpPatchWithResponse<Audience>;
    @observable httpPostCriteria: HttpPostWithResponse<Audience>;
    @observable renewExpOp: HttpPostWithResponse<Date>;
    @observable linkOp: HttpPost;
    @observable unlinkOp: HttpPost;

    @computed
    get isLoading() {
        return this.http != null && this.http.inProgress;
    }

    @computed
    get isLoadingPatch() {
        return this.httpPatch != null && this.httpPatch.inProgress;
    }

    @computed
    get isLinking() {
        return this.linkOp != null && this.linkOp.inProgress;
    }

    @computed
    get isUnlinking() {
        return this.unlinkOp != null && this.unlinkOp.inProgress;
    }

    @computed
    get isLoaded() {
        return this.data != null;
    }

    @action
    private async fetch(path:string, refresh: boolean = false) {
        this.data = null;
        if (refresh || !this.http) {
            this.http = HttpOperation.get<Audience>(config.adal.clientId, config.api.endpointUrl + path)
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                    this.data = this.http.data
                } else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, "AudienceDetailsStore failed to fetch api data.");
                }
            });
        }
    }

    @action
    async UnlinkAudience(parentFG: string, parentName: string, childFG: string, childName: string) {
        if (!this.unlinkOp) {
            this.unlinkOp = HttpOperation.post(config.adal.clientId, config.api.endpointUrl
                + `/api/Audiences/UnlinkAudience?parentFGname=${parentFG}&parentName=${parentName}&childFGname=${childFG}&childName=${childName}`, {
                "parentFGname": parentFG, "parentName": parentName, "childFGname": childFG, "childName": childName
            })
            await this.unlinkOp.wait();
            runInAction(() => {
                if (this.unlinkOp.success) {
                    AlertStore.add(AlertType.Success, "Audiences unlinked successfully");
                } else {
                    AlertStore.add(AlertType.Error, this.unlinkOp.errorMessage);
                }
            });
        }
        return this.unlinkOp.wait();
    }

    @action
    async LinkAudience(parentFG: string, parentName: string, childFG: string, childName: string) {
        if (!this.linkOp) {
            this.linkOp = HttpOperation.post(config.adal.clientId, config.api.endpointUrl
                + `/api/Audiences/LinkAudience?parentFGname=${parentFG}&parentName=${parentName}&childFGname=${childFG}&childName=${childName}`, {
                "parentFGname": parentFG, "parentName": parentName, "childFGname": childFG, "childName": childName
            })
            await this.linkOp.wait();
            runInAction(() => {
                if (this.linkOp.success) {
                    AlertStore.add(AlertType.Success, "Audiences linked successfully");
                } else {
                    AlertStore.add(AlertType.Error, this.linkOp.errorMessage);
                }
            });
        }
        return this.linkOp.wait();
    }

    @action
    async fetchAudienceDetails(funcGroupAudienceName:string, audienceName: string, revision?: string, refresh?: boolean) {
        await this.fetch(`/api/FunctionalGroups/${funcGroupAudienceName}/Audiences/${audienceName}${revision ? '/' + revision : ''}?excludedAttributes=0`, refresh)
    }

    @action
    async updateAudienceMetadata(options: {funcGroupName: string, audienceName: string, audienceOwner: string, audienceAdmins: string[], labels: Map<string, string>}) {
        this.httpPatch = HttpOperation.patchWithResponse<Audience>(config.adal.clientId, config.api.endpointUrl + `/api/FunctionalGroups/${options.funcGroupName}/Audiences/${options.audienceName}/Metadata`, {
            "Owner": options.audienceOwner, "Admins": options.audienceAdmins, "Labels": options.labels
        });
        await this.httpPatch.wait();
        runInAction(() => {
            if (this.httpPatch.success) {
                AlertStore.add(AlertType.Success, "AudienceDetailsStore successfully updated audience metadata.");
            } else {
                AlertStore.add(AlertType.Error, this.httpPatch.errorMessage);
            }
        });

        return this.httpPatch.wait();
    }

    @action
    async updateAudienceCriteria(options: {funcGroupName: string, audienceName: string, audience: Audience, onSuccess?: any, onFailure?: any}) {
        this.httpPostCriteria = HttpOperation.postWithResponse<Audience>(config.adal.clientId, config.api.endpointUrl + `/api/FunctionalGroups/${options.funcGroupName}/Audiences/${options.audienceName}/Update`, options.audience, options.onSuccess, options.onFailure);
        await this.httpPostCriteria.wait();
        runInAction(() => {
            if (this.httpPostCriteria.success) {
                AlertStore.add(AlertType.Success, "AudienceDetailsStore successfully updated audience criteria.");
            } else {
                AlertStore.add(AlertType.Error, this.httpPostCriteria.errorMessage);
            }
        });

        return this.httpPostCriteria.data;
    }

    @action
    async extendExpiration(funcGroupName: string, audienceName: string, extendDays: number) {
        this.renewExpOp = HttpOperation.postWithResponse<Date>(config.adal.clientId, config.api.endpointUrl + `/api/FunctionalGroups/${funcGroupName}/Audiences/${audienceName}/Expiration/Extend?extensionDays=${extendDays}`, null);
        await this.renewExpOp.wait();
        runInAction(() => {
            if (this.renewExpOp.success) {
                AlertStore.add(AlertType.Success, "Successfully renewed audience. New expiration date: " + this.renewExpOp.data);
            } else {
                AlertStore.add(AlertType.Error, "Failed to renew audience: " + this.renewExpOp.errorMessage);
            }
        });

        return this.renewExpOp;
    }
}

const store = new AudienceDetailsStore();
export default store;
