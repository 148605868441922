import LZUTF8 = require("lzutf8");

export class TestJson {
    compressedJson: string;
    title: string;
    generatedTimestamp: string;
    generatedTimestampRaw: number;
    constructor(json?: string, title?: string, ts?: Date) {
        if (json) {
            this.compressedJson = LZUTF8.compress(json, {
                outputEncoding: "Base64"
            });
        }

        this.title = title;
        if (ts) {
            this.generatedTimestamp = ts.toLocaleString();
            this.generatedTimestampRaw = ts.getDate();
        }
    }

    getJson() {
        const testJson = LZUTF8.decompress(this.compressedJson, {
            inputEncoding: "Base64"
        });
        return testJson;
    }
}