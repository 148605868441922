import { observer } from "mobx-react";
import React from "react";
import { WidgetProps } from "react-awesome-query-builder";
import { ListGroupWidgetState } from "./ListGroupWidgetState";
import { TSQueryConfig } from "./TSQueryConfig";
import { v4 as createGuid } from "node-uuid";
import { UserRoles } from "ts-infra-common";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { DeviceListComponent } from "./DeviceListComponent";
import { Alert } from "reactstrap";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AlertType } from "@ts/ts-ux-contracts";

@observer
export class ListGroupWidget extends React.Component<WidgetProps & TSQueryConfig, ListGroupWidgetState> {
    widgetId;
    constructor(props) {
        super(props);
        this.state = new ListGroupWidgetState(this.props.listGroupService, this.props.userService, this.props.alertStore);
        this.widgetId = createGuid();
    }

    componentDidMount() {
        this.state.updateGroupId(this.props.value);
        if (!this.state.userRole) this.state.initUserRole();
        //TODO: Remove if not needed - Modal.setAppElement('body');
    }

    render() {
        const save = !this.state.groupId ? this.saveNew : this.saveUpdate;
        const globalRoleLoaded = this.state.userRole !== null && typeof this.state.userRole !== "undefined";
        const groupEditEnabled = globalRoleLoaded && this.state.userRole >= UserRoles.GlobalAdmin;
        return <div>
            <TextField value={this.props.value} onBlur={(ev) => {
                this.state.updateGroupId(ev.target.value);
                this.props.setValue(ev.target.value);
            }} onChange={(ev) => {
                this.state.updateGroupId(ev.target.value);
                this.props.setValue(ev.target.value);
            }} disabled={true} label="Group ID" variant="filled" id={`group-id-${this.widgetId}`} size="small" />
            <Button onClick={() => {
                this.openModal();
            }} disabled={this.props.readonly} aria-label="Open group edit modal">{globalRoleLoaded || !this.state.groupId ? "Open" : "Checking access..."}</Button>
            {globalRoleLoaded && !groupEditEnabled && this.state.groupId ? `You do not have permission to edit this group. Contact ${this.props.helpAlias} for help` : null}
            <Dialog open={this.state.modalIsOpen} onClose={this.closeModal} fullWidth={true} maxWidth='md'>
                {this.state.isSaving ? this.renderSavingForm() : this.renderCreateOrModifyForm(save)}
            </Dialog>
        </div>;
    }

    renderCreateOrModifyForm = (save) => {
        return <Grid style={{ padding: '20px' }}>
            {this.props.alertStore.alerts.map((alert, i) => <Alert key={`alert-${i}`} style={{ width: "100%" }} toggle={() => this.props.alertStore.clear(alert)} color={this.props.alertStore.getAlertColor(alert.type)}>{AlertType[alert.type].toUpperCase()}: {alert.message}</Alert>)}
            <div><h4 className="create-form-header">{!this.state.groupId ? "Create New" : "Modify Existing"} List Based Group</h4></div>
            {!this.state.groupId
                ? <div><strong>Select a file</strong><br /><input type="file" id="file" onChange={this.selectFile} />{this.state.isFileLoading ? <LinearProgress /> : null}</div>
                : <div><strong>Existing group Id</strong><br /><input readOnly={true} type="text" value={this.state.groupId} /></div>
            }
            <div style={{ margin: '10px' }}>
                <p>{!this.state.groupId ? "Or manually enter device IDs below" : "Add or remove device IDs"}</p>
                <DeviceListComponent deviceIdOps={this.state.deviceIdOps} onAddDeviceIdOp={(newOp) => {
                    this.state.addDeviceIdOp(newOp);
                }} onRemoveDeviceIdOp={(opToRemove) => {
                    this.state.removeDeviceIdOp(opToRemove);
                }} />
            </div>
            <button className="ts-btn modal-btn" onClick={this.closeModal}>Close</button>
            <button className="ts-btn modal-btn" onClick={save} disabled={this.state.isSaving}>Save</button>
        </Grid>;
    }

    renderSavingForm = () => {
        return <div><h4 className="create-form-header">{!this.state.groupId ? "Create New" : "Modify Existing"} List Based Group</h4>
            <div><CircularProgress /></div>
            <button className="ts-btn modal-btn ts-btn-disabled" disabled={true}>Close</button>
            <button className="ts-btn modal-btn ts-btn-disabled" disabled={true}>Saving</button></div>;
    }

    private openModal = () => this.state.toggleModal(true);
    private closeModal = () => this.state.toggleModal(false);
    private selectFile = (event) => {
        const file: File = event.target.files[0];
        this.state.updateFilename(file.name);
        const fileReader = new FileReader();
        this.state.clearDeviceIdOps();
        fileReader.onloadend = () => {
            try {
                const deviceListStr = fileReader.result.toString();
                let hasDevices: boolean = false;
                const devicesMap: any = {};
                const tokens = deviceListStr.split('\r\n');
                const deviceIdOps = [];
                for (const s1 of tokens) {
                    for (const s2 of s1.split('\n')) {
                        for (const s3 of s2.split(',')) {
                            const deviceId = s3.trim();
                            if (deviceId) {
                                if (!devicesMap.hasOwnProperty(deviceId)) {
                                    deviceIdOps.push(`+${deviceId}`);
                                    devicesMap[deviceId] = true;
                                    hasDevices = true;
                                }
                            }
                        }
                    }
                }

                if (deviceIdOps.length > 0) {
                    this.state.setDeviceIdOps(deviceIdOps);
                }

                if (!hasDevices) {
                    this.props.alertStore.add(AlertType.Warning, `Selected file ${file.name} doesn't have any device IDs in it.`);
                }
            } finally {
                this.state.setIsFileLoading(false);
            }
        }
        this.state.setIsFileLoading(true);
        fileReader.readAsText(file);
    };
    private saveNew = () => {
        const principalType = this.props.field === 'MSAListBasedGroupId' ? 'MsaDeviceId' : 'SqmId';
        this.state.setIsSaving(true);
        let deviceIdBlob = "";
        for (const deviceIdOp of this.state.deviceIdOps) {
            // When making a new group, this is always an add
            if (deviceIdBlob.length > 0) {
                deviceIdBlob += "\n";
            }
            deviceIdBlob += deviceIdOp.substr(1);
        }
        this.state.store.createBlob(deviceIdBlob, principalType).then((tsOp) => {
            if (tsOp.success) {
                const blobId = tsOp.data;
                this.state.updateBlobId(blobId);
                this.props.setValue(this.state.blobId);
                this.closeModal();
            } else {
                this.props.alertStore.add(AlertType.Error, "Failed to create device ID list blob: " + tsOp.message);
            }
        }).finally(() => {
            this.state.setIsSaving(false);
        });
    }
    private saveUpdate = () => {
        this.state.setIsSaving(true);
        const addIds = [];
        const removeIds = [];
        for (const op of this.state.deviceIdOps) {
            if (op[0] === "+") {
                addIds.push(op.substr(1));
            } else if (op[0] === "-") {
                removeIds.push(op.substr(1));
            } else {
                this.props.alertStore.add(AlertType.Error, "Couldn't run device ID operation: " + op[0]);
            }
        }

        if (addIds || removeIds) {
            this.state.store.updateIds(this.state.groupId, addIds, removeIds, this.props.queryBuilderStore.functionalGroupAppId)
                .then((tsOp) => {
                    if (tsOp.success) {
                        this.closeModal();
                        this.state.clearDeviceIdOps();
                    } else {
                        this.props.alertStore.add(AlertType.Error, "Failed to add or remove device IDs: " + tsOp.message);
                    }
                }).finally(() => {
                    this.state.setIsSaving(false);
                });
        }
    }
}