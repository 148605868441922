/* tslint:disable:max-classes-per-file */
import { IObservableArray } from "mobx";
import { UserRoles, AudienceCriteriaType, AttributeRequest, AttributeKind, Audience, BulkIngestionParameters } from "ts-infra-common";

export class ObjectSimilarityResult<T> {
    OriginalEntity: T;
    Similarity: number;
    Type: string;
    IsSimilar: boolean;
}

export class AttributeRequestResultBase {
    Attribute: AudienceCriteriaType;
    Request: AttributeRequest;
}

export class AttributeRequestCreateResult extends AttributeRequestResultBase {
    SimilarAttributesSorted: ObjectSimilarityResult<AudienceCriteriaType>[];
}

export class AttributeRequestApproveResult extends AttributeRequestResultBase {
    AddedToCollection: boolean;
    NextOneSettingsPushFriendlyTimestamp: string;
    Approvers: string[];
    Approver: string;
}

export class AttributeRequestRejectResult extends AttributeRequestResultBase {
    Reason: string;
    DenyFutureRequests: boolean;
    Approvers: string[];
    Approver: string;
}

export class AttributeRequestRejection {
    Reason: string;
    DenyFutureRequests: boolean;
    SpecificAttribute: AudienceCriteriaType;
}

export class AttributeRequestCreatedCollectionResult {
    Results: AttributeRequestCreateResult[];
    AttributeCollection: string;
    Approvers: string[];
    Requestor: string;
    RequestorIsUser: boolean;
    RequestId: string;
}

export enum AttributeAudienceCriteriaKind {
    String = 35,
    Number = 36,
    Version = 37,
    Boolean = 38,
    Date = 42,
}

export class AttributeToCollectionId {
    AttributeName: string;
    AttributeAlias: string;
    AttributeKind: AttributeKind;
}

export enum AlertType {
    Error,
    Warning,
    Info,
    Success
}

export class Alert {
    message: string;
    type: AlertType;
    time: number;

    constructor(type: AlertType, message: string) {
        this.type = type;
        this.message = message;
        this.time = Date.now();
    }
}

export class TSOperation<T> {
    success: boolean;
    message: string;
    data: T;
}

export interface ICriteriaService {
    isValidValue(criteriaName: string, criteriaValue: string): Promise<TSOperation<boolean>>;
    getCriteriaInFunctionalGroup(functionalGroup: string, refresh: boolean): Promise<TSOperation<AudienceCriteriaType[]>>;
    getValidValues(criteriaName: string, refresh: boolean): Promise<TSOperation<string[]>>;
}

export interface IListGroupService {
    createBlob(deviceIds: string, principalType: string): Promise<TSOperation<any>>;
    getGroupDeviceIds(groupId: string): Promise<TSOperation<string[]>>;
    updateIds(groupId: string, idsToAdd: string[], idsToRemove: string[], groupAppId: string): Promise<TSOperation<any>>;
    getIngestionStatus(appId: string, groupId: string): Promise<TSOperation<any>>;
    getOperationTrackerStatus(appId: string, groupId: string): Promise<TSOperation<number>>;
    validateStream(uri: string): Promise<TSOperation<boolean>>;
    validateBulkIngestion(fgName: string, parameters: BulkIngestionParameters): Promise<TSOperation<boolean>>;
    retryBulkIngestion(appId: string, groupid: string): Promise<TSOperation<boolean>>;
    runOnDemandIngestion(fgName: string, audName: string): Promise<TSOperation<any>>;
    getDataConnectionNames(): Promise<TSOperation<string[]>>;
    count(groupId: string, appId?: string): Promise<TSOperation<number>>;
}

export interface IAlertStore {
    alerts: IObservableArray<Alert>;
    hasAlert: boolean;
    add(type: AlertType, message: string);
    clear(alert: Alert);
    clearAll();
    getAlertColor(type: AlertType): string;
}

export interface IUserService {
    userRole: UserRoles;
    isLoading: boolean;
    username: string;
    initUserRole(refresh: boolean): Promise<TSOperation<UserRoles>>;
}

export interface IQueryBuilderStore {
    functionalGroupAppId: string;
    setFunctionalGroupAppId(functionalGroupAppId: string);
}

export class AudienceContext {
    audience: Audience;
    groupsAppId: string;
}