import { AudienceCriteriaType, AttributeRequest, AttributeKind } from "ts-infra-common";

export class ObjectSimilarityResult<T> {
    OriginalEntity: T;
    Similarity: number;
    Type: string;
    IsSimilar: boolean;
}

export class AttributeRequestResultBase {
    Attribute: AudienceCriteriaType;
    Request: AttributeRequest;
}

export class AttributeRequestCreateResult extends AttributeRequestResultBase {
    SimilarAttributesSorted: ObjectSimilarityResult<AudienceCriteriaType>[];
}

export class AttributeRequestApproveResult extends AttributeRequestResultBase {
    AddedToCollection: boolean;
    NextOneSettingsPushFriendlyTimestamp: string;
    Approvers: string[];
    Approver: string;
}

export class AttributeRequestRejectResult extends AttributeRequestResultBase {
    Reason: string;
    DenyFutureRequests: boolean;
    Approvers: string[];
    Approver: string;
}

export class AttributeRequestRejection {
    Reason: string;
    DenyFutureRequests: boolean;
    SpecificAttribute: AudienceCriteriaType;
}

export class AttributeRequestCreatedCollectionResult {
    Results: AttributeRequestCreateResult[];
    AttributeCollection: string;
    Approvers: string[];
    Requestor: string;
    RequestorIsUser: boolean;
    RequestId: string;
}

export enum AttributeAudienceCriteriaKind {
    String = 35,
    Number = 36,
    Version = 37,
    Boolean = 38,
    //Enum = 39,
    Date = 42,
}

export class AttributeToCollectionId {
    AttributeName: string;
    AttributeAlias: string;
    AttributeKind: AttributeKind;
}

export enum AuthLogicalOperator {
    And = 0,
    Or = 1
}

export class FGAuthResult {
    name: string;
    completed: boolean;
    hasAccess: boolean;
}