import { IListGroupService, IUserService, IAlertStore, AlertType } from "@ts/ts-ux-contracts";
import { observable, action, runInAction } from "mobx";
import { UserRoles } from "ts-infra-common";

export class ListGroupWidgetState {
    store: IListGroupService;
    userService: IUserService;
    alertStore: IAlertStore;
    constructor(store: IListGroupService, userService: IUserService, alertStore: IAlertStore) {
        this.store = store;
        this.userService = userService;
        this.alertStore = alertStore;
    }

    @observable modalIsOpen: boolean;
    @observable filename: string = '';
    @observable deviceIdOps: string[] = [];
    @observable blobId: string = '';
    @observable groupId: string = '';
    @observable isSaving: boolean = false;
    @observable isFileLoading: boolean = false;
    @observable userRole: UserRoles;

    @action toggleModal(open: boolean) { this.modalIsOpen = open; }
    @action updateFilename(val: string) { this.filename = val; }
    @action updateDeviceIdOps(val: string[]) { this.deviceIdOps = val; }
    @action updateBlobId(val: string) { this.blobId = val; }
    @action updateGroupId(val: string) { this.groupId = val; }
    @action setIsSaving(isSaving: boolean) { this.isSaving = isSaving; }
    @action clearDeviceIdOps() { this.deviceIdOps = []; }
    @action setDeviceIdOps(deviceIdOps: string[]) { this.deviceIdOps = deviceIdOps; }
    @action addDeviceIdOp(deviceIdOp: string) {
        const index = this.deviceIdOps.indexOf(deviceIdOp);
        if (index === -1) {
            this.deviceIdOps.push(deviceIdOp);
        }
    }
    @action removeDeviceIdOp(deviceIdOp: string) {
        const index = this.deviceIdOps.indexOf(deviceIdOp);
        if (index !== -1) {
            this.deviceIdOps.splice(index, 1);
        }
    }
    @action setIsFileLoading(isFileLoading: boolean) {
        this.isFileLoading = isFileLoading;
    }
    @action initUserRole() {
        this.userService.initUserRole(true).then((tsOp) => {
            runInAction(() => {
                if (tsOp.success) {
                    this.userRole = tsOp.data;
                } else {
                    this.alertStore.add(AlertType.Error, "Failed to retrieve user role: " + tsOp.message);
                }
            });
        }).catch((err) => {
            this.alertStore.add(AlertType.Error, "Failed to retrieve user role: " + err);
        });
    }
}