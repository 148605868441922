import { observer } from "mobx-react";
import React from "react";
import { Label } from "reactstrap";
import { CustomCheckboxProps } from "./CustomCheckboxProps";

@observer
export class CustomCheckbox extends React.Component<CustomCheckboxProps> {
    render() {
        return <input id={this.props.id} type="checkbox" key={this.props.id}
                onChange={(ev) => this.props.onChange(ev, ev.target.checked)}
                checked={this.props.checked} aria-label={this.props["aria-label"] ?? this.props.label} value={this.props.value} disabled={this.props.disabled}
                style={{
                    margin: '10px'
                }} />
    }
}