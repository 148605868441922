import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { FunctionalGroup } from "ts-infra-common";
import { HttpGet, HttpPatch, HttpPostWithResponse } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class FunctionalGroupStore {
    @observable data: FunctionalGroup[];
    @observable dataFull: FunctionalGroup[];
    @observable http: HttpGet<FunctionalGroup[]>;
    @observable httpFull: HttpGet<FunctionalGroup[]>;

    @observable httpPost: HttpPostWithResponse<FunctionalGroup>;
    @observable httpPatch: HttpPatch;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @computed
    get isCreateInProgress(){
        return this.httpPost && this.httpPost.inProgress;
    }

    @computed
    get isUpdateInProgress(){
        return this.httpPatch && this.httpPatch.inProgress;
    }

    @computed
    get groupsCanAddToCollection() {
        return this.data ? this.data.slice().filter(i => i.CTACRedirectAttributeGroup || i.CTACAttributeGroup || i.TSAttributeGroup) : null;
    }

    @action
    async fetch(path: string, refresh:boolean=false) {
        if (!this.http || refresh) {
            this.http = HttpOperation.get<FunctionalGroup[]>(config.adal.clientId, config.api.endpointUrl + path);
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                   this.data = this.http.data;
                }
                else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, "Failed to fetch api data.");
                }
            });
        }
    }

    @action
    async fetchAllFunctionalGroups(refresh: boolean) {
        await this.fetch(`/api/FunctionalGroups/Slim`, refresh);
    }

    @action
    async fetchAllFunctionalGroupsFull(refresh: boolean) {
        if (!this.httpFull || refresh) {
            this.httpFull = HttpOperation.get<FunctionalGroup[]>(config.adal.clientId, config.api.endpointUrl + '/api/FunctionalGroups');
            await this.httpFull.wait();
            runInAction(() => {
                if (this.httpFull.success) {
                    this.dataFull = this.httpFull.data;
                }
                else {
                    this.dataFull = null;
                    AlertStore.add(AlertType.Error, "Failed to fetch api data.");
                }
            });
        }
    }

    @action
    async addFunctionalGroup(funcGroupName: string, funcGroupOwner: string[]) {
        this.httpPost = HttpOperation.postWithResponse<FunctionalGroup>(config.adal.clientId, config.api.endpointUrl + `/api/FunctionalGroups/${funcGroupName}`, funcGroupOwner);
        await this.httpPost.wait();
        runInAction(() => {
            if (this.httpPost.success) {
                AlertStore.add(AlertType.Success, "Successfully created new functional group.");
                this.data.push(this.httpPost.data);
            } else {
                AlertStore.add(AlertType.Error, this.httpPost.errorMessage);
            }
        });
        return this.httpPost.wait();
    }

    @action
    async updateFunctionalGroup(fg: any) {
        this.httpPatch = HttpOperation.patch(config.adal.clientId, config.api.endpointUrl + `/api/FunctionalGroups/${fg.id}`, fg);
        await this.httpPatch.wait();
        runInAction(() => {
            if (this.httpPatch.success) {
                AlertStore.add(AlertType.Success, "Successfully updated functional group.");
            } else {
                AlertStore.add(AlertType.Error, this.httpPatch.errorMessage);
            }
        });
        return this.httpPatch.wait();
    }
}

const store = new FunctionalGroupStore();
export default store;
