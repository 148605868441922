import React from "react";
import { observer } from "mobx-react";
import { v4 as createGuid } from "node-uuid";
import { WidgetProps } from "react-awesome-query-builder";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CustomCheckbox } from "./CustomCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TSQueryConfig } from "./TSQueryConfig";
import Button from "@material-ui/core/Button";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Dialog, DialogTitle, FormControl, InputLabel, LinearProgress, Popover, TableCell, TableRow } from "@material-ui/core";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import { GenericIngestionWidgetProps } from "./GenericIngestionWidgetProps";
import { BlobIngestionWidgetState } from "./BlobIngestionWidgetState";
import HelpIcon from "@material-ui/icons/Help";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BlobBulkIngestionParameters, BulkIngestionType } from "ts-infra-common";
import Alert from "@material-ui/lab/Alert";
import isEqual from "lodash/isEqual";
import { BlobIngestionParametersBuilderProps } from "./BlobIngestionParametersBuilderProps";
import { action, observable } from "mobx";
import moment from "moment";
import { BulkIngestionActions } from "./BulkIngestionActions";

@observer
export class BlobIngestionWidget extends React.Component<WidgetProps & TSQueryConfig & TSWidgetOptionsProps & GenericIngestionWidgetProps, BlobIngestionWidgetState> {
    widgetId;
    constructor(props) {
        super(props);
        this.state = new BlobIngestionWidgetState();
        this.widgetId = createGuid();
    }

    componentDidMount() {
        if (this.props.value) {
            const params: BlobBulkIngestionParameters = JSON.parse(this.props.value);
            this.state.setIngestionParameters(params);
        }

        if (this.props.parameters) {
            this.state.setEnabled(this.props.parameters.Enabled);
            this.state.setSizeCap(this.props.parameters.SizeCap);
            this.state.setPrincipalType(this.props.parameters.PrincipalType);
        }

        if (this.props.parameters && this.props.parameters.GroupId) {
            this.state.setValidated(this.getParametersObject());
        }

        if (!this.state.aliasesLoaded) {
            this.state.setLoading(true);
            this.props.listGroupService.getDataConnectionNames().then((op) => {
                if (op.success) {
                    this.state.setAliases(op.data);
                } else {
                    this.state.addError(op.message);
                }
            }).catch((err) => {
                this.state.addError(err);
            }).finally(() => {
                this.state.setAliasesLoaded(true);
                this.state.setLoading(false);
            });
        }
    }

    sendUpdate(enabled?: boolean) {
        const finalEnabled = enabled !== undefined ? enabled : Boolean(this.state.validated && isEqual(this.state.validated, this.getParametersObject()));
        this.props.onUpdate(finalEnabled, this.state.sizeCap, this.state.principalType, this.state.ingestionParameters);
    }

    getParametersObject() {
        return {
            Type: BulkIngestionType.Blob,
            Enabled: true,
            SizeCap: this.state.sizeCap,
            PrincipalType: this.state.principalType,
            Parameters: this.state.ingestionParameters
        };
    }

    render() {
        if (this.state.loading) {
            return <CircularProgress />;
        }
        const validated = Boolean(this.state.validated && isEqual(this.state.validated, this.getParametersObject()));
        const created = Boolean(this.props.parameters && this.props.parameters.GroupId);
        return <Grid container className="blob-ingestion-input">
            {created ? this.renderCreatedView() : null}
            {created ? this.renderEditView(validated) : this.renderCreateView(validated)}
        </Grid>
    }

    renderCreatedView() {
        return <BlobIngestionStatusWidget
            appId={this.props.parameters.AudienceContext.groupsAppId}
            groupId={this.props.parameters.GroupId}
            {...this.props} />;
    }

    renderEditView(validated: boolean) {
        return <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="edit-panel-content"
                id="edit-panel-header"
            >
                <Typography>Edit</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container className="blob-ingestion-input">
                    <FormControlLabel control={<CustomCheckbox
                        checked={this.state.enabled}
                        onChange={(e) => {
                            this.state.setEnabled(e.target.checked);
                            this.sendUpdate(this.state.enabled);
                        }}
                        aria-label='Blob ingestion enabled checkbox'
                        id='blob-ingestion-enabled-checkbox'
                        label=''
                        value="enabled"
                        disabled={false}
                    />} label="Enabled" className="blob-ingestion-checkbox" />
                    {this.renderForm(validated, true)}
                </Grid>
            </AccordionDetails>
        </Accordion>;
    }

    renderCreateView(validated: boolean) {
        return this.renderForm(validated, false);
    }

    renderForm(validated: boolean, created: boolean) {
        return <React.Fragment>
            <BlobIngestionParametersBuilder dataConnections={this.state.aliases} dataConnectionName={this.state.ingestionParameters.DataConnectionName}
                directoryPattern={this.state.ingestionParameters.DirectoryPattern} filePattern={this.state.ingestionParameters.FilePattern} disabled={this.state.validating}
                onUpdate={(newParams) => {
                    this.state.setIngestionParameters(newParams);
                }} />
            <Grid direction="row" container>
                <FormControl fullWidth>
                    <InputLabel id="principal-type-label">Principal Type</InputLabel>
                    <Select value={this.state.principalType} onChange={e => {
                        this.state.setPrincipalType(Number(e.target.value));
                        this.sendUpdate();
                    }} disabled={created || this.state.validating} labelId={"principal-type-label"}>
                        <MenuItem value={1}>MSA (g:)</MenuItem>
                        <MenuItem value={2}>SQM (s:)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid direction="row" container>
                <TextField type="number" value={this.state.sizeCap} onChange={e => {
                    this.state.setSizeCap(Number(e.target.value));
                    this.sendUpdate();
                }} fullWidth disabled={this.state.validating} label={"Size Cap"} />
            </Grid>
            {!validated ? <Grid direction="row" container><Alert severity="warning">{"Some changes haven't been validated. Ingestion will be DISABLED when saving."}</Alert></Grid> : null}
            <Grid direction="row" container justify="center">
                {this.state.validating ? <CircularProgress /> : <Button variant="outlined" color="primary" fullWidth disabled={!Boolean(this.state.ingestionParameters.DataConnectionName) || !Boolean(this.state.ingestionParameters.FilePattern) ||
                    !Boolean(this.state.principalType) || !Boolean(this.state.sizeCap) || this.state.validating} onClick={() => {
                        this.state.setValidating(true);
                        this.state.setErrors([]);
                        this.state.setValidated(null);
                        const parameters = this.getParametersObject();
                        const fgName = this.props.audienceContext.audience.FunctionalGroup;
                        this.props.listGroupService.validateBulkIngestion(fgName, parameters).then((op) => {
                            if (op.success) {
                                this.state.setValidated(parameters);
                                this.sendUpdate();
                            } else {
                                this.state.addError(op.message);
                            }
                        }).catch((err) => {
                            this.state.addError(err);
                        }).finally(() => {
                            this.state.setValidating(false);
                        })
                    }}>Validate</Button>}
            </Grid>
            <Grid direction="row" container>
                {this.state.errors && this.state.errors.map((err) => {
                    if (err) {
                        return <Alert severity="error" onClose={() => {
                            this.state.setErrors(this.state.errors.filter(a => a !== err));
                        }}>{`${err}`}</Alert>;
                    } else {
                        return null;
                    }
                })}
                {validated ? <Alert severity="success">{"All changes have been validated. Ingestion will be ENABLED when saving."}</Alert> : null}
            </Grid>
        </React.Fragment>
    }
}

@observer
export class BlobIngestionParametersBuilder extends React.Component<BlobIngestionParametersBuilderProps> {
    @observable anchorEl: any;
    @action setAnchorEl(anchorEl: any) {
        this.anchorEl = anchorEl;
    }
    render() {
        return <React.Fragment>
            <Grid direction="row" container>
                <FormControl fullWidth>
                    <InputLabel id="select-alias-label">Data Connection Name</InputLabel>
                    <Select value={this.props.dataConnectionName} onChange={e => {
                        this.props.onUpdate({
                            DataConnectionName: e.target.value as string,
                            DirectoryPattern: this.props.directoryPattern,
                            FilePattern: this.props.filePattern
                        });
                    }} fullWidth disabled={this.props.disabled} labelId="select-alias-label">
                        {this.props.dataConnections && this.props.dataConnections.map((alias) => {
                            return <MenuItem value={alias}>{alias}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid container>
                <Grid container>
                    <TextField value={this.props.directoryPattern} onChange={e => {
                        this.props.onUpdate({
                            DataConnectionName: this.props.dataConnectionName,
                            DirectoryPattern: e.target.value,
                            FilePattern: this.props.filePattern
                        });
                    }} fullWidth disabled={this.props.disabled} label={"Directory"} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid container direction="row">
                    <Popover
                        open={Boolean(this.anchorEl)}
                        anchorEl={this.anchorEl}
                        onClose={() => { this.setAnchorEl(null); }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        PaperProps={{ style: { maxWidth: '100rem', maxHeight: '40rem' } }}
                    >
                        <Typography style={{ margin: '5px' }}>{`Blob name pattern with supported parameters. The Date (year, month, day) parameters are required. Parameters match .NET DateTime format specification.`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`%Y == Year`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`%M == Month (from 01 to 12)`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`%D == Day`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`%h == Hour (24 hour)`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`%m == Minute`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`%s == Second`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`%s == Part number (0+)`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`Example: Pattern = "blob_%Y_%M%D_%n"`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`"blob_2023_0202_100" -> DateTime 2023-02-02 00:00:00 PartNum 100`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`"blob_2023_0202_010" -> DateTime 2023-02-02 00:00:00 PartNum 10`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`"blobs_2023_0202_100" -> No match`}</Typography>
                        <Typography style={{ margin: '5px' }}>{`"Blob_2023_0202_100" -> No match`}</Typography>
                    </Popover>
                    <Button onClick={(ev) => {
                        this.setAnchorEl(ev.target);
                    }}><HelpIcon />File Pattern Help</Button>
                </Grid>
                <Grid container>
                    <TextField value={this.props.filePattern} onChange={e => {
                        this.props.onUpdate({
                            DataConnectionName: this.props.dataConnectionName,
                            DirectoryPattern: this.props.directoryPattern,
                            FilePattern: e.target.value
                        });
                    }} fullWidth disabled={this.props.disabled} label={"File Pattern"} />
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

class BlobIngestionStatusWidgetState {
    @observable ingestionStatus: string;
    @observable ingestionSummary: string;
    @observable lastProcessedTime: string;
    @observable lastTimestamp: string;
    @observable refreshingIngestionStatus: boolean;
    @observable checkTrackers: boolean;
    @observable trackerProgress: number = -1;
    @observable lastError: string;

    @action
    setIngestionState(ingestionStatus: any) {
        const pipelineState: number = ingestionStatus.PipelineState;
        const knownIssue: boolean = ingestionStatus.Data ? ingestionStatus.Data.KnownIssue : false;
        const retry: boolean = ingestionStatus.Data ? ingestionStatus.Data.Retry : false;
        const lastProcessedTime: Date = ingestionStatus.Data ? ingestionStatus.Data.LastProcessedTime : null;
        const timestamp: Date = ingestionStatus.Data ? ingestionStatus.Data.Timestamp : null;
        const errors: string[] = ingestionStatus.Errors ? ingestionStatus.Errors : null;
        this.lastError = "None";
        let oldCheckTrackers = this.checkTrackers;
        this.checkTrackers = false;
        this.ingestionSummary = "";
        if (knownIssue) {
            this.ingestionStatus = "Disabled";
        } else if (retry) {
            this.ingestionStatus = "Waiting for Retry";
        } else {
            switch (pipelineState) {
                case 0:
                    this.ingestionStatus = "Ready";
                    this.checkTrackers = true;
                    break;
                case 1:
                    this.ingestionStatus = "Queued";
                    break;
                case 2:
                    this.ingestionStatus = "Preparing";
                    break;
                case 3:
                    this.ingestionStatus = "Calculating diffs";
                    break;
                case 4:
                    this.ingestionStatus = "Ingesting";
                    break;
                case 5:
                    this.ingestionStatus = "Error";
                    if (errors && errors.length > 0) {
                        this.lastError = errors[errors.length - 1];
                    }
                    break;
                case 6:
                    this.ingestionStatus = "Queued in Groups";
                    this.checkTrackers = true;
                    break;
                case 7:
                    this.ingestionStatus = "Ingestion Paused";
                    break;
                default:
                    this.ingestionStatus = "In Progress";
                    this.checkTrackers = true;
                    break;
            }
        }

        if (lastProcessedTime) {
            this.lastProcessedTime = moment(lastProcessedTime).fromNow();
        } else {
            this.lastProcessedTime = "Never";
        }

        if (timestamp) {
            this.lastTimestamp = moment(timestamp).fromNow();
        } else {
            this.lastTimestamp = "Never";
        }

        // If we're starting to track ops, reset tracker progress
        if (!oldCheckTrackers && this.checkTrackers) {
            this.trackerProgress = -1;
        }

        this.updateIngestionSummary();
    }

    @action
    setRefreshingIngestionStatus(refreshingIngestionStatus: boolean) {
        this.refreshingIngestionStatus = refreshingIngestionStatus;
    }

    @observable groupCount: number;
    @action setGroupCount(groupCount: number) {
        this.groupCount = groupCount;
    }

    @observable ingestionErrors: string[] = [];
    @action setIngestionErrors(ingestionErrors: string[]) {
        this.ingestionErrors = ingestionErrors;
    }

    @action addIngestionError(err: string) {
        const newErrs = [];
        for (const e of this.ingestionErrors) {
            newErrs.push(e);
        }
        newErrs.push(err);
        this.setIngestionErrors(newErrs);
    }

    @action
    setTrackerProgress(trackerProgress: number) {
        this.trackerProgress = trackerProgress;
    }

    @action
    setCheckTrackers(checkTrackers: boolean) {
        this.checkTrackers = checkTrackers;
    }

    @observable errors: string[] = [];
    @action setErrors(errors: string[]) {
        this.errors = errors;
    }

    @action addError(err: string) {
        const newErrs = [];
        for (const e of this.errors) {
            newErrs.push(e);
        }
        newErrs.push(err);
        this.setErrors(newErrs);
    }

    @action
    setIngestionStatus(ingestionStatus: string) {
        this.ingestionStatus = ingestionStatus;
        this.updateIngestionSummary();
    }

    @action
    updateIngestionSummary() {
        if (this.ingestionStatus === "Ready") {
            this.ingestionSummary = `Group has ~${this.groupCount} device(s).`;
        } else if (this.ingestionStatus === "Ingestion Paused") {
            this.ingestionSummary = "Manually disabled by admin."
        } else if (this.ingestionStatus === "Queued in Groups") {
            this.ingestionSummary = "Waiting for Groups service to process."
        } else if (this.ingestionStatus === "Error") {
            this.ingestionSummary = this.lastError;
        } else {
            this.ingestionSummary = "";
        }
    }

    @observable detailsDialogOpen: boolean;
    @action setDetailsDialogOpen(detailsDialogOpen: boolean) {
        this.detailsDialogOpen = detailsDialogOpen;
    }

    @observable actionInProgress: string;
    @action setActionInProgress(actionInProgress: string) {
        this.actionInProgress= actionInProgress;
    }

    @observable actionSuccesses: string[];
    @action clearActionSuccesses() {
        this.actionSuccesses = [];
    }
    @action addActionSuccess(msg: string) {
        if (!this.actionSuccesses.find(a => a === msg)) {
            this.actionSuccesses.push(msg);
        }
    }
    @action removeActionSuccess(msg: string) {
        this.actionSuccesses = this.actionSuccesses.filter(a => a !== msg);
    }

    @observable actionErrors: string[];
    @action clearActionErrors() {
        this.actionErrors = [];
    }
    @action addActionError(msg: string) {
        if (!this.actionErrors.find(a => a === msg)) {
            this.actionErrors.push(msg);
        }
    }
    @action removeActionError(msg: string) {
        this.actionErrors = this.actionErrors.filter(a => a !== msg);
    }
}

@observer
export class BlobIngestionStatusWidget extends React.Component<TSQueryConfig & GenericIngestionWidgetProps & {
    appId: string,
    groupId: string
}, BlobIngestionStatusWidgetState> {
    readTrackersFunc = null;
    constructor(props) {
        super(props);
        this.state = new BlobIngestionStatusWidgetState();
    }
    componentDidMount() {
        this.refreshIngestionStatus();
        if (!this.readTrackersFunc) {
            this.readTrackersFunc = () => {
                if (this.state.checkTrackers) {
                    this.props.listGroupService.getOperationTrackerStatus(this.props.appId, this.props.groupId)
                        .then((op) => {
                            if (op.success) {
                                this.state.setTrackerProgress(op.data);
                                if (this.state.trackerProgress === 100) {
                                    this.state.setCheckTrackers(false);
                                    this.state.setIngestionStatus("Ready");
                                }
                            }
                        }).finally(() => {
                            setTimeout(this.readTrackersFunc, 60000);
                        });
                } else {
                    setTimeout(this.readTrackersFunc, 1000);
                }
            };
            setTimeout(this.readTrackersFunc, 1);
        }
    }

    refreshIngestionStatus() {
        this.state.setRefreshingIngestionStatus(true);
        this.props.listGroupService.getIngestionStatus(this.props.appId, this.props.groupId)
            .then((result) => {
                if (result.success) {
                    this.state.setIngestionState(result.data);
                    this.props.listGroupService.count(this.props.groupId, this.props.appId).then((op) => {
                        if (op.success) {
                            this.state.setGroupCount(op.data);
                            this.state.updateIngestionSummary();
                        } else {
                            this.state.addIngestionError(op.message);
                        }
                    }).catch((err) => {
                        this.state.addIngestionError(err.toString());
                    }).finally(() => {
                        this.state.setRefreshingIngestionStatus(false);
                    });
                } else {
                    this.state.addIngestionError(result.message);
                    this.state.setRefreshingIngestionStatus(false);
                }
            }).catch((err) => {
                this.state.addIngestionError(err.toString());
                this.state.setRefreshingIngestionStatus(false);
            });
    }

    render() {
        return <React.Fragment>
            <Grid container className="ingestion-status">
                <Grid direction="row" container>
                    <TextField value={this.props.groupId} disabled fullWidth label="Group ID" />
                </Grid>
                {this.state.refreshingIngestionStatus ? <CircularProgress style={{ marginTop: "10px", marginBottom: "10px" }} /> : <div>
                    <Grid direction="row" container>
                        <TextField value={this.state.groupCount} disabled fullWidth label="Count" />
                    </Grid>
                    <Grid direction="row" container>
                        <Typography style={{ fontWeight: 'bold' }}>Ingestion Status:</Typography>
                        <Typography style={{ marginLeft: '5px' }}>{this.state.ingestionStatus}</Typography>
                    </Grid>
                    {this.state.checkTrackers ? <Grid direction="row" container>
                        <Typography style={{ fontWeight: 'bold' }}>{"Progress of sample devices: " + (this.state.trackerProgress >= 0 ? `(${this.state.trackerProgress}%)` : "")}</Typography>
                        {this.state.trackerProgress >= 0 ? <div style={{ width: '100px', marginTop: '10px', marginLeft: '5px' }}><LinearProgress variant="determinate" value={this.state.trackerProgress} /></div> : <Typography style={{ marginLeft: '5px' }}>Pending</Typography>}
                    </Grid> : null}
                    <Grid direction="row" container>
                        <Typography style={{ fontWeight: 'bold' }}>Last Error:</Typography>
                        <Typography style={{ marginLeft: '5px' }} noWrap>{this.state.lastError}</Typography>
                    </Grid>
                    <Grid direction="row" container>
                        <Typography style={{ fontWeight: 'bold' }}>Last Processed:</Typography>
                        <Typography style={{ marginLeft: '5px' }}>{this.state.lastTimestamp}</Typography>
                    </Grid>
                    <Grid direction="row" container>
                        <Typography style={{ fontWeight: 'bold' }}>Data Last Updated:</Typography>
                        <Typography style={{ marginLeft: '5px' }}>{this.state.lastProcessedTime}</Typography>
                    </Grid>
                    <BulkIngestionActions actionInProgress={this.state.actionInProgress}
                    actionSuccesses={this.state.actionSuccesses}
                    actionErrors={this.state.actionErrors}
                    removeActionSuccess={(msg) => {
                        this.state.removeActionSuccess(msg);
                    }}
                    removeActionError={(msg) => {
                        this.state.removeActionError(msg);
                    }}
                    onRefresh={() => {
                        this.refreshIngestionStatus();
                    }}
                    onSetRetry={() => {
                        this.state.setActionInProgress("Flagging for retry");
                        this.state.clearActionSuccesses();
                        this.state.clearActionErrors();
                        this.props.listGroupService.retryBulkIngestion(this.props.parameters.AudienceContext.groupsAppId,
                            this.props.parameters.GroupId).then((op) => {
                                if (op.success) {
                                    this.state.addActionSuccess("Successfully flagged for retry.");
                                    this.refreshIngestionStatus();
                                } else {
                                    this.state.addActionError(op.message);
                                }
                            }).catch((err) => {
                                this.state.addActionError(err.toString());
                            }).finally(() => {
                                this.state.setActionInProgress(null);
                            });
                    }}
                    onRunIngestion={() => {
                        this.state.setActionInProgress("Starting ingestion");
                        this.state.clearActionSuccesses();
                        this.state.clearActionErrors();
                        this.props.listGroupService.runOnDemandIngestion(this.props.parameters.AudienceContext.audience.FunctionalGroup,
                            this.props.parameters.AudienceContext.audience.ShortName).then((op) => {
                                if (op.success) {
                                    for (const prop in op.data) {
                                        this.state.addActionSuccess(`${prop}: ${op.data[prop]}`);
                                    }

                                    this.refreshIngestionStatus();
                                } else {
                                    this.state.addActionError(op.message);
                                }
                            }).catch((err) => {
                                this.state.addActionError(err.toString());
                            }).finally(() => {
                                this.state.setActionInProgress(null);
                            });
                    }} />
                    {this.state.errors && this.state.errors.map((err) => {
                        if (err) {
                            return <Alert severity="error" onClose={() => {
                                this.state.setIngestionErrors(this.state.ingestionErrors.filter(a => a !== err));
                            }}>{`${err}`}</Alert>;
                        } else {
                            return null;
                        }
                    })}
                </div>}
            </Grid>
        </React.Fragment>
    }
}

@observer
export class BlobIngestionStatusSummaryWidget extends React.Component<TSQueryConfig & {
    appId: string,
    groupId: string
}, BlobIngestionStatusWidgetState> {
    readTrackersFunc = null;
    constructor(props) {
        super(props);
        this.state = new BlobIngestionStatusWidgetState();
    }

    componentDidMount() {
        this.refreshIngestionStatus();
        if (!this.readTrackersFunc) {
            this.readTrackersFunc = () => {
                if (this.state.checkTrackers) {
                    this.props.listGroupService.getOperationTrackerStatus(this.props.appId, this.props.groupId)
                        .then((op) => {
                            if (op.success) {
                                this.state.setTrackerProgress(op.data);
                                if (this.state.trackerProgress === 100) {
                                    this.state.setCheckTrackers(false);
                                    this.state.setIngestionStatus("Ready");
                                }
                            }
                        }).finally(() => {
                            setTimeout(this.readTrackersFunc, 60000);
                        });
                } else {
                    setTimeout(this.readTrackersFunc, 1000);
                }
            };
            setTimeout(this.readTrackersFunc, 1);
        }
    }

    refreshIngestionStatus() {
        this.state.setRefreshingIngestionStatus(true);
        this.props.listGroupService.getIngestionStatus(this.props.appId, this.props.groupId)
            .then((result) => {
                if (result.success) {
                    this.state.setIngestionState(result.data);
                    this.props.listGroupService.count(this.props.groupId, this.props.appId).then((op) => {
                        if (op.success) {
                            this.state.setGroupCount(op.data);
                            this.state.updateIngestionSummary();
                        } else {
                            this.state.addIngestionError(op.message);
                        }
                    }).catch((err) => {
                        this.state.addIngestionError(err.toString());
                    }).finally(() => {
                        this.state.setRefreshingIngestionStatus(false);
                    });
                } else {
                    this.state.addIngestionError(result.message);
                    this.state.setRefreshingIngestionStatus(false);
                }
            }).catch((err) => {
                this.state.addIngestionError(err.toString());
                this.state.setRefreshingIngestionStatus(false);
            });
    }

    render() {
        return <React.Fragment>
            <Dialog onClose={() => {
                this.state.setDetailsDialogOpen(false);
            }} aria-labelledby="group-status-dialog-title" open={this.state.detailsDialogOpen} fullWidth>
                <DialogTitle id="group-status-dialog-title">
                    <Typography variant="h6" align="center">{`Detailed Ingestion Status`}</Typography>
                </DialogTitle>
                <div style={{ padding: '10px'}}>
                    <BlobIngestionStatusWidget {...this.props} />
                </div>
            </Dialog>
            <TableRow key={`subgroup-summary-${this.props.groupId}`}>
                <TableCell component="th" scope="row">{this.props.groupId}</TableCell>
                {this.state.refreshingIngestionStatus ?
                    <TableCell colSpan={3}>
                        <LinearProgress variant="indeterminate" />
                    </TableCell> :
                    <React.Fragment>
                        <TableCell component="th" scope="row">{this.state.ingestionStatus}</TableCell>
                        <TableCell component="th" scope="row">{this.state.ingestionSummary}</TableCell>
                        <TableCell>
                            <Button className="ts-btn" type={"button"} onClick={() => {
                                this.state.setDetailsDialogOpen(true);
                            }}>More Details</Button>
                        </TableCell>
                    </React.Fragment>}
            </TableRow>
        </React.Fragment>
    }
}