import * as React from "react";
import { observer } from "mobx-react";
import { Button, Input } from "reactstrap";
import CriteriaService from "../shared/services/CriteriaService";
import { AudienceCriteriaType, } from "ts-infra-common";
import { AttributeKind } from "ts-infra-common";
import { AttributeWorkflowState } from "./AttributeWorkflowState";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import AlertStore from "../shared/stores/AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import AttributeCollectionStore from "../shared/stores/AttributeCollectionStore";
import { AttributeRequestCreatedCollectionResult } from "@ts/ts-ux-contracts";
import AttributeRequestsForAttribute from "../shared/components/AttributeRequestsForAttribute/AttributeRequestsForAttribute";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TestJson } from "./TestJson";
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { AttributeWorkflowView } from "./AttributeWorkflowView";
import { saveAs } from 'file-saver';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";
import config from "../config.json";
import CriteriaStore from "../shared/stores/CriteriaStore";
import { TSCheckbox } from "../shared/components/Checkbox/Checkbox";

interface AttributeWorkflowProps {
    allowCreateOrModify: boolean,
    attributeForm: any,
    attribute: AudienceCriteriaType,
    view: AttributeWorkflowView,
    onApproveReject: ()=>void;
}

@observer
export default class AttributeWorkflow extends React.Component<AttributeWorkflowProps, AttributeWorkflowState> {
    downloadBlobs: any = {};
    constructor(props) {
        super(props);
        this.state = new AttributeWorkflowState(this.props.attribute.Name, this.props.attribute.AttributePropertiesKind, "panel1");
        this.state.events.on("approveRejectDone", () => {
            props.onApproveReject();
        });
    }

    componentDidMount() {
        if (!FunctionalGroupStore.isLoaded) FunctionalGroupStore.fetchAllFunctionalGroups(true);
    }

    getPanelOnChangeHandler = (panelName) => {
        return (ev, isExpanded) => {
            this.state.setCurrentlyExpandedPanel(isExpanded ? panelName : null);
        }
    }

    onSelectedFunctionalGroupChanged = (e: any) => {
        if (e.target) {
            const oldValue = this.state.selectedFunctionalGroup;
            this.state.setSelectedFunctionalGroup(e.target.value);
            if (oldValue !== this.state.selectedFunctionalGroup && this.state.selectedFunctionalGroup !== "-1") {
                this.state.setJsonTested(false);
                this.state.setVerifyingAttributeCollection(true);
                const ac = this.getAttributeCollection(true);
                CriteriaStore.getCriteriaInCollection(ac).then((data) => {
                    if (this.nameOrAliasExistInCollection(data)) {
                        this.state.setSelectedFunctionalGroupVerified(false);
                    } else {
                        this.state.setSelectedFunctionalGroupVerified(true);
                    }

                    this.state.setVerifyingAttributeCollection(false);
                }).catch((err) => {
                    AlertStore.add(AlertType.Error, "Failed to verify if attribute is already in collection.");
                    this.state.setSelectedFunctionalGroupVerified(true);
                    this.state.setVerifyingAttributeCollection(false);
                });
            }
        }
    }

    // checks if the given attribute's name or one of its aliases (if any) is present in the collection.
    nameOrAliasExistInCollection = (collection: AudienceCriteriaType[]) => {
        const nameAndAliases = [this.props.attribute.Name.toLowerCase()];
        if (!!this.props.attribute.Aliases && this.props.attribute.Aliases.length) {
            const aliasesLower = this.props.attribute.Aliases.map(a => a.toLowerCase());
            nameAndAliases.push(...aliasesLower);
        }

        for (const attr of collection) {
            for (const nameOrAlias of nameAndAliases) {
                if (attr.NameLower === nameOrAlias || attr.Aliases?.find(a => a.toLowerCase() === nameOrAlias))
                    return true;
            }
        }
        return false;
    }

    downloadTestJson = () => {
        this.state.setDownloadingTestJson(true);
        CriteriaService.getTestJson(this.state.selectedFunctionalGroup, this.props.attribute.Name, this.props.attribute.AttributePropertiesKind).then((testJson) => {
            if (testJson) {
                const testJsonObj = new TestJson(testJson, this.props.attribute.Name ? this.props.attribute.Name : "UNNAMED_ATTRIBUTE", new Date());
                this.state.addTestJson(testJsonObj);
            } else {
                AlertStore.add(AlertType.Error, "Failed to download test JSON");
            }
            this.state.setDownloadingTestJson(false);
        }).catch((err) => {
            this.state.setDownloadingTestJson(false);
        });
    }

    getDeleteTestJsonHandler = (testJson) => {
        return () => {
            this.state.deleteTestJson(testJson);
        };
    }

    getDownloadHandler = (testJson: TestJson) => {
        return () => {
            saveAs(new Blob([testJson.getJson()]), `${testJson.title}.json`);
        }
    }

    submitAttribute = () => {
        const attributeCollection = this.getAttributeCollection(true);
        if (attributeCollection) {
            this.state.setAttributeSubmissionInProgress(true);
            AttributeCollectionStore.addAttributeToCollection(attributeCollection, this.props.attribute.Name, this.props.attribute.AttributePropertiesKind)
                .then((v) => {
                    this.state.setAttributeSubmissionInProgress(false);
                    if (v.hasOwnProperty("RequestId")) {
                        // A request was created
                        const result: AttributeRequestCreatedCollectionResult = v as AttributeRequestCreatedCollectionResult;
                        AlertStore.add(AlertType.Success, `A new request was created for attribute collection ${attributeCollection} and sent to: ${result.Approvers && result.Approvers.join(", ")}`);
                        this.state.setCurrentlyExpandedPanel("panel5");
                        this.state.events.emit('newRequest', result.Results);
                    } else if (v) {
                        // Assume it was added
                        AlertStore.add(AlertType.Success, `Successfully added this attribute to attribute collection ${attributeCollection}.`);
                    }
                }).catch((err) => {
                    this.state.setAttributeSubmissionInProgress(false);
                });
        }
    }

    getAttributeCollection(includeRedirect: boolean) {
        const matchingFg = FunctionalGroupStore.data.find((fg) => {
            return fg.Name === this.state.selectedFunctionalGroup;
        });
        if (matchingFg) {
            let attributeCollection;
            if (includeRedirect) {
                attributeCollection = matchingFg.CTACRedirectAttributeGroup || (this.isCtacAttribute() ? matchingFg.CTACAttributeGroup : matchingFg.TSAttributeGroup);
            } else {
                attributeCollection = this.isCtacAttribute() ? matchingFg.CTACAttributeGroup : matchingFg.TSAttributeGroup;
            }
            return attributeCollection;
        }

        return null;
    }

    isCtacAttribute() {
        return this.props.attribute.IsCtacAttribute;
    }

    renderCreateView() {
        return <div aria-live="assertive">
            {this.renderAttributeDetailsStepPanel("Create attribute details", false)}
         </div>;
    }

    renderModifyView() {
        return <div>
            {this.renderAttributeDetailsStepPanel("Modify attribute details", true)}
            {this.renderAddToCollectionView()}
        </div>;
    }

    renderReviewView() {
        return <div>
            {this.renderAttributeDetailsStepPanel("Review attribute details", false)}
            {this.renderAttributeCollectionRequests()}
        </div>;
    }

    renderViewView() {
        return <div>
            {this.renderAttributeDetailsStepPanel("Attribute details", true)}
            {this.renderAddToCollectionView()}
        </div>;
    }

    renderAddToCollectionView() {
        return <div><ExpansionPanel expanded={this.state.addToCollectionViewIsOpen && this.state.currentlyExpandedPanel !== "panel1"} onChange={() => this.state.toggleAddToCollectionViewIsOpen()}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="create-or-modify-attribute-panel"
                id="create-or-modify-attribute-panel-header"
            >
                <Typography variant="h6">Want to add this attribute to a functional group or collection? Expand me!</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="stretch"
                >
                    {this.renderSelectFunctionalGroupStepPanel()}
                    {this.isCtacAttribute() ? this.renderTestJsonStep() : null}
                    {this.renderSubmitStep()}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel></div>;
    }

    render() {
        let view;
        switch (this.props.view) {
            case AttributeWorkflowView.Create:
                view = this.renderCreateView();
                break;
            case AttributeWorkflowView.Modify:
                view = this.renderModifyView();
                break;
            case AttributeWorkflowView.Review:
                view = this.renderReviewView();
                break;
            case AttributeWorkflowView.View:
                view = this.renderViewView();
                break;
            default:
                // TODO: View not found?
                break;
        }
        return <div>
            {view}
        </div>
    }

    renderAttributeDetailsStepPanel(title: string, renderNextButton: boolean) {
        return <ExpansionPanel expanded={this.state.currentlyExpandedPanel === "panel1"} onChange={this.getPanelOnChangeHandler("panel1")}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="create-or-modify-attribute-panel"
                id="create-or-modify-attribute-panel-header"
            >
                <Typography variant="h6">{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="stretch"
                >
                    <div>
                        {this.props.attributeForm}
                    </div>
                    {renderNextButton ? <Button className={`ts-btn`} type={"submit"} block onClick={() => {
                        this.state.toggleAddToCollectionViewIsOpen();
                    }} style={{ width: "77%", marginLeft: "auto", marginRight: "auto" }}>Next: Add to a functional group or collection</Button> : ""}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>;
    }

    renderSelectFunctionalGroupStepPanel() {
        return <ExpansionPanel expanded={this.state.currentlyExpandedPanel === "panel2"} onChange={this.getPanelOnChangeHandler("panel2")}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="select-functional-group-panel"
                id="select-functional-group-panel-header"
            >
                <Typography variant="h6">Step 1: Select a functional group or collection</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="stretch"
                >
                        <Typography>
                            Select a functional group or CTAC collection to add this attribute to. Typically this will be the functional group in which the audiences you want to add this new attribute currently reside.
                        </Typography>
                            <Grid
                                container
                                item
                                direction="column"
                                justify="space-evenly"
                                alignItems="stretch"
                            >
                        {/* TODO: Couldn't figure out how to actually disable the form while verifying collection, so just hiding it for now. */}
                        {this.state.verifyingAttributeCollection ? "" : <AvForm>
                            <AvField type="select" name="functionalGroup" label="Functional Group" id="functionalGroup" value={this.state.selectedFunctionalGroup}
                                onChange={this.onSelectedFunctionalGroupChanged} validate={{ required: { value: this.state.selectedFunctionalGroup !== "-1" } }} errorMessage="Required"
                                readOnly={this.state.verifyingAttributeCollection}>
                                <option key="-1" value="-1">Select A Group</option>
                                {FunctionalGroupStore.data.slice().filter(a => (a.CTACAttributeGroup || a.CTACRedirectAttributeGroup || a.TSAttributeGroup) && a.TSAttributeGroup !== "*" && a.CTACAttributeGroup !== "*" && a.CTACRedirectAttributeGroup !== "*").sort((a, b) => a.Name > b.Name ? 1 : -1)
                                    .map((i) => {
                                        let attributeCollection = 'NO CTAC COLLECTION DEFINED';
                                        if (i.CTACAttributeGroup) {
                                            if (i.TSAttributeGroup) {
                                                attributeCollection = `${i.CTACAttributeGroup} / ${i.TSAttributeGroup}`;
                                            } else {
                                                attributeCollection = `${i.CTACAttributeGroup}`;
                                            }
                                        } else {
                                            if (i.TSAttributeGroup) {
                                                attributeCollection = `${i.TSAttributeGroup}`;
                                            }
                                        }
                                        return <option key={i.Name} value={i.Name}>{i.Name} ({attributeCollection})</option>;
                                    })}
                            </AvField>
                        </AvForm>}
                        {this.state.selectedFunctionalGroup && this.state.selectedFunctionalGroup !== "-1" ? <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <Grid
                                container
                                direction="row">
                                    {this.state.verifyingAttributeCollection ? <CircularProgress /> : this.state.selectedFunctionalGroupVerified ? <Done /> : <Clear />}
                                <div style={{ marginLeft: "5px" }}><Typography>{this.state.verifyingAttributeCollection ? "Verifying..." : this.state.selectedFunctionalGroupVerified ? "This attribute can be added to this group." : `This attribute's name already exists in this group. If a new revision is definitely required, contact ${config.helpAlias}.`}</Typography></div>
                            </Grid>
                        </div> : ""}
                            <Button className={`ts-btn ${this.state.selectedFunctionalGroupVerified ? "" : "ts-btn-disabled"}`} type={"submit"} block onClick={() => {
                            if (this.isCtacAttribute()) { this.state.setCurrentlyExpandedPanel("panel3"); } else { this.state.setCurrentlyExpandedPanel("panel4"); }
                            }} style={{ width: "77%", marginLeft: "auto", marginRight: "auto" }} disabled={!this.state.selectedFunctionalGroupVerified}>Next</Button>
                            </Grid>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    }

    renderTestJsonStep() {
        return <ExpansionPanel expanded={this.state.currentlyExpandedPanel === "panel3"} onChange={this.getPanelOnChangeHandler("panel3")} disabled={!this.state.selectedFunctionalGroup || this.state.selectedFunctionalGroup === "-1" || !this.state.selectedFunctionalGroupVerified}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="test-ctac-json-panel"
                id="test-ctac-json-panel-header"
            >
                <Typography variant="h6">Step 2: Test the attribute</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="stretch"
                >
                    <div style={{ margin: "20px" }}>
                        <Typography>
                            1. Generate test CTAC JSON for this attribute and functional group combination, if the attribute has changed since the last time the JSON was generated (or it hasn't been generated at all). This can take some time (around 20s on average).
                        </Typography>
                        <Grid
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <Button className="ts-btn ts-btn-small" type={"submit"} block onClick={this.downloadTestJson} disabled={this.state.downloadingTestJson} style={{ width: "50%" }}>Prepare Test JSON</Button>

                            {this.state.downloadingTestJson ? <CircularProgress /> : null}
                        </Grid>
                    </div>
                    <div style={{ margin: "20px" }}>
                        <Typography>
                            2. Download generated test JSON files.
                    </Typography>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="Test JSON table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Functional Group</TableCell>
                                        <TableCell>Generated On</TableCell>
                                        <TableCell>Download</TableCell>
                                        <TableCell>Delete</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.savedTestJsons.map(json => {
                                        return (
                                            <TableRow key={`${json.title}_${json.generatedTimestamp}`}>
                                                <TableCell component="th" scope="row">{json.title}</TableCell>
                                                <TableCell component="th" scope="row">{json.generatedTimestamp.toLocaleString()}</TableCell>
                                                <TableCell><Button className="ts-btn" type={"button"} block onClick={this.getDownloadHandler(json)}>Download</Button></TableCell>
                                                <TableCell><Button className="ts-btn" type={"submit"} block onClick={this.getDeleteTestJsonHandler(json)}>Delete</Button></TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div style={{ margin: "20px" }}>
                        <Typography>
                            {`3. Run in Command Prompt (replace <parameters>): \\\\winbuilds\\release\\<branch>\\<build>\\amd64fre\\bin\\ctac\\ctac.exe ${this.getAttributeCollection(false)} /JSON <path to JSON>`}
                        </Typography>
                    </div>
                    <div style={{ margin: "20px" }}>
                        <Typography>4. Verify the output. After running the ctac.exe command, you will see the output for all the attributes in the collection you are adding to. The ctac.exe output has four sections:</Typography>
                        <div style={{ marginLeft: "20px" }}>
                            <Typography>
                                a. The output of all attributes present on your device. Verify the newly created attribute is present and contains the expected value. Example:
                                </Typography>
                            <Card>
                                <CardContent>
                                    <Typography>d:\os\bin\amd64fre\ctac{'>'}ctac.exe WU</Typography>
                                    <Typography>IsContainerMgrInstalled=1</Typography>
                                    <Typography>FlightRing=Canary</Typography>
                                    <Typography>TelemetryLevel=3</Typography>
                                    <Typography>...</Typography>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            <Typography>
                                b. The JSON encoding of all the attributes present on your device.
                                </Typography>
                            <Card>
                                <CardContent>
                                    <Typography>d:\os\bin\amd64fre\ctac{'>'}ctac.exe WU</Typography>
                                    <Typography>JSON:</Typography>
                                    <Typography>{'{'}"IsContainerMgrInstalled":"1","FlightRing":"Canary","TelemetryLevel":"3", … {'}'}</Typography>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            <Typography>
                                c. The URI encoding of all the attributes present on your device.
                                </Typography>
                            <Card>
                                <CardContent>
                                    <Typography>d:\os\bin\amd64fre\ctac{'>'}ctac.exe WU</Typography>
                                    <Typography>URI:</Typography>
                                    <Typography>IsContainerMgrInstalled=1&FlightRing=Canary&TelemetryLevel=3&H…</Typography>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            <Typography>
                                d. Any errors retrieving attributes that are defined in the collection. Verify your attribute isn't here, or that an error is expected.
                                </Typography>
                            <Card>
                                <CardContent>
                                    <Typography>d:\os\bin\amd64fre\ctac{'>'}ctac.exe WU</Typography>
                                    <Typography>ERRORS:</Typography>
                                    <Typography>EdgeWithChromiumInstallVersionWow=0x80070002</Typography>
                                    <Typography>PonchAllowWowKey=0x80070002</Typography>
                                    <Typography>DucDeviceModelId=0x86000011</Typography>
                                    <Typography>…</Typography>
                                </CardContent>
                            </Card>
                        </div>
                        <div style={{ margin: "20px" }}>
                            <Typography>5. Acknowledge that you have tested the JSON using the ctac.exe test tool. This will enable you to move on to the next step (Submit the attribute).</Typography>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                            >
                                <FormControlLabel control={<TSCheckbox value="Show disabled audiences" checked={this.state.jsonTested} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.state.setJsonTested(e.target.checked);
                                    if (e.target.checked) {
                                        this.state.setCurrentlyExpandedPanel("panel4");
                                    }
                                }} label="" id="confirm-json-test" aria-label="By checking here you are confirming that you have validated that the attribute returns by testing with the test json provided." />} label="By checking here you are confirming that you have validated that the attribute returns by testing with the test json provided." />
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    }

    renderSubmitStep() {
        return <ExpansionPanel expanded={this.state.currentlyExpandedPanel === "panel4"} onChange={this.getPanelOnChangeHandler("panel4")} disabled={!this.state.selectedFunctionalGroup || this.state.selectedFunctionalGroup === "-1" || (!this.state.jsonTested && this.isCtacAttribute())}>
            <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="submit-request-panel"
                id="submit-request-panel-header"
            >
                <Typography variant="h6">Step {this.isCtacAttribute() ? "3" : "2"}: Submit the attribute</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Typography>
                        Submit the request to add this attribute to the functional group selected. If you are an owner of the underlying attribute collection, it will automatically be added. If not, a new attribute collection request will be generated and sent to the owners.
                    </Typography>

                    <Button className="ts-btn" type={"submit"} block onClick={this.submitAttribute} disabled={this.state.attributeSubmissionInProgress} style={{ width: "50%" }}>{`Submit attribute to collection ${this.getAttributeCollection(false)}`}</Button>

                    {this.state.attributeSubmissionInProgress ? <CircularProgress /> : null}
                </Grid>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    }

    renderAttributeCollectionRequests() {
        return <div style={{ margin: '5px' }}><Typography>
            You can view the state of all past attribute collection requests here.
                    </Typography>
            <AttributeRequestsForAttribute attribute={this.props.attribute} events={this.state.events} /></div>;
    }
}