import Service from "./service";
import { Audience } from "ts-infra-common";

export default class AudienceCriteriaService extends Service {
    static async getAudienceByRevision(fg: string, name: string, revision: string) {
        const response = await this.get(`api/FunctionalGroups/${fg}/Audiences/${name}/${revision}`);
        const json = await response.json();
        return {
            success: response.ok,
            audience: json
        };
    }
}