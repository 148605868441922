import * as React from "react";
import { AttributeKind, AudienceCriteriaType, ApprovalState } from "ts-infra-common";
import { observer } from "mobx-react";
import { observable, runInAction } from "mobx";
import moment from "moment";
import AttributeStore from "../../stores/AttributeStore";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { getRevisionDateTimeString } from "../../helpers/dateTimeHelpers";
import AppInsightsService from '../../services/AppInsightsService';

interface RevisionListProps {
    name: string;
    kind: AttributeKind
}

@observer
export class RevisionList extends React.Component<RevisionListProps> {
    @observable revisions: AudienceCriteriaType[];
    @observable loading: boolean;

    constructor (props) {
        super(props);

        this.load();
    }

    load = async () => {
        this.loading = true;

        const revisions = await AttributeStore.getAllRevisions(this.props.name, this.props.kind);
        revisions.sort((a,b) => {
            return parseInt(b.Revision, null) - parseInt(a.Revision, null);
        });
        runInAction(() => {
            this.revisions = revisions
            this.loading = false;
        });
    }

    renderRevision(revision: AudienceCriteriaType) {
        let message;
        switch(revision.ApprovalState) {
            case ApprovalState.Approved:
                message = `${revision.Changer} approved the attribute`;
                break;
            case ApprovalState.Deleted:
                message = `${revision.Changer} deleted the attribute`;
                break;
            case ApprovalState.Live:
                message = "Attribute is live";
                break;
            case ApprovalState.Rejected:
                message = `${revision.Changer} rejected the attribute. Reason: ${revision.RejectionReason}`;
                break;
            case ApprovalState.Submitted:
                message = `${revision.Changer} submitted the attribute`;
                break;
            case ApprovalState.Testing:
                message = `${revision.Changer} saved the attribute`;
                break;
        }

        return (
            <div key={revision.Revision}>{moment(getRevisionDateTimeString(revision.Revision)).fromNow()}: {message}</div>
        )
    }

    render() {
        if (this.loading) return <LoadingIndicator text="Loading Revisions..."/>
        else if (this.revisions == null) return null;

        return (
            <div>
                <b>Revisions:</b>
                {this.revisions.map(this.renderRevision)}
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(RevisionList);