import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { HttpGet, HttpPost } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class EmailSubscriptionStore {
    @observable data: string[];
    @observable http: HttpGet<string[]>;
    @observable httpPost: HttpPost;

    @computed
    get isCreateInProgress() {
        return this.httpPost != null && this.httpPost.inProgress;
    }

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    async fetch(path: string, refresh: boolean = false) {
        if (!this.http || refresh) {
            this.data = null;
            this.http = HttpOperation.get<string[]>(config.adal.clientId, config.api.endpointUrl + path);
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                   this.data = this.http.data;
                }
                else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, "Failed to fetch api data.");
                }
            });
        }
    }

    @action
    async fetchAllUserSubscriptions(user: string, refresh: boolean) {
        await this.fetch(`/api/Subscriptions/Email/${user}`, refresh);
    }

    @action
    async UnsubscribeUser(subID: string, fgName: string, audName: string) {
        const url = `/api/Subscriptions/Email/FunctionalGroups/${fgName}${audName?`/Audiences/${audName}`:""}/Unsubscribe`;
        this.httpPost = HttpOperation.post(config.adal.clientId, config.api.endpointUrl + url, {});
        await this.httpPost.wait();
        runInAction(() => {
            if(this.httpPost.success){
                AlertStore.add(AlertType.Success, `Successfully unsubscribed from: ${subID}`);
            }
            else{
                AlertStore.add(AlertType.Error, this.httpPost.errorMessage);
            }
        });
        return await this.httpPost.wait();
    }

    @action
    async SubscribeUser(subID: string, fgName: string, audName: string) {
        const url = `/api/Subscriptions/Email/FunctionalGroups/${fgName}${audName?`/Audiences/${audName}`:""}/Subscribe`;
        this.httpPost = HttpOperation.post(config.adal.clientId, config.api.endpointUrl + url, {});
        await this.httpPost.wait();
        runInAction(() => {
            if(this.httpPost.success){
                AlertStore.add(AlertType.Success, `Successfully Subscribed to: ${subID}`);
            }
            else{
                AlertStore.add(AlertType.Error, this.httpPost.errorMessage);
            }
        });
        return await this.httpPost.wait();
    }
}

const store = new EmailSubscriptionStore();
export default store;