import React from "react";
import { observer } from "mobx-react";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import { CustomAttributeFieldComponentState } from "./CustomAttributeFieldComponentState";
import Grid from "@material-ui/core/Grid";
import TSCustomPopper from "./TSCustomPopper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import isNil from "lodash/isNil";
@observer
export class CustomAttributeFieldComponent extends React.Component<{ items, setField, selectedKey, readonly, placeholder, id, label, infoProvider?} & TSWidgetOptionsProps, CustomAttributeFieldComponentState> {
    constructor(props) {
        super(props);
        this.state = new CustomAttributeFieldComponentState();
    }
    render() {
        const selectedItem = this.props.items.find(i => i.path === this.props.selectedKey);
        const selectedItemInfo = selectedItem && this.props.infoProvider && this.props.infoProvider(selectedItem);
        const enableInfoTooltip = isNil(this.props.disableAttributeInfoHover) || !this.props.disableAttributeInfoHover;
        const enableInfoPopover = isNil(this.props.disableAttributeInfoPopover) || !this.props.disableAttributeInfoPopover;
        return <Grid container direction="row" style={{ display: 'inline-flex' }} alignItems="center" aria-live="assertive"><Autocomplete
            PopperComponent={TSCustomPopper}
            style={{ width: `${this.state.width}px` }}
            disabled={this.props.readonly}
            id={this.props.id}
            options={this.props.items}
            getOptionLabel={(option) => (option as any).label}
            getOptionSelected={(option: any, value: any) => {
                return option.path === value.path;
            }}
            renderInput={(params) => {
                params["inputProps"]["aria-haspopup"] = true;
                const textField = <TextField {...params} label={this.props.label} variant="filled" id={`criteria-${this.props.id}`} size="small" />;
                if (!selectedItem || !selectedItem.label) {
                    return textField;
                } else {
                    return <Tooltip title={<Typography variant="caption">{selectedItem ? selectedItem.label : "no attribute"}</Typography>} arrow placement="bottom">{textField}</Tooltip>;
                }
            }}
            renderOption={(option, { selected }) => {
                const info = this.props.infoProvider && this.props.infoProvider(option);
                if (info && enableInfoTooltip) {
                    return <Tooltip title={<Typography variant="caption">{info}</Typography>} arrow placement="left">
                        <Typography>{option.label}</Typography>
                    </Tooltip>;
                } else {
                    return <Typography>{option.label}</Typography>;
                }
            }}
            onChange={(e, value) => {
                if (value === undefined) {
                    return;
                }
                this.props.setField(value.path);
            }}
            value={selectedItem ?? ''}
        />{selectedItemInfo && enableInfoPopover ? <React.Fragment><Popover
            open={Boolean(this.state.anchorEl)}
            anchorEl={this.state.anchorEl}
            onClose={() => { this.state.setAnchorEl(null); }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            PaperProps={{ style: { maxWidth: '20rem', maxHeight: '20rem' } }}
        ><Typography style={{ padding: '15px' }}>{selectedItemInfo}</Typography></Popover><IconButton onClick={(ev) => { this.state.setAnchorEl(ev.target); }} style={{ width: '10px', height: '10px', marginLeft: '10px' }}><InfoIcon /></IconButton></React.Fragment> : null}</Grid>
    }
}