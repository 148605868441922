import { observable, action } from "mobx";
import { Config } from "react-awesome-query-builder";
import { AudienceCriteriaType } from "ts-infra-common";
import { AudienceQueryProvider } from "./AudienceQueryProvider";

export class TSQueryBuilderState {
    @observable tree: any;
    @observable audienceQueryProvider: AudienceQueryProvider;
    @observable configJson: string;
    @observable qbConfig: Config;
    constructor(audienceQueryProvider: AudienceQueryProvider) {
        this.audienceQueryProvider = audienceQueryProvider;
    }

    @action updateConfig(data: AudienceCriteriaType[]) {
        if (data) {
            this.qbConfig = this.audienceQueryProvider.getQueryBuilderConfigForCriteria(data);
        } else {
            this.qbConfig = this.audienceQueryProvider.config;
        }
        this.configJson = JSON.stringify(this.qbConfig);
    }

    @action updateTree(tree: any) {
        this.tree = tree;
    }
}