import { observer } from "mobx-react";
import React from "react";
import { Label } from "reactstrap";
import { CheckboxProps } from "./CheckboxProps";

@observer
export class TSCheckbox extends React.Component<CheckboxProps> {
    render() {
        return <div className={this.props.className}>
            <input id={this.props.id} type="checkbox" key={this.props.id}
                onChange={(ev) => this.props.onChange(ev, ev.target.checked)}
                checked={this.props.checked} aria-label={this.props["aria-label"] ?? this.props.label} value={this.props.value} /> <Label for={this.props.id}>{this.props.label}</Label>
        </div>
    }
}