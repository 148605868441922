import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { v4 as createGuid } from "node-uuid";
import { TSQueryConfig } from "./TSQueryConfig";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import { AlertType, ICriteriaService } from "@ts/ts-ux-contracts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TSCustomPopper from "./TSCustomPopper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import isNil from "lodash/isNil";
import Chip from "@material-ui/core/Chip";
import copy from 'copy-to-clipboard';
@observer
export class CustomTextWidget extends React.Component<{ value, setValue, field?, operator?, config?, readonly?, min?, max?, step?, placeholder?, customProps?, isNumber?: boolean } & TSQueryConfig & TSWidgetOptionsProps> {
    @observable text: string;
    @observable textFieldText: string;
    @observable textArray: any[];
    @observable warning: string = '';
    @observable typingTimeout: number = 0;
    @observable suggestions: string[] = [];
    @observable loadingSuggestions: boolean = true;
    @observable width: number = 250;
    @observable autoCompleteId: string;
    @observable error: string = '';
    @action setError(error: string) {
        this.error = error;
    }
    @action setTextFieldText(textFieldText: string) {
        this.textFieldText = textFieldText;
    }
    @action setText(text: string) {
        this.text = text;
        if (this.text) {
            this.autoCompleteId = createGuid();
        }
        // NOTE: Hardcoded global validation should be replaced by per-field validation when/if something more complicated is required later
        if (!this.text) {
            if (this.props.onFieldValidationChange) {
                this.setError("Value required");
                this.props.onFieldValidationChange(this.widgetId, false);
            }
        }
        else {
            if (this.props.onFieldValidationChange) {
                this.setError(null);
                this.props.onFieldValidationChange(this.widgetId, true);
            }
        }
    }
    @action addText(text: string) {
        const items = text.split(",").filter(a => !!a).map(a => a.trim());
        for (const item of items) {
            this.textArray.push(item);
        }
        this.text = this.textArray.join(",");
        if (this.text) {
            this.autoCompleteId = createGuid();
        }
        // NOTE: Hardcoded global validation should be replaced by per-field validation when/if something more complicated is required later
        if (!this.text) {
            if (this.props.onFieldValidationChange) {
                this.setError("Value required");
                this.props.onFieldValidationChange(this.widgetId, false);
            }
        }
        else {
            if (this.props.onFieldValidationChange) {
                this.setError(null);
                this.props.onFieldValidationChange(this.widgetId, true);
            }
        }
        if (this.text) {
            for (const item of this.textArray) {
                if (!item) {
                    if (this.props.onFieldValidationChange) {
                        this.setError("Value required");
                        this.props.onFieldValidationChange(this.widgetId, false);
                    }
                }
                else {
                    if (this.props.onFieldValidationChange) {
                        this.setError(null);
                        this.props.onFieldValidationChange(this.widgetId, true);
                    }
                }
            }
        }
    }
    widgetId;
    constructor(props) {
        super(props);
        this.widgetId = createGuid();
        this.autoCompleteId = createGuid();
    }

    @action
    componentDidMount() {
        this.setText(typeof this.props.value !== "undefined" ? this.props.value + "" : "");
        this.textArray = this.text ? this.text.split(",").map((v) => v.trim()) : [];
        if (this.loadingSuggestions) {
            this.props.criteriaService.getValidValues(this.props.field, false).then((op) => {
                runInAction(() => {
                    if (op.success) {
                        for (const suggestion of op.data) {
                            if (!this.props.isNumber || Number.isInteger(parseInt(suggestion, 10))) {
                                this.suggestions.push(suggestion);
                            }
                        }
                    }
                });
            }).catch((err) => {
                runInAction(() => {
                    this.warning = "Failed to load suggestions";
                });
            }).finally(() => {
                runInAction(() => {
                    this.loadingSuggestions = false;
                });
            });
        }
    }

    componentWillUnmount() {
        if (this.props.onFieldValidationChange) {
            this.setError(null);
            this.props.onFieldValidationChange(this.widgetId, true);
        }
    }

    @action
    updateWarning = (message: string): void => {
        this.warning = message;
    }

    async checkValidity(criteriaName: string, val: string) {
        const criteriaService: ICriteriaService = this.props.criteriaService;
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        if (val !== null && val !== '') {
            const response = await criteriaService.isValidValue(criteriaName, val);
            if (response === null) {
                this.updateWarning('');
            } else if (response.success) {
                const data: any = response.data;
                this.updateWarning(data.Result ? '' : data.Message);
            } else {
                this.updateWarning('');
            }
        } else {
            this.updateWarning('');
        }
    }

    handleOnBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        let val = event.target.value;
        this.setTextFieldText(null);
        if (val && val.trim()) {
            if (this.isArrayOperator) {
                this.addText(val);
            } else {
                this.setText(val);
            }

            this.props.setValue(this.text);
            if (this.isArrayOperator) {
                val = this.getLastElement(val.split(",").map((v) => v.trim()));
            }
            this.checkValidity(this.props.field, val);
        }
    }

    @action
    handleChange = (event: React.ChangeEvent<HTMLInputElement>, val): void => {
        this.setText(val);
        this.props.setValue(this.text);
        this.setTextFieldText(null);
        if (this.isArrayOperator) {
            val = this.getLastElement(val.split(",").map((v) => v.trim()));
        }
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        const criteriaName = this.props.field;
        const self = this;
        this.typingTimeout = window.setTimeout(action(() => {
            self.checkValidity(criteriaName, val);
        }), 1000);
    }

    @action
    handleChangeArray = (event: React.ChangeEvent<HTMLInputElement>, val): void => {
        const newTextArray = [];
        for (const item of val) {
            for (const itemSplit of item.split(",").map((v) => v.trim())) {
                if (itemSplit) {
                    newTextArray.push(itemSplit);
                }
            }
        }
        this.textArray = newTextArray;
        const joined = this.textArray.join(",");
        this.props.setValue(joined);
        this.setText(joined);
        this.setTextFieldText(null);
    }

    @action
    handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let val = event.target.value;
        this.setText(val);
        this.setTextFieldText(null);
        this.props.setValue(this.text);
        if (this.isArrayOperator) {
            val = this.getLastElement(val.split(",").map((v) => v.trim()));
        }

        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        const criteriaName = this.props.field;
        const self = this;
        this.typingTimeout = window.setTimeout(action(() => {
            self.checkValidity(criteriaName, val);
        }), 1000);
    }

    handleChangeTextFieldNoChange = (ev) => {
        let val = ev.target.value;
        this.setTextFieldText(val);
        if (this.isArrayOperator) {
            val = this.getLastElement(val.split(",").map((v) => v.trim()));
        }

        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        const criteriaName = this.props.field;
        const self = this;
        this.typingTimeout = window.setTimeout(action(() => {
            self.checkValidity(criteriaName, val);
        }), 1000);
    }

    render() {
        const inputStyle = {
            color: '#FF8800'
        }
        // If isNumber is true, props should be NumberWidgetProps
        const inputProps = this.props.isNumber ? {
            type: 'number',
            min: this.props.min,
            max: this.props.max,
            step: this.props.step,
            readOnly: this.props.readonly,
            placeholder: this.props.placeholder
        } : {
            readOnly: this.props.readonly,
            placeholder: this.props.placeholder
        };
        const enableSuggestions = isNil(this.props.disableAttributeSuggestions) || !this.props.disableAttributeSuggestions;
        let autoCompleteProps;
        if (enableSuggestions) {
            if (this.isArrayOperator) {
                autoCompleteProps = {
                    options: this.suggestions ?? [],
                    multiple: true,
                    value: this.textArray ?? [],
                    onChange: this.handleChangeArray,
                    loading: this.loadingSuggestions,
                    limitTags: 5,
                    renderTags: (v: any[], getTagProps: any) => {
                        return v.map((option, index) => {
                            const tagProps = getTagProps({ index });
                            return <Chip label={option} key={option} {...tagProps} clickable onClick={() => {
                                copy(option);
                                this.props.alertStore.add(AlertType.Info, `Copied '${option}' to clipboard`);
                            }} />;
                        });
                    }
                };
            } else {
                autoCompleteProps = {
                    options: this.suggestions ?? [],
                    value: this.text ?? "",
                    onChange: this.handleChange,
                    loading: this.loadingSuggestions
                };
            }
        } else {
            autoCompleteProps = {
                options: [],
                value: this.text ?? "",
                onChange: this.handleChange
            };
        }
        const hasPendingValue = Boolean(this.textFieldText);
        return <div>
            <Autocomplete
                PopperComponent={TSCustomPopper}
                disabled={this.props.readonly}
                renderInput={(params) => {
                    const textField = <TextField error={Boolean(this.error)} helperText={this.error} {...params} label="Value" variant="filled" id={`criteria-value-${this.widgetId}`} size="small" {...inputProps} style={{ width: `${this.width}px`, borderRight: hasPendingValue ? "2px solid #ffa500" : null }} onChange={this.handleChangeTextFieldNoChange} />;
                    return <Tooltip title={<Typography variant="caption">{this.textFieldText ? this.textFieldText : this.text ? this.text : 'no text'}</Typography>} arrow placement="bottom">{textField}</Tooltip>;
                }}
                freeSolo
                onBlur={this.handleOnBlur}
                key={`autocomplete-${this.autoCompleteId}`}
                {...autoCompleteProps}
            />
            <label style={inputStyle} aria-label="Warning for criteria value">{this.warning}</label>
        </div>;
    }

    get isArrayOperator() {
        return this.props.operator === "in" || this.props.operator === "not_in" || this.props.operator === "contains_in" || this.props.operator === "not_contains_in";
    }

    getLastElement(arr: any[]) {
        return arr[arr.length - 1];
    }
}
