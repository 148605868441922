import { observable, action } from 'mobx';

export class SubscriptionListState {
    @observable currentUser: string = '';
    @observable checkedSubscriptions: any[] = [];

    @action
    updateUser(val: string) {
        this.currentUser = val;
    }

    @action setCheckedSubscriptions = (values: any[]) => {
        this.checkedSubscriptions = values;
    }
}