import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { EstimationResult, Audience } from "ts-infra-common";
import { HttpGet, HttpPostWithResponse } from '../services/HttpOperation';
import { HttpOperation } from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class EstimationStore {
    @observable data: EstimationResult = null;
    @observable http: HttpGet<EstimationResult>;
    @observable onDemandEstimationOp: HttpPostWithResponse<EstimationResult>;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    async fetch(path: string, refresh: boolean = false) {
        if (!this.http || refresh) {
            this.http = HttpOperation.get<EstimationResult>(config.adal.clientId, config.api.endpointUrl + path)
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                    this.data = this.http.data
                }
                else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, `EstimationStore failed to fetch api data. Message: ${this.http.errorMessage}`);
                }
            });
        }
    }

    @action
    async fetchEstimate(funcGroupName: string, audienceName: string, refresh: boolean, revision?: string) {
        const rev = (revision ? `/${revision}` : '');
        await this.fetch(`/api/Estimate/FunctionalGroups/${funcGroupName}/Audiences/${audienceName}${rev}/v2`, refresh);
    }

    @action
    async fetchEstimateByAudience(auds: Audience[]) {
        this.onDemandEstimationOp = HttpOperation.postWithResponse<EstimationResult>(config.adal.clientId, config.api.endpointUrl + '/api/Estimate/Audience/SizesForDeviceTypes/v2', auds);
    }
}

const store = new EstimationStore();
export default store;
