import React from "react";
import { enUS } from "@material-ui/core/locale";
import { AlertType, AudienceContext } from "@ts/ts-ux-contracts";
import { Config, RuleValue, Field, ValueField, JsonTree, JsonGroup, Utils, SelectFieldSettings } from "react-awesome-query-builder";
import { AudienceCriteria, AudienceCriteriaGroup, AudienceCriteriaKind, AudienceCriteriaType, CriteriaGroupOperator, CriteriaOperator } from "ts-infra-common";
import { AttributeExampleWrapper } from "./AttributeExampleWrapper";
import MaterialConfig from "react-awesome-query-builder/lib/config/material";
import * as Immutable from "immutable";
import { List as ImmutableList, Map as ImmutableMap } from "immutable";
import { CustomTextWidget } from "./CustomTextWidget";
import { CustomSwitchWidget } from "./CustomSwitchWidget";
import { ListGroupWidget } from "./ListGroupWidget";
import { CosmosWidget } from "./CosmosWidget";
import { BulkIngestionWidget } from "./BulkIngestionWidget";
import { MinSliderWidget } from "./MinSliderWidget";
import { v4 as createGuid } from "node-uuid";
import { CustomMaterialButton } from "./CustomMaterialButton";
import { CustomConjunctionsComponent } from "./CustomConjunctionsComponent";
import { CustomAttributeFieldComponent } from "./CustomAttributeFieldComponent";
import { TSQueryConfig } from "./TSQueryConfig";
import { QueryBuilderWidgets } from "./QueryBuilderWidgets";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import isNil from "lodash/isNil";
const QBConfig = MaterialConfig;

export class AudienceQueryProvider {
    TypeMapping = [
        { acType: AudienceCriteriaKind.ListBasedGroupId, qbType: 'text' },
        { acType: AudienceCriteriaKind.CosmosStreamTxt, qbType: 'cosmosstream' },
        { acType: AudienceCriteriaKind.BulkIngestion, qbType: 'bulkingestion' },
        { acType: AudienceCriteriaKind.String, qbType: 'text' },
        { acType: AudienceCriteriaKind.Number, qbType: 'number' },
        { acType: AudienceCriteriaKind.Version, qbType: 'version' },
        { acType: AudienceCriteriaKind.Boolean, qbType: 'boolean' },
        { acType: AudienceCriteriaKind.Enum, qbType: 'select' },
        { acType: AudienceCriteriaKind.RolloutPercentage, qbType: 'percentageSlider' },
        { acType: AudienceCriteriaKind.Date, qbType: 'number' },
        { acType: AudienceCriteriaKind.MSAListBasedGroupId, qbType: 'listgroup' },
        { acType: AudienceCriteriaKind.SqmListBasedGroupId, qbType: 'listgroup' }
    ];

    ConjunctionMapping = [
        { acConjunction: CriteriaGroupOperator.And, qbConjunction: 'AND' },
        { acConjunction: CriteriaGroupOperator.Or, qbConjunction: 'OR' },
        { acConjunction: CriteriaGroupOperator.Not, qbConjunction: 'not' },
    ];

    OperatorMapping = [
        { acOperator: CriteriaOperator.Contains, qbOperator: 'contains' },
        { acOperator: CriteriaOperator.ContainsIn, qbOperator: 'contains_in' },
        { acOperator: CriteriaOperator.EndsWith, qbOperator: 'ends_with' },
        { acOperator: CriteriaOperator.Equals, qbOperator: 'equal' },
        { acOperator: CriteriaOperator.Exists, qbOperator: 'exist' },
        { acOperator: CriteriaOperator.GreaterThan, qbOperator: 'greater' },
        { acOperator: CriteriaOperator.GreaterThanOrEqualTo, qbOperator: 'greater_or_equal' },
        { acOperator: CriteriaOperator.In, qbOperator: 'in' },
        { acOperator: CriteriaOperator.LessThan, qbOperator: 'less' },
        { acOperator: CriteriaOperator.LessThanOrEqualTo, qbOperator: 'less_or_equal' },
        { acOperator: CriteriaOperator.NotContainsIn, qbOperator: 'not_contains_in' },
        { acOperator: CriteriaOperator.NotEquals, qbOperator: 'not_equal' },
        { acOperator: CriteriaOperator.NotExists, qbOperator: 'not_exist' },
        { acOperator: CriteriaOperator.NotGreaterThan, qbOperator: 'less_or_equal' },
        { acOperator: CriteriaOperator.NotGreaterThanOrEqualTo, qbOperator: 'less' },
        { acOperator: CriteriaOperator.NotIn, qbOperator: 'not_in' },
        { acOperator: CriteriaOperator.NotLessThan, qbOperator: 'greater_or_equal' },
        { acOperator: CriteriaOperator.NotLessThanOrEqualTo, qbOperator: 'greater' },
        { acOperator: CriteriaOperator.StartsWith, qbOperator: 'starts_with' },
    ];

    DefaultValueMappingByKind = [
        { kind: AudienceCriteriaKind.Boolean, value: false },
        { kind: AudienceCriteriaKind.Number, value: 0 },
        { kind: AudienceCriteriaKind.RolloutPercentage, value: 0 }
    ];

    DefaultValueMappingByName = [
        { name: 'cosmosstreamtxt', value: '{ "Enabled": false, "CosmosPath": "", "SizeCap": 0, "RefreshIntervalSeconds": 0, "PrincipalType": 1 }' }
    ];

    config: Config;
    invalidFields: string[] = [];
    lastFormValidationState: boolean = true;
    audienceContext: AudienceContext;
    constructor(private tsQueryConfig: TSQueryConfig,
        private onMissingAttribute: (attributeName: string) => void,
        readOnly: boolean,
        widgets?: QueryBuilderWidgets,
        private widgetOptions?: TSWidgetOptionsProps,
        private onFormValidationChanged?: (formValid: boolean) => void,
        audienceContext?: AudienceContext) {
        
        this.audienceContext = audienceContext;

        const wrapInfo = (widgetFactory) => {
            if (!widgetOptions || isNil(widgetOptions.disableAttributeExamplePopover) || !widgetOptions.disableAttributeExamplePopover) {
                return (props) => <AttributeExampleWrapper {...props} {...tsQueryConfig} {...widgetOptions}>{widgetFactory(props)}</AttributeExampleWrapper>;
            } else {
                return widgetFactory;
            }
        };
        const wrapFormValidation = (widgetFactory) => {
            return (props) => {
                // tslint:disable-next-line:no-string-literal
                props["onFieldValidationChange"] = (widgetId: string, fieldValid: boolean) => {
                    if (fieldValid) {
                        this.invalidFields = this.invalidFields.filter(a => a !== widgetId);
                    } else {
                        if (!this.invalidFields.find(a => a === widgetId)) {
                            this.invalidFields.push(widgetId);
                        }
                    }

                    const isFormValid = !Boolean(this.invalidFields && this.invalidFields.length > 0);
                    if (isFormValid) {
                        if (!this.lastFormValidationState) {
                            this.lastFormValidationState = isFormValid;
                            if (this.onFormValidationChanged) {
                                this.onFormValidationChanged(isFormValid);
                            }
                        }
                    } else {
                        if (this.lastFormValidationState) {
                            this.lastFormValidationState = isFormValid;
                            if (this.onFormValidationChanged) {
                                this.onFormValidationChanged(isFormValid);
                            }
                        }
                    }
                };
                return widgetFactory(props);
            };
        };
        this.config = {
            ...QBConfig,
            fields: {}, // Filled in later by getQueryBuilderConfigForCriteria
            conjunctions: {
                'AND': {
                    label: 'And',
                    reversedConj: 'or',
                    // Different versions of Immutable - it SEEMS to be okay for now
                    formatConj: (children, conj, not, isForDisplay) => this.formatGroup(children as any, conj, not),
                    mongoConj: "$and",
                    sqlFormatConj: (children, conj, not) => {
                        return children.size > 1
                            ? (not ? "NOT " : "") + "(" + children.join(" " + "AND" + " ") + ")"
                            : (not ? "NOT (" : "") + children.first() + (not ? ")" : "");
                    }
                },
                'OR': {
                    label: 'Or',
                    reversedConj: 'and',
                    formatConj: (children, conj, not, isForDisplay) => this.formatGroup(children as any, conj, not),
                    mongoConj: "$or",
                    sqlFormatConj: (children, conj, not) => {
                        return children.size > 1
                            ? (not ? "NOT " : "") + "(" + children.join(" " + "OR" + " ") + ")"
                            : (not ? "NOT (" : "") + children.first() + (not ? ")" : "");
                    }
                }
            },

            operators: {
                'equal': {
                    label: 'Equals',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_equal',
                },
                'not_equal': {
                    label: 'Not equals',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'equal',
                },
                'less': {
                    label: 'Less than',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_less',
                },
                'less_or_equal': {
                    label: 'Less than or equal',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_less_or_equal',
                },
                'greater': {
                    label: 'Greater than',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_greater',
                },
                'greater_or_equal': {
                    label: 'Greater than or equal',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_greater_or_equal',
                },
                'not_less': {
                    label: 'Not less than',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'less',
                },
                'not_less_or_equal': {
                    label: 'Not less than or equal',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'less_or_equal',
                },
                'not_greater': {
                    label: 'Not greater than',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'greater',
                },
                'not_greater_or_equal': {
                    label: 'Not greater than or equal',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'greater_or_equal',
                },
                'contains': {
                    label: 'Contains',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_contains',
                },
                'contains_in': {
                    label: 'Contains in',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_contains_in',
                },
                'not_contains_in': {
                    label: 'Not contains in',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'contains_in',
                },
                'starts_with': {
                    label: 'Starts with',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_starts_with',
                },
                'ends_with': {
                    label: 'Ends with',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_ends_with',
                },
                'exist': {
                    label: 'Exists',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    cardinality: 0,
                    reversedOp: 'not_exist',
                },
                'not_exist': {
                    label: 'Does not exist',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    cardinality: 0,
                    reversedOp: 'exist',
                },
                'in': {
                    label: 'In',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'not_in',
                },
                'not_in': {
                    label: 'Not in',
                    formatOp: (field, op, value) => this.formatRule(field, op, value),
                    reversedOp: 'in',
                },
            },

            types: {
                'cosmosstream': {
                    defaultOperator: 'not_equal',
                    widgets: {
                        'cosmosstream': {
                            operators: [
                                'equal',
                                'not_equal',
                                'exist',
                                'not_exist',
                            ],
                        },
                    },
                },
                'bulkingestion': {
                    defaultOperator: 'equal',
                    widgets: {
                        'bulkingestion': {
                            operators: [
                                'equal',
                                'exist',
                                'not_exist',
                            ],
                        },
                    },
                },
                'text': {
                    defaultOperator: 'equal',
                    widgets: {
                        'text': {
                            operators: [
                                'equal',
                                'not_equal',
                                'starts_with',
                                'ends_with',
                                'exist',
                                'not_exist',
                                'contains',
                                'in',
                                'not_in',
                                'contains_in',
                                'not_contains_in'
                            ],
                        },
                        'select': {
                            operators: [
                                'equal',
                                'not_equal',
                            ],
                        },
                        'multiselect': {
                            operators: [
                                'in',
                                'not_in',
                                'contains_in',
                                'not_contains_in',
                            ],
                        },
                    },
                },
                'number': {
                    defaultOperator: 'equal',
                    widgets: {
                        'number': {
                            operators: [
                                'equal',
                                'not_equal',
                                'less',
                                'less_or_equal',
                                'greater',
                                'greater_or_equal',
                                'not_less',
                                'not_less_or_equal',
                                'not_greater',
                                'not_greater_or_equal',
                                'exist',
                                'not_exist',
                            ],
                        },
                    },
                },
                'version': {
                    defaultOperator: 'equal',
                    widgets: {
                        'version': {
                            operators: [
                                'equal',
                                'not_equal',
                                'less',
                                'less_or_equal',
                                'greater',
                                'greater_or_equal',
                                'not_less',
                                'not_less_or_equal',
                                'not_greater',
                                'not_greater_or_equal',
                                'exist',
                                'not_exist',
                            ]
                        },
                    },
                },
                'select': {
                    defaultOperator: 'equal',
                    widgets: {
                        'select': {
                            operators: [
                                'equal',
                                'not_equal',
                                'exist',
                                'not_exist',
                            ],
                        },
                        'multiselect': {
                            operators: [
                                'in',
                                'not_in',
                            ],
                        },
                    },
                },
                'boolean': {
                    defaultOperator: 'equal',
                    widgets: {
                        'boolean': {
                            operators: [
                                'equal',
                                'not_equal',
                                'exist',
                                'not_exist',
                            ],
                        },
                    },
                },
                'listgroup': {
                    defaultOperator: 'equal',
                    widgets: {
                        'listgroup': {
                            operators: [
                                'equal',
                                'not_in',
                                'exist',
                                'not_exist',
                            ],
                        },
                    },
                },
                'percentageSlider': {
                    defaultOperator: 'equal',
                    widgets: {
                        'percentageSlider': {
                            operators: [
                                'equal'
                            ],
                        },
                    },
                },
            },

            widgets: {
                'text': {
                    type: "text",
                    valueSrc: 'value',
                    factory: wrapInfo(wrapFormValidation(widgets && widgets.textWidget ? widgets.textWidget : (props) => <CustomTextWidget {...props} {...this.tsQueryConfig} />)),
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return val;
                    }
                },
                'version': {
                    type: "version",
                    valueSrc: 'value',
                    factory: wrapInfo(widgets && widgets.versionWidget ? widgets.versionWidget : (props) => <CustomTextWidget {...props} {...this.tsQueryConfig} />),
                    validateValue: val => /^\d*(\.\d*){0,3}$/.test(val),
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return val;
                    },
                },
                'number': {
                    ...QBConfig.widgets.number,
                    type: "number",
                    valueSrc: 'value',
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return String(val);
                    },
                    factory: wrapInfo(widgets && widgets.numberWidget ? widgets.numberWidget : (props) => <CustomTextWidget {...props} {...this.tsQueryConfig} isNumber={true} />)
                },
                'select': {
                    ...QBConfig.widgets.select,
                    type: "select",
                    valueSrc: 'value',
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return val;
                    },
                    factory: wrapInfo(widgets && widgets.selectWidget ? widgets.selectWidget : QBConfig.widgets.select.factory)
                },
                'multiselect': {
                    ...QBConfig.widgets.multiselect,
                    type: "multiselect",
                    valueSrc: 'value',
                    formatValue: (val: string[]) => `${val.join(',')}`,
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    factory: wrapInfo(widgets && widgets.multiSelectWidget ? widgets.multiSelectWidget : QBConfig.widgets.multiselect.factory)
                },
                'boolean': {
                    ...QBConfig.widgets.boolean,
                    type: "boolean",
                    valueSrc: 'value',
                    formatValue: (val: boolean, fieldDef: Field) => this.formatBooleanValue(val, fieldDef),
                    labelYes: "1",
                    labelNo: "0",
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    factory: wrapInfo(widgets && widgets.booleanWidget ? widgets.booleanWidget : (props) => <CustomSwitchWidget {...props} {...this.tsQueryConfig} {...this.widgetOptions} />)
                },
                'listgroup': {
                    type: 'listgroup',
                    valueSrc: 'value',
                    factory: wrapInfo(widgets && widgets.listGroupWidget ? widgets.listGroupWidget : (props) => <ListGroupWidget {...props} {...this.tsQueryConfig} />),
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return val;
                    }
                },
                'cosmosstream': {
                    type: 'cosmosstream',
                    valueSrc: 'value',
                    factory: wrapInfo(wrapFormValidation(widgets && widgets.cosmosStreamWidget ? widgets.cosmosStreamWidget : (props) => <CosmosWidget {...props} {...this.tsQueryConfig} {...{audienceContext: this.audienceContext}} />)),
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return val;
                    }
                },
                'bulkingestion': {
                    type: 'bulkingestion',
                    valueSrc: 'value',
                    factory: wrapInfo(wrapFormValidation(widgets && widgets.bulkIngestionWidget ? widgets.bulkIngestionWidget : (props) => <BulkIngestionWidget {...props} {...this.tsQueryConfig} {...{audienceContext: this.audienceContext}}/>)),
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return val;
                    }
                },
                'percentageSlider': {
                    type: "percentageSlider",
                    valueSrc: 'value',
                    factory: wrapInfo(widgets && widgets.percentageSliderWidget ? widgets.percentageSliderWidget : (props) => <MinSliderWidget {...props} />),
                    sqlFormatValue: (val: RuleValue) => {
                        return val;
                    },
                    formatValue: (val: RuleValue) => {
                        return val;
                    },
                    marks: { 0: '0%', 50: '50%', 100: '100%' }
                },
            },

            settings: {
                ...QBConfig.settings,
                locale: {
                    material: enUS
                },
                maxLabelsLength: 50,
                dropdownPlacement: 'bottomRight',
                hideConjForOne: true,
                renderSize: 'small',
                customFieldSelectProps: {
                    showSearch: true
                },
                groupActionsPosition: 'topRight',
                setOpOnChangeField: ['keep', 'default'],
                clearValueOnChangeField: true,
                clearValueOnChangeOp: false,
                maxNesting: 10,
                showLabels: false,
                showNot: true,
                valuePlaceholder: "Value",
                fieldPlaceholder: "Select attribute",
                operatorPlaceholder: "Select operator",
                deleteLabel: 'Remove criteria',
                addGroupLabel: "Add criteria group",
                addRuleLabel: "Add criteria",
                notLabel: "Not",
                delGroupLabel: 'Remove criteria group',
                valueSourcesPopupTitle: "Select value source",
                canLeaveEmptyGroup: true,
                formatReverse: (q, operator, reversedOp, operatorDefinition, revOperatorDefinition, isForDisplay) => {
                    return '';
                },
                formatField: (field, parts, label2, fieldDefinition, config, isForDisplay) => {
                    return field;
                },
                valueSourcesInfo: {
                    'value': {
                        label: "Value"
                    },
                },
                canReorder: true,
                canCompareFieldWithField: () => true,
                renderField: (props) => {
                    const itemsArray = JSON.parse(JSON.stringify(props.items));
                    const id = createGuid();
                    if (widgets && widgets.fieldWidget) {
                        return widgets.fieldWidget(id, itemsArray, props);
                    } else {
                        return <CustomAttributeFieldComponent label="Name" id={id} items={itemsArray} setField={props.setField} selectedKey={props.selectedKey} readonly={props.readonly} placeholder={props.placeholder}
                            infoProvider={(option) => {
                                const criteria = this.tsQueryConfig.criteriaStore.data.find(a => a.Name === option.label);
                                if (criteria && criteria.Description) {
                                    return criteria.Description.trim();
                                } else {
                                    return null;
                                }
                            }} {...widgetOptions} />;
                    }
                },
                renderButton: (props) => {
                    return <CustomMaterialButton {...props} />
                },
                renderOperator: (props) => {
                    const itemsArray = JSON.parse(JSON.stringify(props.items));
                    const id = createGuid();
                    if (widgets && widgets.fieldWidget) {
                        return widgets.fieldWidget(id, itemsArray, props);
                    } else {
                        return <CustomAttributeFieldComponent label="Operator" id={id} items={itemsArray} setField={props.setField} selectedKey={props.selectedKey} readonly={props.readonly} placeholder={props.placeholder} />;
                    }
                },
                renderConjs: (props) => {
                    const id = createGuid();
                    if (widgets && widgets.conjWidget) {
                        return widgets.conjWidget(id, props);
                    }
                    return <CustomConjunctionsComponent {...props} id={id} />;
                },
                immutableGroupsMode: readOnly,
                immutableFieldsMode: readOnly,
                immutableOpsMode: readOnly,
                immutableValuesMode: readOnly
            }
        };
    }

    toQBType(type: AudienceCriteriaKind): string {
        const mapping = type && this.TypeMapping.find(t => t.acType === type);
        return mapping ? mapping.qbType : 'text';
    }

    toQBConjunction(conj: CriteriaGroupOperator): string {
        const mapping = conj && this.ConjunctionMapping.find(o => o.acConjunction === conj);
        return mapping ? mapping.qbConjunction : 'AND';
    }

    toACConjunction(conj: string): CriteriaGroupOperator {
        const mapping = conj && this.ConjunctionMapping.find(o => o.qbConjunction === conj);
        return mapping ? mapping.acConjunction : CriteriaGroupOperator.And;
    }

    toQBOperator(op: CriteriaOperator): string {
        const mapping = op !== null && typeof op !== "undefined" && this.OperatorMapping.find(o => o.acOperator === op);
        return mapping ? mapping.qbOperator : 'equal';
    }

    toACOperator(op: string): CriteriaOperator {
        const mapping = op !== null && typeof op !== "undefined" && this.OperatorMapping.find(o => o.qbOperator === op);
        return mapping ? mapping.acOperator : CriteriaOperator.Equals;
    }

    // Determines if the operator accept multiple right side parameters (operates against a list of values)
    isMultiValueOperator(op: CriteriaOperator): boolean {
        return (op === CriteriaOperator.In || op === CriteriaOperator.NotIn || op === CriteriaOperator.ContainsIn || op === CriteriaOperator.NotContainsIn);
    }

    // Determines if the type context is being treated as a string type
    isTextType(type: string): boolean {
        return type === 'text';
    }

    // Interprets the criteria's string value of a boolean type criteria - 1/true/True are true, and 0/false/False are false
    isTrueValue(boolStr: string): boolean {
        return /^(true|1)$/i.test(boolStr);
    }

    // Converts the string value to a contextually typed value
    getTypedValue(val: string, type: string, isMultiOp: boolean) {
        if (isMultiOp) {
            if (typeof val === "string") {
                return val.split(",");
            } else if (Array.isArray(val)) {
                return (val as []).join(",");
            }
        }
        if (type === 'boolean') return this.isTrueValue(val);
        if (type === 'number') return Number(val);

        return val;
    }

    formatGroup(children: ImmutableList<string>, conj: string, not: boolean) {
        if (children && children.size > 0) {
            const rules = children.filter(i => JSON.parse(i).Type);
            const groups = children.filter(i => Object.keys(JSON.parse(i)).includes('Criteria'));
            let groupJson = `{
                "Operator": ${this.toACConjunction(conj)},
                "Criteria": [ ${rules.join(', ')} ],
                "CriteriaGroups": [ ${groups.join(', ')} ]
            }`;
            if (not) {
                const groupObject = JSON.parse(groupJson);
                if (groupObject.Criteria.length === 1 && (!groupObject.CriteriaGroups || groupObject.CriteriaGroups.length === 0)) {
                    groupJson = `{
                        "Operator": ${this.toACConjunction('not')},
                        "Criteria": [${JSON.stringify(groupObject.Criteria[0])}],
                        "CriteriaGroups": []
                    }`;
                } else {
                    groupJson = `{
                        "Operator": ${this.toACConjunction('not')},
                        "Criteria": [],
                        "CriteriaGroups": [ ${groupJson} ]
                    }`;
                }
            }
            return groupJson;
        }
        return '';
    }

    formatRule(field: string, op: string, value: string | string[]) {
        // The else case is hit when there's a new criteria/rule
        const serverCriteria = this.tsQueryConfig.criteriaStore.data.find(c => c.Name === field);
        if (Array.isArray(value) && value.length > 0) {
            value = value.join();
        }
        if (typeof value !== "undefined" && value !== null) {
            value = value + "";
        } else {
            value = "";
        }
        // This value seems to be created by query builder itself
        if (value === "List []") {
            value = "";
        }
        let criteria;
        if (value && value.startsWith("{")) {
            criteria = JSON.parse(value);
        }
        // BulkIngestionParameters also has a Type prop
        if (criteria && criteria.hasOwnProperty("Type") && !criteria.hasOwnProperty("SizeCap")) {
            // Replace the Type with the new type
            criteria.Type = serverCriteria;
            // This logic of when to clear GroupId might need to be more complicated, but for now this seems to work
            if (!serverCriteria || AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.DistributionList] ||
                !(AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.ListBasedGroupId] ||
                    AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.MSAListBasedGroupId] ||
                    AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.SqmListBasedGroupId] ||
                    AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.AADAccounts] ||
                    AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.RolloutPercentage]) ||
                ((AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.ListBasedGroupId] ||
                    AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.MSAListBasedGroupId] ||
                    AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.SqmListBasedGroupId]) &&
                    // tslint:disable-next-line:triple-equals
                    criteria.GroupId != criteria.Value)) {
                criteria.GroupId = null;
            }
            return JSON.stringify(criteria);
        } else {
            let groupId = null;
            if (serverCriteria && (AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.ListBasedGroupId] ||
                AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.MSAListBasedGroupId] ||
                AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.SqmListBasedGroupId]) &&
                value && Number.isInteger(parseInt(value[0], null))) {
                groupId = value;
            } else if (serverCriteria && (AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.CosmosStreamTxt] ||
                AudienceCriteriaKind[serverCriteria.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.BulkIngestion])) {
                    try{
                        const def = JSON.parse(value);
                        if (def.hasOwnProperty("GroupId")) {
                            groupId = def.GroupId;
                            delete def["GroupId"];
                            delete def["AudienceContext"];
                            value = JSON.stringify(def);
                        }
                    } catch {}
            }
            return `{
                "Type": ${JSON.stringify(serverCriteria)},
                "Value": ${JSON.stringify(value)},
                "Operator": ${this.toACOperator(op)},
                "GroupId": ${JSON.stringify(groupId)}
            }`;
        }
    }

    // Converts a boolean value to the string value representing it in the field's valid values (comes from original criteria type).
    formatBooleanValue(val: boolean, fieldDefinition: Field) {
        return String((fieldDefinition.fieldSettings as any).listValues[String(val)]);
    }

    // Converts a criteria type to a field configuration
    toQueryBuilderField(criteriaType: AudienceCriteriaType): Field {
        const type = this.toQBType(criteriaType.Kind);
        const widgetPropsConfig = {
            preferWidgets: (this.isTextType(type) && criteriaType.AreValuesStatic)
                ? ['select', 'multiselect']
                : null,
        };
        const field: ValueField & Field = {
            label: criteriaType.Name || criteriaType.id,
            type,
            listValues: criteriaType.AreValuesStatic && criteriaType.ValidValues && Object.assign({},
                ...(type === 'boolean'
                    ? criteriaType.ValidValues.map(v => ({ [String(this.isTrueValue(v))]: v }))
                    : criteriaType.ValidValues.map(v => ({ [v]: v })))),
            operators: criteriaType.ApplicableOperators && criteriaType.ApplicableOperators.map(o => this.toQBOperator(o)),
            ...widgetPropsConfig,
        };
        const defaultValueByName = this.DefaultValueMappingByName.filter(d => d.name === criteriaType.NameLower);
        const defaultValueByKind = this.DefaultValueMappingByKind.filter(d => AudienceCriteriaKind[d.kind] === AudienceCriteriaKind[criteriaType.Kind]);
        if (defaultValueByName && defaultValueByName.length > 0) {
            field.defaultValue = defaultValueByName[0].value;
        } else if (defaultValueByKind && defaultValueByKind.length > 0) {
            field.defaultValue = defaultValueByKind[0].value;
        }
        if (type === 'boolean' && !field.listValues) field.listValues = { 'true': 'true', 'false': 'false' };
        if (criteriaType.Kind === AudienceCriteriaKind.RolloutPercentage) field.fieldSettings = { min: 0, max: 100 };
        return field;
    }

    // Extracts a rule from the query tree
    toQueryBuilderRule(criteria: AudienceCriteria, currPath: string[], audienceContext: AudienceContext) {
        let matchingType;
        if (this.tsQueryConfig.criteriaStore.data) {
            matchingType = this.tsQueryConfig.criteriaStore.data.find(c => c.Name === criteria.Type.Name || (c.LegacyNames && c.LegacyNames.find(ln => ln === criteria.Type.Name)));
        }
        if (!this.tsQueryConfig.criteriaStore.data || !matchingType) {
            this.tsQueryConfig.alertStore.add(AlertType.Error, `${criteria.Type.Name} is not a valid attribute in this Functional Group. Associated criteria was removed. The change will be persisted if you save this audience. Contact tshelp@microsoft.com to resolve.`);
            if (this.onMissingAttribute) {
                this.onMissingAttribute(criteria.Type.Name);
            }
            return null;
        }
        const id = createGuid();
        const path: string[] = [...currPath, id];
        const type = this.toQBType(matchingType.Kind);
        // Special handling for boolean ValidValues
        if (matchingType.ValidValues && matchingType.ValidValues.length === 1 && matchingType.ValidValues[0] === "True") {
            if (criteria.Value === "true") {
                criteria.Value = "True";
            }
        }
        // Add the criteria to the rule node, which in this version of react-awesome-query-builder is an Immutable Map, so this extra prop sticks
        let qbOperator = this.toQBOperator(criteria.Operator);
        // Fix up older CosmosStreamTxt criteria
        if (type && (type === "cosmosstream" || type === "bulkingestion")) {
            if (qbOperator === "not_equal") {
                qbOperator = "equal";
            }
            const parsedCosmosDef = JSON.parse(criteria.Value);
            parsedCosmosDef.GroupId = criteria.GroupId;
            parsedCosmosDef.AudienceContext = audienceContext;
            criteria.Value = JSON.stringify(parsedCosmosDef);
        }
        const criteriaNode: any = {
            type: 'rule',
            properties: {
                field: matchingType.Name,
                operator: qbOperator,
                valueSrc: ['value'],
                value: [this.getTypedValue(criteria.Value, type, this.isMultiValueOperator(criteria.Operator))],
                valueType: [this.isTextType(type) ? (matchingType.AreValuesStatic ? (this.isMultiValueOperator(criteria.Operator) ? 'multiselect' : 'select') : 'text') : type],
            },
            criteria,
            id
        };
        return criteriaNode;
    }

    // Extracts a group from the query tree
    toQueryBuilderGroup(criteria: AudienceCriteriaGroup, currPath: string[], audienceContext: AudienceContext): JsonTree {
        let not = false;
        if (criteria && criteria.Operator === CriteriaGroupOperator.Not) {
            not = true;
            if (criteria.CriteriaGroups && criteria.CriteriaGroups.length > 0) {
                criteria = criteria.CriteriaGroups[0];
            }
        }

        const id = createGuid();
        const path: string[] = [...currPath, id];
        const conj = this.toQBConjunction(criteria && criteria.Operator);
        let children;
        if (criteria && criteria.Criteria && criteria.Criteria.length > 0) {
            children = criteria.Criteria.map(c => this.toQueryBuilderRule(c, path, audienceContext)).filter(c => !!c).concat(criteria.CriteriaGroups.map(g => this.toQueryBuilderGroup(g, path, audienceContext))) || [];
        } else if (criteria && criteria.CriteriaGroups && criteria.CriteriaGroups.length > 0) {
            children = criteria.CriteriaGroups.map(g => this.toQueryBuilderGroup(g, path, audienceContext)) || [];
        } else {
            const defaultChildId = createGuid();
            children = [{
                "type": "rule",
                "id": defaultChildId,
                "properties": {
                    "field": null,
                    "operator": null,
                    "value": [],
                    "valueSrc": [],
                    "operatorOptions": null
                },
                "path": [id, defaultChildId]
            }];
        }
        const queryBuilderGroup: JsonGroup = {
            id,
            type: 'group',
            properties: {
                conjunction: conj,
                not,
            },
            children1: Object.assign({}, ...children.map(c => ({ [c.id]: c }))),
        };
        return queryBuilderGroup;
    }

    // Gets the initial query builder tree object based on the audience criteria
    getQueryBuilderTree(criteria: AudienceCriteriaGroup, audienceContext: AudienceContext): JsonTree {
        return this.toQueryBuilderGroup(criteria, [], audienceContext);
    }

    // Converts the criteria types to field definitions and merges them with the base configuration
    getQueryBuilderConfigForCriteria(criteria: AudienceCriteriaType[]): Config {
        let fields = [];
        if (criteria) {
            fields = criteria.map(c => this.toQueryBuilderField(c)).sort((a, b) => a.label.localeCompare(b.label));
        }
        const qbConfig: Config = {
            ...this.config,
            fields: Object.assign({}, ...fields.map(f => ({ [f.label]: f }))),
        };
        return qbConfig;
    }

    // The generic QueryBuilder algorithm can produce a NOT AudienceCriteriaGroup with a single criteria contained in a subgroup.
    // This should be simplified by moving the single criteria out of the subgroup and into the NOT group.
    // Otherwise, the server-side update will fail.
    simplifyCriteriaNotGroups(criteria: AudienceCriteriaGroup) {
        if (criteria) {
            const fixedCriteria = JSON.parse(JSON.stringify(criteria));
            if (fixedCriteria.Operator === CriteriaGroupOperator.Not) {
                if (fixedCriteria.CriteriaGroups && fixedCriteria.CriteriaGroups.length === 1 && fixedCriteria.CriteriaGroups[0].Criteria && fixedCriteria.CriteriaGroups[0].Criteria.length === 1 && (!fixedCriteria.CriteriaGroups[0].CriteriaGroups || fixedCriteria.CriteriaGroups[0].CriteriaGroups.length === 0)) {
                    fixedCriteria.Criteria = [fixedCriteria.CriteriaGroups[0].Criteria[0]];
                    return fixedCriteria;
                }
            }

            if (fixedCriteria.CriteriaGroups) {
                const newGroups = [];
                for (const group of fixedCriteria.CriteriaGroups) {
                    newGroups.push(this.simplifyCriteriaNotGroups(group));
                }

                fixedCriteria.CriteriaGroups = newGroups;
            }

            return fixedCriteria;
        } else {
            return criteria;
        }
    }

    // Reverse the effect of simplifyCriteriaNotGroups into a tree structure that QueryBuilder can understand.
    expandCriteriaNotGroups(criteria: AudienceCriteriaGroup) {
        if (criteria) {
            const fixedCriteria = JSON.parse(JSON.stringify(criteria));
            if (fixedCriteria.Operator === CriteriaGroupOperator.Not) {
                if (fixedCriteria.Criteria && fixedCriteria.Criteria.length === 1) {
                    const dummyGroup = new AudienceCriteriaGroup();
                    dummyGroup.Operator = CriteriaGroupOperator.And;
                    dummyGroup.CriteriaGroups = [];
                    dummyGroup.Criteria = [criteria.Criteria[0]];
                    fixedCriteria.CriteriaGroups = [dummyGroup];
                    fixedCriteria.Criteria = [];
                    return fixedCriteria;
                }
            }

            if (fixedCriteria.CriteriaGroups) {
                const newGroups = [];
                for (const group of fixedCriteria.CriteriaGroups) {
                    newGroups.push(this.expandCriteriaNotGroups(group));
                }

                for (const criteriaExpression of fixedCriteria.Criteria) {
                    this.fixCriteriaValidValue(criteriaExpression);
                }

                fixedCriteria.CriteriaGroups = newGroups;
            }

            return fixedCriteria;
        } else {
            return criteria;
        }
    }

    // If criteria value is one of the valid values but with different casing, just fix it
    // Still requires a save
    fixCriteriaValidValue(criteria: AudienceCriteria) {
        if (criteria.Type.ValidValues) {
            for (const vv of criteria.Type.ValidValues) {
                if (vv !== criteria.Value && vv.toLowerCase() === criteria.Value.toLowerCase()) {
                    criteria.Value = vv;
                    break;
                }
            }
        }
    }

    fixArrayCriteriaValues(criteriaGroup: AudienceCriteriaGroup) {
        if (criteriaGroup.Criteria) {
            for (const criteria of criteriaGroup.Criteria) {
                if (Array.isArray(criteria.Value)) {
                    criteria.Value = (criteria.Value as unknown as []).join(",");
                }
            }
        }

        if (criteriaGroup.CriteriaGroups) {
            for (const criteriaSubGroup of criteriaGroup.CriteriaGroups) {
                this.fixArrayCriteriaValues(criteriaSubGroup);
            }
        }

        return criteriaGroup;
    }

    getTree(criteria: AudienceCriteriaGroup, audienceContext: AudienceContext) {
        return this.getQueryBuilderTree(this.expandCriteriaNotGroups(criteria), audienceContext);
    }

    getGroupValue(g: AudienceCriteriaGroup, level: number = 1) {
        if (!g) return '';
        const conj = this.toQBConjunction(g.Operator).toUpperCase() + '\n';
        // This is to normalize the true/false True/False mismatch between UX and data that sometimes exists on legacy audiences
        const normalizeTrueFalse = (tfValue: string) => {
            if (tfValue === "true" || tfValue === "True" || tfValue === "false" || tfValue === "False") {
                return tfValue.toLowerCase();
            }
            return tfValue;
        }
        const rules = g.Criteria ? g.Criteria.map(c => `${' '.repeat(level * 2)}- ${c.Type.Name} : ${this.toQBOperator(c.Operator)} : ${normalizeTrueFalse(c.Value)}`).join('\n') : null;
        const groups = g.CriteriaGroups ? g.CriteriaGroups.map(sub => {
            const groupVal = this.getGroupValue(sub, level + 1);
            if (!groupVal) return null;
            return `${' '.repeat(level * 2)}+ ${groupVal}`;
        }).filter(i => !!i).join('\n') : null;
        if (!rules && !groups) return '';
        return g ? `${conj}${rules}${rules && groups ? '\n' : ''}${groups}` : '';
    }

    getSerializedCriteria(tree) {
        if (!tree.hasOwnProperty('has')) {
            tree = Immutable.fromJS(
                tree,
                (key, value) => {
                    const indexedListValue = value as Immutable.Collection.Indexed<any>;
                    return (key === 'value' && indexedListValue.get(0) && indexedListValue.get(0).toJS !== undefined)
                        ? Immutable.List.of(indexedListValue.get(0).toJS())
                        : Immutable.isIndexed(value)
                            ? value.toList()
                            : value.toOrderedMap();
                }
            );
        }
        const modifiedTree = this.setTreeNodeValues(tree);
        let workingQuery = Utils.queryString(modifiedTree, this.config);
        if (workingQuery) {
            let parsed = JSON.parse(workingQuery);
            parsed = this.simplifyCriteriaNotGroups(parsed);
            parsed = this.fixArrayCriteriaValues(parsed);
            workingQuery = JSON.stringify(parsed);
        } else {
            workingQuery = "{}";
        }
        return workingQuery;
    }

    setTreeNodeValues(tree: any) {
        if (tree.has("criteria")) {
            let criteria = tree.get("criteria");
            const criteriaParsed = JSON.parse(JSON.stringify(criteria));
            let propsTree = tree.get("properties");
            const propsField = propsTree.get("field");
            if (criteriaParsed.Type && criteriaParsed.Type.Name !== propsField) {
                // No special modifications needed
                return tree;
            }
            if (criteriaParsed.Type && (AudienceCriteriaKind[criteriaParsed.Type.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.ListBasedGroupId] ||
                AudienceCriteriaKind[criteriaParsed.Type.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.MSAListBasedGroupId] ||
                AudienceCriteriaKind[criteriaParsed.Type.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.SqmListBasedGroupId] ||
                AudienceCriteriaKind[criteriaParsed.Type.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.AADAccounts] ||
                AudienceCriteriaKind[criteriaParsed.Type.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.DistributionList] ||
                AudienceCriteriaKind[criteriaParsed.Type.Kind] === AudienceCriteriaKind[AudienceCriteriaKind.RolloutPercentage])) {
                let propsValue = propsTree.get("value");
                const firstValue = propsValue.get(0);
                let firstValueParsed;
                try {
                    firstValueParsed = JSON.parse(JSON.stringify(firstValue));
                } catch { }
                if (firstValueParsed && firstValueParsed.hasOwnProperty("Type")) {
                    criteria = criteria.withMutations((m) => {
                        m.set('Value', firstValueParsed.Value);
                    });
                } else {
                    criteria = criteria.withMutations((m) => {
                        m.set('Value', firstValue);
                    });
                }
                propsValue = propsValue.withMutations((list) => {
                    while (list.size > 0) {
                        list.pop();
                    }

                    list.push(JSON.stringify(criteria));
                });
                propsTree = propsTree.withMutations((map) => {
                    map.set("value", propsValue);
                });
                tree = tree.withMutations((map) => {
                    map.set("properties", propsTree);
                });
            }
        }

        if (tree.has('children1')) {
            let children = tree.get("children1");
            children = children.withMutations((map) => {
                map.forEach((v, k) => {
                    map.set(k, this.setTreeNodeValues(v))
                });
            });
            tree = tree.withMutations((map) => {
                map.set("children1", children);
            });
        }

        return tree;
    }
}