import { observable, action } from "mobx";
import { TestJson } from "./TestJson";
import config from "../config.json";
import { EventEmitter } from "events";
import { AttributeKind } from "ts-infra-common";
export class AttributeWorkflowState {
    testJsonCacheKey: string = "tstestjsons";
    @observable currentlyExpandedPanel: string;
    @observable selectedFunctionalGroup: string = "-1";
    @observable selectedBranch: string = "main";
    @observable selectedBuild: string;
    @observable selectedArchitecture: string = "amd64fre";
    @observable downloadingTestJson: boolean = false;
    @observable savedTestJsons: TestJson[];
    @observable jsonTested: boolean = false;
    @observable attributeSubmissionInProgress: boolean = false;
    @observable addToCollectionViewIsOpen: boolean = false;
    @observable verifyingAttributeCollection: boolean = false;
    @observable selectedFunctionalGroupVerified: boolean = false;

    events: EventEmitter = new EventEmitter();

    constructor(attributeName: string, attributePropertiesKind: AttributeKind, currentlyExpandedPanel?: string) {
        this.testJsonCacheKey = this.testJsonCacheKey + "_" + attributeName + "_" + attributePropertiesKind;
        this.currentlyExpandedPanel = currentlyExpandedPanel;
        this.savedTestJsons = [];
        const savedJsons = sessionStorage.getItem(this.testJsonCacheKey);
        if (savedJsons) {
            const testJsons = JSON.parse(savedJsons).sort((a, b) => {
                return b.generatedTimestampRaw - a.generatedTimestampRaw;
            });
            for (const testJson of testJsons) {
                const testJsonObj = new TestJson();
                testJsonObj.compressedJson = testJson.compressedJson;
                testJsonObj.generatedTimestamp = testJson.generatedTimestamp;
                testJsonObj.generatedTimestampRaw = testJson.generatedTimestampRaw;
                testJsonObj.title = testJson.title;
                this.savedTestJsons.push(testJsonObj);
            }
        }
    }

    @action toggleAddToCollectionViewIsOpen() {
        this.addToCollectionViewIsOpen = !this.addToCollectionViewIsOpen;
        if (this.addToCollectionViewIsOpen) {
            this.setCurrentlyExpandedPanel("panel2");
        }
    }

    @action setCurrentlyExpandedPanel(currentlyExpandedPanel: string) {
        this.currentlyExpandedPanel = currentlyExpandedPanel;
    }

    @action setSelectedFunctionalGroup(selectedFunctionalGroup: string) {
        this.selectedFunctionalGroup = selectedFunctionalGroup;
    }

    @action setSelectedBranch(selectedBranch: string) {
        this.selectedBranch = selectedBranch;
    }

    @action setSelectedBuild(selectedBuild: string) {
        this.selectedBuild = selectedBuild;
    }

    @action setSelectedArchitecture(selectedArchitecture: string) {
        this.selectedArchitecture = selectedArchitecture;
    }

    @action setDownloadingTestJson(downloadingTestJson: boolean) {
        this.downloadingTestJson = downloadingTestJson;
    }

    @action addTestJson(testJson: TestJson) {
        this.savedTestJsons.unshift(testJson);
        sessionStorage.setItem(this.testJsonCacheKey, JSON.stringify(this.savedTestJsons));
    }

    @action deleteTestJson(testJson: TestJson) {
        const index = this.savedTestJsons.indexOf(testJson);
        if (index > -1) {
            this.savedTestJsons.splice(index, 1);
            sessionStorage.setItem(this.testJsonCacheKey, JSON.stringify(this.savedTestJsons));
        }
    }

    @action setJsonTested(jsonTested: boolean) {
        this.jsonTested = jsonTested;
    }

    @action setAttributeSubmissionInProgress(attributeSubmissionInProgress: boolean) {
        this.attributeSubmissionInProgress = attributeSubmissionInProgress;
    }

    @action setVerifyingAttributeCollection(verifyingAttributeCollection: boolean) {
        this.verifyingAttributeCollection = verifyingAttributeCollection;
    }

    @action setSelectedFunctionalGroupVerified(selectedFunctionalGroupVerified: boolean) {
        this.selectedFunctionalGroupVerified = selectedFunctionalGroupVerified;
    }
}