import { observable, action, IObservableArray, computed } from "mobx";
import { Alert, AlertType, IAlertStore } from "@ts/ts-ux-contracts";

export class AlertStoreV2 implements IAlertStore {
    @observable alerts = [] as IObservableArray<Alert>;

    @action
    add = (type: AlertType, message: string) => {
        const alert = new Alert(type, message);
        if (!this.alerts.find(a => a.type === type && a.message === message)) {
            this.alerts.push(alert);
        }
        setTimeout(() => this.clear(alert), 30000);
    }

    @action
    clear = (alert: Alert) => {
        this.alerts.remove(alert);
    }

    @action
    clearAll = () => {
        this.alerts.clear();
    }

    @computed
    get hasAlert() {
        return this.alerts.length > 0;
    }

    getAlertColor(type: AlertType) {
        switch (type) {
            case AlertType.Error: return "danger";
            case AlertType.Info: return "info";
            case AlertType.Success: return "success";
            case AlertType.Warning: return "warning";

            default: return "primary";
        }
    }
}

const store = new AlertStoreV2();
export default store;
