import React from "react";
import { observer } from "mobx-react";
import { v4 as createGuid } from "node-uuid";
import { WidgetProps } from "react-awesome-query-builder";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CosmosWidgetState } from "./CosmosWidgetState";
import { CustomCheckbox } from "./CustomCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TSQueryConfig } from "./TSQueryConfig";
import Button from "@material-ui/core/Button";
import { Dialog, FormControl, InputLabel, CircularProgress, LinearProgress } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import { GenericIngestionWidgetProps } from "./GenericIngestionWidgetProps";
import { BulkIngestionType } from "ts-infra-common";
import { CosmosIngestionParametersBuilderProps } from "./CosmosIngestionParametersBuilderProps";
import { BulkIngestionActions } from "./BulkIngestionActions";

@observer
export class CosmosWidget extends React.Component<WidgetProps & TSQueryConfig & TSWidgetOptionsProps & GenericIngestionWidgetProps, CosmosWidgetState> {
    widgetId;
    readTrackersFunc = null;
    constructor(props) {
        super(props);
        this.state = new CosmosWidgetState();
        this.widgetId = createGuid();
    }

    componentDidMount() {
        if (this.props.value) {
            this.state.updateQuery(JSON.parse(this.props.value));
        } else {
            this.state.updateQuery(JSON.parse('{ "Enabled": false, "CosmosPath": "", "SizeCap": 0, "RefreshIntervalSeconds": 0, "PrincipalType": 1 }'));
        }
        this.updateValidationState();
        this.refreshIngestionStatus();
        if (!this.readTrackersFunc) {
            this.readTrackersFunc = () => {
                if (this.state.checkTrackers) {
                    this.props.listGroupService.getOperationTrackerStatus(this.state.query["AudienceContext"].groupsAppId, this.state.query["GroupId"])
                        .then((op) => {
                            if (op.success) {
                                this.state.setTrackerProgress(op.data);
                                if (this.state.trackerProgress === 100) {
                                    this.state.setCheckTrackers(false);
                                }
                            }
                        }).finally(() => {
                            setTimeout(this.readTrackersFunc, 60000);
                        });
                } else {
                    setTimeout(this.readTrackersFunc, 1000);
                }
            };
            setTimeout(this.readTrackersFunc, 1);
        }
    }

    refreshIngestionStatus() {
        if (this.state.query["AudienceContext"]) {
            this.state.setRefreshingIngestionStatus(true);
            this.props.listGroupService.getIngestionStatus(this.state.query["AudienceContext"].groupsAppId, this.state.query["GroupId"])
                .then((result) => {
                    if (result.success) {
                        this.state.setIngestionState(result.data);
                        this.props.listGroupService.count(this.state.query["GroupId"], this.state.query["AudienceContext"].groupsAppId)
                        .then((countResult) => {
                            if (countResult.success) {
                                this.state.setGroupCount(countResult.data);
                            } else {
                                this.state.setIngestionStatusError(countResult.message);
                            }
                        }).catch((err) => {
                            this.state.setIngestionStatusError(err.toString());
                        }).finally(() => {
                            this.state.setRefreshingIngestionStatus(false);
                        });
                    } else {
                        this.state.setIngestionStatusError(result.message);
                        this.state.setRefreshingIngestionStatus(false);
                    }
                }).catch((err) => {
                    this.state.setIngestionStatusError(err.toString());
                    this.state.setRefreshingIngestionStatus(false);
                });
        }
    }

    render() {
        return this.state.query
            ? this.state.query["GroupId"] ? this.renderReadOnlyView() : this.renderCreateView()
            : this.renderCreateView();
    }

    renderReadOnlyView() {
        return <Grid container className="ingestion-status">
            <Grid direction="row" container>
                <TextField value={this.state.query["GroupId"]} onChange={e => { this.setProp('GroupId', e.target.value) }} disabled style={{
                    width: '100%'
                }} label={"Group ID"} />
            </Grid>
            {this.state.refreshingIngestionStatus ? <CircularProgress style={{ marginTop: "10px", marginBottom: "10px" }} /> : <div>
                <Grid direction="row" container>
                    <TextField value={this.state.groupCount} disabled fullWidth label="Count" />
                </Grid>
                <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>Ingestion Status:</Typography>
                    <Typography style={{ marginLeft: '5px' }}>{this.state.ingestionStatus}</Typography>
                </Grid>
                {this.state.checkTrackers ? <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>{"Progress of sample devices: " + (this.state.trackerProgress >= 0 ? `(${this.state.trackerProgress}%)` : "")}</Typography>
                    {this.state.trackerProgress >= 0 ? <div style={{ width: '100px', marginTop: '10px', marginLeft: '5px' }}><LinearProgress variant="determinate" value={this.state.trackerProgress} /></div> : <Typography style={{ marginLeft: '5px' }}>Pending</Typography>}
                </Grid> : null}
                <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>Last Error:</Typography>
                    <Typography style={{ marginLeft: '5px' }} noWrap>{this.state.lastError}</Typography>
                </Grid>
                <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>Last Processed:</Typography>
                    <Typography style={{ marginLeft: '5px' }}>{this.state.lastTimestamp}</Typography>
                </Grid>
                <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>Data Last Updated:</Typography>
                    <Typography style={{ marginLeft: '5px' }}>{this.state.lastProcessedTime}</Typography>
                </Grid>
                <BulkIngestionActions actionInProgress={this.state.actionInProgress}
                    actionSuccesses={this.state.actionSuccesses}
                    actionErrors={this.state.actionErrors}
                    removeActionSuccess={(msg) => {
                        this.state.removeActionSuccess(msg);
                    }}
                    removeActionError={(msg) => {
                        this.state.removeActionError(msg);
                    }}
                    onRefresh={() => {
                        this.refreshIngestionStatus();
                    }}
                    onSetRetry={() => {
                        this.state.setActionInProgress("Flagging for retry");
                        this.state.clearActionSuccesses();
                        this.state.clearActionErrors();
                        const audienceContext = this.props.audienceContext;
                        const groupId = this.state.query["GroupId"];
                        this.props.listGroupService.retryBulkIngestion(audienceContext.groupsAppId,
                            groupId).then((op) => {
                                if (op.success) {
                                    this.state.addActionSuccess("Successfully flagged for retry.");
                                    this.refreshIngestionStatus();
                                } else {
                                    this.state.addActionError(op.message);
                                }
                            }).catch((err) => {
                                this.state.addActionError(err.toString());
                            }).finally(() => {
                                this.state.setActionInProgress(null);
                            });
                    }}
                    onRunIngestion={() => {
                        this.state.setActionInProgress("Starting ingestion");
                        this.state.clearActionSuccesses();
                        this.state.clearActionErrors();
                        const audience = this.props.audienceContext.audience;
                        this.props.listGroupService.runOnDemandIngestion(audience.FunctionalGroup,
                            audience.ShortName).then((op) => {
                                if (op.success) {
                                    for (const prop in op.data) {
                                        this.state.addActionSuccess(`${prop}: ${op.data[prop]}`);
                                    }

                                    this.refreshIngestionStatus();
                                } else {
                                    this.state.addActionError(op.message);
                                }
                            }).catch((err) => {
                                this.state.addActionError(err.toString());
                            }).finally(() => {
                                this.state.setActionInProgress(null);
                            });
                    }} /></div>}
            <Grid direction="row" container>
                <CosmosIngestionParametersBuilder cosmosPath={this.state.query.CosmosPath} isNgpCompliant={this.state.query["IsNgpCompliant"]} onUpdate={(path, ngp) => {
                    this.setProp('CosmosPath', path);
                    this.setProp('IsNgpCompliant', ngp);
                    this.updateValidationState();
                }} disabled />
            </Grid>
            <FormControlLabel control={<CustomCheckbox
                checked={this.state.query.Enabled}
                onChange={(e) => this.setProp('Enabled', e.target.checked)}
                aria-label='Cosmos stream enabled checkbox'
                id='cosmos-stream-enabled-checkbox'
                label=''
                value="enabled"
                disabled
            />} label="Enabled" className="cosmos-stream-checkbox" />
            <Grid direction="row" container>
                <TextField type="number" value={this.state.query.SizeCap} onChange={e => { this.setProp('SizeCap', e.target.value) }} disabled label={"Size Cap"} />
            </Grid>
            <Grid direction="row" container>
                <FormControl fullWidth>
                    <InputLabel id="principal-type-label-ro">Principal Type</InputLabel>
                    <Select value={this.state.query.PrincipalType} onChange={e => this.setProp('PrincipalType', e.target.value)} disabled labelId="principal-type-label-ro">
                        <MenuItem value={1}>MSA (g:)</MenuItem>
                        <MenuItem value={2}>SQM (s:)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    }

    updateValidationState = () => {
        if (!this.state.query || !this.state.query["GroupId"]) {
            const allowEdit = (this.state.lastValidatedStream === this.state.query.CosmosPath && this.state.query["IsNgpCompliant"]) ||
                (!this.state.lastValidatedStream && this.state.query["IsNgpCompliant"]);
            if (!allowEdit) {
                this.props.onFieldValidationChange(this.widgetId, false);
            } else {
                this.props.onFieldValidationChange(this.widgetId, true);
            }
        } else {
            this.props.onFieldValidationChange(this.widgetId, true);
        }
    }

    renderCreateView() {
        if (!this.state.query) {
            return <CircularProgress />;
        }
        this.updateValidationState();
        return <Grid container className="cosmos-stream-input">
            <Grid direction="row" container>
                <CosmosIngestionParametersBuilder cosmosPath={this.state.query.CosmosPath} isNgpCompliant={this.state.query["IsNgpCompliant"]} onUpdate={(path, ngp) => {
                    this.setProp('CosmosPath', path);
                    this.setProp('IsNgpCompliant', ngp);
                    this.updateValidationState();
                }} disabled={this.state.validatingStream} />
            </Grid>
            <Grid direction="row" container>
                <TextField type="number" value={this.state.query.SizeCap} onChange={e => { this.setProp('SizeCap', e.target.value) }}
                    label={"Size Cap"} />
            </Grid>
            <Grid direction="row" container>
                <FormControl fullWidth>
                    <InputLabel id="principal-type-label">Principal Type</InputLabel>
                    <Select value={this.state.query.PrincipalType} onChange={e => this.setProp('PrincipalType', e.target.value)} labelId="principal-type-label">
                        <MenuItem value={1}>MSA (g:)</MenuItem>
                        <MenuItem value={2}>SQM (s:)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Button variant="outlined" color="primary" onClick={() => {
                this.validateStream();
            }} style={{ width: '100%' }} disabled={this.state.validatingStream}>Validate</Button>
            {this.state.validatingStream ? <Grid direction="row" container><CircularProgress style={{ margin: '5px' }} /></Grid> : this.state.streamValidationError ?
                <Grid direction="row" container item alignContent="center" alignItems="center"><Typography style={{ marginRight: "10px", marginTop: "5px" }}>Validation failed</Typography><Button variant="outlined" onClick={() => {
                    this.state.setErrorModalIsOpen(true);
                }}>View Error</Button></Grid> : this.state.lastValidatedStream === this.state.query.CosmosPath ? <Grid direction="row" container><Typography style={{ marginRight: "10px", marginTop: "5px" }}>Validation succeeded</Typography><CheckIcon /></Grid> : null}
            <Dialog open={this.state.errorModalIsOpen} onClose={() => {
                this.state.setErrorModalIsOpen(false);
            }} fullWidth={true} maxWidth='md'>
                <Typography style={{ margin: '10px' }}>{this.state.streamValidationError}</Typography>
            </Dialog>
        </Grid>
    }

    validateStream = () => {
        this.state.setValidatingStream(true);
        this.state.setStreamValidationError(null);
        const fgName = this.props.audienceContext.audience.FunctionalGroup;
        this.props.listGroupService.validateBulkIngestion(fgName, {
            Type: BulkIngestionType.Cosmos,
            Enabled: true,
            SizeCap: this.state.query.SizeCap,
            PrincipalType: this.state.query.PrincipalType,
            Parameters: this.state.query
        }).then((result) => {
            if (result.success) {
                this.state.setLastValidatedStream(this.state.query.CosmosPath);
                this.setProp('Enabled', true);
            } else {
                this.state.setStreamValidationError(result.message);
            }
        }).catch((err) => {
            this.state.setStreamValidationError(err.toString());
        }).finally(() => {
            this.state.setValidatingStream(false);
        });
    }

    private setProp = (prop: string, val: any) => {
        this.state.updateQueryProp(prop, val);
        const def: any = JSON.parse(JSON.stringify(this.state.query));
        if (this.props.onUpdate) {
            // GenericBulkIngestion sub-widget
            this.props.onUpdate(def.Enabled, def.SizeCap, def.PrincipalType, def);
        } else {
            // Legacy CosmosStreamTxt widget
            delete def.GroupId;
            delete def.AudienceContext;
            this.props.setValue(JSON.stringify(def));
        }
    }
}

@observer
export class CosmosIngestionParametersBuilder extends React.Component<CosmosIngestionParametersBuilderProps> {
    render() {
        return <React.Fragment>
            <TextField value={this.props.cosmosPath} onChange={e => { this.props.onUpdate(e.target.value, this.props.isNgpCompliant) }} disabled={this.props.disabled} style={{
                    width: '100%',
                    minWidth: '300px'
                }} label={"Cosmos Path"} />
            <FormControlLabel control={<CustomCheckbox
                checked={this.props.isNgpCompliant}
                onChange={(e, checked) => {
                    this.props.onUpdate(this.props.cosmosPath, checked);
                }}
                aria-label='Acknowledge stream is NGP compliant checkbox'
                id='cosmos-stream-ngp-client-checkbox'
                label=''
                value="enabled"
            />} label="Acknowledge stream is NGP compliant" className="cosmos-stream-checkbox" />
        </React.Fragment>
    }
}