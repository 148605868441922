import React from "react";
import { observable, action, ObservableMap } from 'mobx';
import { TableColumn } from "./Table";
import Select from "../AccessibleSelect/Select";

export type FilterType = 'search' | 'select' | 'custom';

export type FilterOptions = {
    filterable?: boolean,
    filterType?: FilterType,
    filterFn?: (val: any, search: string) => boolean,
    renderFilterInputFn?: (col: TableColumn, value: string, onChange: (field: string, value: string) => void, colData: any[]) => React.ReactChild
};
export type SearchOptions = {
    enableSearch?: boolean,
    filterRowFn?: (item: any, search: string) => boolean,
    filterColFn?: (column: TableColumn, val: any, search: string) => boolean,
    renderSearchInputFn?: (search: string, onChange: (value: string) => void) => React.ReactChild
};

export class FilterState {
    @observable currentSearch: string = '';
    @observable currentFilters: ObservableMap<string, string> = new ObservableMap<string, string>();

    @action setCurrentSearch = (val: string) => {
        this.currentSearch = val;
    }

    @action setFilter = (field: string, search: string) => {
        if (search && this.currentFilters.get(field) !== search) {
            this.currentFilters.set(field, search);
        }
        else if (!search && this.currentFilters.get(field)) {
            this.currentFilters.delete(field);
        }
    }
}

export const defaultFilterFn = {
    'search': (val: any, search: string) => {
        if (!search) return true;
        if (!val) return false;
        const vStr = val.toString ? val.toString() : JSON.stringify(val);
        return vStr.toLowerCase().indexOf(search.toLowerCase()) >= 0;
    },
    'select': (val: any, search: string) => {
        if (!search) return true;
        if (!val) return false;
        return val === search;
    },
    'custom': (val: any, search: string) => {
        // For example, a "date range" could be implemented to parse search into start and end time, then compare with val
        return false; // Custom filter function must be defined for results to be given
    },
};

export function defaultSearchColFilterFn(column: TableColumn, val: any, search: string): boolean {
    const fn = column.filterOptions?.filterFn || defaultFilterFn.search;
    return fn(val, search);
}

export const defaultRenderFilterFn = {
    'select': (col: TableColumn, value: string, onChange: (field: string, value: string) => void, colData: any[]) => {
        const options = colData.map(val => ({ label: val, value: val }));
        return <select className="form-control" aria-label={`Filter ${col.label} column`} value={value} onChange={e => onChange(col.field, e.currentTarget.value)}>
            <option value="" aria-label="Blank"></option>
            {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
        </select>;
    },
    'search': (col: TableColumn, value: string, onChange: (field: string, value: string) => void, colData: any[]) => {
        return <input className="form-control" aria-label={`Filter ${col.label} column`} type="text" data-field={col.field} value={value} onChange={e => onChange(col.field, e.target.value)} />;
    },
    'custom': (col: TableColumn, value: string, onChange: (field: string, value: string) => void, colData: any[]) => {
        // For example, for a number range show 2 input fields for min and max
        return null; // Custom function must be defined to display any input field
    },
}

export function defaultRenderSearchFn(search: string, onChange: (value: string) => void) {
    return <span>Search: <input className="form-control search-box" aria-label="Search table data" type="text" value={search} onChange={e => onChange(e.target.value)} /></span>
}