import { AttributeRequest, AudienceCriteriaType, ApprovalState } from "ts-infra-common";
import { action, observable, runInAction } from "mobx";
import AttributeRequestStore from "../../stores/AttributeRequestStore";
import { HttpPostWithResponse } from "../../services/HttpOperation";
import { AttributeRequestApproveResult, AttributeRequestRejection, AttributeRequestRejectResult } from "@ts/ts-ux-contracts";

export class AttributeRequestForAttributeView {
    isActionable: boolean;
    request: AttributeRequest;
    attribute: AudienceCriteriaType;
    @observable requestStatus: string;
    @observable inProgress: boolean;
    @observable isPending: boolean;
    constructor(request: AttributeRequest, attribute: AudienceCriteriaType, isActionable: boolean) {
        this.isActionable = isActionable;
        this.request = request;
        this.attribute = attribute;
        this.inProgress = false;
        this.isPending = true;
        this.requestStatus = isActionable ? "Pending" : ApprovalState[attribute.ApprovalState];
    }

    @action approve() {
        this.inProgress = true;
        this.requestStatus = "Approving";
        AttributeRequestStore.approve(this.request.RequestId, [this.attribute], `Approve request ${this.request.RequestId}`)
            .then((httpOp: HttpPostWithResponse<AttributeRequestApproveResult[]>) => {
                runInAction(() => {
                    this.inProgress = false;
                    if (httpOp.success) {
                        this.isPending = false;
                        const approvedRequest = httpOp.data.filter(v => v.Request.RequestId === this.request.RequestId && v.Attribute.Name === this.attribute.Name && v.Attribute.AttributePropertiesKind === this.attribute.AttributePropertiesKind);
                        if (approvedRequest && approvedRequest.length > 0) {
                            this.requestStatus = "Approved. Next OneSettings push: " + approvedRequest[0].NextOneSettingsPushFriendlyTimestamp;
                            this.isPending = false;
                        } else {
                            // Couldn't approve, so it was probably rejected or approved by someone else. A force refresh or the background refresh will fix the status.
                            this.requestStatus = "Out of sync. Refresh page.";
                        }
                    } else {
                        this.requestStatus = "Failed to approve";
                    }
                });
            });
    }

    @action reject(reason: string, denyFutureRequests: boolean) {
        const rejection = new AttributeRequestRejection();
        rejection.DenyFutureRequests = denyFutureRequests;
        rejection.Reason = reason;
        rejection.SpecificAttribute = this.attribute;
        this.inProgress = true;
        this.requestStatus = "Rejecting";
        AttributeRequestStore.reject(this.request.RequestId, [rejection], `Reject request ${this.request.RequestId}`)
            .then((httpOp: HttpPostWithResponse<AttributeRequestRejectResult[]>) => {
                runInAction(() => {
                    this.inProgress = false;
                    if (httpOp.success) {
                        this.isPending = false;
                        const rejectedRequest = httpOp.data.filter(v => v.Request.RequestId === this.request.RequestId && v.Attribute.Name === this.attribute.Name && v.Attribute.AttributePropertiesKind === this.attribute.AttributePropertiesKind);
                        if (rejectedRequest && rejectedRequest.length > 0) {
                            this.requestStatus = "Rejected";
                            this.isPending = false;
                        } else {
                            // Couldn't approve, so it was probably rejected or approved by someone else. A force refresh or the background refresh will fix the status.
                            this.requestStatus = "Out of sync. Refresh page.";
                        }
                    } else {
                        this.requestStatus = "Failed to reject";
                    }
                });
            });
    }
}