import * as React from "react";
import { Link } from "react-router-dom";
import AppInsightsService from '../shared/services/AppInsightsService';

export class PageNotFound extends React.Component {
    render () {
        return (
            <div>
                <h1>Error Page Not Found</h1>
                <Link to="/">Back to Index</Link>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(PageNotFound);