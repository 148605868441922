// React/MOBX
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { action, runInAction, observable } from 'mobx';
import { observer } from "mobx-react";

// 3rd-party React controls
import { Prompt } from 'react-router'
import { NavigationBlockerState } from "./NavigationBlockerState";

@observer
export class NavigationBlocker extends React.Component<{ state?: NavigationBlockerState, message?: string }, NavigationBlockerState> {
    constructor(props) {
        super(props);
        this.state = props.state ?? new NavigationBlockerState();
    }

    componentDidUpdate = () => {
        if (this.state.blockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    render() {
            return <Prompt
                when={this.state.blockNavigation}
                message={this.props.message ?? 'Are you sure you to want to leave this page?'}
            />;
    }
}