import * as React from "react";
import { Row, Col, FormGroup, Label } from "reactstrap";
import { observer } from "mobx-react";
import { AvField, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import AttributeFormDetailsProps from "../AttributeFormDetailsProps";
import { RegType, Encoding } from "ts-infra-common";
import { mapEnumToOptions } from "../../../utils";
import AppInsightsService from '../../../services/AppInsightsService';

@observer
export class RegistryAttributeDetails extends React.Component<AttributeFormDetailsProps> {
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target) {
            this.props.onChange(e.target.name, e.target.value);
        }
    }

    valueNameValidator = (value, ctx, input, cb) => {
        if ((!this.props.data.IfExists || this.props.data.IfExists === "false") && !value) {
            cb("ValueName is required when IfExists = false");
        } else {
            cb(true);
        }
    }

    ifExistsValidator = (value, ctx, input, cb) => {
        if ((!value || value === "false") && !this.props.data.ValueName) {
            cb("ValueName is required when IfExists = false");
        } else {
            cb(true);
        }
        this.props.formRef.current.validateInput('ValueName');
    }

    render() {
        const AvRadioStyle = {
            background: 'transparent'
        };
        return (
            <div>
                <Row>
                    <Col>
                        <Label>HKey</Label>
                        <AvRadioGroup style={AvRadioStyle} inline name="HKey" value={this.props.data.HKey} onChange={this.onChange}>
                            <AvRadio label="HKEY_LOCAL_MACHINE" value="HKEY_LOCAL_MACHINE" disabled={this.props.disabled}/>
                            <AvRadio label="HKEY_CLASSES_ROOT" value="HKEY_CLASSES_ROOT" disabled={this.props.disabled}/>
                        </AvRadioGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="FullPath" label="FullPath" aria-label="FullPath" id="FullPath" type="text" value={this.props.data.FullPath} onChange={this.onChange} disabled={this.props.disabled}
                                errorMessage="Required" helpMessage="The registry key, excluding the root. Example: SOFTWARE\Microsoft\Windows NT\CurrentVersion\CorruptedFileRecovery. RS5+ supports a single wild card in place of one of the key path elements. All keys in that location will be enumerated and the name of the key will be used as the name in the name/value pair (if EncodingType) is specified."
                            validate={!this.props.disabled ? {
                                required: {value: true},
                                pattern: { value: '^((?!(HKEY_LOCAL_MACHINE|HKEY_CLASSES_ROOT)).)*$', errorMessage: "Registry path should NOT contain root."},
                            } : null}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="ValueName" label="ValueName" aria-label="ValueName" id="ValueName" type="text" value={this.props.data.ValueName} onChange={this.onChange} disabled={this.props.disabled}
                            errorMessage="Required" helpMessage="The value name (use empty string for default value or omit in RS3 to check if key exists). Required in RS2 (optional in RS3+ only when IfExists=true)"
                                validate={!this.props.disabled ? {
                                custom: this.valueNameValidator
                            } : null}/>
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <AvField type="select" value={this.props.data.IfExists} name="IfExists" label="IfExists (optional)" aria-label="IfExists (optional)" onChange={this.onChange} disabled={this.props.disabled}
                            helpMessage="default false: Supported in RS3+ only - converts result to 1 if the key or value exists." validate={!this.props.disabled ? { custom: this.ifExistsValidator} : null}>
                            <option value=""></option>
                            <option value="false">false</option>
                            <option value="true">true</option>
                        </AvField>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <AvField name="PersistedSourceId" label="PersistedSourceId (optional)" aria-label="PersistedSourceId (optional)" id="PersistedSourceId" type="text" value={this.props.data.PersistedSourceId} onChange={this.onChange} disabled={this.props.disabled}
                            helpMessage={<div>"Supported in RS5+ only - if specified, value is passed to <a target="_blank" href="https://osgwiki.com/wiki/State_Separation#RedirectionMap_Win32_Helper_APIs">GetPersistedRegistryValueW()</a> to get a possible mapping to a state separated location.</div>} />
                        </FormGroup>
                    </Col>
                    <Col xs="4">
                        <AvField type="select" value={this.props.data.RegValueType} name="RegValueType" label="RegValueType" aria-label="RegValueType" onChange={this.onChange} disabled={this.props.disabled}
                        helpMessage="The expected registry value type. Required in RS2 (RS3+ can figure out type)">
                            {mapEnumToOptions(RegType, "")}
                        </AvField>
                    </Col>
                </Row>
                <Row>
                <Col xs="4">
                        <AvField type="select" value={this.props.data.EncodingType} name="EncodingType" label="EncodingType" aria-label="EncodingType" onChange={this.onChange} disabled={this.props.disabled || !this.props.data.FullPath || this.props.data.FullPath.indexOf("*") === -1}
                        helpMessage="Supported in RS5+ only - used only when including a wild card in the key name. If no EncodingType is specified and there is a wild card, only the first value (or true/false IfExists is true) will be returned. Note UriPadded is the same as Uri but ensures there is an ampersand at the beginning and end of the string (which allows targeting on the sub attributes without accidentally matching values with similar truncated values)." >
                            {mapEnumToOptions(Encoding, "")}
                        </AvField>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(RegistryAttributeDetails);