import * as React from 'react';

type AccessibleMultiInputProps = {
    values: string[];
    onChange: (value: string, op: 'add' | 'remove') => void;
    id: string;
    labelId: string;
    required?: boolean;
}

type AccessibleMultiInputState = {
    inputValue: string;
}

// Behaves similar to the AccessibleMultiSelect, but allows custom input instead of defined options.
export default class AccessibleMultiInput extends React.Component<AccessibleMultiInputProps, AccessibleMultiInputState> {
    private input: React.RefObject<HTMLInputElement>;
    private addButton: React.RefObject<HTMLButtonElement>;

    constructor(props) {
        super(props);

        this.input = React.createRef();
        this.addButton = React.createRef();

        this.state = { inputValue: '' };
    }

    render() {
        return <span className="multiinput" aria-live="assertive">
            <ul className="multiinput-tags" aria-labelledby={this.props.labelId}>
                {this.props.values ? this.props.values.map((o, i) =>
                    <li key={`${o} ${i}`}>
                        <button aria-label={`Remove ${o}`} data-value={o} onClick={this.removeOption}>
                            {o} <span aria-hidden={true} className="multiinput-remove-icon">&#x2715;</span>
                        </button>
                    </li>) : null}
            </ul>
            <span className={`multiinput-input-group ${(this.input.current && this.input.current.value) ? 'multiinput-add-ready' : ''}`}>
                <input type="text"
                    aria-labelledby={this.props.labelId}
                    aria-required={this.props.required}
                    ref={this.input}
                    onKeyUp={this.keyUp}
                    onKeyDown={this.keyDown}
                    onChange={this.setValue} />
                {(this.input.current && this.input.current.value)
                    ? <button
                        className="multiinput-add-button"
                        id={`${this.props.id}-add-btn`}
                        aria-labelledby={`${this.props.id}-add-btn ${this.props.labelId}`}
                        ref={this.addButton}
                        onClick={this.addClick}>
                        <span className="symbol add-plus" role="img" aria-label={`add value ${this.input.current.value}`}>&#xE710;</span>
                    </button>
                    : null}
            </span>
        </span>;
    }

    keyUp = (e: React.KeyboardEvent) => {
        const key = e.key;

        switch (key) {
            case 'Enter':
            case 'Tab':
                // Don't tab away if we are adding a value
                if (this.state.inputValue) {
                    e.preventDefault();
                }
                return;
        }
    }

    keyDown = (e: React.KeyboardEvent) => {
        const key = e.key;

        switch (key) {
            case 'Enter':
            case 'Tab':
                if (this.state.inputValue) {
                    // Add the value instead of tabbing away
                    this.addOption(this.state.inputValue);
                    e.preventDefault();
                }
                return;
        }
    }

    setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ inputValue: e.currentTarget.value });
    }

    addClick = () => {
        // Add the current input value
        this.addOption(this.state.inputValue);
    }

    removeOption = (e: React.MouseEvent<HTMLButtonElement>) => {
        const value = (e.currentTarget as HTMLButtonElement).dataset.value;
        this.props.onChange(value, 'remove');
        this.forceUpdate();
    }

    addOption = (value: string) => {
        this.props.onChange(value, 'add');
        this.input.current.value = null;
        this.setState({ inputValue: null });
        this.forceUpdate();
    }
}