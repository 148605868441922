import * as React from "react";
import parseQueryString = require('query-string');

export function mapEnumToOptions(enumType, customFirst?: string) {
    const keys = Object.keys(enumType).filter(key => !isNaN(Number(enumType[key]))).sort();
    const options = keys.map((key) => <option key={enumType[key]} value={enumType[key]}>{key}</option>);
    if (customFirst !== null && customFirst !== undefined) options.unshift(<option key={-1} value={-1}>{customFirst}</option>)
    return options;
}

export function getQueryParameterValues(queryParams: parseQueryString.ParsedQuery<string>, parameterName: string) {
    let ret: string[] = [];
    if (queryParams && queryParams[parameterName]) {
        if (Array.isArray(queryParams[parameterName])) {
            ret = queryParams[parameterName] as string[];
        } else {
            ret.push(queryParams[parameterName] as string);
        }
    }
    return ret;
}