import { adalApiFetch } from "../auth/Adal";
import config from "../../config.json";

export default class Service {
    static post(endpoint: string, body?: string | object) {
        const isFormData = body instanceof FormData;
        const options: any = {
            method: "POST"
        };

        if (body != null) {
            options.body = isFormData ? body : JSON.stringify(body);
            options.headers = isFormData ? {} : {
                "Content-Type": "application/json"
            }
        }

        return adalApiFetch(`${config.api.endpointUrl}/${endpoint}`, options);
    }

    static get(endpoint: string) {
        return adalApiFetch(`${config.api.endpointUrl}/${endpoint}`, {method: "GET"});
    }

    static patch(endpoint: string, body?: string | object) {
        const options: any = {
            method: "PATCH"
        };

        if(body != null){
            options.body = JSON.stringify(body);
            options.headers = {
                "Content-Type": "application/json"
            }
        }

        return adalApiFetch(`${config.api.endpointUrl}/${endpoint}`, options);
    }
}