import { observable, action } from 'mobx';

export class AudienceListState {
    @observable selectedFunctionalGroupId: string = '';
    @observable showDisabled: boolean = false;
    @observable showDeleted: boolean = false;
    @observable selectedUserName: string = '';
    @observable selectedAudience: string = '';
    @observable isUser: boolean = true;
    @observable currentUser: string = '';
    @observable subscribeText: string = 'Subscribe  ';
    @observable unsubscribeText: string = 'Unsubscribe  ';
    @observable pageSize: number = 10;

    @action
    updateSubscribeText(val: string) {
        this.subscribeText = val;
    }

    @action
    updateUnsubscribeText(val: string) {
        this.unsubscribeText = val;
    }

    @action
    updateCurrentUser(val: string) {
        this.currentUser = val;
    }

    @action
    update(val: string) {
        this.selectedFunctionalGroupId = val;
    }

    @action
    updateUser(val: string) {
        this.selectedUserName = val;
    }

    @action
    updateShowDisabled() {
        this.showDisabled = !this.showDisabled;
    }

    @action
    updateShowDeleted() {
        this.showDeleted = !this.showDeleted;
    }

    @action
    updateAudience(val: string) {
        this.selectedAudience = val;
    }

    @action
    updateIsUser(val: boolean) {
        this.isUser = val;
    }

    @action
    setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }
}