import React from "react";
import { observer } from "mobx-react";
import { v4 as createGuid } from "node-uuid";
import { WidgetProps } from "react-awesome-query-builder";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CustomCheckbox } from "./CustomCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TSQueryConfig } from "./TSQueryConfig";
import Button from "@material-ui/core/Button";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, FormControl, InputLabel,  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import { GenericIngestionWidgetProps } from "./GenericIngestionWidgetProps";
import { BatchIngestionWidgetState } from "./BatchIngestionWidgetState";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BatchBulkIngestionParameters, BulkIngestionType } from "ts-infra-common";
import Alert from "@material-ui/lab/Alert";
import isEqual from "lodash/isEqual";
import { BlobIngestionParametersBuilder, BlobIngestionStatusSummaryWidget } from "./BlobIngestionWidget";
import { BlobBulkIngestionParameters } from "ts-infra-common";
import { CosmosDeviceGroupDefinition } from "ts-infra-common";
import { CosmosIngestionParametersBuilder } from "./CosmosWidget";
import TableFooter from "@material-ui/core/TableFooter";
import { BulkIngestionActions } from "./BulkIngestionActions";

@observer
export class BatchIngestionWidget extends React.Component<WidgetProps & TSQueryConfig & TSWidgetOptionsProps & GenericIngestionWidgetProps, BatchIngestionWidgetState> {
    widgetId;
    readTrackersFunc = null;
    constructor(props) {
        super(props);
        this.state = new BatchIngestionWidgetState();
        this.widgetId = createGuid();
    }

    componentDidMount() {
        if (this.props.value) {
            const params: BatchBulkIngestionParameters = JSON.parse(this.props.value);
            this.state.setBatchIngestionParameters(params);
        }

        if (this.props.parameters) {
            this.state.setEnabled(this.props.parameters.Enabled);
            this.state.setSizeCap(this.props.parameters.SizeCap);
            this.state.setPrincipalType(this.props.parameters.PrincipalType);
        }

        if (this.props.parameters && this.props.parameters.GroupId) {
            this.state.setValidated(this.getParametersObject());
            this.refreshIngestionStatus();
        }

        if (!this.state.aliasesLoaded) {
            this.state.setLoading(true);
            this.props.listGroupService.getDataConnectionNames().then((op) => {
                if (op.success) {
                    this.state.setAliases(op.data);
                } else {
                    this.state.addError(op.message);
                }
            }).catch((err) => {
                this.state.addError(err);
            }).finally(() => {
                this.state.setAliasesLoaded(true);
                this.state.setLoading(false);
            });
        }
    }

    sendUpdate(enabled?: boolean) {
        const finalEnabled = enabled !== undefined ? enabled : Boolean(this.state.validated && isEqual(this.state.validated, this.getParametersObject()));
        this.props.onUpdate(finalEnabled, this.state.sizeCap, this.state.principalType, {
            ...this.state.batchIngestionParameters,
            Parameters: this.state.ingestionParameters
        });
    }

    getParametersObject() {
        return {
            Type: BulkIngestionType.Batch,
            Enabled: true,
            SizeCap: this.state.sizeCap,
            PrincipalType: this.state.principalType,
            Parameters: {
                ...this.state.batchIngestionParameters,
                Parameters: this.state.ingestionParameters
            }
        };
    }

    refreshIngestionStatus() {
        this.state.setRefreshingIngestionStatus(true);
        this.props.listGroupService.getIngestionStatus(this.props.parameters.AudienceContext.groupsAppId, this.props.parameters.GroupId)
            .then((result) => {
                if (result.success) {
                    this.state.setIngestionState(result.data);
                } else {
                    this.state.addIngestionError(result.message);
                    this.state.setRefreshingIngestionStatus(false);
                }
            }).catch((err) => {
                this.state.addIngestionError(err.toString());
                this.state.setRefreshingIngestionStatus(false);
            }).finally(() => {
                this.state.setRefreshingIngestionStatus(false);
            });
    }


    render() {
        if (this.state.loading) {
            return <CircularProgress />;
        }
        const validated = Boolean(this.state.validated && isEqual(this.state.validated, this.getParametersObject()));
        const created = Boolean(this.props.parameters && this.props.parameters.GroupId);
        return <Grid container className="batch-ingestion-input">
            {created ? this.renderCreatedView() : null}
            {created ? this.renderEditView(validated) : this.renderCreateView(validated)}
        </Grid>
    }

    renderCreatedView() {
        const pageStart = this.state.page * this.state.pageSize;
        const pageSubGroups = this.state.subGroups ? this.state.subGroups.slice(pageStart, pageStart + this.state.pageSize) : [];
        return <Grid container className="batch-ingestion-input">
            {this.state.refreshingIngestionStatus ? <CircularProgress style={{ marginTop: "10px", marginBottom: "10px" }} /> : <div>
                <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>Batch Ingestion Status:</Typography>
                    <Typography style={{ marginLeft: '5px' }}>{this.state.ingestionStatus}</Typography>
                </Grid>
                <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>Batch File Last Processed:</Typography>
                    <Typography style={{ marginLeft: '5px' }}>{this.state.lastTimestamp}</Typography>
                </Grid>
                <Grid direction="row" container>
                    <Typography style={{ fontWeight: 'bold' }}>Batch File Last Updated:</Typography>
                    <Typography style={{ marginLeft: '5px' }}>{this.state.lastProcessedTime}</Typography>
                </Grid>
                <TableContainer component={Paper} style={{ maxHeight: '375px' }} >
                    <Table size="small" stickyHeader aria-label="Sub Groups Summaries">
                        <TableHead>
                            <TableRow>
                                <TableCell>Group ID</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Summary</TableCell>
                                <TableCell>More Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageSubGroups.map((groupId) => {
                                return <BlobIngestionStatusSummaryWidget appId={this.props.parameters.AudienceContext.groupsAppId} groupId={groupId} {...this.props} />;
                            })}
                        </TableBody>
                        <TableFooter>
                            <TablePagination count={this.state.subGroups ? this.state.subGroups.length : 0}
                                rowsPerPage={4} rowsPerPageOptions={[4, 8, 12]} onChangeRowsPerPage={(e) => {
                                    this.state.setPageSize(Number(e.target.value));
                                }}
                                page={this.state.page} onChangePage={(ev, page) => {
                                    this.state.setPage(page);
                                }} />
                        </TableFooter>
                    </Table>
                </TableContainer>
                <BulkIngestionActions actionInProgress={this.state.actionInProgress}
                    actionSuccesses={this.state.actionSuccesses}
                    actionErrors={this.state.actionErrors}
                    removeActionSuccess={(msg) => {
                        this.state.removeActionSuccess(msg);
                    }}
                    removeActionError={(msg) => {
                        this.state.removeActionError(msg);
                    }}
                    onRefresh={() => {
                        this.refreshIngestionStatus();
                    }}
                    onSetRetry={() => {
                        this.state.setActionInProgress("Flagging for retry");
                        this.state.clearActionSuccesses();
                        this.state.clearActionErrors();
                        this.props.listGroupService.retryBulkIngestion(this.props.parameters.AudienceContext.groupsAppId,
                            this.props.parameters.GroupId).then((op) => {
                                if (op.success) {
                                    this.state.addActionSuccess("Successfully flagged for retry.");
                                    this.refreshIngestionStatus();
                                } else {
                                    this.state.addActionError(op.message);
                                }
                            }).catch((err) => {
                                this.state.addActionError(err.toString());
                            }).finally(() => {
                                this.state.setActionInProgress(null);
                            });
                    }}
                    onRunIngestion={() => {
                        this.state.setActionInProgress("Starting ingestion");
                        this.state.clearActionSuccesses();
                        this.state.clearActionErrors();
                        this.props.listGroupService.runOnDemandIngestion(this.props.parameters.AudienceContext.audience.FunctionalGroup,
                            this.props.parameters.AudienceContext.audience.ShortName).then((op) => {
                                if (op.success) {
                                    for (const prop in op.data) {
                                        this.state.addActionSuccess(`${prop}: ${op.data[prop]}`);
                                    }

                                    this.refreshIngestionStatus();
                                } else {
                                    this.state.addActionError(op.message);
                                }
                            }).catch((err) => {
                                this.state.addActionError(err.toString());
                            }).finally(() => {
                                this.state.setActionInProgress(null);
                            });
                    }} />
                {this.state.errors && this.state.errors.map((err) => {
                    if (err) {
                        return <Alert severity="error" onClose={() => {
                            this.state.setIngestionErrors(this.state.ingestionErrors.filter(a => a !== err));
                        }}>{`${err}`}</Alert>;
                    } else {
                        return null;
                    }
                })}
            </div>}
        </Grid>
    }

    renderEditView(validated: boolean) {
        return <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="edit-panel-content"
                id="edit-panel-header"
            >
                <Typography>Edit</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container className="blob-ingestion-input">
                    <FormControlLabel control={<CustomCheckbox
                        checked={this.state.enabled}
                        onChange={(e) => {
                            this.state.setEnabled(e.target.checked);
                            this.sendUpdate(this.state.enabled);
                        }}
                        aria-label='Blob ingestion enabled checkbox'
                        id='blob-ingestion-enabled-checkbox'
                        label=''
                        value="enabled"
                        disabled={false}
                    />} label="Enabled" className="blob-ingestion-checkbox" />
                    {this.renderForm(validated, true)}
                </Grid>
            </AccordionDetails>
        </Accordion>;
    }

    renderCreateView(validated: boolean) {
        return this.renderForm(validated, false);
    }

    renderForm(validated: boolean, created: boolean) {
        let paramsBuilder: any;
        const numType: number = this.state.batchIngestionParameters.Type === undefined ? -1 : Number.isInteger(this.state.batchIngestionParameters.Type) ? this.state.batchIngestionParameters.Type : BulkIngestionType[this.state.batchIngestionParameters.Type.toString()];
        switch (numType) {
            case -1:
                paramsBuilder = <Typography>Select a valid ingestion type</Typography>;
                break;
            case 0:
                const blobParams = this.state.ingestionParameters as BlobBulkIngestionParameters;
                paramsBuilder = <BlobIngestionParametersBuilder dataConnections={this.state.aliases} dataConnectionName={blobParams.DataConnectionName}
                    directoryPattern={blobParams.DirectoryPattern} filePattern={blobParams.FilePattern} disabled={this.state.validating}
                    onUpdate={(newParams) => {
                        this.state.setIngestionParameters(newParams);
                        this.sendUpdate();
                    }} />;
                break;
            case 1:
                const cosmosParams: CosmosDeviceGroupDefinition & { IsNgpCompliant: boolean } = this.state.ingestionParameters as CosmosDeviceGroupDefinition & { IsNgpCompliant: boolean };
                paramsBuilder = <CosmosIngestionParametersBuilder cosmosPath={cosmosParams.CosmosPath} isNgpCompliant={cosmosParams.IsNgpCompliant} onUpdate={(path, ngp) => {
                    this.state.setIngestionParameters({
                        CosmosPath: path,
                        IsNgpCompliant: ngp
                    });
                    this.sendUpdate();
                }} disabled={this.state.validating} />;
                break;
        }
        return <React.Fragment>
            <Grid direction="row" container>
                <FormControl fullWidth>
                    <InputLabel id="principal-type-label">Principal Type</InputLabel>
                    <Select value={this.state.principalType} onChange={e => {
                        this.state.setPrincipalType(Number(e.target.value));
                        this.sendUpdate();
                    }} disabled={this.state.validating} labelId={"principal-type-label"}>
                        <MenuItem value={1}>MSA (g:)</MenuItem>
                        <MenuItem value={2}>SQM (s:)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid direction="row" container>
                <TextField type="number" value={this.state.sizeCap} onChange={e => {
                    this.state.setSizeCap(Number(e.target.value));
                    this.sendUpdate();
                }} fullWidth disabled={this.state.validating} label={"Size Cap"} />
            </Grid>
            <Grid container alignContent="center" alignItems="center" justify="center">
                <Typography style={{ marginTop: '10px' }}>Batch File Information</Typography>
            </Grid>
            <Grid style={{ padding: '10px' }} container direction="column">
                <Grid direction="row" container>
                    <FormControl fullWidth>
                        <InputLabel id="ingestion-type-label">Type</InputLabel>
                        <Select value={this.state.batchIngestionParameters.Type} onChange={e => this.state.updateBatchParameters({
                            Type: Number(e.target.value)
                        })} labelId="ingestion-type-label">
                            <MenuItem value={-1}>None</MenuItem>
                            <MenuItem value={0}>{BulkIngestionType[BulkIngestionType.Blob]}</MenuItem>
                            <MenuItem value={1}>{BulkIngestionType[BulkIngestionType.Cosmos]}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {paramsBuilder}
            </Grid>
            {!validated ? <Grid direction="row" container><Alert severity="warning">{"Some changes haven't been validated. Ingestion will be DISABLED when saving."}</Alert></Grid> : null}
            <Grid direction="row" container justify="center">
                {this.state.validating ? <CircularProgress /> : <Button variant="outlined" color="primary" fullWidth disabled={!Boolean(this.state.principalType) || !Boolean(this.state.sizeCap) || this.state.validating} onClick={() => {
                    this.state.setValidating(true);
                    this.state.setErrors([]);
                    this.state.setValidated(null);
                    const parameters = this.getParametersObject();
                    const fgName = this.props.audienceContext.audience.FunctionalGroup;
                    this.props.listGroupService.validateBulkIngestion(fgName, parameters).then((op) => {
                        if (op.success) {
                            this.state.setValidated(parameters);
                            this.sendUpdate();
                        } else {
                            this.state.addError(op.message);
                        }
                    }).catch((err) => {
                        this.state.addError(err);
                    }).finally(() => {
                        this.state.setValidating(false);
                    })
                }}>Validate</Button>}
            </Grid>
            <Grid direction="row" container>
                {this.state.errors && this.state.errors.map((err) => {
                    if (err) {
                        return <Alert severity="error" onClose={() => {
                            this.state.setErrors(this.state.errors.filter(a => a !== err));
                        }}>{`${err}`}</Alert>;
                    } else {
                        return null;
                    }
                })}
                {validated ? <Alert severity="success">{"All changes have been validated. Ingestion will be ENABLED when saving."}</Alert> : null}
            </Grid>
        </React.Fragment>
    }
}