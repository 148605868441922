import * as React from "react";
import Table from "../shared/components/AccessibleTable/Table";
import { defaultRenderSearchFn } from "../shared/components/AccessibleTable/TableSearchAndFilter";
import { Link, RouteComponentProps } from "react-router-dom";
import './AttributesList.css';
import AppStore from "../shared/stores/AppStore";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import Modal from 'react-modal';
import AttributeStore from "../shared/stores/AttributeStore";
import AttributeRequestStore from "../shared/stores/AttributeRequestStore";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import AttributeCollectionStore from "../shared/stores/AttributeCollectionStore";
import { AudienceCriteriaKind, AttributeKind, ApprovalState, Provider, AudienceCriteriaType } from "ts-infra-common";
import { observer } from "mobx-react";
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import { observable, action, computed, runInAction } from "mobx";
import AlertStore from "../shared/stores/AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import { BarLoader } from "react-spinners";
import { AttributeRequestCreatedCollectionResult, AttributeToCollectionId } from "@ts/ts-ux-contracts";
import Label from "reactstrap/lib/Label";
import AppInsightsService from '../shared/services/AppInsightsService';
import AppInsightsPageTracker from '../shared/services/AppInsightsPageTracker';
import { AttributeListState } from "./AttributeListState";
import parseQueryString = require('query-string');
import utils = require('../shared/utils');

@observer
export class AttributeTable extends React.Component<RouteComponentProps<{ actionableOnly?: string }>, AttributeListState> {
    @observable filterToActionableAttributes: boolean;
    filterToActionableAttributesDefault: boolean;
    pageTracker: AppInsightsPageTracker;

    constructor(props) {
        super(props);
        this.state = new AttributeListState();
    }

    componentDidMount() {
        this.pageTracker = AppInsightsService.startTrackPage("AttributeList");
        AppStore.setPageTitle("Attribute Management");
        if (!FunctionalGroupStore.isLoaded) FunctionalGroupStore.fetchAllFunctionalGroups(true);
        if (!AttributeStore.isLoaded) AttributeStore.fetch(true);
        if (!AttributeRequestStore.fetchByUserOp) AttributeRequestStore.getByUser("Get requests by user", true);
        Modal.setAppElement(document.getElementById("root"));
        this.initFilterToActionableAttributes();
    }

    render() {
        if (!AttributeStore.isLoaded || AttributeStore.isLoading || !FunctionalGroupStore.isLoaded || FunctionalGroupStore.isLoading || (AttributeRequestStore.fetchByUserOp && AttributeRequestStore.fetchByUserOp.inProgress)) return <div style={{ paddingTop: "100px" }}><LoadingIndicator text="Loading..." /></div>

        if (this.pageTracker) this.pageTracker.stop();

        const data = AttributeStore.data.filter(attr => attr.Name && !attr.IsFallBack).map((i) => {
            return {
                ...i,
                Kind: AudienceCriteriaKind[i.Kind],
                AttributePropertiesKind: AttributeKind[i.AttributePropertiesKind],
                ApprovalState: ApprovalState[i.ApprovalState],
                Provider: Provider[i.Provider]
            }
        }).sort((a, b) => a.Name.localeCompare(b.Name));

        return (
            <div>
                {AttributeCollectionStore.isCreateInProgress ?
                    <div style={{ paddingTop: "70px" }}><BarLoader /></div> :
                    <div className="attribute-actions">
                        <div className="attribute-create-action"><Button className="ts-btn" onClick={e =>  window.location.href='/attribute/create'}>Create Attribute</Button></div>
                        <div className="attribute-add-to-collection-action">
                            {this.renderAddToCollection()}
                        </div>
                    </div>
                }

                <div className="attributes-list">
                    <Table title="" name="Attributes table"
                        columns={[
                            { label: 'Name', field: 'Name', type: 'string', filterOptions: { filterable: true, filterType: 'search' }, renderDataFn: this.renderAttributeLink },
                            { label: 'Attribute Kind', field: 'AttributePropertiesKind', type: 'string', filterOptions: { filterable: true, filterType: 'select' } },
                            { label: 'Description', field: 'Description', type: 'string', filterOptions: { filterable: true, filterType: 'search' } },
                            { label: 'Type', field: 'Kind', type: 'string', filterOptions: { filterable: true, filterType: 'select' } },
                            { label: 'Approval State', field: 'ApprovalState', type: 'string', filterOptions: { filterable: true, filterType: 'select' } },
                        ]}
                        data={data}
                        paginationOptions={{ paginateTable: true, initialRowsPerPage: 50, rowsPerPageChoices: [20, 50, 100] }}
                        searchOptions={{ enableSearch: true, filterRowFn: this.filterRows, renderSearchInputFn: this.renderTableSearch }}
                        selectionOptions={{ enableSelection: true, selectionField: 'id', onSelectionChanged: this.state.setCheckedAttributes }}
                    />
                </div>

            </div>
        );
    }

    private renderTableSearch = (search: string, onChange: (value: string) => void) => {
        return <div>
            {defaultRenderSearchFn(search, onChange)}
            <div>
                <input id="showActionableOnlyCb" type="checkbox" key="filterToActionableCheckbox"
                    onChange={this.onChangeFilterToActionableAttributes}
                    defaultChecked={this.filterToActionableAttributesDefault} /> <Label for="showActionableOnlyCb">Show only approvable attributes</Label>
            </div>
        </div>;
    }

    private renderAttributeLink = (name: FunctionStringCallback, attribute: AudienceCriteriaType) => {
        let page;
        // This might look weird, but don't change - it has to do with how some enums come over the wire
        if (ApprovalState[attribute.ApprovalState].toString() === ApprovalState.Testing.toString() || ApprovalState[attribute.ApprovalState].toString() === ApprovalState.Rejected.toString()) page = "create";
        else page = "view";

        let queryParams = '';
        if (this.filterToActionableAttributes) {
            queryParams = "?skipToRequests=true";
        }

        return <Link to={`/attribute/${page}/${attribute.AttributePropertiesKind}/${name}${queryParams}`}>{name}</Link>;
    }

    private filterRows = (attr: AudienceCriteriaType, search: string): boolean => {
        if (!this.filterToActionableAttributes) return true;
        if (AttributeRequestStore.fetchByUserOp && AttributeRequestStore.fetchByUserOp.success && AttributeRequestStore.fetchByUserOp.data) {
            // This might look weird, but don't change - it has to do with how some enums come over the wire
            const request = AttributeRequestStore.fetchByUserOp.data.find(v => v.Attribute.NameLower === attr.NameLower && attr.AttributePropertiesKind.toString() === AttributeKind[v.Attribute.AttributePropertiesKind]
                && v.Request.State === ApprovalState.Submitted);
                if (request) {
                    return true;
                } else {
                    return false;
                }
        }
    }

    @action
    private onChangeFilterToActionableAttributes = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.filterToActionableAttributes = e.target.checked;
    }

    private onChangeCollection = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.state.updateSelectedCollection(e.target.value);
    }

    private addToCollection = async () => {
        AppInsightsService.trackEvent("AddAttributeToCollectionClicked");
        if (this.state.selectedCollection == null || this.state.selectedCollection === "unselected") AlertStore.add(AlertType.Error, "Collection not selected");
        else if (this.state.checkedAttributes.length === 0) AlertStore.add(AlertType.Error, "No Attributes Selected");
        else {
            this.state.setBulkAddInProgress(true);
            try {
                const attributesToAddMap: { [attributeCollection: string]: AttributeToCollectionId[] } = {};
                this.state.checkedAttributes.forEach(attr => {
                    const attributeCollection = this.getAttributeCollection(attr);
                    let attributesToAdd: AttributeToCollectionId[];
                    if (attributesToAddMap.hasOwnProperty(attributeCollection)) {
                        attributesToAdd = attributesToAddMap[attributeCollection];
                    } else {
                        attributesToAdd = [];
                    }

                    const id: AttributeToCollectionId = new AttributeToCollectionId();
                    id.AttributeName = attr.Name;
                    id.AttributeKind = attr.AttributePropertiesKind;
                    attributesToAdd.push(id);
                    attributesToAddMap[attributeCollection] = attributesToAdd;
                });

                for (const attributeCollection in attributesToAddMap) {
                    if (attributesToAddMap.hasOwnProperty(attributeCollection)) {
                        const ids = attributesToAddMap[attributeCollection];
                        await AttributeCollectionStore.bulkAddAttributesToCollection(attributeCollection, ids).then((v) => {
                            if (v.hasOwnProperty("RequestId")) {
                                // A request was created
                                const result: AttributeRequestCreatedCollectionResult = v as AttributeRequestCreatedCollectionResult;
                                AlertStore.add(AlertType.Success, `A new request was created for attribute collection ${attributeCollection} and sent to: ${result.Approvers.join(", ")}`);
                            } else {
                                // Assume it was added
                                AlertStore.add(AlertType.Success, `Successfully added this attribute to attribute collection ${attributeCollection}.`);
                            }
                        });
                    }
                }
            } finally {
                this.state.setBulkAddInProgress(false);
            }
        }
    }

    private renderAddToCollection = () => {
        const allowedGroups = FunctionalGroupStore.groupsCanAddToCollection || [];
        return (
            <InputGroup>
                <Input aria-label="Add attribute to collection. Select a value," style={{ marginTop: "10px" }} type="select" onChange={this.onChangeCollection}>
                    <option key="unselected" value="unselected">Select A Value</option>
                    {allowedGroups.slice().sort((a, b) => a.Name.localeCompare(b.Name)).map(i => {
                        const attributeCollection = i.CTACRedirectAttributeGroup || `${i.CTACAttributeGroup} / ${i.TSAttributeGroup}`;
                        return <option key={i.id} value={i.id}>{`${i.Name} (${attributeCollection})`}</option>;
                    })}
                </Input>
                <InputGroupAddon addonType="append">
                    <Button className="ts-btn" onClick={this.addToCollection} disabled={this.state.bulkAddInProgress || allowedGroups.length === 0 || this.state.checkedAttributes.length === 0 || this.state.addToCollectionDisabled}>Add To Collection</Button>
                </InputGroupAddon>
            </InputGroup>
        );
    }

    getAttributeCollection(attribute: AudienceCriteriaType) {
        const matchingFg = FunctionalGroupStore.data.find((fg) => {
            return fg.Name === this.state.selectedCollection;
        });
        if (matchingFg) {
            const attributeCollection = matchingFg.CTACRedirectAttributeGroup || (attribute.IsCtacAttribute ? matchingFg.CTACAttributeGroup : matchingFg.TSAttributeGroup);
            return attributeCollection;
        }

        return null;
    }

    @action
    initFilterToActionableAttributes() {
        const queryString = this.props.location.search;
        const queryParams = parseQueryString.parse(queryString);
        const parsedParams = utils.getQueryParameterValues(queryParams, "actionableOnly");
        this.filterToActionableAttributesDefault = parsedParams && parsedParams.length > 0 && parsedParams[0].toLowerCase() === "true";
        this.filterToActionableAttributes = this.filterToActionableAttributesDefault;
    }
}

export default AppInsightsService.trackComponent(AttributeTable);