import { observable, action, runInAction, computed } from "mobx";
import { HttpGet, HttpPostWithResponse, HttpOperation, HttpPost } from './HttpOperation';
import AlertStore from "../stores/AlertStore";
import { Alert, AlertType, IListGroupService, TSOperation } from "@ts/ts-ux-contracts";
import Service from "./service";
import { adalApiFetch, authContext } from "../auth/Adal";
import { authContext as RBACAuthContext, adalApiFetch as RBACApiFetch } from "../auth/RBAC";
import { AuthLogicalOperator } from "../../../types/ts-private-contracts";
import config from "../../config.json";

export class AuthService {
    checkAccess(userId: string, permissions: string[], op: AuthLogicalOperator = AuthLogicalOperator.And) {
        let statusCode = 0;
        return new Promise<boolean>((resolve, reject) => {
            const permissionsArr = [];
            for (let i = 0; i < permissions.length; i+=2) {
                permissionsArr.push({
                    objectId: permissions[i],
                    action: permissions[i+1]
                });
            }
            RBACApiFetch(`${config.rbacEndpoint}/api/v1/CheckAccess`, { method: "POST", body: JSON.stringify({
                userId,
                permissionsGroup: {
                    operator: op,
                    permissions: permissionsArr
                }
            }), headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-ms-frbac-scopes': 'TargetingService'
            } }).then((response) => {
                statusCode = response.status;
                if (response.ok) {
                    return response.json();
                }

                return response.text();
            }).then((searchResponse: boolean | string) => {
                if (typeof searchResponse === "string") {
                    reject({
                        code: statusCode,
                        message: searchResponse
                    });
                } else {
                    resolve(searchResponse);
                }
            }).catch((err) => {
                reject({
                    code: 500,
                    message: JSON.stringify(err)
                });
            });
        });
    }
}

const service = new AuthService();
export default service;