import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { AudienceCriteriaType, Audience } from "ts-infra-common";
import { HttpGet } from '../services/HttpOperation';
import {HttpOperation} from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class CriteriaStore {
    @observable data: AudienceCriteriaType[];
    @observable http: HttpGet<AudienceCriteriaType[]>;
    @observable criteraInCollection: Map<string, AudienceCriteriaType[]> = new Map();

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    async fetch(path: string, refresh:boolean = false, tries: number = 3) {
        if (!this.http || refresh) {
            let currentAttempt = 0;
            let success = false;
            while (!success && currentAttempt < tries) {
                this.http = HttpOperation.get<AudienceCriteriaType[]>(config.adal.clientId, config.api.endpointUrl + path)
                await this.http.wait();
                runInAction(() => {
                    if (this.http.success) {
                        this.data = this.http.data;
                        success = true;
                    }
                });

                currentAttempt++;
            }

            if (!success) {
                this.data = null;
                AlertStore.add(AlertType.Error, `Failed to retrieve attributes. Try refreshing, or email '${config.helpAlias}' if the problem persists. Additional info: ${this.http.errorMessage}`);
            }
        }
    }

    @action
    async fetchCriteria(functionalGroup: string, refresh: boolean) {
        await this.fetch(`/api/FunctionalGroups/${functionalGroup}/Criteria`, refresh);
    }

    @action
    getCriteriaInCollection(collectionName: string, refresh: boolean = false): Promise<AudienceCriteriaType[]> {
        return new Promise((resolve, reject) => {
            if (!this.criteraInCollection.has(collectionName) || refresh) {
                const criteraInCollectionOp = HttpOperation.get<AudienceCriteriaType[]>(config.adal.clientId, config.api.endpointUrl + `/api/AttributeCollections/${collectionName}/Attributes`);
                criteraInCollectionOp.wait().then(() => {
                    if (criteraInCollectionOp.success) {
                        this.criteraInCollection.set(collectionName, criteraInCollectionOp.data);
                        resolve(criteraInCollectionOp.data);
                    } else {
                        reject(criteraInCollectionOp.errorMessage);
                    }
                }).catch((err) => {
                    reject(err);
                });
            } else {
                resolve(this.criteraInCollection.get(collectionName));
            }
        });
    }
}

const store = new CriteriaStore();
export default store;
