import React from "react";
import { observer } from "mobx-react";
import { v4 as createGuid } from "node-uuid";
import { WidgetProps } from "react-awesome-query-builder";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { CustomCheckbox } from "./CustomCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TSQueryConfig } from "./TSQueryConfig";
import Button from "@material-ui/core/Button";
import { Dialog, FormControl, InputLabel, LinearProgress } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import { BulkIngestionWidgetState } from "./BulkIngestionWidgetState";
import { CosmosWidget } from "./CosmosWidget";
import { BlobIngestionWidget } from "./BlobIngestionWidget";
import { BulkIngestionParameters, BulkIngestionType } from "ts-infra-common";
import { BatchIngestionWidget } from "./BatchIngestionWidget";

@observer
export class BulkIngestionWidget extends React.Component<WidgetProps & TSQueryConfig & TSWidgetOptionsProps, BulkIngestionWidgetState> {
    widgetId;
    constructor(props) {
        super(props);
        this.state = new BulkIngestionWidgetState();
        this.widgetId = createGuid();
    }

    componentDidMount() {
        if (this.props.value) {
            const params: BulkIngestionParameters & { GroupId: string, AudienceContext: any } = JSON.parse(this.props.value);
            this.state.setType(params.Type);
            this.state.setParams(JSON.stringify( { ...params.Parameters, GroupId: params.GroupId, AudienceContext: params.AudienceContext}));
            this.state.setGroupId(params.GroupId);
        }
    }

    render() {
        let widget = undefined;
        const numType: number = this.state.type === undefined ? -1 : Number.isInteger(this.state.type) ? this.state.type : BulkIngestionType[this.state.type.toString()];
        switch (numType) {
            case -1:
                widget = <Typography>Select a valid ingestion type</Typography>;
                break;
            case BulkIngestionType.Cosmos:
                widget = <CosmosWidget {...this.props} onUpdate={(enabled, sizeCap, principalType, params) => {
                    const existingParams: BulkIngestionParameters & { GroupId: string, AudienceContext: any } = this.props.value ? JSON.parse(this.props.value) : {};
                    const bulkIngestParams: BulkIngestionParameters = {
                        Type: BulkIngestionType.Cosmos,
                        Enabled: enabled,
                        SizeCap: sizeCap,
                        PrincipalType: principalType,
                        Parameters: params
                    };
                    const finalValue = { ...bulkIngestParams, GroupId: existingParams.GroupId, AudienceContext: existingParams.AudienceContext};
                    this.props.setValue(JSON.stringify(finalValue));
                    this.state.setParams(JSON.stringify({ ...params.Parameters, GroupId: existingParams.GroupId, AudienceContext: existingParams.AudienceContext}));
                }} value={this.state.params} parameters={JSON.parse(this.props.value?? "{}")} />;
                break;
            case BulkIngestionType.Blob:
                widget = <BlobIngestionWidget {...this.props} onUpdate={(enabled, sizeCap, principalType, params) => {
                    const existingParams: BulkIngestionParameters & { GroupId: string, AudienceContext: any } = this.props.value ? JSON.parse(this.props.value) : {};
                    const bulkIngestParams: BulkIngestionParameters = {
                        Type: BulkIngestionType.Blob,
                        Enabled: enabled,
                        SizeCap: sizeCap,
                        PrincipalType: principalType,
                        Parameters: params
                    };
                    const finalValue = { ...bulkIngestParams, GroupId: existingParams.GroupId, AudienceContext: existingParams.AudienceContext};
                    this.props.setValue(JSON.stringify(finalValue));
                    this.state.setParams(JSON.stringify({ ...params.Parameters, GroupId: existingParams.GroupId, AudienceContext: existingParams.AudienceContext}));
                }} value={this.state.params} parameters={JSON.parse(this.props.value ?? "{}")} />;
                break;
                case BulkIngestionType.Batch:
                    widget = <BatchIngestionWidget {...this.props} onUpdate={(enabled, sizeCap, principalType, params) => {
                        const existingParams: BulkIngestionParameters & { GroupId: string, AudienceContext: any } = this.props.value ? JSON.parse(this.props.value) : {};
                        const bulkIngestParams: BulkIngestionParameters = {
                            Type: BulkIngestionType.Batch,
                            Enabled: enabled,
                            SizeCap: sizeCap,
                            PrincipalType: principalType,
                            Parameters: params
                        };
                        const finalValue = { ...bulkIngestParams, GroupId: existingParams.GroupId, AudienceContext: existingParams.AudienceContext};
                        this.props.setValue(JSON.stringify(finalValue));
                        this.state.setParams(JSON.stringify({ ...params.Parameters, GroupId: existingParams.GroupId, AudienceContext: existingParams.AudienceContext}));
                    }} value={this.state.params} parameters={JSON.parse(this.props.value ?? "{}")} />;
                    break;
            default:
                widget = <Typography>Unsupported</Typography>;
                break;
            }
        
        return <Grid container className="bulk-ingestion-input">
            <Grid direction="row" container>
                <FormControl fullWidth>
                    <InputLabel id="ingestion-type-label">Type</InputLabel>
                    <Select value={numType} onChange={e => this.state.setType(Number(e.target.value))} disabled={Boolean(this.state.groupId)} labelId="ingestion-type-label">
                        <MenuItem value={-1}>None</MenuItem>
                        <MenuItem value={0}>{BulkIngestionType[BulkIngestionType.Blob]}</MenuItem>
                        <MenuItem value={1}>{BulkIngestionType[BulkIngestionType.Cosmos]}</MenuItem>
                        <MenuItem value={2}>{BulkIngestionType[BulkIngestionType.Batch]}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid direction="row" container>
                <div>{widget}</div>
            </Grid>
        </Grid>
    }
}