import { observable, action } from 'mobx';
import moment from "moment";
import { SortDirection } from '@material-ui/core';

export type SortOptions = { sortable?: boolean, compareFn?: (a: any, b: any) => number };

export class SortState {
    @observable sortColumn: string = '';
    @observable sortDirection: SortDirection = false;

    @action setSortColumn = (val: string) => {
        if (val && this.sortColumn === val) {
            // Adjust sort on current sort column
            switch (this.sortDirection) {
                case 'asc':
                    // If it was ascending, go to descending
                    this.setSortDirection('desc');
                    break;
                case 'desc':
                    // If it was descending, clear sort
                    this.sortColumn = '';
                    this.setSortDirection(false);
                    break;
                case false:
                    // Shouldn't get here, but if direction was not set, go to ascending
                    this.setSortDirection('asc');
                    break;
            }
        }
        else if (val && this.sortColumn !== val) {
            // Set new sort column
            this.sortColumn = val;
            this.setSortDirection('asc');
        }
        else {
            // Clear sort
            this.sortColumn = val;
            this.setSortDirection(false);
        }
    }

    @action setSortDirection = (val: SortDirection) => {
        this.sortDirection = val;
    }
}

export const defaultCompareFn = {
    'string': (a: string, b: string) => { return a.localeCompare(b); },
    'number': (a: number, b: number) => { return a - b; },
    'boolean': (a: boolean, b: boolean) => { return (a ? 1 : 0) - (b ? 1 : 0); },
    'datetime': (a: string, b: string) => { const mA = moment(a); const mB = moment(b); return mA.isBefore(mB) ? -1 : (mA.isAfter(mB) ? 1 : 0); },
    'custom': (a: any, b: any) => { return 0; }, // No sorting is done without a custom function
};