import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import { observer } from "mobx-react";
import { v4 as createGuid } from "node-uuid";
import React from "react";
// TODO: This is basically the default slider widget that comes with react-awesome-query-builder, except the default one is broken. Replace this with the default one when it works.
@observer
export class MinSliderWidget extends React.Component<{ placeholder, customProps?, value, setValue, min?, max?, step?, marks?, readonly?}, {}> {
    widgetId;
    constructor(props) {
        super(props);
        this.widgetId = createGuid();
    }

    render() {
        let sliderValue = typeof this.props.value !== "undefined" ? parseInt(this.props.value, 10) : null;
        if (Number.isNaN(sliderValue)) {
            sliderValue = 0;
        }
        const sliderMin = typeof this.props.min !== "undefined" ? parseInt(this.props.min, 10) : 0;
        const sliderMax = typeof this.props.max !== "undefined" ? parseInt(this.props.max, 10) : 100;
        const muiMarks: any[] = this.props.marks ? Object.keys(this.props.marks).map((v) => ({ value: parseInt(v, 10), label: this.props.marks[v] })) : [{ value: sliderMin, label: sliderMin }, { value: sliderMax, label: sliderMax }];
        const sliderStep = typeof this.props.step !== "undefined" ? parseInt(this.props.step, 10) : 1;
        return <div style={{
            display: "inline-flex",
            alignItems: "center"
        }}><Slider
                onChange={(e, newValue) => { this.props.setValue(newValue); }}
                disabled={Boolean(this.props.readonly)}
                min={sliderMin}
                max={sliderMax}
                step={sliderStep}
                value={sliderValue}
                marks={muiMarks}
                valueLabelDisplay="auto"
                aria-valuetext={String(sliderValue)}
                aria-labelledby={'input-slider'}
                className='query-builder-slider-slider'
            /><TextField
                value={sliderValue}
                margin="dense"
                onChange={(e) => this.props.setValue(e.target.value === '' ? sliderMin : Number(e.target.value))}
                onBlur={() => {
                    if (sliderValue < sliderMin) {
                        this.props.setValue(sliderMin);
                    } else if (sliderValue > sliderMax) {
                        this.props.setValue(sliderMax);
                    }
                }}
                inputProps={{
                    step: sliderStep,
                    min: sliderMin,
                    max: sliderMax,
                    type: 'number'
                }}
                label="%" variant="filled" id={`percentage-value-${this.widgetId}`} size="small"
                style={{
                    width: '65px',
                    marginLeft: '30px'
                }}
            /></div>;
    }
}