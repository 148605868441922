import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { Audience, Attributes } from "ts-infra-common";
import { HttpGet, HttpPost } from '../services/HttpOperation';
import { HttpOperation } from "../services/HttpOperation"
import AlertStore from "./AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";

class AudienceStore {
    @observable data: Audience[];
    @observable http: HttpGet<Audience[]>;

    @computed
    get isLoading() {
        return this.http && this.http.inProgress;
    }

    @computed
    get isLoaded() {
        return !!this.data;
    }

    @action
    private async fetch(path: string, refresh: boolean = false) {
        if (refresh || !this.http) {
            this.http = HttpOperation.get<Audience[]>(config.adal.clientId, config.api.endpointUrl + path);
            await this.http.wait();
            runInAction(() => {
                if (this.http.success) {
                    this.data = this.http.data;
                } else {
                    this.data = null;
                    AlertStore.add(AlertType.Error, "AudienceStore failed to fetch api data.");
                }
            });
        }
    }

    @action
    async fetchAudienceDetails(functionalGroupAudienceName: string, audienceName: string, refresh: boolean) {
        await this.fetch(`/api/FunctionalGroups/${functionalGroupAudienceName}/Audiences/${audienceName}`, refresh);
    }

    @action
    async fetchFuncGroupAudience(functionalGroupAudienceName: string, refresh: boolean) {
        await this.fetch(`/api/FunctionalGroups/${functionalGroupAudienceName}/Audiences/Slim?excludedAttributes=${Attributes.None}`, refresh);
    }

    @action
    async fetchUserAudiences(user: string, refresh: boolean) {
        await this.fetch(`/api/Audiences/Slim/ByUser?alias=${user}&excludedAttributes=${Attributes.None}`, refresh);
    }

    @action
    async addAudience(options: { funcGroupName: string, audienceName: string, audienceDescription: string, audienceOwner: string, audienceAdmins: string[] }) {
        const httpPost = HttpOperation.post(config.adal.clientId, config.api.endpointUrl + `/api/FunctionalGroups/${options.funcGroupName}/AddAudience`, {
            "ShortName": options.audienceName, "Description": options.audienceDescription, "Owner": options.audienceOwner, "FunctionalGroup": options.funcGroupName, "Admins": options.audienceAdmins
        })
        await httpPost.wait();
        runInAction(() => {
            if (httpPost.success) {
                AlertStore.add(AlertType.Success, "AudienceStore successfully created new audience.");
            } else {
                this.data = null;
                AlertStore.add(AlertType.Error, httpPost.errorMessage);
            }
        });

        await httpPost.wait();
        return httpPost.success;
    }
}

const store = new AudienceStore();
export default store;
