import { observable, action, computed } from 'mobx';
import { AudienceCriteriaType } from "ts-infra-common";
import { EventEmitter } from 'events';

enum DownloadStatus {
    DownloadingTest,
    DoNothing
}

enum CreateStatus {
    UnsavedChanges,
    Saving,
    Saved
}

export class AttributeViewState {
    @observable testJson: string;
    @observable jsonName: string;
    @observable downloadStatus = DownloadStatus.DoNothing;
    @observable createStatus = CreateStatus.Saved;
    @observable attribute: AudienceCriteriaType;
    @observable modalIsOpen: boolean;
    @observable selectedFunctionalGroupId: string;
    @observable formDisabled: boolean;
    @observable isSaving: boolean;

    events: EventEmitter = new EventEmitter();

    @action
    updateIsSaving(isSaving: boolean) {
        this.isSaving = isSaving;
    }

    @action
    updateTestJson(val: string) {
        this.testJson = val;
    }

    @action
    updateJsonName(val: string) {
        this.jsonName = val;
    }

    @action
    updateCreateStatus(val: CreateStatus) {
        this.createStatus = val;
    }

    @action
    updateFormDisabled(val: boolean) {
        this.formDisabled = val;
    }

    @action
    updateDownloadStatus(val: DownloadStatus) {
        this.downloadStatus = val;
    }

    @action
    updateSelectedFunctionalGroupId(val: string) {
        this.selectedFunctionalGroupId = val;
    }

    @action
    updateAttribute(val: any) {
        this.attribute = val;
    }

    @action
    updateModalIsOpen(val: boolean) {
        this.modalIsOpen = val;
    }
}