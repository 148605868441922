import { ICriteriaService, IListGroupService, IAlertStore, IUserService, IQueryBuilderStore } from "@ts/ts-ux-contracts";
import { AudienceCriteriaType } from "ts-infra-common";
import { IDataStore } from "./IDataStore";

export class TSQueryConfig {
    criteriaService: ICriteriaService;
    listGroupService: IListGroupService;
    alertStore: IAlertStore;
    userService: IUserService;
    queryBuilderStore: IQueryBuilderStore;
    criteriaStore: IDataStore<AudienceCriteriaType[]>;
    helpAlias: string;
}