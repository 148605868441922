import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { action } from 'mobx';
import { observer } from "mobx-react";
import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Button } from "reactstrap";
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import AlertStore from "../shared/stores/AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import AppStore from "../shared/stores/AppStore";
import AudienceStore from "../shared/stores/AudienceStore";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import "./AudienceCreate.css";
import { AudienceCreateState } from "./AudienceCreateState";
import UserStore from "../shared/stores/UserStore";
import AppInsightsService from '../shared/services/AppInsightsService';
import AppInsightsPageTracker from '../shared/services/AppInsightsPageTracker';
import { UserRoles } from "ts-infra-common";
import parseQueryString = require('query-string');
import utils = require('../shared/utils');
import MultiInput from "../shared/components/AccessibleSelect/MultiInput";
import AuthService from "../shared/services/AuthService";
import { AuthLogicalOperator } from "../../types/ts-private-contracts";

@observer
export class AudienceCreate extends React.Component<RouteComponentProps, AudienceCreateState> {
    pageTracker: AppInsightsPageTracker;
    constructor(props) {
        super(props);
        this.state = new AudienceCreateState();
    }

    componentDidMount() {
        const queryString = this.props.location.search;
        const queryParams = parseQueryString.parse(queryString);
        const parsedParams = utils.getQueryParameterValues(queryParams, 'fg');
        this.pageTracker = AppInsightsService.startTrackPage("AudienceCreate");
        AppStore.setPageTitle("Create Audience");
        this.state.clearForm();
        if(!FunctionalGroupStore.httpFull) {
            FunctionalGroupStore.fetchAllFunctionalGroupsFull(true).then(() => {
                for (const fg of FunctionalGroupStore.dataFull) {
                    if (fg.Name) {
                        // Allow access to anyone if an FG owner is "*"
                        if (fg.Owners && fg.Owners.find(a => a === "*")) {
                            this.state.addFgAuth({
                                name: fg.Name,
                                completed: true,
                                hasAccess: true
                            });
                        } else {
                            this.state.setAuthCheckStarted(true);
                            this.state.addFgAuth({
                                name: fg.Name,
                                completed: false,
                                hasAccess: false
                            });
                            AuthService.checkAccess(UserStore.username.toLowerCase(), [`(TS)_FunctionalGroup_${fg.Name.toLowerCase().replace(/ /g, "_")}`, "admin", "_ts_globaladmin", "access"], AuthLogicalOperator.Or).then((hasAccess) => {
                                this.state.setFgAuth(fg.Name, hasAccess);
                            }).catch((err) => {
                                AlertStore.add(AlertType.Error, `Failed to verify access to Functional Group ${fg.Name}`);
                                /* tslint:disable-next-line */
                                console.log("Functional Group Access Error", err);
                                this.state.setFgAuth(fg.Name, false);
                            });
                        }
                    }
                }
            });
        }
        this.state.updateFuncGroup(localStorage.functionalGroup);
        this.state.updateAudienceOwner(UserStore.username.replace(/@.+/, ''));
        this.state.updateAudienceAdmins([this.state.selectedAudienceOwner]);
        if (parsedParams && parsedParams.length > 0) {
            this.state.updateFuncGroup(parsedParams[0]);
        }
    }

    render() {
        if (!FunctionalGroupStore.httpFull || FunctionalGroupStore.httpFull.inProgress || !FunctionalGroupStore.dataFull || !this.state.authCheckStarted || this.state.fgAuths.filter(a => !a.completed).length > 0) {
            return <div style={{paddingTop: "100px"}}><LoadingIndicator text="Loading..."/></div>
        }

        if (this.pageTracker) this.pageTracker.stop();

        if (this.state.isCreating) {
            return <div style={{paddingTop: "100px"}}><LoadingIndicator text="Creating..."/></div>
        }

        const functionalGroups = FunctionalGroupStore.dataFull.slice().filter(v => {
            return this.state.hasFgAccess(v.Name);
        }).sort((a,b) => a.Name > b.Name ? 1 : -1).map((i) => <option key={i.Name} value={i.Name}>{i.Name}</option>);
        return (
            <AvForm className="create-form" aria-live="assertive">
                <h4 className="create-form-header"> AUDIENCE INFORMATION </h4>
                <AvField type="select" name="functionalGroup" label="Functional Group" id="functionalGroup" onChange={this.onChangeFunctionalGroup}
                        value={this.state.selectedFunctionalGroupId} validate={{required:{value: this.state.selectedFunctionalGroupId !== "-1"}}} errorMessage="Please select a functional group from the dropdown.">
                        <option key="-1" value="-1">Select a Group</option>
                        {functionalGroups}
                </AvField>
                <AvField name="audienceName" label="Give this audience a unique short name" id="audienceName" onChange={this.onChangeAudienceName}
                        value={this.state.selectedAudienceName || ''} errorMessage="Please enter an audience name." required />
                <AvField name="audienceDescription" label="Give this audience a description" id="audienceDescription" onChange={this.onChangeAudienceDescription}
                        value={this.state.selectedAudienceDescription || ''} />
                <AvForm>
                    <label id="create-audience-admins-label" className="audience-admins-select"> Audience Admin(s)</label>
                    <MultiInput id="create-audience-admins" labelId="create-audience-admins-label" aria-label="Enter the admin aliases for the new audience"
                        values={this.state.currentAdmins} onChange={this.setAdmins}
                    />
                </AvForm>
                <AvGroup className="button" style={{float: "right"}}>
                    <Button className="audience-create-btn" onClick={()=>this.onSubmit()}>Submit</Button>
                    <Button onClick={() => window.location.href='/audiences'} className="audience-create-btn">Cancel</Button>
                </AvGroup>
            </AvForm>
        )
    }

    @action
    private setAdmins = (value: string, op: 'add' | 'remove') => {
        const index = this.state.currentAdmins.findIndex(a => a === value);
        if (op === 'add' && index < 0) {
            this.state.updateAudienceAdmins([...this.state.currentAdmins, value]);
        }
        else if (op === 'remove' && index >= 0) {
            const newAdmins = this.state.currentAdmins.slice();
            newAdmins.splice(index, 1);
            this.state.updateAudienceAdmins(newAdmins);
        }
    }

    @action
    private onSubmit = () => {
        AlertStore.clearAll();
        if ((this.state.selectedFunctionalGroupId.localeCompare( "-1") !== 0 || this.state.selectedFunctionalGroupId.localeCompare('') !== 0)
                && this.state.selectedAudienceName.localeCompare('') !== 0
                && this.state.selectedAudienceOwner.localeCompare('') !== 0) {
            this.state.setIsCreating(true);
            AudienceStore.addAudience({
                funcGroupName: this.state.selectedFunctionalGroupId,
                audienceName: this.state.selectedAudienceName.trim(),
                audienceDescription: this.state.selectedAudienceDescription,
                audienceOwner: this.state.selectedAudienceOwner,
                audienceAdmins: this.state.currentAdmins
            }).then((success)=>{
                this.state.setIsCreating(false);
                if (success) {
                    this.props.history.push(`/audience/details/functionalgroup/${this.state.selectedFunctionalGroupId}/audience/${this.state.selectedAudienceName}`);
                }
            }).catch((err) => {
                // Only infra errors - don't know if err is object or string...
                AlertStore.add(AlertType.Error, "Unexpected error: " + JSON.stringify(err));
                this.state.setIsCreating(false);
            });
        } else {
            AlertStore.add(AlertType.Error, "One or more required fields are empty, unable to create new audience.");
        }
    }

    private onChangeFunctionalGroup = (value: any) => {
        if(value.target.value != null) {
            this.state.updateFuncGroup(value.target.value);
        }
    }

    private onChangeAudienceName = (value: any) => {
        if(value.target.value != null) {
            this.state.updateAudienceName(value.target.value);
        }
    }

    private onChangeAudienceDescription = (value: any) => {
        if(value.target.value != null) {
            this.state.updateAudienceDescription(value.target.value);
        }
    }
}

export default AppInsightsService.trackComponent(AudienceCreate);