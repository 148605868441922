import { observable, action, runInAction, computed } from "mobx";
import config from "../../config.json";
import { AudienceCriteriaType, Audience } from "ts-infra-common";
import { HttpGet } from './HttpOperation';
import { HttpOperation } from "./HttpOperation"
import AlertStore from "../stores/AlertStore";
import { Alert, AlertType, ICriteriaService, TSOperation } from "@ts/ts-ux-contracts";
import { adalApiFetch } from "../auth/Adal";
import Service from "./service";

class CriteriaServiceV2 extends Service implements ICriteriaService {
    getCriteriaInFunctionalGroup(functionalGroup: string, refresh: boolean): Promise<TSOperation<AudienceCriteriaType[]>> {
        return new Promise((resolve, reject) => {
            let success = false;
            CriteriaServiceV2.get(`api/FunctionalGroups/${functionalGroup}/Criteria`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (!success) {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                } else {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }
    isValidValue(criteriaName: string, criteriaValue: string): Promise<TSOperation<boolean>> {
        return new Promise((resolve, reject) => {
            let success = false;
            CriteriaServiceV2.get(`api/Suggestions/Criteria/${criteriaName}/Values/${criteriaValue}`).then((response) => {
                if (response.ok) {
                    success = true;
                    return response.json();
                }

                return response.text();
            }).then((txt) => {
                if (!success) {
                    resolve({
                        success: false,
                        data: null,
                        message: txt
                    });
                } else {
                    resolve({
                        success: true,
                        data: txt,
                        message: null
                    });
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }
    getValidValuesMap: Map<string, Promise<TSOperation<string[]>>> = new Map<string, Promise<TSOperation<string[]>>>();
    getValidValues(criteriaName: string, refresh: boolean = false): Promise<TSOperation<string[]>> {
        if (!this.getValidValuesMap.has(criteriaName) || refresh) {
            this.getValidValuesMap.set(criteriaName, new Promise((resolve, reject) => {
                let success = false;
                CriteriaServiceV2.get(`api/Suggestions/Criteria/${criteriaName}/Values`).then((response) => {
                    if (response.ok) {
                        success = true;
                        return response.json();
                    }

                    return response.text();
                }).then((txt) => {
                    if (!success) {
                        resolve({
                            success: false,
                            data: null,
                            message: txt
                        });
                    } else {
                        resolve({
                            success: true,
                            data: txt,
                            message: null
                        });
                    }
                }).catch((err) => {
                    reject(err);
                });
            }));
        }

        return this.getValidValuesMap.get(criteriaName);
    }
}

const store = new CriteriaServiceV2();
export default store;
