import { observable, action } from "mobx";
import { IQueryBuilderStore } from "@ts/ts-ux-contracts";

class QueryBuilderStore implements IQueryBuilderStore {
    @observable functionalGroupAppId: string;
    @action setFunctionalGroupAppId(functionalGroupAppId: string) {
        this.functionalGroupAppId = functionalGroupAppId;
    }
}

const store = new QueryBuilderStore();
export default store;