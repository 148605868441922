/* tslint:disable:no-console max-classes-per-file */
import { observable, action, computed, runInAction } from 'mobx';
import { adalApiFetch } from "../auth/Adal";
import AppInsightsService from "./AppInsightsService";

// Runs and provides the state of an async HTTP operation.
// Provides observable details so the operation and results can be represented in the UI.
export abstract class HttpOperation {
    private api: string;
    private serviceClientId: string;
    private task: any;
    private statusCode: number;
    private statusText: string;

    @observable query: any;
    @observable inProgress: boolean;
    @observable success: boolean;
    protected result: any; // If success, this is the result content, or if failure, this is the error message
    @computed get errorMessage(): string { return !this.inProgress && !this.success && this.result; }
    onSuccess?: any;
    onFailure?: any;
    protected abstract get options(): { method: string };

    protected constructor(serviceClientId: string, api: string) {
        this.api = api;
        this.serviceClientId = serviceClientId;
        const search = new URL(api).searchParams;
        this.query = search && { ...Array.from(search.entries()).map(p => ({ [p[0]]: p.length > 1 ? p[1] : true })) };
    }

    static get<T>(serviceClientId: string, api: string, postObj: any = null): HttpGet<T> {
        const op = new HttpGet<T>(serviceClientId, api, postObj);
        op.runApi(true);
        return op;
    }

    static patch(serviceClientId: string, api: string, postObj: any): HttpPatch {
        const op = new HttpPatch(serviceClientId, api, postObj);
        op.runApi(false);
        return op;
    }

    static patchWithResponse<T>(serviceClientId: string, api: string, postObj: any): HttpPatchWithResponse<T> {
        const op = new HttpPatchWithResponse<T>(serviceClientId, api, postObj);
        op.runApi(true);
        return op;
    }

    static post(serviceClientId: string, api: string, postObj: any): HttpPost {
        const op = new HttpPost(serviceClientId, api, postObj);
        op.runApi(false);
        return op;
    }

    static postWithResponse<T>(serviceClientId: string, api: string, postObj: any, onSuccess?: any, onFailure?: any): HttpPostWithResponse<T> {
        const op = new HttpPostWithResponse<T>(serviceClientId, api, postObj);
        op.onSuccess = onSuccess;
        op.onFailure = onFailure;
        op.runApi(true);
        return op;
    }

    static delete(serviceClientId: string, api: string): HttpDelete {
        const op = new HttpDelete(serviceClientId, api);
        op.runApi(true);
        return op;
    }

    wait() {
        return this.task;
    }

    @action
    private setInProgress(progress: boolean = false) {
        this.inProgress = progress
    }

    @action
    private runApi(getResponse: boolean) {
        this.setInProgress(true);
        this.success = false;
        const startTime = new Date().getTime();
        let elapsed = null;
        this.task = adalApiFetch(this.api, this.options)
            .then((response: Response) => {
                elapsed = (new Date().getTime() - startTime);
                const splitUrl = this.api.split('/');
                console.log(`${this.api}: HTTP response = ${response.status}`);
                runInAction(() => {
                    this.statusCode = response.status;
                    this.statusText = response.statusText;
                });
                if (response.ok) {
                    runInAction(() => {
                        this.success = true;
                    });
                    if (getResponse) {
                        return response.json();
                    }
                }
                else {
                    return response.text();
                }
            })
            .then(data => {
                AppInsightsService.trackDependencyData(this.api + " " + startTime.toString(), this.statusCode, this.success, elapsed, {
                    exception: false,
                    data: JSON.stringify(data),
                    statusText: this.statusText
                });
                if (!this.success) {
                    if (this.onFailure) {
                        this.onFailure();
                    }
                    this.result = `API failed ${this.statusCode} - ${data || this.statusText}`;
                }
                else {
                    if (this.onSuccess) {
                        this.onSuccess(data);
                    }
                    this.result = data;
                }
            })
            .catch(err => {
                AppInsightsService.trackDependencyData(this.api + " " + startTime.toString(), this.statusCode, false, elapsed, {
                    exception: true,
                    err: err.message || JSON.stringify(err)
                });
                this.result = `Exception trying to run API - ${err.message || err}`;
            })
            // anonymouse function
            .finally(() => {
                // this.inProgress = false;
                this.setInProgress(false);
            });
    }
}

export class HttpGet<TResult> extends HttpOperation {
    postObj: any;

    // The result is casted directly to the correct data type on success.
    @computed get data(): TResult { return !this.inProgress && this.success && this.result; }

    constructor(serviceClientId: string, api: string, postObj: any = null) {
        super(serviceClientId, api);

        this.postObj = postObj;
    }

    get options() {
        return !this.postObj
            ? {
                method: 'GET'
            }
            : {
                method: 'POST',
                body: JSON.stringify(this.postObj),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            };
    }
}

export class HttpPatch extends HttpOperation {
    postObj: any;

    constructor(serviceClientId: string, api: string, postObj: any) {
        super(serviceClientId, api);

        this.postObj = postObj;
    }

    get options() {
        return {
            method: 'PATCH',
            body: JSON.stringify(this.postObj),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}

export class HttpPatchWithResponse<TResult> extends HttpOperation {
    postObj: any;

    // The result is casted directly to the correct data type on success.
    @computed get data(): TResult { return !this.inProgress && this.success && this.result; }

    constructor(serviceClientId: string, api: string, postObj: any) {
        super(serviceClientId, api);

        this.postObj = postObj;
    }

    get options() {
        return {
            method: 'PATCH',
            body: JSON.stringify(this.postObj),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}

export class HttpPost extends HttpOperation {
    postObj: any;

    constructor(serviceClientId: string, api: string, postObj: any) {
        super(serviceClientId, api);

        this.postObj = postObj;
    }

    get options() {
        return {
            method: 'POST',
            body: JSON.stringify(this.postObj),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}

export class HttpPostWithResponse<TResult> extends HttpOperation {
    postObj: any;

    // The result is casted directly to the correct data type on success.
    @computed get data(): TResult { return !this.inProgress && this.success && this.result; }

    constructor(serviceClientId: string, api: string, postObj: any) {
        super(serviceClientId, api);
        this.postObj = postObj;
    }

    get options() {
        const isFormData = this.postObj instanceof FormData;
        return {
            method: 'POST',
            body: isFormData ? this.postObj : JSON.stringify(this.postObj),
            headers: !isFormData ? {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            } : {}
        };
    }
}

export class HttpDelete extends HttpOperation {
    constructor(serviceClientId: string, api: string) {
        super(serviceClientId, api);
    }

    get options() {
        return {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        };
    }
}
