import * as React from "react";
import { action, runInAction, observe } from "mobx";
import { observer } from "mobx-react";
import { Button } from "reactstrap";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import AttributeForm from "../shared/components/AttributeForm/AttributeForm";
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import CriteriaService from "../shared/services/CriteriaService";
import { AudienceCriteriaType, ApprovalState, AudienceCriteriaKind } from "ts-infra-common";
import AttributeStore from "../shared/stores/AttributeStore";
import { AttributeKind } from "ts-infra-common";
import { RevisionList } from "../shared/components/RevisionList/RevisionList";
import { AttributeCreateState } from "./AttributeCreateState";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import AlertStore from "../shared/stores/AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import LZUTF8 from 'lzutf8';
import AttributeWorkflow from "../AttributeWorkflow/AttributeWorkflow";
import AppInsightsService from '../shared/services/AppInsightsService';
import AppInsightsPageTracker from '../shared/services/AppInsightsPageTracker';
import AttributeRequestStore from "../shared/stores/AttributeRequestStore";
import { AttributeWorkflowView } from "../AttributeWorkflow/AttributeWorkflowView";
import parseQueryString = require('query-string');
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

enum CreateStatus {
    UnsavedChanges,
    Saving,
    Saved,
    DownloadingTest,
    Testing,
    Tested,
    Submitting,
    Submitted
}

@observer
class AttributeCreate extends React.Component<RouteComponentProps<{name?: string, kind?: string, skipToRequests?: string}> & {refreshStore: boolean}, AttributeCreateState> {


    revisions: RevisionList;
    pageTracker: AppInsightsPageTracker;

    constructor(props) {
        super(props);
        this.state = new AttributeCreateState();
    }

    get attribute() {
        return {
            Id: this.state.data.Id || this.state.data.Name,
            Kind: parseInt(this.state.data.Kind, null),
            Name: this.state.data.Name,
            Description: this.state.data.Description,
            AttributePropertiesKind: parseInt(this.state.data.AttributePropertiesKind, null),
            AttributeProperties: this.state.data.AttributeProperties,
            Justification: this.state.data.Justification,
            Example: this.state.data.Example,
            LastSavedName: this.state.data.Name,
            AddToCollectionRequests: this.state.data.AddToCollectionRequests,
            IsCtacAttribute: this.state.data.IsCtacAttribute
        } as any as AudienceCriteriaType;
    }

    componentDidMount() {
        this.pageTracker = AppInsightsService.startTrackPage("AttributeCreate");
        if (!FunctionalGroupStore.isLoaded) FunctionalGroupStore.fetchAllFunctionalGroups(true);
        if (this.props.match.params.name) {
            this.state.updateDisableNameKind(true);
            if (AttributeStore.data != null) {
                AttributeStore.fetch(this.props.refreshStore);
                const disposer = observe(AttributeStore, "data", () => {
                    this.setData(AttributeStore.data.find(i => i.Name === this.props.match.params.name && AttributeKind[i.AttributePropertiesKind] === this.props.match.params.kind));
                    this.state.updateDataWithFunc((data) => {
                        data.LastSavedName = data.Name;
                    });

                    disposer();
                });
                if (!this.props.refreshStore) {
                    this.setData(AttributeStore.data.find(i => i.Name === this.props.match.params.name && AttributeKind[i.AttributePropertiesKind] === this.props.match.params.kind));
                    this.state.updateDataWithFunc((data) => {
                        data.LastSavedName = data.Name;
                    });
                }
            } else {
                AttributeStore.fetch();
                const disposer = observe(AttributeStore, "data", (change) => {
                    if (change.newValue) {
                        this.setData(change.newValue.find(i => i.Name === this.props.match.params.name && AttributeKind[i.AttributePropertiesKind] === this.props.match.params.kind));
                        this.state.updateDataWithFunc((data) => {
                            data.LastSavedName = data.Name;
                        });
                    }

                    disposer();
                })
            }
        } else {
            this.setData({ AttributeProperties: {Transform: {}}, Kind: AudienceCriteriaKind.String, AttributePropertiesKind: -1 });
        }
    }

    componentDidUpdate() {
        if (this.state.redirectPath) {
            this.state.clearRedirection();
            this.state.updateFormDisabled(false);
        }
    }

    render() {
        if (this.state.data == null) {
            return <LoadingIndicator text="Loading..." />;
        }
        if (!FunctionalGroupStore.isLoaded || FunctionalGroupStore.isLoading) {
            return <div style={{ paddingTop: "100px" }}><LoadingIndicator text="Loading..." /></div>
        }
        if (this.pageTracker) this.pageTracker.stop();
        if (!this.state.data.hasOwnProperty("functionalGroup") && AttributeStore.data) {
            const matchingAttribute = AttributeStore.data.find(i => i.Name === this.props.match.params.name && AttributeKind[i.AttributePropertiesKind] === this.props.match.params.kind);
            if (matchingAttribute && matchingAttribute.AddToCollectionRequests) {
                const pendingRequest = matchingAttribute.AddToCollectionRequests.find((r) => {
                    return r.State === ApprovalState.Submitted;
                });
                if (pendingRequest) {
                    const matchingFg = FunctionalGroupStore.data.find((fg) => {
                        return fg.CTACRedirectAttributeGroup === pendingRequest.AttributeCollection || fg.CTACAttributeGroup === pendingRequest.AttributeCollection || fg.TSAttributeGroup === pendingRequest.AttributeCollection;
                    });
                    const matchingFgName = matchingFg ? matchingFg.Name : "";
                    this.state.updateDataWithFunc((data) => {
                        data.functionalGroup = matchingFgName;
                        // When the FG is updated, check if we've got the test JSON already stored
                        const compressedTestJson = sessionStorage.getItem(this.getTestJsonCacheKey());
                        if (compressedTestJson) {
                            const testJson = LZUTF8.decompress(compressedTestJson, {
                                inputEncoding: "Base64"
                            });
                            this.state.updateTestJson("text/json;charset=utf-8," + encodeURIComponent(testJson));
                            this.state.updateCreateStatus(CreateStatus.Testing);
                            this.state.updateJsonSource(true);
                        }
                    });
                }
            }
        }

        const attributeForm = this.getAttributeFormComponent();
        const queryString = this.props.location.search;
        const queryParams = parseQueryString.parse(queryString);
        let skipToRequests = false;
        if (queryParams.skipToRequests) {
            let skipToRequestsQueryVal;
            if (Array.isArray(queryParams.skipToRequests)) {
                skipToRequestsQueryVal = queryParams.skipToRequests[0];
            } else {
                skipToRequestsQueryVal = queryParams.skipToRequests as string;
            }

            skipToRequests = skipToRequestsQueryVal.toLowerCase() === "true";
        }

        if (!skipToRequests) {
            const actionableRequest = this.state.data.AddToCollectionRequests && AttributeRequestStore.fetchByUserOp && this.state.data.AddToCollectionRequests.find(v => {
                return AttributeRequestStore.fetchByUserOp.data.find(r => r.Request.RequestId === v.RequestId && r.Request.State === ApprovalState.Submitted && r.Attribute.NameLower === this.state.data.NameLower && r.Attribute.AttributePropertiesKind === this.state.data.AttributePropertiesKind);
            });
            if (actionableRequest) {
                skipToRequests = true;
            }
        }

        const wfView = this.props.match.params.name ? skipToRequests ? AttributeWorkflowView.Review : AttributeWorkflowView.Modify : AttributeWorkflowView.Create;
        return (this.state.redirectPath && !this.props.match.params.name ?
            <Redirect push to={this.state.redirectPath} /> :
            <div>
                <AttributeWorkflow attributeForm={attributeForm} allowCreateOrModify={true} attribute={this.attribute} view={wfView} onApproveReject={() => {
                    if (this.revisions) {
                        this.revisions.load();
                    }
                }} />
            </div>
        )
    }

    getAttributeFormComponent() {
        let revisionsComponent;
        if (this.props.match.params.name) {
            revisionsComponent = <RevisionList ref={(r) => this.revisions = r} name={this.attribute.Name} kind={this.attribute.AttributePropertiesKind} />;
        } else {
            revisionsComponent = null;
        }
        return <AttributeForm data={this.state.data} onChange={this.onChange} disabled={this.state.formDisabled} disabledOnView={this.state.formDisabled} onSubmit={this.onSave} disableNameKind={this.state.disableNameKind} readonly={false} renderChildrenByRow={true} revisionHistoryComponent={revisionsComponent}>
            <Button className={`ts-btn ${this.state.isSaving ? "ts-btn-disabled" : ""}`} type={"submit"} block onClick={null} style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }} disabled={this.state.isSaving}>{this.state.isSaving ? <CircularProgress size={"1.5rem"} color={"inherit"} /> : "Save"}</Button>
        </AttributeForm>;
    }

    @action
    private setData = (value) => {
        this.state.updateData(value);
    }

    @action
    private onChange = (name: string[] | string, value: any, type: string) => {
        if (!Array.isArray(name)) {
            name = [name];
        }

        let rootObject = this.state.data;
        if (type === 'details') {
            rootObject = rootObject.AttributeProperties;
            if (this.state.data.functionalGroup) {
                // Clear any test JSON for this attribute and group, if any
                sessionStorage.removeItem(this.getTestJsonCacheKey());
                this.state.updateTestJson(null);
            }
        }

        let bindObject;
        let previousPath;
        for (const path of name) {
            if (bindObject === undefined) {
                bindObject = rootObject;
            } else {
                // This must be during nested path evaluation
                if (!bindObject.hasOwnProperty(previousPath)) {
                    bindObject[previousPath] = {};
                }
                bindObject = bindObject[previousPath];
            }

            previousPath = path;
        }

        if (value === 0 || value) {
            bindObject[previousPath] = value;
        } else {
            delete bindObject[previousPath];
        }

        this.state.updateCreateStatus(CreateStatus.UnsavedChanges);

        // When the FG is updated, check if we've got the test JSON already stored
        if (previousPath === "functionalGroup") {
            const compressedTestJson = sessionStorage.getItem(this.getTestJsonCacheKey());
            if (compressedTestJson) {
                const testJson = LZUTF8.decompress(compressedTestJson, {
                    inputEncoding: "Base64"
                });
                this.state.updateTestJson("text/json;charset=utf-8," + encodeURIComponent(testJson));
                this.state.updateCreateStatus(CreateStatus.Testing);
                this.state.updateJsonSource(true);
            } else {
                this.state.updateTestJson(null);
                this.state.updateCreateStatus(CreateStatus.UnsavedChanges);
            }
        }

    }

    @action
    private onSave = async (errors: string[], values: any, updateCreateStatus: boolean = true) => {
        if (errors.length === 0) {
            AlertStore.clearAll();
            this.state.updateIsSaving(true);
            const presaveStatus = this.state.createStatus;
            if (updateCreateStatus) {
                this.state.updateCreateStatus(CreateStatus.Saving);
            }
            this.state.updateFormDisabled(true);

            const criteria = this.attribute;
            if (updateCreateStatus) {
                criteria.ApprovalState = ApprovalState.Testing;
            }

            let response = null;
            let error = null;
            try{
                response = await CriteriaService.addWithResponse(criteria);
            } catch (e) {
                error = e.toString();
            }

            runInAction(() => {
                this.state.updateIsSaving(false);
                if (response && response.success) {
                    const selectedFg = this.state.data.functionalGroup;
                    this.setData(response.criteria);
                    this.state.updateDataWithFunc((data) => {
                        data.functionalGroup = selectedFg;
                    });
                    if (!this.state.data.LastSavedName && this.state.data.Name) {
                        const newAttribute = this.attribute;
                        newAttribute.ApprovalState = ApprovalState.Testing;
                        if (AttributeStore.data) {
                            const matchingAttribute = AttributeStore.data.find((v) => {
                                return v.Name === newAttribute.Name && v.AttributePropertiesKind === newAttribute.AttributePropertiesKind;
                            });
                            if (!matchingAttribute) {
                                AttributeStore.data.push(newAttribute);
                            }
                        }
                        this.state.requiresRedirect(`/attribute/create/${AttributeKind[this.state.data.AttributePropertiesKind]}/${this.state.data.Name}`);
                    }
                    this.state.updateDataWithFunc((data) => {
                        data.LastSavedName = data.Name;
                    });
                    if (updateCreateStatus) {
                        this.state.updateCreateStatus(presaveStatus);
                    }
                    AttributeStore.fetch();
                    this.state.updateDisableNameKind(true);
                    if (this.revisions) {
                        this.revisions.load();
                    }
                }
                else {
                    AlertStore.add(AlertType.Error, `Failed to save attribute. Message: ${response ? response.errorMessage : error}`);
                    if (updateCreateStatus) {
                        this.state.updateCreateStatus(CreateStatus.UnsavedChanges);
                    }
                }

                this.state.updateFormDisabled(false);
            });
        }
    }

    getAttributeCollection() {
        const matchingFg = FunctionalGroupStore.data.find((fg) => {
            return fg.Name === this.state.data.functionalGroup;
        });
        if (matchingFg) {
            const attributeCollection = matchingFg.CTACRedirectAttributeGroup || (this.state.data.IsCtacAttribute ? matchingFg.CTACAttributeGroup : matchingFg.TSAttributeGroup);
            return attributeCollection;
        }

        return null;
    }

    getTestJsonCacheKey() {
        return `${this.state.data.Name}_${this.state.data.AttributePropertiesKind}_${this.getAttributeCollection()}_TEST_JSON`;
    }
}

export default AppInsightsService.trackComponent(withRouter(AttributeCreate));