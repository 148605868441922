import { action, observable } from "mobx";
import { BulkIngestionParameters, CosmosStreamDeviceGroupDefinition, GroupPrincipalType, BlobBulkIngestionParameters } from "ts-infra-common";
import moment from "moment";
import { BatchBulkIngestionParameters } from "ts-infra-common";

export class BatchIngestionWidgetState {
    @observable ingestionParameters: BlobBulkIngestionParameters | (CosmosStreamDeviceGroupDefinition & { IsNgpCompliant: boolean }) = new BlobBulkIngestionParameters();
    @action setIngestionParameters(ingestionParameters: any) {
        this.ingestionParameters = ingestionParameters;
    }

    @observable batchIngestionParameters: BatchBulkIngestionParameters = new BatchBulkIngestionParameters();
    @action setBatchIngestionParameters(batchIngestionParameters: BatchBulkIngestionParameters) {
        this.batchIngestionParameters = batchIngestionParameters;
        if (batchIngestionParameters.Parameters) {
            this.ingestionParameters = batchIngestionParameters.Parameters;
        }
    }
    @action updateBatchParameters(batchIngestionParameters: any) {
        this.batchIngestionParameters = {
            ...this.batchIngestionParameters,
            ...batchIngestionParameters
        };
    }

    @observable principalType: GroupPrincipalType;
    @action setPrincipalType(principalType: GroupPrincipalType) {
        this.principalType = principalType;
    }

    @observable sizeCap: number;
    @action setSizeCap(sizeCap: number) {
        this.sizeCap = sizeCap;
    }

    @observable enabled: boolean;
    @action setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    @observable aliasesLoaded: boolean;
    @action setAliasesLoaded(aliasesLoaded: boolean) {
        this.aliasesLoaded = aliasesLoaded;
    }

    @observable loading: boolean;
    @action setLoading(loading: boolean) {
        this.loading = loading;
    }

    @observable aliases: string[];
    @action setAliases(aliases: string[]) {
        this.aliases = aliases;
    }

    @observable anchorEl: any;
    @action setAnchorEl(anchorEl: any) {
        this.anchorEl = anchorEl;
    }

    @observable validated: BulkIngestionParameters;
    @action setValidated(validated: BulkIngestionParameters) {
        this.validated = validated;
    }

    @observable validating: boolean;
    @action setValidating(validating: boolean) {
        this.validating = validating;
    }

    @observable errors: string[] = [];
    @action setErrors(errors: string[]) {
        this.errors = errors;
    }

    @action addError(err: string) {
        const newErrs = [];
        for (const e of this.errors){
            newErrs.push(e);
        }
        newErrs.push(err);
        this.setErrors(newErrs);
    }

    @observable ingestionStatus: string;
    @observable lastProcessedTime: string;
    @observable lastTimestamp: string;
    @observable refreshingIngestionStatus: boolean;
    @observable lastError: string;
    @observable subGroups: string[];
    @action
    setIngestionState(ingestionStatus: any) {
        const pipelineState: number = ingestionStatus.PipelineState;
        const knownIssue: boolean = ingestionStatus.Data ? ingestionStatus.Data.KnownIssue : false;
        const retry: boolean = ingestionStatus.Data ? ingestionStatus.Data.Retry : false;
        const lastProcessedTime: Date = ingestionStatus.Data ? ingestionStatus.Data.LastProcessedTime : null;
        const timestamp: Date = ingestionStatus.Data ? ingestionStatus.Data.Timestamp : null;
        const errors: string[] = ingestionStatus.Errors ? ingestionStatus.Errors : null;
        this.lastError = "None";
        if (knownIssue) {
            this.ingestionStatus = "Disabled";
        } else if (retry) {
            this.ingestionStatus = "Waiting for Retry";
        } else {
            switch (pipelineState) {
                case 0:
                    this.ingestionStatus = "Ready";
                    break;
                case 1:
                    this.ingestionStatus = "Queued";
                    break;
                case 2:
                    this.ingestionStatus = "Preparing";
                    break;
                case 3:
                    this.ingestionStatus = "Calculating diffs";
                    break;
                case 4:
                    this.ingestionStatus = "Ingesting";
                    break;
                case 5:
                    this.ingestionStatus = "Error";
                    if (errors && errors.length > 0) {
                        this.lastError = errors[errors.length - 1];
                    }
                    break;
                case 6:
                    this.ingestionStatus = "Queued in Groups";
                    break;
                case 7:
                    this.ingestionStatus = "Ingestion Paused";
                    break;
                default:
                    this.ingestionStatus = "In Progress";
                    break;
            }
        }

        if (lastProcessedTime) {
            this.lastProcessedTime = moment(lastProcessedTime).fromNow();
        } else {
            this.lastProcessedTime = "Never";
        }

        if (timestamp) {
            this.lastTimestamp = moment(timestamp).fromNow();
        } else {
            this.lastTimestamp = "Never";
        }

        if (ingestionStatus.Data && ingestionStatus.Data.LastProcessedStreams) {
            this.subGroups = JSON.parse(ingestionStatus.Data.LastProcessedStreams);
        }  else {
            this.subGroups = [];
        }
    }

    @action
    setRefreshingIngestionStatus(refreshingIngestionStatus: boolean) {
        this.refreshingIngestionStatus = refreshingIngestionStatus;
    }

    @observable groupCount: number;
    @action setGroupCount(groupCount: number) {
        this.groupCount = groupCount;
    }

    @observable ingestionErrors: string[] = [];
    @action setIngestionErrors(ingestionErrors: string[]) {
        this.ingestionErrors = ingestionErrors;
    }

    @action addIngestionError(err: string) {
        const newErrs = [];
        for (const e of this.ingestionErrors){
            newErrs.push(e);
        }
        newErrs.push(err);
        this.setIngestionErrors(newErrs);
    }

    @observable pageSize: number = 4;
    @action setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    @observable page: number = 0;
    @action setPage(page: number) {
        this.page = page;
    }

    @observable actionInProgress: string;
    @action setActionInProgress(actionInProgress: string) {
        this.actionInProgress= actionInProgress;
    }

    @observable actionSuccesses: string[];
    @action clearActionSuccesses() {
        this.actionSuccesses = [];
    }
    @action addActionSuccess(msg: string) {
        if (!this.actionSuccesses.find(a => a === msg)) {
            this.actionSuccesses.push(msg);
        }
    }
    @action removeActionSuccess(msg: string) {
        this.actionSuccesses = this.actionSuccesses.filter(a => a !== msg);
    }

    @observable actionErrors: string[];
    @action clearActionErrors() {
        this.actionErrors = [];
    }
    @action addActionError(msg: string) {
        if (!this.actionErrors.find(a => a === msg)) {
            this.actionErrors.push(msg);
        }
    }
    @action removeActionError(msg: string) {
        this.actionErrors = this.actionErrors.filter(a => a !== msg);
    }
}