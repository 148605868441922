import * as React from "react";
import { match } from "react-router-dom";
import { observer } from "mobx-react";
import AppStore from "../shared/stores/AppStore";
import RevisionList from "../shared/components/RevisionList/RevisionList";
import Modal from "react-modal";
import "./AttributeView.css";
import AttributeView from "./AttributeView";
import AppInsightsService from '../shared/services/AppInsightsService';

interface AttributeViewPageProps {
    match: match<{ name: string, kind: string }>
}

@observer
export class AttributeViewPage extends React.Component<AttributeViewPageProps> {

    revisions: any;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AppStore.setPageTitle("View Attribute");
        Modal.setAppElement(document.getElementById("root"));
    }

    render() {
        return <AttributeView match={this.props.match} />
    }
}

export default AppInsightsService.trackComponent(AttributeViewPage);