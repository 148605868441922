import { observable, action, computed } from 'mobx';
import { EventEmitter } from 'events';

enum CreateStatus {
    UnsavedChanges,
    Saving,
    Saved,
    DownloadingTest,
    Testing,
    Tested,
    Submitting,
    Submitted
}

export class AttributeCreateState {
    @observable testJson: string;
    @observable jsonName: string;
    @observable createStatus = CreateStatus.UnsavedChanges;
    @observable data: {[key: string]: any};
    @observable formDisabled: boolean;
    @observable disableNameKind: boolean;
    @observable redirectPath: string;
    @observable savedJson: boolean;
    @observable isSaving: boolean;
    @observable transformsExist: boolean
    eventEmitter: EventEmitter = new EventEmitter();

    @action
    updateIsSaving(isSaving: boolean) {
        this.isSaving = isSaving;
    }

    @action
    requiresRedirect(newRedirectPath: string) {
        this.redirectPath = newRedirectPath;
    }

    @action
    clearRedirection() {
        this.redirectPath = null;
    }

    @action
    updateTransformsExist(val: boolean) {
        this.transformsExist = val;
    }

    @action
    updateTestJson(val: string) {
        this.testJson = val;
    }

    @action
    updateJsonName(val: string) {
        this.jsonName = val;
    }

    @action
    updateDisableNameKind(val: boolean) {
        this.disableNameKind = val;
    }

    @action
    updateCreateStatus(val: CreateStatus) {
        this.createStatus = val;
    }

    @action
    updateFormDisabled(val: boolean) {
        this.formDisabled = val;
    }

    @action
    updateData(val: any) {
        this.data = val;
    }

    @action
    updateDataWithFunc(updater: (arg0: any)=>void) {
        updater(this.data);
    }

    @action
    updateJsonSource(savedLocally: boolean) {
        this.savedJson = savedLocally;
    }
}
