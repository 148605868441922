import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { observer } from "mobx-react";
import React from "react";
import { Query, Builder } from "react-awesome-query-builder";
import { TSQueryBuilderProps } from "./TSQueryBuilderProps";
import { TSQueryBuilderState } from "./TSQueryBuilderState";
import * as Immutable from "immutable";
import { AudienceQueryProvider } from "./AudienceQueryProvider";
import "../ts-ux-querybuilder.scss";

@observer
export class TSQueryBuilder extends React.Component<TSQueryBuilderProps, TSQueryBuilderState> {
    antBtnListener;
    audienceContext;
    constructor(props) {
        super(props);
        const queryProvider = new AudienceQueryProvider(this.props.tsQueryConfig, this.props.onMissingAttribute, this.props.readOnly ?? false, this.props.widgets, this.props.widgetOptions, this.props.onFormValidationChanged, this.props.audienceContext);
        if (this.props.onQueryProviderCreated) {
            this.props.onQueryProviderCreated(queryProvider);
        }
        this.state = new TSQueryBuilderState(queryProvider);
        this.audienceContext = this.props.audienceContext;
        this.props.tsQueryConfig.criteriaStore.setOnDataChanged((data) => {
            this.state.updateConfig(data);
            this.state.updateTree(this.state.audienceQueryProvider.getTree(this.props.criteria, this.props.audienceContext));
        });
    }

    componentDidMount() {
        if (!this.state.qbConfig) {
            this.state.updateConfig(this.props.tsQueryConfig.criteriaStore.data);
        }

        this.state.updateTree(this.state.audienceQueryProvider.getTree(this.props.criteria, this.audienceContext));
    }

    render() {
        if (!this.props.tsQueryConfig.criteriaStore.data || !this.state.qbConfig) {
            return <Grid container direction="row">
                <CircularProgress></CircularProgress>
            </Grid>;
        }
        return <div id={this.props.id || "ts-query-builder"}><Query
            {...this.state.qbConfig}
            value={Immutable.fromJS(
                this.state.tree,
                (key, value) => {
                    const indexedListValue = value as Immutable.Collection.Indexed<any>;
                    return (key === 'value' && indexedListValue.get(0) && indexedListValue.get(0).toJS !== undefined)
                        ? Immutable.List.of(indexedListValue.get(0).toJS())
                        : Immutable.isIndexed(value)
                            ? value.toList()
                            : value.toOrderedMap();
                }
            )}
            onChange={(tree) => {
                const serializedCriteria = this.state.audienceQueryProvider.getSerializedCriteria(tree);
                this.state.updateTree(tree);
                if (this.props.onCriteriaChanged) {
                    this.props.onCriteriaChanged(serializedCriteria);
                }
            }}
            renderBuilder={(props) => {
                return <div style={{ padding: '10px' }}>
                    <div className="query-builder">
                        <Builder {...props} />
                    </div>
                </div>;
            }}
        /><Hidden xsUp>{this.state.configJson}</Hidden></div>;
    }
}

export default TSQueryBuilder;