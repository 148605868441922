import { observable, action, computed } from 'mobx';
import { FGAuthResult } from '../../types/ts-private-contracts';

export class AudienceCreateState {
    @observable selectedFunctionalGroupId: string;
    @observable selectedAudienceName: string;
    @observable selectedAudienceDescription: string;
    @observable selectedAudienceOwner: string;
    @observable isCreating: boolean = false;
    @observable currentAdmins: string[] = [];
    @observable fgAuths: FGAuthResult[] = [];
    @observable authCheckStarted: boolean = false;
    @action
    updateFuncGroup(val: string) {
        this.selectedFunctionalGroupId = val;
    }

    @action
    updateAudienceName(val: string) {
        this.selectedAudienceName = val;
    }

    @action
    updateAudienceOwner(val: string) {
        this.selectedAudienceOwner = val;
    }

    @action
    updateAudienceDescription(val: string) {
        this.selectedAudienceDescription = val;
    }

    @action
    clearForm = () => {
        this.selectedFunctionalGroupId = '';
        this.selectedAudienceName = '';
        this.selectedAudienceOwner = '';
        this.selectedAudienceDescription = '';
        this.currentAdmins = [];
    }

    @action
    setIsCreating(v: boolean) {
        this.isCreating = v;
    }

    @action
    updateAudienceAdmins(newAdmins: string[]) {
        this.currentAdmins = newAdmins;
    }

    @action
    addFgAuth(fgAuth: FGAuthResult) {
        this.fgAuths.push(fgAuth);
    }

    @action
    setFgAuth(name: string, hasAccess: boolean) {
        const fgAuth = this.fgAuths.find(a => a.name === name);
        fgAuth.completed = true;
        fgAuth.hasAccess = hasAccess;
    }

    @action
    setAuthCheckStarted(authCheckStarted: boolean) {
        this.authCheckStarted = authCheckStarted;
    }

    hasFgAccess(name: string) {
        const fgAuth = this.fgAuths.find(a => a.name === name);
        return fgAuth && fgAuth.hasAccess;
    }
}