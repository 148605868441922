import * as React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio, AvInput, AvGroup } from "availity-reactstrap-validation";
import { observable, computed, action } from "mobx";
import { AttributeKind, ApprovalState } from "ts-infra-common";
import FunctionalGroupStore from "../../stores/FunctionalGroupStore";
import { mapEnumToOptions } from "../../utils";
import AttributeFormDetailsProps from "./AttributeFormDetailsProps";
import AttributeFormTransformProps from "./AttributeFormTransformProps";
import Contains from "./components/Contains";
import OtherTransforms from "./components/OtherTransforms"
import FileDetails from "./components/FileDetails";
import LicensingDetails from "./components/LicensingDetails";
import PolicyDetails from "./components/PolicyDetails";
import RegistryDetails from "./components/RegistryDetails";
import UpdatePolicyDetails from "./components/UpdatePolicyDetails";
import WMIDetails from "./components/WMIDetails";
import { observer } from "mobx-react";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { AttributeAudienceCriteriaKind } from '@ts/ts-ux-contracts';
import AttributeStore from "../../stores/AttributeStore";
import AppInsightsService from '../../services/AppInsightsService';

interface AttributeFormProps {
    data: { [key: string]: any };
    onChange?: (n: string[] | string, v: any, t: string) => void;
    disabled?: boolean;
    disabledOnView?: boolean;
    readonly?: boolean;
    onSubmit?: (errors: any, values: any) => void;
    disableNameKind?: boolean;
    renderChildrenByRow?: boolean;
    revisionHistoryComponent?: any;
}

interface AttributeFormState {
    transform: string;
    enableTransforms: boolean;
}

@observer
export class AttributeForm extends React.Component<AttributeFormProps, AttributeFormState> {
    @observable functionalGroup: string;
    @observable mounted: boolean = false;
    @observable nameErrorMessage: string;
    @observable formErrors: string = '';
    formRef: React.RefObject<AvForm>;

    constructor(props) {
        super(props);
        this.state = {transform: "", enableTransforms: this.hasTransforms};
        this.formRef = React.createRef();
    }

    @action
    changeMounted(mounted: boolean) {
        this.mounted = mounted;
    }

    componentDidMount() {
        this.changeMounted(true);
        if (!FunctionalGroupStore.isLoaded) FunctionalGroupStore.fetchAllFunctionalGroups(true);
        if (!AttributeStore.data) AttributeStore.fetch(false);
    }

    componentWillUnmount() {
        this.changeMounted(false);
    }

    get readonly() {
        return this.props.readonly;
    }

    @computed
    get disabled() {
        return this.readonly || this.props.disabled;
    }

    get disabledOnView() {
        return this.props.disabledOnView;
    }

    get hasTransforms() {
        if(this.props.data.AttributeProperties && this.props.data.AttributeProperties.Transform) {
            return JSON.stringify(this.props.data.AttributeProperties.Transform) !== JSON.stringify({})
        }

        return false;
    }

    clearTransformFields = () => {
            const target = ['Transform']
            this.onChangeAttributeDetailsProps(target, {})
    }

    onSelectTransform = (event: any) => {
        event.persist()

        if(!this.disabled) {
            this.clearTransformFields()
        }

        this.setState({transform: event.target.value})
    }

    toggleTransforms = (e: any) => {
        if(this.state.enableTransforms) {
            this.setState({enableTransforms: !this.state.enableTransforms, transform: ""})
            this.clearTransformFields()
        }

        this.setState({enableTransforms: !this.state.enableTransforms})
    }

    renderDetails = () => {
        let Details: React.ComponentClass<AttributeFormDetailsProps>
        switch (this.props.data.AttributePropertiesKind.toString()) {
            case "0": Details = RegistryDetails; break;
            case "1": Details = FileDetails; break;
            case "2": Details = UpdatePolicyDetails; break;
            case "3": Details = LicensingDetails; break;
            case "4": Details = WMIDetails; break;
            case "5": Details = PolicyDetails; break;
            // case "14": Details = UTCDetails; break;
            default: return null;
        }

        return (
            <Row>
                <Col style={{ marginTop: "20px" }}>
                    <div>Details</div>
                    <div style={{ border: "1px solid #c0c0c0", borderRadius: "5px", padding: "20px", marginBottom: "25px", marginTop: "10px" }}>
                        <Details data={this.props.data.AttributeProperties} disabled={this.disabled} onChange={this.onChangeAttributeDetailsProps} rootAttribute={this.props.data} formRef={this.formRef} />
                    </div>
                </Col>
            </Row>
        )
    }

    renderTransformOptions = () => {
            return (
             <AvRadioGroup inline name='TransformOptions' onChange={this.onSelectTransform} >
                 <AvRadio label="Contains" value="Contains" disabled={this.disabled}/>
                 <AvRadio label="Other" value="Other" disabled={this.disabled}/>
             </AvRadioGroup>
            )
    }

    renderTransforms = () => {
        let Transform: React.ComponentClass<AttributeFormTransformProps>
        switch(this.state.transform) {
            case "Contains": Transform = Contains; break;
            case "Other": Transform = OtherTransforms; break;
            default:
                if(this.hasTransforms) {
                    if(this.props.data.AttributeProperties.Transform.Contains) {
                        Transform = Contains;
                    }

                    else {
                        Transform = OtherTransforms;
                    }
                }

                else {
                    return null;
                }
        }

        return (
            <Row>
                <Col>
                    <div>
                        <Transform data={this.props.data.AttributeProperties} onChange={this.onChangeAttributeDetailsProps} disabled={this.disabled} />
                    </div>
                </Col>
            </Row>
        )
    }

    onSubmit = (event: any, errors: any, values: any) => {
        this.handleFormErrors(errors);
        if(!this.hasTransforms) {
            const target = ['Transform']
            this.onChangeAttributeDetailsProps(target, null)
        }
        this.props.onSubmit(errors, values);
    }

    @action
    handleFormErrors = (errors: string[]) => {
        this.formErrors = '';
        if(errors.length > 0) {
            errors.forEach(e => {   if(!!e) this.formErrors += e + " required. "; } );
        }
    }

    onChangeAttributeDetailsProps = (n: string[] | string, v: any) => {
        this.props.onChange(n, v, 'details');
    }

    onChangeAttributeProps = (e: any) => {
        if (e.target) {
            this.props.onChange(e.target.name, e.target.value, 'attribute');
        }
    }

    validateAttributeName = (value, ctx, input, cb) => {
        if (!value) {
            cb("Required");
            return;
        }

        if (!value.match(/^[A-Za-z0-9_]+$/)) {
            cb("Only alphanumeric characters and underscores are allowed");
            return;
        }

        if (this.props.data.AttributePropertiesKind && AttributeStore.data && AttributeStore.data.find((v) => {
            return v.NameLower === value.toLowerCase() && v.AttributePropertiesKind === this.props.data.AttributePropertiesKind;
        }) && !this.props.data.Revision) {
            cb("Attribute already exists");
            return;
        }

        cb(true);
    }

    validateAttributePropertiesKind = (value, ctx, input, cb) => {
        if (!value || value.toString() === "-1") {
            cb("Required");
            return;
        }

        if (this.props.data.Name && AttributeStore.data && AttributeStore.data.find((v) => {
            return v.NameLower === this.props.data.Name.toLowerCase() && v.AttributePropertiesKind === value;
        }) && !this.props.data.Revision) {
            cb("Attribute already exists");
            return;
        }

        cb(true);
    }

    renderErrors = () => {
        return (
            <label hidden={this.formErrors.length === 0} className="sr" aria-label="Cannot submit, there are some errors.">{this.formErrors}</label>
        );
    }

    render() {
        const hasTransforms = this.hasTransforms
        const containsTransform = ((hasTransforms && this.props.data.AttributeProperties.Transform.Contains) || this.state.transform === 'Contains')
        const otherTransform = ((hasTransforms && !containsTransform) || this.state.transform === 'Other')
        let defaultTransform = ''
        if(containsTransform) defaultTransform = 'Contains'
        if(otherTransform) defaultTransform = 'Other'

        const defaultValues = {
            TransformOptions: defaultTransform,
            Transform: this.state.enableTransforms || containsTransform || otherTransform
        }

        if (!this.readonly && (!FunctionalGroupStore.isLoaded && FunctionalGroupStore.isLoading)) return <LoadingIndicator text="Loading..." />;
        return (
            <div>
                <AvForm ref={this.formRef} onSubmit={this.onSubmit} model={defaultValues}>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <AvField aria-required={true} name="Name" label="Name" aria-label="Name" id="Name" type="text" value={this.props.data.Name} onChange={this.onChangeAttributeProps}
                                            disabled={this.disabled || this.props.disableNameKind}
                                            validate={{ required: {value: this.readonly || this.disabled || this.disabledOnView || this.props.disableNameKind ? null : { custom: this.validateAttributeName }, errorMessage: 'Name required'}, pattern: { value: '^(?:((?!\\d)\\w+(?:\\.(?!\\d)\\w+)*)\\.)?((?!\\d)\\w+)$', errorMessage: 'Must be a valid C# identifier' }}}
                                            helpMessage={"Unique, descriptive name with alphanumeric characters and underscores only."}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <AvField type="select" name="AttributePropertiesKind" aria-label="AttributePropertiesKind" label="Attribute Type" id="AttributePropertiesKind" value={this.props.data.AttributePropertiesKind.toString()}
                                            onChange={this.onChangeAttributeProps} disabled={this.disabled || this.props.disableNameKind}
                                            validate={{ required: {value: this.readonly || this.disabled || this.disabledOnView || this.props.disableNameKind ? null : { custom: this.validateAttributePropertiesKind }, errorMessage: 'Attribute type required'}}}
                                            helpMessage={"Determines how this attribute will be collected on devices."}>
                                            {mapEnumToOptions(AttributeKind, "Select A Type")}
                                        </AvField>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="Kind">Audience Criteria Value Kind</Label>
                                        <Input type="select" name="Kind" id="Kind" value={this.props.data.Kind} onChange={this.onChangeAttributeProps} disabled={this.disabled}>
                                            {mapEnumToOptions(AttributeAudienceCriteriaKind)}
                                        </Input>
                                        <small className="form-text text-muted">The value type when creating an audience with this attribute. For example, IsFlightingEnabled is a boolean value when adding it to an audience.</small>
                                    </FormGroup>
                                </Col>
                                {this.readonly &&
                                    <Col>
                                        <label>Approval State</label>
                                        <div style={{ marginTop: "5px", marginBottom: "5px" }}><b>{ApprovalState[this.props.data.ApprovalState]}</b></div>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <AvField aria-required={true} name="Description" label="Description" aria-label="Description" id="Description" type="textarea" rows="5" value={this.props.data.Description} onChange={this.onChangeAttributeProps}
                                            errorMessage="Required"
                                            validate={{ required: {value: true, errorMessage: 'Description required' }}}
                                            helpMessage="Enter a user-friendly description of what this attribute is. For example, FlightRing contains the internal channel of 'Canary' or 'Selfhost', or the external channel of 'Dev', etc."
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <AvField aria-required={true} name="Example" label="Example" aria-label="Example" id="Example" type="textarea" rows="5" value={this.props.data.Example} onChange={this.onChangeAttributeProps}
                                            errorMessage="Required"
                                            validate={{ required: {value: true, errorMessage: 'Example required' }}}
                                            helpMessage="Enter some examples of how this attribute should appear on an audience (how it will be targeted). For example, 'FlightRing Equals Canary' or 'IsFlightingEnabled Exists'."
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {!this.readonly &&
                                <Row>
                                    <Col>
                                        <FormGroup>
                                        <AvField aria-required={true} name="Justification" label="Business Justification" aria-label="Business Justification" id="Justification" type="textarea" rows="5" value={this.props.data.Justification} onChange={this.onChangeAttributeProps}
                                                disabled={this.disabled} errorMessage="Required"
                                                validate={{ required: {value: true, errorMessage: 'Justification required' }}}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>}
                        </Col>
                        <Col>
                            {!this.props.renderChildrenByRow ? <div>{this.props.children}{this.props.revisionHistoryComponent}</div> :this.props.revisionHistoryComponent}
                        </Col>
                    </Row>
                    {this.renderDetails()}

                    <AvInput type="checkbox" name="Transform" aria-labelledby="Apply Transforms" disabled={this.disabled} onChange={this.toggleTransforms}/>
                    <Label for="Transform"> Apply Transforms</Label>

                    { (this.state.enableTransforms || containsTransform || otherTransform) && this.renderTransformOptions()}
                    {this.renderTransforms()}

                    {this.props.renderChildrenByRow ? <Row>
                        {this.props.children}
                    </Row> : null}
                </AvForm>
                {this.renderErrors()}
            </div>
        );
    }
}

export default AppInsightsService.trackComponent(AttributeForm);