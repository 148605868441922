import React from "react";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { action, observable } from "mobx";
import CircularProgress from "@material-ui/core/CircularProgress";

@observer
export class BulkIngestionActions extends React.Component<{
    actionInProgress: string,
    actionSuccesses: string[],
    actionErrors: string[],
    removeActionSuccess: (msg: string) => void,
    removeActionError: (msg: string) => void,
    onRefresh: () => void,
    onSetRetry?: () => void,
    onRunIngestion?: () => void
}> {
    @observable anchorEl: any;
    @action setAnchorEl(anchorEl: any) {
        this.anchorEl = anchorEl;
    }

    constructor(props) {
        super(props);
    }

    onCloseMenu = () => {
        this.setAnchorEl(null);
    }

    render() {
        const atLeastOneExtraAction = this.props.onSetRetry || this.props.onRunIngestion;
        return <React.Fragment>
            <Grid direction="row" container>
                <Menu
                    anchorEl={this.anchorEl}
                    keepMounted
                    open={Boolean(this.anchorEl)}
                    onClose={this.onCloseMenu}>
                    <MenuItem onClick={() => {
                        this.props.onSetRetry();
                        this.onCloseMenu();
                    }} disabled={!Boolean(this.props.onSetRetry)}>Queue for Retry</MenuItem>
                    <MenuItem onClick={() => {
                        this.props.onRunIngestion();
                        this.onCloseMenu();
                    }} disabled={!Boolean(this.props.onRunIngestion)}>Run On-Demand Ingestion</MenuItem>
                </Menu>
                {this.props.actionInProgress ?
                    <Grid direction="column" container justify="center" alignContent="center" alignItems="center">
                        <Typography>{this.props.actionInProgress}</Typography>
                        <CircularProgress />
                    </Grid> :
                    <React.Fragment>
                        <Button variant="outlined" color="primary" onClick={() => {
                        this.props.onRefresh();
                    }} style={{ width: '85%' }}>Refresh Ingestion Status</Button>
                        {atLeastOneExtraAction ? <Button variant="outlined" color="primary" onClick={(ev) => {
                            this.setAnchorEl(ev.target);
                        }} >...</Button> : null}
                    </React.Fragment>}
                <Grid direction="column" container justify="center" alignContent="center" alignItems="center">
                    {this.props.actionSuccesses && this.props.actionSuccesses.map((msg) => {
                        return <Alert severity="success" onClose={() => {
                            this.props.removeActionSuccess(msg);
                        }}>{`${msg}`}</Alert>
                    })}
                    {this.props.actionErrors && this.props.actionErrors.map((msg) => {
                        return <Alert severity="error" onClose={() => {
                            this.props.removeActionError(msg);
                        }}>{`${msg}`}</Alert>
                    })}
                </Grid>
            </Grid>
        </React.Fragment>;
    }
}