import config from "../../config.json";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import AppInsightsPageTracker from "./AppInsightsPageTracker";
import { string } from "prop-types";
import { AppInsightsConfig } from "./AppInsightsConfig.js";

class AppInsightsService {
    private inst: ApplicationInsights;
    private reactPlugin: ReactPlugin;
    constructor() {
        this.reactPlugin = new ReactPlugin();
        const clonedConfig: AppInsightsConfig = { ... config.appInsights };
        clonedConfig.extensions = [this.reactPlugin];
        this.inst = new ApplicationInsights({
            config: clonedConfig
        });
        this.inst.loadAppInsights();
    }

    /**
     * Get the inner ApplicationInsights instance to run APIs directly, for those that are missing from the shim.
     *
     * @readonly
     * @type {ApplicationInsights}
     * @memberof AppInsightsService
     */
    get instance(): ApplicationInsights {
        return this.inst;
    }

    /**
     * Adds Application Insights telemetry for the input component and returns the higher-order component.
     *
     * @template P
     * @param {React.ComponentType<P>} component Component to instrument with Application Insights
     * @param {string} [componentName]
     * @param {string} [className]
     * @returns {React.ComponentClass<P>} Instrumented component. Use this instead of the input component.
     * @memberof AppInsightsService
     */
    trackComponent<P>(component: React.ComponentType<P>, componentName?: string, className?: string): React.ComponentClass<P> {
        return withAITracking(this.reactPlugin, component, componentName, className);
    }

    /**
     * Track page view and page view performance data automatically. Note that this doesn't measure when the page is interactable - just loaded.
     *
     * @param {string} [name] Name of the pageview. Defaults to the document title.
     * @param {string} [uri]A relative or absolute URL that identifies the page or other item. Defaults to the window location.
     * @param {string} [refUri] The URL of the previous page that sent the user to the current page.
     * @param {string} [pageType] Page Type string. Describes how you classify this page, e.g. errorPage, formPage, etc.
     * @param {boolean} [isLoggedIn=true] Whether or not the user is logged in
     * @param {*} [properties] Map of string to any: Additional data used to filter pages in the portal. Defaults to empty.
     * @memberof AppInsightsService
     */
    trackPageView(name?: string, uri?: string, refUri?: string, pageType?: string, isLoggedIn: boolean = true, properties?: any) {
        this.instance.trackPageView({name, uri, refUri, pageType, isLoggedIn, properties});
    }

    /**
     * Track page view data manually. Use this to track interactable load duration. Most pages in this website should use this one.
     *
     * @param {string} [name] Custom name of page. Defaults to document title, which is usually "Targeting Service", so this parameter is recommended.
     * @returns {AppInsightsPageTracker}
     * @memberof AppInsightsService
     */
    startTrackPage(name?: string): AppInsightsPageTracker {
        this.instance.startTrackPage(name);
        return new AppInsightsPageTracker(this.instance, name);
    }

    /**
     * Log a positive numeric value that is not associated with a specific event. Typically used to send regular reports of performance indicators.
     *
     * To send a single measurement, use just the first two parameters. If you take measurements very frequently, you can reduce the telemetry bandwidth by aggregating multiple measurements and sending the resulting average and sampleCount at intervals.
     *
     * @param {string} name A string that identifies the metric. In the portal, you can select metrics for display by name.
     * @param {number} average Either a single measurement, or the average of several measurements. Should be >=0 to be correctly displayed.
     * @param {number} [sampleCount] Count of measurements represented by the average. Defaults to 1. Should be >=1.
     * @param {number} [min] The smallest measurement in the sample. Defaults to the average. Should be >= 0.
     * @param {number} [max] The largest measurement in the sample. Defaults to the average. Should be >= 0.
     * @param {*} [properties] Map of string to any: Additional data used to filter pages in the portal. Defaults to empty.
     * @memberof AppInsightsService
     */
    trackMetric(name: string, average: number, sampleCount?: number, min?: number, max?: number, properties?: any) {
        this.instance.trackMetric({name, average, sampleCount, min, max, properties});
    }

    /**
     * Log an exception you have caught. Can also log a simple error message by wrapping in a new Error object.
     *
     * @param {(Error | string)} exception The exception
     * @param {number} [severityLevel] Severity of the message, ranging from verbose to critical. Use the SeverityLevel enum in the applicationinsights package.
     * @param {*} [properties] Map of string to any: Additional data used to filter pages in the portal. Defaults to empty.
     * @memberof AppInsightsService
     */
    trackException(exception: Error | string, severityLevel?: number, properties?: any) {
        let err;
        if (exception instanceof string) {
            err = new Error(exception as string);
        } else {
            err = exception as Error;
        }

        this.instance.trackException({exception: err, severityLevel, properties});
    }

    /**
     * Log a diagnostic event such as entering or leaving a method.
     *
     * @param {string} message Diagnostic data. Can be much longer than an event's name.
     * @param {number} [severityLevel] Severity of the message, ranging from verbose to critical. Use the SeverityLevel enum in the applicationinsights package.
     * @param {*} [properties] Map of string to any: Additional data used to filter pages in the portal. Defaults to empty.
     * @memberof AppInsightsService
     */
    trackTrace(message: string, severityLevel?: number, properties?: any) {
        this.instance.trackTrace({message, severityLevel, properties});
    }

    /**
     * Log a dependency call (for instance: ajax)
     *
     * @param {string} id Unique id, this is used by the backend to correlate server requests.
     * @param {number} responseCode Response code returned by the dependency request (e.g., 200 for a success)
     * @param {boolean} [success] Whether or not the request was successful or not (e.g., responseCode in the range 200-299)
     * @param {number} [duration] Elapsed time of request & reply
     * @param {*} [properties] Map of string to any: Additional data used to filter pages in the portal. Defaults to empty.
     * @memberof AppInsightsService
     */
    trackDependencyData(id: string, responseCode: number, success?: boolean, duration?: number, properties?: any) {
        this.instance.trackDependencyData({id, responseCode, success, duration, properties});
    }

    /**
     * Track a customEvent
     *
     * @param {string} name An event name string.
     * @param {*} [properties] Map of string to any: Additional data used to filter pages in the portal. Defaults to empty.
     * @memberof AppInsightsService
     */
    trackEvent(name: string, properties?: any) {
        this.instance.trackEvent({name, properties})
    }

    /**
     * Immediately send all queued telemetry. By default, it is sent async.
     *
     * Note: You don't have to use flush, as it is automatically called at an interval and when the user closes the window.
     *
     * @param {boolean} [async=true] Defaults to true
     * @memberof AppInsightsService
     */
    flush(async: boolean = true) {
        this.instance.flush(async);
    }
}

const inst = new AppInsightsService();
export default inst;