import React from "react";
import { observer } from "mobx-react";
import { AttributeExampleWrapperState } from "./AttributeExampleWrapperState";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { TSQueryConfig } from "./TSQueryConfig";
import { TSWidgetOptionsProps } from "./TSWidgetOptionsProps";
import isNil from "lodash/isNil";
@observer
export class AttributeExampleWrapper extends React.Component<{ field?} & TSQueryConfig & TSWidgetOptionsProps, AttributeExampleWrapperState> {
    constructor(props) {
        super(props);
        this.state = new AttributeExampleWrapperState();
    }

    render() {
        const serverCriteria = this.props.criteriaStore.data.find(a => a.Name === this.props.field);
        const enableExamplePopover = isNil(this.props.disableAttributeExamplePopover) || !this.props.disableAttributeExamplePopover;
        if (serverCriteria && serverCriteria.Example && enableExamplePopover) {
            return <Grid container direction="row" style={{ display: 'inline-flex' }} alignItems="center" >{this.props.children}<Popover
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={() => { this.state.setAnchorEl(null); }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                PaperProps={{ style: { maxWidth: '20rem', maxHeight: '20rem', overflow: 'auto' } }}
            ><Typography style={{ padding: '15px' }}><b>Example</b></Typography><Typography style={{ padding: '15px' }}>{serverCriteria.Example}</Typography></Popover><IconButton onClick={(ev) => { this.state.setAnchorEl(ev.target); }} style={{ width: '10px', height: '10px', marginLeft: '10px' }}><InfoIcon /></IconButton></Grid>;
        } else {
            return this.props.children;
        }
    }
}