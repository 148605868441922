import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { action, runInAction, observe } from "mobx";
import { observer } from "mobx-react";
import AttributeForm from "../shared/components/AttributeForm/AttributeForm";
import AttributeStore from "../shared/stores/AttributeStore";
import LoadingIndicator from "../shared/components/LoadingIndicator/LoadingIndicator";
import { AttributeKind, AudienceCriteriaType, ApprovalState, UserRoles } from "ts-infra-common";
import { RevisionList } from "../shared/components/RevisionList/RevisionList";
import { Button } from "reactstrap";
import AlertStore from "../shared/stores/AlertStore";
import { Alert, AlertType } from "@ts/ts-ux-contracts";
import CriteriaService from "../shared/services/CriteriaService";
import FunctionalGroupStore from "../shared/stores/FunctionalGroupStore";
import { AttributeViewState } from "./AttributeViewState";
import "./AttributeView.css";
import AttributeRequestStore from "../shared/stores/AttributeRequestStore";
import AppInsightsService from '../shared/services/AppInsightsService';
import AppInsightsPageTracker from '../shared/services/AppInsightsPageTracker';
import AttributeWorkflow from "../AttributeWorkflow/AttributeWorkflow";
import { AttributeWorkflowView } from "../AttributeWorkflow/AttributeWorkflowView";
import parseQueryString = require('query-string');
import UserStore from "../shared/stores/UserStore";
import CircularProgress from "@material-ui/core/CircularProgress";

enum CreateStatus {
    UnsavedChanges,
    Saving,
    Saved
}

@observer
class AttributeView extends React.Component<RouteComponentProps<{ name?: string, kind?: string, skipToRequests?: string }>, AttributeViewState> {
    revisions: RevisionList;
    revisionsComponent: any;
    pageTracker: AppInsightsPageTracker;
    constructor(props) {
        super(props);
        this.state = new AttributeViewState();
    }

    componentDidMount() {
        this.pageTracker = AppInsightsService.startTrackPage("AttributeView");
        this.loadAttribute();
        if (!UserStore.userRole) UserStore.initUserRole();
        if (!AttributeRequestStore.fetchByUserOp) {
            AttributeRequestStore.getByUser("Get requests by user", true);
        }
    }

    @action
    loadAttribute = () => {
        if (!FunctionalGroupStore.isLoaded) FunctionalGroupStore.fetchAllFunctionalGroups(true);
        let criteria: AudienceCriteriaType;
        if (AttributeStore.data != null) {
            criteria = AttributeStore.data.find(i => i.Name === this.props.match.params.name && AttributeKind[i.AttributePropertiesKind] === this.props.match.params.kind);
            this.state.updateAttribute(criteria);
        } else {
            AttributeStore.fetch();
            const disposer = observe(AttributeStore, "data", (change) => {
                this.state.updateAttribute(change.newValue.find(i => i.Name === this.props.match.params.name && AttributeKind[i.AttributePropertiesKind] === this.props.match.params.kind));
                disposer();
            });
        }
    }

    render() {
        if (this.state.attribute == null) return <LoadingIndicator text="Loading..." />;
        else this.state.updateFormDisabled(this.state.attribute.ApprovalState !== 2);
        if (!FunctionalGroupStore.isLoaded || FunctionalGroupStore.isLoading || !AttributeRequestStore.fetchByUserOp || AttributeRequestStore.fetchByUserOp.inProgress || UserStore.isLoading) return <LoadingIndicator text="Loading..." />;

        if (this.pageTracker) this.pageTracker.stop();

        const saveDisabled = this.state.isSaving;
        const revisionsComponent = <RevisionList ref={(r) => this.revisions = r} name={this.state.attribute.Name} kind={this.state.attribute.AttributePropertiesKind} />;
        const attributeForm = <AttributeForm onChange={this.onChange} onSubmit={this.onSave} data={this.state.attribute} disabledOnView={this.state.formDisabled} readonly={true} renderChildrenByRow={true} revisionHistoryComponent={revisionsComponent}>
            <Button className={`ts-btn ${saveDisabled ? "ts-btn-disabled" : ""}`} type={"submit"} block onClick={null} style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }} disabled={saveDisabled}>{this.state.isSaving ? <CircularProgress size={"1.5rem"} color={"inherit"} /> : "Save"}</Button>
        </AttributeForm>;
        const queryString = this.props.location.search;
        const queryParams = parseQueryString.parse(queryString);
        let skipToRequests = false;
        if (queryParams.skipToRequests) {
            let skipToRequestsQueryVal;
            if (Array.isArray(queryParams.skipToRequests)) {
                skipToRequestsQueryVal = queryParams.skipToRequests[0];
            } else {
                skipToRequestsQueryVal = queryParams.skipToRequests as string;
            }

            skipToRequests = skipToRequestsQueryVal.toLowerCase() === "true";
        }

        if (!skipToRequests) {
            const actionableRequest = this.state.attribute.AddToCollectionRequests && this.state.attribute.AddToCollectionRequests.find(v => {
                return AttributeRequestStore.fetchByUserOp.data.find(r => r.Request.RequestId === v.RequestId && r.Request.State === ApprovalState.Submitted && r.Attribute.NameLower === this.state.attribute.NameLower && r.Attribute.AttributePropertiesKind === this.state.attribute.AttributePropertiesKind);
            });
            if (actionableRequest) {
                skipToRequests = true;
            }
        }

        return (
            <div>
                <AttributeWorkflow attributeForm={attributeForm} allowCreateOrModify={false} attribute={this.state.attribute} view={skipToRequests ? AttributeWorkflowView.Review : AttributeWorkflowView.View} onApproveReject={() => {
                    if (this.revisions) {
                        this.revisions.load();
                    }
                }} />
            </div>
        );
    }

    @action
    private onChange = (name: string[] | string, value: any, type: string) => {
        if (!Array.isArray(name)) {
            name = [name];
        }

        let rootObject = this.state.attribute;
        if (type === 'details') {
            rootObject = rootObject.AttributeProperties;
        }

        let bindObject;
        let previousPath;
        for (const path of name) {
            if (bindObject === undefined) {
                bindObject = rootObject;
            } else {
                // This must be during nested path evaluation
                if (!bindObject.hasOwnProperty(previousPath)) {
                    bindObject[previousPath] = {};
                }
                bindObject = bindObject[previousPath];
            }

            previousPath = path;
        }

        if (!bindObject) {
            // No attribute properties
            return;
        }

        if (value) {
            bindObject[previousPath] = value;
        } else {
            delete bindObject[previousPath];
        }

        this.state.updateCreateStatus(CreateStatus.UnsavedChanges);
    }

    @action
    private onSave = async (errors: string[], values: any) => {
        if (errors.length === 0) {
            this.state.updateIsSaving(true);
            AlertStore.clearAll();
            this.state.updateCreateStatus(CreateStatus.Saving);
            this.state.updateFormDisabled(true);

            const criteria = this.state.attribute;
            let response = null;
            let error = null;
            try {
                response = await CriteriaService.update(criteria);
                if (!response.success) {
                    error = response.errorMessage;
                }
            } catch (e) {
                error = e.toString();
            }

            runInAction(() => {
                this.state.updateIsSaving(false);
                if (response && response.success) {
                    this.state.updateCreateStatus(CreateStatus.Saved);
                    AttributeStore.fetch();
                    this.revisions.load();
                }
                else {
                    AlertStore.add(AlertType.Error, `Failed to save attribute.${error ? ` Message: ${error}` : ""}`);
                    this.state.updateCreateStatus(CreateStatus.UnsavedChanges);
                }
                this.state.updateFormDisabled(false);
            });
        }
    }
}

export default AppInsightsService.trackComponent(withRouter(AttributeView));