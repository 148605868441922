import React from "react";
import { observer } from "mobx-react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Paper, TableSortLabel, Checkbox } from "@material-ui/core";
import { PaginationOptions, PaginationState } from "./TablePagination";
import { SearchOptions, FilterOptions, FilterState, defaultRenderSearchFn, defaultRenderFilterFn, defaultSearchColFilterFn, defaultFilterFn } from "./TableSearchAndFilter";
import { SelectionOptions, SelectionState } from "./TableSelection";
import { SortOptions, SortState, defaultCompareFn } from "./TableSort";
import { TSCheckbox } from "../Checkbox/Checkbox";
import { computed } from "mobx";
import { computedFn } from "mobx-utils";

export type ColumnDataType = 'string' | 'number' | 'boolean' | 'datetime' | 'custom';

export type TableColumn = { label: string, field: string, type?: ColumnDataType, sortOptions?: SortOptions, filterOptions?: FilterOptions, renderDataFn?: (val: any, item: any, col: TableColumn) => React.ReactChild };

type AccessibleTableProps = {
    title: React.ReactChild;
    name: string;
    columns: TableColumn[];
    data: any[];
    paginationOptions?: PaginationOptions;
    searchOptions?: SearchOptions;
    selectionOptions?: SelectionOptions;
}

type AccessibleTableState = {
    paginationState: PaginationState;
    filterState: FilterState;
    sortState: SortState;
    selectionState: SelectionState;
}

@observer
export default class AccessbleTable extends React.Component<AccessibleTableProps, AccessibleTableState> {
    constructor(props) {
        super(props);
        this.state = {
            paginationState: new PaginationState(),
            filterState: new FilterState(),
            sortState: new SortState(),
            selectionState: new SelectionState(),
        };
    }

    componentDidMount() {
        const initialRowsPerPage = this.props?.paginationOptions?.initialRowsPerPage;
        if (initialRowsPerPage) {
            this.state.paginationState.setCurrentRowsPerPage(initialRowsPerPage);
        }
    }

    render() {
        if (!this.props.columns) return null;
        if (!this.props.data) return null;
        const filteredData = this.filterData(this.props.data);
        const sortedData = this.sortData(filteredData);
        const currentPageData = this.getPageData(sortedData);
        return <>
            <div className="table-overview">
                <div className="table-title">{this.props.title}</div>
                <div>
                    <span className="table-search">
                        {this.props.searchOptions?.enableSearch
                            ? (this.props.searchOptions?.renderSearchInputFn || defaultRenderSearchFn)(this.state.filterState.currentSearch, this.onSearchChange)
                            : null
                        }
                    </span>
                    <p className="table-results">
                        {this.props.data.length} total rows
                        <span aria-live="assertive">{(this.props.searchOptions?.enableSearch || this.props.columns.some(col => col.filterOptions?.filterable)) ? ` | ${filteredData.length} matching rows` : ''}</span>
                        <span aria-live="assertive">{this.props.selectionOptions?.enableSelection ? ` | ${this.state.selectionState.currentSelection.length} rows selected` : ''}</span>
                    </p>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table aria-label={this.props.name}>
                    <TableHead>
                        <TableRow>
                            {this.props.selectionOptions?.enableSelection && this.props.selectionOptions.selectionField
                                ? <TableCell padding="checkbox" className="col-item-select">
                                    <TSCheckbox
                                        checked={filteredData.length > 0 && this.state.selectionState.currentSelection.length === filteredData.length}
                                        onChange={this.onSelectionToggleAll}
                                        label=""
                                        aria-label="Select all items"
                                        id="select-all-items"
                                        value="all" />
                                </TableCell>
                                : null
                            }
                            {this.props.columns.map(col => {
                                const sort = this.state.sortState;
                                const active = sort.sortColumn === col.field;
                                const sortLabel = active ? (sort.sortDirection === 'asc' ? 'Sorted ascending' : 'Sorted descending') : 'Not sorted';
                                return <TableCell key={col.field}
                                    className={`${col.filterOptions?.filterable ? 'table-column-filterable' : ''} ${col.sortOptions?.sortable ? 'table-column-sortable' : ''} col-${col.field}`}
                                    sortDirection={sort.sortDirection}>
                                    {col.sortOptions?.sortable
                                        ? <TableSortLabel
                                            aria-label={`${col.label} - ${sortLabel}`}
                                            active={active}
                                            direction={sort.sortDirection || 'asc'}
                                            onClick={this.onSortingColumnSortChange}
                                            data-field={col.field}>
                                                {col.label}
                                            </TableSortLabel>
                                        : col.label
                                    }
                                    {col.filterOptions?.filterable
                                        ? <span className="table-column-filter-control">{this.renderFilterInput(col, this.props.data)}</span>
                                        : null
                                    }
                                </TableCell>;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentPageData.map((row, i) =>
                            <TableRow key={`row-${i}`}>
                                {this.props.selectionOptions?.enableSelection && this.props.selectionOptions.selectionField
                                    ? <TableCell padding="checkbox" className="col-item-select">
                                        <TSCheckbox
                                            checked={!!this.state.selectionState.currentSelection.find(val => val === row[this.props.selectionOptions.selectionField])}
                                            onChange={this.onSelectionToggleItem}
                                            label=""
                                            aria-label={`Select row ${row[this.props.selectionOptions.selectionField]}`}
                                            id={`select-row-${row[this.props.selectionOptions.selectionField]}`}
                                            value={row[this.props.selectionOptions.selectionField]} />
                                    </TableCell>
                                    : null
                                }
                                {this.props.columns.map(col =>
                                    <TableCell key={col.field} className={`col-${col.field}`}>
                                        {col.renderDataFn ? col.renderDataFn(row[col.field], row, col) : row[col.field]}
                                    </TableCell>)}
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            {this.props.paginationOptions?.paginateTable
                ? <TablePagination component={Paper} count={sortedData.length}
                    rowsPerPage={this.state.paginationState.currentRowsPerPage} onChangeRowsPerPage={this.onPaginationRowsPerPageChange}
                    page={this.state.paginationState.currentPage} onChangePage={this.onPaginationPageChange} />
                : null
            }
        </>;
    }

    private renderFilterInput(col: TableColumn, data: any[]): React.ReactChild {
        const renderFn = col.filterOptions?.renderFilterInputFn || defaultRenderFilterFn[col.filterOptions.filterType];
        const uniqueValues = Array.from(new Set(data.map(d => d[col.field])));
        return renderFn(col, this.state.filterState.currentFilters.get(col.field), this.onFilterChange, uniqueValues);
    }

    private filterData = (data: any[]) => {
        let result = data.slice();

        // Apply global search
        if (this.props.searchOptions?.enableSearch) {
            const searchRowFn = this.props.searchOptions.filterRowFn;
            const searchColFn = this.props.searchOptions.filterColFn || defaultSearchColFilterFn;
            if (searchRowFn) result = result.filter(row => searchRowFn(row, this.state.filterState.currentSearch));
            result = result.filter(row => this.props.columns.some(col => searchColFn(col, row[col.field], this.state.filterState.currentSearch)));
        }

        // Apply column filters
        const filterCols = this.props.columns.filter(col => col.filterOptions?.filterable && this.state.filterState.currentFilters.get(col.field));
        filterCols.forEach((col) => {
            const filterFn = col.filterOptions.filterFn || defaultFilterFn[col.filterOptions.filterType];
            result = result.filter(row => filterFn(row[col.field], this.state.filterState.currentFilters.get(col.field)));
        });

        return result;
    }

    private sortData = (data: any[]) => {
        if (this.state.sortState.sortColumn && this.state.sortState.sortDirection) {
            const sortCol = this.props.columns.filter(col => col.sortOptions?.sortable).find(col => col.field === this.state.sortState.sortColumn);
            if (sortCol) {
                const sortFn = sortCol.sortOptions?.compareFn || defaultCompareFn[sortCol.type || 'string'];
                const result = data.sort((a: any, b: any) => sortFn(a[sortCol.field], b[sortCol.field]));
                if (this.state.sortState.sortDirection === 'desc') {
                    return result.reverse();
                }
                return result;
            }
        }

        return data;
    }

    private getPageData = (data: any[]) => {
        if (this.props.paginationOptions?.paginateTable) {
            const start = this.state.paginationState.currentPage * this.state.paginationState.currentRowsPerPage;
            const end = start + this.state.paginationState.currentRowsPerPage;
            return data.slice(start, end);
        }

        return data;
    }

    private onPaginationRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = parseInt(event.target.value, 10);
        this.state.paginationState.setCurrentRowsPerPage(val);
    }

    private onPaginationPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, page: number) => {
        this.state.paginationState.setCurrentPage(page);
    }

    private onSortingColumnSortChange = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const column = this.props.columns.find(col => col.field === (event.target as Element).getAttribute('data-field'));
        this.state.sortState.setSortColumn(column.field);

        // Reset to first page
        this.state.paginationState.setCurrentPage(0);
    }

    private onSearchChange = (value: string) => {
        this.state.filterState.setCurrentSearch(value);

        // Reset to first page
        this.state.paginationState.setCurrentPage(0);
        // Reset selection
        this.state.selectionState.setSelection([]);
    }

    private onFilterChange = (field: string, value: string) => {
        this.state.filterState.setFilter(field, value);

        // Reset to first page
        this.state.paginationState.setCurrentPage(0);
        // Reset selection
        this.state.selectionState.setSelection([]);
    }

    private onSelectionToggleAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
            this.state.selectionState.setSelection(this.filterData(this.props.data).map(item => item[this.props.selectionOptions.selectionField]));
        }
        else {
            this.state.selectionState.setSelection([]);
        }

        this.notifySelectionChange();
    }

    private onSelectionToggleItem = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.state.selectionState.toggleSelection(this.props.data.find(item => item[this.props.selectionOptions.selectionField] === event.target.value)[this.props.selectionOptions.selectionField]);
        this.notifySelectionChange();
    }

    private notifySelectionChange() {
        if (this.props.selectionOptions.onSelectionChanged) {
            this.props.selectionOptions.onSelectionChanged(this.props.data.filter(item => this.state.selectionState.currentSelection.some(val => val === item[this.props.selectionOptions.selectionField])));
        }
    }
}