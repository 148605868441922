import { observable, action, computed } from "mobx";
import { AudienceCriteriaType, ApprovalState } from "ts-infra-common";

export class AttributeListState {
    @observable selectedCollection: string;
    @observable bulkAddInProgress: boolean = false;
    @observable checkedAttributes: AudienceCriteriaType[] = [];

    @computed
    get addToCollectionDisabled(): boolean {
        return this.notApprovedSelected !== 0 || this.selectedCollection == null || this.selectedCollection === "unselected";
    }

    @computed get notApprovedSelected(): number {
        return this.checkedAttributes.filter(a => parseInt(ApprovalState[a.ApprovalState], 10) !== parseInt(ApprovalState.Approved.toString(), 10)).length;
    }

    @action
    updateSelectedCollection(val: string) {
        this.selectedCollection = val;
    }

    @action
    setBulkAddInProgress(inProgress: boolean) {
        this.bulkAddInProgress = inProgress;
    }

    @action setCheckedAttributes = (values: AudienceCriteriaType[]) => {
        this.checkedAttributes = values;
    }
}